import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Drawer,
  Box,
  IconButton,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Divider,
  Pagination,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Container, height } from "@mui/system";
import axios from "axios";
import { CrmAgendaAsesorType, CrmAsesorType } from "../../types";
import "@fontsource/orbitron";

export const AgendaPage: React.FC<{}> = () => {
  const listadoTemp: CrmAgendaAsesorType[] = [];
  const [dataAgenda, setDataAgenda] =
    useState<CrmAgendaAsesorType[]>(listadoTemp);
  const listaAsesor: CrmAsesorType[] = [];
  const [dataAsesores, setDataAsesores] =
    useState<CrmAsesorType[]>(listaAsesor);
  const [selectedObject, setSelectedObject] =
    useState<CrmAgendaAsesorType | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAsesor, setSelectedAsesor] = useState<string>("");
  const [page, setPage] = useState(1);
  const [mostrarBusqueda, setMostrarBusqueda] = useState(false);
  const itemsPerPage = 4; // Número de cards por página
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getAsesores();
  }, []);

  const handleOpenDrawer = (object: CrmAgendaAsesorType) => {
    setSelectedObject(object);
    setIsDrawerOpen(true);
    setFechaCita(
      `${selectedAnoInicio}-${String(selectedMesInicio).padStart(
        2,
        "0"
      )}-${String(selectedDiaInicio).padStart(2, "0")}`
    );
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedObject(null);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1); // Reset page to 1 when search query changes
  };

  const filteredObjects = dataAgenda.filter((object) =>
    Object.values(object).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const paginatedObjects = filteredObjects.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const totalPages = Math.ceil(filteredObjects.length / itemsPerPage);

  async function getAgenda(_idAsesor: string) {
    try {
      await axios
        .get<CrmAgendaAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmagendaasesor/consultar/" +
            _idAsesor +
            "/" +
            String(selectedAnoInicio) +
            "-" +
            String(selectedMesInicio) +
            "-" +
            String(selectedDiaInicio) +
            "/" +
            String(selectedAnoFin) +
            "-" +
            String(selectedMesFin) +
            "-" +
            String(selectedDiaFin)
        )
        .then((response) => {
          console.log("mi agenda es => ", response.data);
          const transformedEvents = response.data.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          }));

          setDataAgenda(transformedEvents);
          transformedEvents.length > 0
            ? setMostrarBusqueda(true)
            : setMostrarBusqueda(false);
          //setDataAgenda(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getAsesores() {
    try {
      await axios
        .get<CrmAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/todo"
        )
        .then((response) => {
          setDataAsesores(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [asistio, setAsistio] = React.useState("");

  const handleChangeAsistio = (event: SelectChangeEvent) => {
    setAsistio(event.target.value as string);
  };

  const [selectedDateInicio, setSelectedDateInicio] = useState<string>("");
  const [selectedAnoInicio, setSelectedAnoInicio] = useState<number>(0);
  const [selectedMesInicio, setSelectedMesInicio] = useState<number>(0);
  const [selectedDiaInicio, setSelectedDiaInicio] = useState<number>(0);
  const [selectedDateFin, setSelectedDateFin] = useState<string>("");
  const [selectedAnoFin, setSelectedAnoFin] = useState<number>(0);
  const [selectedMesFin, setSelectedMesFin] = useState<number>(0);
  const [selectedDiaFin, setSelectedDiaFin] = useState<number>(0);

  const handleDateChangeInicio = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const dateValueInicio = event.target.value;
    setSelectedDateInicio(dateValueInicio);
    const dateInicio = new Date(dateValueInicio);
    // date.setDate(date.getDate() + 1);
    setSelectedAnoInicio(dateInicio.getFullYear());
    setSelectedMesInicio(dateInicio.getMonth() + 1);
    setSelectedDiaInicio(dateInicio.getDate() + 1);
    console.log(
      "año, mes y dia inicio >> ",
      selectedAnoInicio,
      selectedMesInicio,
      selectedDiaInicio
    );
  };

  const handleDateChangeFin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValueFin = event.target.value;
    setSelectedDateFin(dateValueFin);
    const dateFin = new Date(dateValueFin);
    // date.setDate(date.getDate() + 1);
    setSelectedAnoFin(dateFin.getFullYear());
    setSelectedMesFin(dateFin.getMonth() + 1);
    setSelectedDiaFin(dateFin.getDate() + 1);
    console.log(
      "año, mes y dia fin >> ",
      selectedAnoFin,
      selectedMesFin,
      selectedDiaFin
    );
  };

  const [fechaCita, setFechaCita] = useState<string>("");

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Grid alignItems={"center"} justifyContent={"center"}>
        <Grid item>
          <Paper
            sx={{
              padding: "1.2em",
              borderRadius: "0.5em",
              backgroundColor: "rgb(255,255,255,0.95)",
              mt: 2,
            }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Agenda
            </Typography>
          </Paper>
          <Paper
            sx={{
              padding: "1.2em",
              borderRadius: "0.5em",
              backgroundColor: "rgb(255,255,255,0.95)",
              mt: 1,
            }}
          >
            <Typography
              sx={{
                mt: 1,
                mb: 1,
                width: "7%",
                marginLeft: "32%",
              }}
              style={{ display: "inline-block" }}
            >
              Asesor:
            </Typography>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              margin="normal"
              value={selectedAsesor}
              onChange={(e) => setSelectedAsesor(e.target.value)}
              sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "30%" }}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  style: {
                    maxHeight: 450,
                  },
                },
              }}
            >
              {dataAsesores.map((option) => (
                <MenuItem key={option.ownerId} value={option.ownerId}>
                  {option.nombre}
                </MenuItem>
              ))}
            </TextField>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
                width: "7%",
                marginLeft: "32%",
              }}
              style={{ display: "inline-block" }}
            >
              F. Cita:
            </Typography>
            <TextField
              id="outlined-select-currency"
              type="date"
              size="small"
              margin="normal"
              onChange={handleDateChangeInicio}
              sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "14%" }}
            />
            <TextField
              id="outlined-select-currency"
              type="date"
              size="small"
              margin="normal"
              onChange={handleDateChangeFin}
              sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "14%" }}
            />
            <Button
              variant="contained"
              sx={{ ml: "45%", width: "10%", mt: 2 }}
              onClick={(e) => {
                getAgenda(selectedAsesor);
              }}
            >
              Consultar
            </Button>
            <Divider
              sx={{ marginLeft: "10%", marginRight: "10%", mt: 2, mb: 2 }}
            />
            {mostrarBusqueda ? (
              <TextField
                size="small"
                margin="normal"
                label="Buscar"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                sx={{ mt: 0.5, mb: 0.5, width: "37%", ml: "32%" }}
              />
            ) : (
              <></>
            )}

            <Grid sx={{ height: 570 }}>
              {paginatedObjects.length > 0 ? (
                <>
                  {paginatedObjects.map((object) => (
                    <Card
                      key={object.id}
                      sx={{ width: "100%", marginBottom: 2, display: "flex" }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            backgroundColor: "rgba(21, 57, 105, 0.75)",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              height: "100%",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>
                              {object.mesTexto}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Orbitron, sans-serif",
                                textAlign: "center",
                                fontSize: 26,
                              }}
                            >
                              {object.hora}:{object.minuto}
                            </Typography>
                            <Typography sx={{ textAlign: "center" }}>
                              Día {object.dia}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={11}>
                          <CardContent>
                            <Typography>
                              {object.cedula}
                              {" - "}
                              <strong>{object.cliente.toUpperCase()}</strong>
                            </Typography>
                            <Typography
                              sx={{ width: "60%" }}
                              style={{ display: "inline-block" }}
                              variant="body2"
                            >
                              <strong>Modelo: </strong>
                              {object.modelo}
                            </Typography>
                            <Button
                              sx={{
                                marginLeft: "25%",
                                width: "10%",
                                marginRight: "5%",
                              }}
                              // variant="contained"
                              color="primary"
                              onClick={() => handleOpenDrawer(object)}
                            >
                              Ver detalles
                            </Button>
                            <Typography
                              sx={{ width: "25%" }}
                              style={{ display: "inline-block" }}
                              variant="body2"
                            >
                              <strong>ID HubSpot: </strong>
                              {object.idCrm}
                            </Typography>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      width: "20%",
                      marginLeft: "40%",
                      mt: 10,
                      textAlign: "center",
                    }}
                  >
                    Lista de negocios vacio
                  </Typography>
                  <Button
                    //variant="contained"
                    sx={{ ml: "45%", width: "10%", mt: 2 }}
                    href="/ventas/negocio/crear"
                    //onClick={(e) => {getAgenda(selectedAsesor);}}
                  >
                    Crear nuevo
                  </Button>
                </>
              )}
            </Grid>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            />
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
              sx={{ width: "33%", flexShrink: 0 }}
              PaperProps={{ sx: { width: "33%" } }}
            >
              <Box sx={{ p: 2 }}>
                <IconButton onClick={handleCloseDrawer} sx={{ mb: 2 }}>
                  <CloseIcon />
                </IconButton>
                {selectedObject && (
                  <>
                    <Typography
                      sx={{ width: "80%", ml: "10%", mr: "10%" }}
                      style={{ display: "inline-block" }}
                      variant="body2"
                    ></Typography>
                    <Typography
                      sx={{
                        width: "90%",
                        marginLeft: "10%",
                        mt: 2,
                        mb: 2,
                        fontSize: 36,
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>{selectedObject.title}</strong>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 2, ml: "10%", mr: "10%" }} />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>ID CRM: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.idCrm}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Identificación: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.cedula}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cliente: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.cliente}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Teléfono: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.telefono}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Correo: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.correo}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Modelo: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.modelo}
                      sx={{ width: "63%" }}
                    />
                    <Typography
                      sx={{ width: "17%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cita: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      type="date"
                      value={fechaCita}
                      sx={{ width: "25%" }}
                    />
                    <Typography
                      sx={{ width: "8%", marginLeft: "9%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Hora: </strong>
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.hora}
                      sx={{ width: "9%", mr: "1%" }}
                    />
                    :
                    <TextField
                      size="small"
                      margin="normal"
                      variant="standard"
                      value={selectedObject.minuto}
                      sx={{ width: "9%", ml: "1%" }}
                    />
                    <Typography
                      sx={{ width: "18%", marginLeft: "10%", mt: 2 }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Asistio a la cita: </strong>
                    </Typography>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={asistio}
                      sx={{ width: "20%" }}
                      //label="Asistio"
                      onChange={handleChangeAsistio}
                    >
                      <MenuItem value={1}>Si</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </Select>
                    <Button
                      disabled={false}
                      variant="contained"
                      sx={{ ml: "40%", width: "20%", mt: 6, mb: 2 }}
                    >
                      Guardar
                    </Button>
                    <Button
                      target="_blank"
                      component="a"
                      href={
                        selectedObject.idMarca === 1
                          ? `https://app.hubspot.com/contacts/8008779/record/0-3/${selectedObject.idCrm}`
                          : selectedObject.idMarca === 2
                          ? `https://app.hubspot.com/contacts/9244718/record/0-3/${selectedObject.idCrm}`
                          : selectedObject.idMarca === 3
                          ? `https://app.hubspot.com/contacts/9269500/record/0-3/${selectedObject.idCrm}`
                          : ""
                      }
                      variant="contained"
                      sx={{ ml: "40%", width: "20%", mt: 2, mb: 2 }}
                    >
                      Ir al negocio
                    </Button>
                  </>
                )}
              </Box>
            </Drawer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
