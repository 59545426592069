import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { jsPDF } from "jspdf";
import logoCorp from "../../assets/logo_corp.png";
import qrImagen from "../../assets/qr_con_cli.png";
import imagenautorizacion from "../../assets/imgautorizacion.png";
import qrAutorizacion from "../../assets/pff_aut_fac.png";
import { useParams } from "react-router-dom";

type tablaRentaType = {
  tarId: number;
  tarFecha: string;
  tarNumeroCotizacion: string;
  tarAgencia: string;
  tarAsesor: string;
  tarCedulaCliente: string;
  tarNombreCliente: string;
  tarModelo: string;
  tarModeloAnio: number;
  tarColor: string;
  tarDiasInv: number;
  tarEscenarioFlota: string;
  tarEscenarioFlotaPorcentaje: number;
  tarFormaPago: string;
  tarAnticipos: number;
  tarPvpMarca: number;
  tarDealerNet: number;
  tarDealerNetValor: number;
  tarMargenBruto: number;
  tarMargenBrutoValor: number;
  tarDescCirular: number;
  tarDescCirularValor: number;
  tarDescConcecionario: number;
  tarDescConcecionarioValor: number;
  tarDescTotal: number;
  tarDescTotalValor: number;
  tarRebate: number;
  tarRebateValor: number;
  tarUtilidadBruta: number;
  tarUtilidadBrutaValor: number;
  tarTotalFacturar: number;
  tarNcConcesionario: number;
  tarNcMarca: number;
  tarValorObsequios: number;
  tarAccDescripcion: string;
  tarAccIncluyeFac: number;
  tarAccValor: number;
  tarDispDescripcion: string;
  tarDispIncluyeFac: number;
  tarDispValor: number;
  tarObservaciones: string;
  tarDestinatario: string;
  tarCorreo: string;
  tarCorreoRemitente: string;
  tarEnviado: number;
  tarNotificado: number;
  tarEstado: number;
  tarMantenimientosPrepa: number;

  fpropiosUsd: number;
  fpropiosPorce: number;
  fpropiosDetalle: string;
  ffinancieraUsd: number;
  ffinancieraPorce: number;
  ffinancieraDetalle: string;
  cinternoUsd: number;
  cinternoPorce: number;
  cinternoDetalle: string;
  vusadoUsd: number;
  vusadoPorce: number;
  vusadoDetalle: string;
  ftercerosUsd: number;
  ftercerosPorce: number;
  ftercerosDetalle: string;
  esBeneficiario: number;
  esPep1: number;
  esPep2: number;
  beneficiarioNombre: string;
  beneficiarioCi: string;
  beneficiarioSexo: string;
  beneficiarioPais: string;
  beneficiarioParentesco: string;
  pep1Cargo: string;
  pep1Fecha: string;
  pep2Nombre: string;
  pep2Cargo: string;
  pep2Fecha: string;

  estadoCivil: string;
  ciudad: string;
  correoCliente: string;
  telefonoCliente: string;
  direccionCliente: string;
  lugarTrabajo: string;
  sexoCliente: string;
  nombreConyuge: string;
  provincia: string;
  cargoCliente: string;
  direccionEmpresa: string;
  telefonoTrabajo: string;

  cliIngresos: number;
  cliEgresos: number;
  cliActivos: number;
  cliPasivos: number;
  patrimonio: number;
  actividadCliente: string;

  repre_cedula: string;
  repre_nombres: string;
  repre_sexo: string;
  repre_nacionalidad: string;
  repre_correo: string;
  repre_civil: string;
  repre_cedula_cony: string;
  repre_nombres_cony: string;

  repre_provincia: string;
  repre_ciudad: string;
  repre_telefono: string;
  repre_direccion: string;

  acc_cedula1: string;
  acc_nombres1: string;
  acc_participacion1: number;
  acc_nacionalidad1: string;
  acc_actividad1: string;
  acc_cargo1: string;
  acc_cedula2: string;
  acc_nombres2: string;
  acc_participacion2: number;
  acc_nacionalidad2: string;
  acc_actividad2: string;
  acc_cargo2: string;
  acc_cedula3: string;
  acc_nombres3: string;
  acc_participacion3: number;
  acc_nacionalidad3: string;
  acc_actividad3: string;
  acc_cargo3: string;

  es_juridica: number;
};

type autorizacionType = {
  tauId: number;
  tarId: number;
  tauEsOtroCanton: number;
  tauOtroCanton: string;
  tauFinanciera: string;

  tauSeguro: number;
  tauSeguroValor: number;
  tauSeguroDetalle: string;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function guardarAutorizacion(
  registro: autorizacionType,
  tabla: tablaRentaType
) {
  console.log("voy a imprimir", registro);
  const doc = new jsPDF();
  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();
  const margin_left = 18;
  const inicio_pdf = 50;

  const texto1 =
    "Declaro que los beneficios antes descritos me han sido presentados y explicados detalladamente por el asesor comercial y voluntariamente he escogido cada una de las opciones. Entiendo que la no contratación de cualquiera de estos elementos puede conllevar a gastos y/o pérdidas futuras, por lo cual libero al concesionario de cualquier reclamo futuro. Ratifico que, de no cumplir con el compromiso de pago acordado, en las fechas acordadas, asumiré los intereses, de acuerdo a la tasa vigente en el mercado, desde el primer día de la facturación.";
  const texto2 =
    "Autorizo expresamente a Corporación Proauto S.A a  retener el porcentaje equivalente al 5% de Precio de Venta del vehículo, por concepto de gastos administrativos, en el evento de solicitar la terminación del contrato de compraventa de un vehículo  o la anulación del negocio facturado. Renuncio, por tanto, a cualquier reclamo referente a tal concepto, en razón del derecho que me asiste previsto en el Art. 11 del Código Civil.";
  const texto3 =
    "Me encuentro en pleno conocimiento que los valores de matriculación del vehículo son de mi responsabilidad. Luego de facturado el vehículo y una vez se haya entregado al Concesionario todos los documentos necesarios para la matriculación del vehículo y una vez cancelado el valor total del vehículo; el concesionario se compromete a matricular la unidad en mínimo de 8 días  hábiles (excepto en la provincia de Pichincha, donde toma más de 10 días hábiles por nuevo impuesto de fondo vial). Es importante recalcar que los proceso de matriculación son asuntos legales ajenos a la gestión del concesionario. Los tiempos promedios del proceso detallados en el presente pueden variar dependiendo de la ubicación física de su vehículo, cumplimiento de los tiempos por parte del cliente, siempre y cuando no registre citaciones o valores pendiente con el Concesionario.";
  const texto4 =
    "Corporación Proauto S.A. se exime de responsabilidad por valores entregados a terceros o en cuentas no autorizadas para cobros. Solo se reciben pagos a cuentas registradas a nombre de Corporación Proauto S.A.";
  const texto5 =
    "Expresamente autorizamos la obtención y verificación en la Central de Riesgos y/o Burós de información crediticia, de todo tipo de información relacionada a la solicitud de crédito, liberando a los consultantes de cualquier responsabilidad civil, penal o de cualquier tipo por esta causa, facultándolos adicionalmente, a proveer a estas instituciones la información de nuestro comportamiento crediticio.";

  doc.setFont("helvetica", "bold");

  var logo = new Image();
  logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(logo, "PNG", margin_left, 10, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

  doc.setFontSize(10);
  doc.text("AUTORIZACIÓN DE FACTURACIÓN", docWidth / 2, 14, {
    align: "center",
  });
  doc.setFontSize(8);
  doc.text("Código", 170, 14);
  doc.text("RG-GCC-VC-01", 166, 19);
  doc.text("Versión: 1.0", 168, 23);
  doc.line(margin_left - 3, 25, docWidth - (margin_left - 3), 25);
  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.text("Nombre del cliente:", margin_left, 30);
  doc.text("Modelo del vehículo:", margin_left, 35);
  doc.text("No. Cédula/RUC:", margin_left, 40);
  doc.text("Color del vehículo:", margin_left, 45);
  doc.text("Matriculación:", margin_left, 50);
  doc.text("Forma de pago:", margin_left, 55);

  doc.text("Precio del Vehículo:", margin_left + 110, 30);
  doc.text("Descuento:", margin_left + 110, 35);
  doc.text("Accesorios:", margin_left + 110, 40);
  doc.text("15% IVA:", margin_left + 110, 45);
  doc.text("Total a facturar:", margin_left + 110, 50);
  doc.text("Financiera:", margin_left + 110, 55);

  doc.setFont("helvetica", "normal");

  doc.text(tabla.tarNombreCliente, margin_left + 35, 30);
  doc.text(tabla.tarModelo, margin_left + 35, 35);
  doc.text(tabla.tarCedulaCliente, margin_left + 35, 40);
  doc.text(tabla.tarColor, margin_left + 35, 45);
  doc.text(
    registro.tauEsOtroCanton === 1
      ? "Otro cantón: " + registro.tauOtroCanton
      : "Local",
    margin_left + 35,
    50
  );
  doc.text(tabla.tarFormaPago, margin_left + 35, 55);

  doc.text("$ " + Number(tabla.tarPvpMarca).toFixed(2), margin_left + 145, 30);
  doc.text(
    "$ " + Number(tabla.tarDescTotalValor).toFixed(2),
    margin_left + 145,
    35
  );
  doc.text("$ " + Number(tabla.tarAccValor).toFixed(2), margin_left + 145, 40);
  doc.text(
    "$ " +
      Number(Number(Number(tabla.tarTotalFacturar) * Number(0.12))).toFixed(2),
    margin_left + 145,
    45
  );
  doc.text(
    "$ " + Number(tabla.tarTotalFacturar).toFixed(2),
    margin_left + 145,
    50
  );
  doc.text(registro.tauFinanciera, margin_left + 145, 55);

  doc.line(margin_left - 3, 57, docWidth - (margin_left - 3), 57);
  //tabla 1
  doc.setFontSize(7);
  doc.setFont("helvetica", "bold");
  doc.text("FORMA DE PAGO", margin_left + 15, 60, { align: "center" });
  doc.text("VALORES", margin_left + 50, 60, { align: "center" });
  //doc.text("REFERENCIA RECIBO", margin_left + 105, 60, { align: "center" });
  // doc.text("FECHA COMPROMISO PAGO", margin_left + 160, 60, { align: "center" });
  doc.setFont("helvetica", "normal");

  //FORMA DE PAGO
  doc.text("Financiamiento", margin_left, 64);
  doc.text("Entrada/Anticipo", margin_left, 68);
  doc.text("Saldo", margin_left, 72);
  doc.text("Usado como parte de pago", margin_left, 76);
  doc.setFont("helvetica", "bold");
  doc.text("TOTAL", margin_left, 80);
  doc.text("Nota de crédito", margin_left, 84);
  doc.setFont("helvetica", "normal");

  //VALORES
  doc.text(
    "$ " + Number(tabla.ffinancieraUsd).toFixed(2),
    margin_left + 50,
    64,
    { align: "center" }
  );
  doc.text("$ " + Number(tabla.fpropiosUsd).toFixed(2), margin_left + 50, 68, {
    align: "center",
  });
  doc.text(
    "$ " +
      Number(Number(tabla.cinternoUsd) + Number(tabla.ftercerosUsd)).toFixed(2),
    margin_left + 50,
    72,
    { align: "center" }
  );
  doc.text("$ " + Number(tabla.vusadoUsd).toFixed(2), margin_left + 50, 76, {
    align: "center",
  });
  doc.setFont("helvetica", "bold");
  doc.text("$ " + Number(tabla.tarTotalFacturar), margin_left + 50, 80, {
    align: "center",
  });

  doc.text(
    "$ " +
      Number(
        Number(tabla.tarNcConcesionario) + Number(tabla.tarNcMarca)
      ).toFixed(2),
    margin_left + 50,
    84,
    { align: "center" }
  );
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 87, docWidth - (margin_left - 3), 87);

  //tabla 2

  doc.setFontSize(7);
  doc.setFont("helvetica", "bold");
  doc.text("NEGOCIO", margin_left + 8, 90, { align: "center" });
  doc.text("SI/NO", margin_left + 30, 90, { align: "center" });
  doc.text("VALOR", margin_left + 50, 90, { align: "center" });
  doc.text("DETALLE", margin_left + 125, 90, { align: "center" });
  //doc.text("NOTAS", margin_left + 160, 90, { align: "center" });
  doc.setFont("helvetica", "normal");

  //NEGOCIO
  doc.text("Disp. Satelital", margin_left, 94);
  doc.text("Seguro", margin_left, 98);
  doc.text("Acesorios", margin_left, 102);
  doc.text("Obsequio", margin_left, 106);

  //si - no
  doc.text(tabla.tarDispIncluyeFac === 0 ? "NO" : "SI", margin_left + 30, 94, {
    align: "center",
  });
  doc.text(registro.tauSeguroValor === 0 ? "NO" : "SI", margin_left + 30, 98, {
    align: "center",
  });
  doc.text(tabla.tarAccIncluyeFac === 0 ? "NO" : "SI", margin_left + 30, 102, {
    align: "center",
  });
  doc.text(tabla.tarValorObsequios === 0 ? "NO" : "SI", margin_left + 30, 106, {
    align: "center",
  });

  //valor
  doc.text(
    tabla.tarDispIncluyeFac === 0
      ? ""
      : "$ " + Number(tabla.tarDispValor).toFixed(2),
    margin_left + 50,
    94,
    { align: "center" }
  );
  doc.text(
    "$ " + Number(registro.tauSeguroValor).toFixed(2),
    margin_left + 50,
    98,
    { align: "center" }
  );
  doc.text(
    tabla.tarAccIncluyeFac === 0
      ? ""
      : "$ " + Number(tabla.tarAccValor).toFixed(2),
    margin_left + 50,
    102,
    { align: "center" }
  );
  doc.text(
    tabla.tarValorObsequios === 0
      ? ""
      : "$ " + Number(tabla.tarValorObsequios).toFixed(2),
    margin_left + 50,
    106,
    { align: "center" }
  );

  //detalle
  doc.text(tabla.tarDispDescripcion, margin_left + 125, 94, {
    align: "center",
  });
  doc.text(registro.tauSeguroDetalle, margin_left + 125, 98, {
    align: "center",
  });
  doc.text(tabla.tarAccDescripcion, margin_left + 125, 102, {
    align: "center",
  });

  doc.line(margin_left - 3, 110, docWidth - (margin_left - 3), 110);

  doc.setFont("helvetica", "bold");
  doc.text("PASOS DE LA ENTREGA PERFECTA", margin_left, 114);
  var imgautorizacio = new Image();
  imgautorizacio.src = imagenautorizacion; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(imgautorizacio, "PNG", margin_left, 118, 170, 60); // Agregar la imagen al PDF (X, Y, Width, Height)

  doc.setFontSize(8);
  doc.setFont("helvetica", "normal");
  doc.text(texto1, margin_left, 183, { maxWidth: 170, align: "justify" });
  doc.text(texto2, margin_left, 200, { maxWidth: 170, align: "justify" });
  doc.text(texto3, margin_left, 214, { maxWidth: 170, align: "justify" });
  doc.text(texto4, margin_left, 238, { maxWidth: 170, align: "justify" });
  doc.text(texto5, margin_left, 246, { maxWidth: 170, align: "justify" });

  doc.setFontSize(8);
  doc.line(30, 270, 70, 270);
  doc.line(90, 270, 130, 270);
  doc.text("CLIENTE: " + tabla.tarCedulaCliente, 50, 275, { align: "center" });
  doc.text(tabla.tarNombreCliente, 50, 280, { align: "center" });

  doc.text(tabla.tarAsesor, 110, 275, { align: "center" });
  doc.text("ASESOR COMERCIAL", 110, 280, { align: "center" });

  doc.setFontSize(7);
  doc.text("Página 1 de 1", 170, 271);
  doc.text("0110IXVI24", 173, 275);
  doc.text(new Date(Date.now()).toISOString().substring(0, 10), 173, 279);

  var qrAut = new Image();
  qrAut.src = qrAutorizacion; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(qrAut, "PNG", margin_left + 125, 256, 25, 25);

  doc.save("AUT_FAC_" + tabla.tarCedulaCliente + ".pdf");
}

function guardaPDF(
  _idTabla: number,
  _cedulaCli: string,
  _nombreCli: string,
  _modelo: string,
  _asesor: string,
  _vFacturar: number,

  fpropios_usd: number,
  fpropios_porce: number,
  fpropios_detalle: string,
  ffinanciera_usd: number,
  ffinanciera_porce: number,
  ffinanciera_detalle: string,
  cinterno_usd: number,
  cinterno_porce: number,
  cinterno_detalle: string,
  vusado_usd: number,
  vusado_porce: number,
  vusado_detalle: string,
  fterceros_usd: number,
  fterceros_porce: number,
  fterceros_detalle: string,
  es_beneficiario: number,
  es_pep1: number,
  es_pep2: number,
  beneficiario_nombre: string,
  beneficiario_ci: string,
  beneficiario_sexo: string,
  beneficiario_pais: string,
  beneficiario_parentesco: string,
  pep1_cargo: string,
  pep1_fecha: string,
  pep2_nombre: string,
  pep2_cargo: string,
  pep2_fecha: string,

  estadoCivil: string,
  ciudad: string,
  correoCliente: string,
  telefonoCliente: string,
  direccionCliente: string,
  lugarTrabajo: string,
  sexoCliente: string,
  nombreConyuge: string,
  provincia: string,
  cargoCliente: string,
  direccionEmpresa: string,
  telefonoTrabajo: string,

  cliIngresos: number,
  cliEgresos: number,
  cliActivos: number,
  cliPasivos: number,
  patrimonio: number,

  actividadCliente: string
) {
  const doc = new jsPDF();
  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();
  const margin_left = 20;
  const inicio_pdf = 50;

  //doc.addImage()

  doc.setFont("helvetica", "bold");

  var logo = new Image();
  logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

  doc.setFontSize(10);
  doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
  doc.text("FORMULARIO CONOZCA A SU CLIENTE", docWidth / 2, 19, {
    align: "center",
  });
  doc.text("PERSONA NATURAL", docWidth / 2, 24, { align: "center" });
  doc.setFontSize(8);
  doc.text("Código", 170, 19);
  doc.text("RG-GCC-VC-01", 166, 23);
  doc.text("Versión: 1.0", 168, 27);
  doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

  doc.line(margin_left - 3, 14, margin_left - 3, 29);
  doc.line(docWidth - (margin_left - 3), 14, docWidth - (margin_left - 3), 29);

  doc.line(margin_left - 3, 30, docWidth - (margin_left - 3), 30);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(
    "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
    margin_left,
    35,
    { maxWidth: 170, align: "justify" }
  );

  doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 30, margin_left - 3, 49);
  doc.line(docWidth - (margin_left - 3), 30, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 55, docWidth - (margin_left - 3), 55);
  doc.setFont("helvetica", "bold");
  doc.text("DATOS PERSONALES", docWidth / 2, 60, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("C.I.:/PASAPORTE/RUC:", margin_left, 65);
  doc.setFont("helvetica", "bold");
  doc.text(_cedulaCli, margin_left + 40, 65);
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRES Y APELLIDOS:", margin_left, 70);
  doc.setFont("helvetica", "bold");
  doc.text(_nombreCli, margin_left + 40, 70);
  doc.setFont("helvetica", "normal");
  doc.text("NACIONALIDAD:", margin_left, 75);
  doc.setFont("helvetica", "bold");
  doc.text("ECUATORIANA", margin_left + 40, 75);
  doc.setFont("helvetica", "normal");
  doc.text("SEXO:", margin_left + 110, 75);
  doc.setFont("helvetica", "bold");
  doc.text(sexoCliente, margin_left + 135, 75);
  doc.setFont("helvetica", "normal");
  doc.text("ESTADO CIVIL:", margin_left, 80);
  doc.setFont("helvetica", "bold");
  doc.text(estadoCivil, margin_left + 40, 80);
  doc.setFont("helvetica", "normal");
  doc.text("APELLIDOS Y NOMBRES DEL CÓNYUGE:", margin_left, 85);
  doc.setFont("helvetica", "bold");
  doc.text(nombreConyuge, margin_left + 60, 85);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 89, docWidth - (margin_left - 3), 89);

  doc.line(margin_left - 3, 55, margin_left - 3, 89);
  doc.line(docWidth - (margin_left - 3), 55, docWidth - (margin_left - 3), 89);

  doc.line(margin_left - 3, 90, docWidth - (margin_left - 3), 90);
  doc.setFont("helvetica", "bold");
  doc.text("INFORMACIÓN DEL DOMICILIO", docWidth / 2, 95, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("PROVINCIA:", margin_left, 100);
  doc.setFont("helvetica", "bold");
  doc.text(provincia, margin_left + 40, 100);
  doc.setFont("helvetica", "normal");
  doc.text("CIUDAD:", margin_left + 110, 100);
  doc.setFont("helvetica", "bold");
  doc.text(ciudad, margin_left + 135, 100);
  doc.setFont("helvetica", "normal");
  doc.text("DIRECCIÓN:", margin_left, 105);
  doc.setFont("helvetica", "bold");
  doc.text(direccionCliente, margin_left + 40, 105);
  doc.setFont("helvetica", "normal");
  doc.text("TELÉFONO/CELULAR:", margin_left, 110);
  doc.setFont("helvetica", "bold");
  doc.text(telefonoCliente, margin_left + 40, 110);
  doc.setFont("helvetica", "normal");
  doc.text("E-MAIL PERSONAL:", margin_left, 115);
  doc.setFont("helvetica", "bold");
  doc.text(correoCliente, margin_left + 40, 115);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 119, docWidth - (margin_left - 3), 119);

  doc.line(margin_left - 3, 90, margin_left - 3, 119);
  doc.line(docWidth - (margin_left - 3), 90, docWidth - (margin_left - 3), 119);

  doc.line(margin_left - 3, 120, docWidth - (margin_left - 3), 120);
  doc.setFont("helvetica", "bold");
  doc.text("INFORMACIÓN LABORAL", docWidth / 2, 125, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("LUGAR DE TRABAJO:", margin_left, 130);
  doc.setFont("helvetica", "bold");
  doc.text(lugarTrabajo, margin_left + 40, 130);
  doc.setFont("helvetica", "normal");
  doc.text("ACTIVIDAD ECONÓMICA:", margin_left, 135);
  doc.setFont("helvetica", "bold");
  doc.text(actividadCliente, margin_left + 40, 135);
  doc.setFont("helvetica", "normal");
  doc.text("CARGO:", margin_left, 140);
  doc.setFont("helvetica", "bold");
  doc.text(cargoCliente, margin_left + 40, 140);
  doc.setFont("helvetica", "normal");
  doc.text("PROVINCIA:", margin_left, 145);
  doc.setFont("helvetica", "bold");
  doc.text(provincia, margin_left + 40, 145);
  doc.setFont("helvetica", "normal");
  doc.text("CIUDAD:", margin_left + 110, 145);
  doc.setFont("helvetica", "bold");
  doc.text(ciudad, margin_left + 135, 145);
  doc.setFont("helvetica", "normal");
  doc.text("DIRECCIÓN:", margin_left, 150);
  doc.setFont("helvetica", "bold");
  doc.text(direccionEmpresa, margin_left + 40, 150);
  doc.setFont("helvetica", "normal");
  doc.text("E-MAIL EMPRESAR:", margin_left, 155);
  doc.setFont("helvetica", "bold");
  doc.text(correoCliente, margin_left + 40, 155);
  doc.setFont("helvetica", "normal");
  doc.text("TELÉFONO:", margin_left + 110, 155);
  doc.setFont("helvetica", "bold");
  doc.text(telefonoTrabajo, margin_left + 135, 155);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 159, docWidth - (margin_left - 3), 159);

  doc.line(margin_left - 3, 120, margin_left - 3, 159);
  doc.line(
    docWidth - (margin_left - 3),
    120,
    docWidth - (margin_left - 3),
    159
  );

  doc.line(margin_left - 3, 160, docWidth - (margin_left - 3), 160);
  doc.setFont("helvetica", "bold");
  doc.text("INFORMACIÓN ECONÓMICA", docWidth / 2, 165, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("Estimados mensuales:", margin_left, 170);
  doc.text("INGRESOS:", margin_left + 10, 175);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliIngresos), margin_left + 40, 175);
  doc.setFont("helvetica", "normal");
  doc.text("ACTIVOS:", margin_left + 110, 175);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliActivos), margin_left + 135, 175);
  doc.setFont("helvetica", "normal");
  doc.text("EGRESOS:", margin_left + 10, 180);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliEgresos), margin_left + 40, 180);
  doc.setFont("helvetica", "normal");
  doc.text("PASIVOS:", margin_left + 110, 180);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliPasivos), margin_left + 135, 180);
  doc.setFont("helvetica", "normal");
  doc.text("PATRIMONIO:", margin_left + 110, 185);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(patrimonio), margin_left + 135, 185);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 189, docWidth - (margin_left - 3), 189);

  doc.line(margin_left - 3, 160, margin_left - 3, 189);
  doc.line(
    docWidth - (margin_left - 3),
    160,
    docWidth - (margin_left - 3),
    189
  );

  doc.line(margin_left - 3, 190, docWidth - (margin_left - 3), 190);

  doc.text(
    "Declaración: Con pleno valor vinculante y consciente de las responsabilidades que ello genera, EL CLIENTE declara (n) bajo juramento que los recursos y fondos que emplea en sus operaciones y transacciones comerciales, así como aquellos que utilizó (aron) para pagar el valor de del bien o servicio, han tenido, tienen y tendrán fuente y origen lícito, y que no han provenido, provienen o provendrán de operaciones o actividades reprimidas por la ley, especialmente no de aquellas reprimidas por la legislación sobre sustancias estupefacientes y psicotrópicas",
    margin_left,
    195,
    { maxWidth: 170, align: "justify" }
  );
  doc.text("Los fondos de esta transacción proceden de:", margin_left, 215);
  doc.setFont("helvetica", "bold");
  doc.text("AHORROS TRABAJO / PERSONAL", margin_left + 70, 215);
  doc.setFont("helvetica", "normal");
  doc.text(
    "Los fondos de esta transacción serán usados para:",
    margin_left,
    220
  );
  doc.setFont("helvetica", "bold");
  doc.text(" COMPRA DE VEHÍCULO NUEVO", margin_left + 70, 220);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 224, docWidth - (margin_left - 3), 224);

  doc.line(margin_left - 3, 190, margin_left - 3, 224);
  doc.line(
    docWidth - (margin_left - 3),
    190,
    docWidth - (margin_left - 3),
    224
  );

  doc.line(margin_left - 3, 225, docWidth - (margin_left - 3), 225);
  doc.setFont("helvetica", "bold");
  doc.text("TRANSACCIÓN", docWidth / 2, 230, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("TIPO DE TRANSACCIÓN:", margin_left, 235);
  doc.setFont("helvetica", "bold");
  doc.text("COMPRA DE VEHÍCULO", margin_left + 40, 235);
  doc.setFont("helvetica", "normal");
  doc.text("MONEDA:", margin_left + 110, 235);
  doc.setFont("helvetica", "bold");
  doc.text("DOLAR", margin_left + 135, 235);
  doc.setFont("helvetica", "normal");
  doc.text("VALOR TRANSACCIÓN:", margin_left, 240);
  doc.setFont("helvetica", "bold");
  doc.text(String(_vFacturar), margin_left + 40, 240);
  doc.setFont("helvetica", "normal");
  doc.text("FECHA DE TRANSACCIÓN:", margin_left + 110, 240);
  doc.setFont("helvetica", "bold");
  doc.text(
    new Date(Date.now()).toISOString().substring(0, 10),
    margin_left + 150,
    240
  );
  doc.setFont("helvetica", "normal");
  doc.text("DOCUMENTO:", margin_left, 245);
  doc.setFont("helvetica", "bold");
  doc.text("TABLA DE RENTABILIDAD", margin_left + 40, 245);
  doc.setFont("helvetica", "normal");
  doc.text("N° DOCUMENTO:", margin_left + 110, 245);
  doc.setFont("helvetica", "bold");
  doc.text(String(_idTabla), margin_left + 135, 245);
  doc.setFont("helvetica", "normal");
  doc.text("DETALLE DEL PRODUCTO*:", margin_left, 250);
  doc.setFont("helvetica", "bold");
  doc.text(_modelo, margin_left + 40, 250);
  doc.setFont("helvetica", "normal");
  doc.text("VIGENCIA:", margin_left + 110, 250);
  doc.setFont("helvetica", "bold");
  doc.text("MENSUAL", margin_left + 135, 250);
  doc.setFont("helvetica", "normal");
  doc.text(
    "*Detalle del modelo del vehículo o el repuesto y/o servicio.",
    margin_left,
    255
  );

  doc.line(margin_left - 3, 259, docWidth - (margin_left - 3), 259);

  doc.line(margin_left - 3, 225, margin_left - 3, 259);
  doc.line(
    docWidth - (margin_left - 3),
    225,
    docWidth - (margin_left - 3),
    259
  );

  ///////////////////////// HOJA 2 ////////////////////////////////////
  doc.addPage("a4");
  doc.line(margin_left - 3, 15, docWidth - (margin_left - 3), 15);

  doc.setFont("helvetica", "bold");
  doc.text("PROCEDENCIA DE FONDOS", docWidth / 2, 18, { align: "center" });
  doc.text("FONDOS", margin_left, 23);
  doc.text("VALOR", margin_left + 60, 23);
  doc.text("% VALOR", margin_left + 90, 23);
  doc.text("DETALLE", margin_left + 130, 23);
  doc.setFont("helvetica", "normal");

  //FONDOS
  doc.text("FONDOS PROPIOS", margin_left, 27);
  doc.text("FONDOS FINANCIERA", margin_left, 31);
  doc.text("CREDITO INTERNO", margin_left, 35);
  doc.text("TOMA DE VEHÍCULO", margin_left, 39);
  doc.text("FONDOS DE TERCEROS", margin_left, 43);
  doc.setFont("helvetica", "bold");
  doc.text("TOTAL", margin_left, 47);
  doc.setFont("helvetica", "normal");

  //FONDOS
  doc.text(
    "$ " + String(Number(fpropios_usd).toFixed(2)),
    margin_left + 60,
    27
  );
  doc.text(
    "$ " + String(Number(ffinanciera_usd).toFixed(2)),
    margin_left + 60,
    31
  );
  doc.text(
    "$ " + String(Number(cinterno_usd).toFixed(2)),
    margin_left + 60,
    35
  );
  doc.text("$ " + String(vusado_usd), margin_left + 60, 39);
  doc.text("$ " + String(fterceros_usd), margin_left + 60, 43);
  doc.setFont("helvetica", "bold");
  doc.text(
    "$ " +
      String(
        Number(
          Number(fpropios_usd) +
            Number(ffinanciera_usd) +
            Number(cinterno_usd) +
            Number(vusado_usd) +
            Number(fterceros_usd)
        ).toFixed(2)
      ),
    margin_left + 60,
    47
  );
  doc.setFont("helvetica", "normal");

  //VALOR
  doc.text(String(fpropios_porce) + " %", margin_left + 90, 27);
  doc.text(String(ffinanciera_porce) + " %", margin_left + 90, 31);
  doc.text(String(cinterno_porce) + " %", margin_left + 90, 35);
  doc.text(String(vusado_porce) + " %", margin_left + 90, 39);
  doc.text(String(fterceros_porce) + " %", margin_left + 90, 43);
  doc.setFont("helvetica", "bold");
  doc.text("100 %", margin_left + 90, 47);
  doc.setFont("helvetica", "normal");

  //DETALLE
  doc.text(fpropios_detalle, margin_left + 130, 27);
  doc.text(ffinanciera_detalle, margin_left + 130, 31);
  doc.text(cinterno_detalle, margin_left + 130, 35);
  doc.text(vusado_detalle, margin_left + 130, 39);
  doc.text(fterceros_detalle, margin_left + 130, 43);

  doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 15, margin_left - 3, 49);
  doc.line(docWidth - (margin_left - 3), 15, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

  doc.setFont("helvetica", "bold");
  doc.text("BENEFICIARIO FINAL", docWidth / 2, 55, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text(
    "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también las personas naturales",
    margin_left,
    60,
    { maxWidth: 170, align: "justify" }
  );
  doc.text(
    "¿Es la usted el beneficiario final (propietario) del vehículo?",
    margin_left,
    70
  );
  doc.setFont("helvetica", "bold");
  doc.text(es_beneficiario === 1 ? "SI" : "NO", margin_left + 100, 70);
  doc.setFont("helvetica", "normal");
  doc.setFont("helvetica", "bold");
  doc.text(
    "Si coloco NO en la pregunta anterior por favor detalle los datos del beneficiario final (propietario) del vehículo:",
    margin_left,
    75
  );
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRE:", margin_left, 80);
  doc.text("N° IDENTIFICACIÓN:", margin_left + 100, 80);
  doc.text("SEXO:", margin_left, 85);
  doc.text("NACIONALIDAD:", margin_left + 100, 85);
  doc.text("PARENTESCO:", margin_left, 90);

  doc.setFont("helvetica", "bold");
  doc.text(beneficiario_nombre, margin_left + 30, 80);
  doc.text(beneficiario_ci, margin_left + 135, 80);
  doc.text(beneficiario_sexo, margin_left + 30, 85);
  doc.text(beneficiario_pais, margin_left + 135, 85);
  doc.text(beneficiario_parentesco, margin_left + 30, 90);

  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 94, docWidth - (margin_left - 3), 94);

  doc.line(margin_left - 3, 50, margin_left - 3, 94);
  doc.line(docWidth - (margin_left - 3), 50, docWidth - (margin_left - 3), 94);

  doc.line(margin_left - 3, 95, docWidth - (margin_left - 3), 95);
  doc.setFont("helvetica", "bold");
  doc.text(
    "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
    docWidth / 2,
    100,
    { align: "center" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "Yo, " +
      _nombreCli +
      " con documento de identificación Nº " +
      _cedulaCli +
      " declaro que ( " +
      (es_pep1 === 1 ? "SI" : "NO") +
      " ) soy una Persona Expuesta Políticamente (PEP'S).",
    margin_left,
    105,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "bold");
  doc.text(
    "Nota: De acuerdo con el Reglamento de la Ley Orgánica de Prevención Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, Personas Expuestas Políticamente (PEP´s) Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.",
    margin_left,
    115,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "En el caso que la respuesta sea positiva, indicar:",
    margin_left,
    135
  );
  doc.text("Cargo o Función:", margin_left, 140);
  doc.text("Fecha Nombramiento o Finalización:", margin_left, 145);

  doc.setFont("helvetica", "bold");
  doc.text(pep1_cargo, margin_left + 50, 140);
  doc.text(pep1_fecha, margin_left + 50, 145);

  doc.setFont("helvetica", "normal");

  doc.text(
    "Adicionalmente, declaro que ( " +
      (es_pep2 === 1 ? "SI" : "NO") +
      " ) mantengo algun tipo de relación Familiar y con Personas Relacionadas de las Personas Expuestas Políticamente:",
    margin_left,
    150,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "bold");
  doc.text(
    "Se consideran como familiares y personas relacionadas de los PEPS que tengan relaciones comerciales o contractuales con los sujetos obligados, al cónyuge o las personas unidas bajo el régimen de unión de hecho reconocido legalmente o, los familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad, o las personas con las cuales se encuentre asociada o vinculada societariamente, o sus colaboradores cercanos.",
    margin_left,
    160,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "En el caso que la respuesta sea afirmativa, indicar el tipo de relación:",
    margin_left,
    175
  );
  doc.text("Tipo de Relación con el Cliente:", margin_left, 180);
  doc.text("que mantiene con:", margin_left, 185);
  doc.text("Nombre:", margin_left, 190);
  doc.text("Cargo o Función:", margin_left, 195);
  doc.text("Fecha Nombramiento o Finalización:", margin_left, 200);

  doc.setFont("helvetica", "bold");
  doc.text("", margin_left + 50, 180);
  doc.text("", margin_left + 50, 185);
  doc.text(pep2_nombre, margin_left + 50, 190);
  doc.text(pep2_cargo, margin_left + 50, 195);
  doc.text(pep2_fecha, margin_left + 50, 200);

  doc.setFont("helvetica", "normal");
  doc.text(
    "Nota: La presente declaración no constituye una autoincriminación de ninguna clase, ni conlleva ninguna clase de responsabilidad administrativa, civil o penal. La empresa solicita la presente declaración en cumplimiento de la normativa vigente en Materia de Prevención, Detección, Erradicación del",
    margin_left,
    205,
    { maxWidth: 170, align: "justify" }
  );

  doc.line(margin_left - 3, 214, docWidth - (margin_left - 3), 214);

  doc.line(margin_left - 3, 95, margin_left - 3, 214);
  doc.line(docWidth - (margin_left - 3), 95, docWidth - (margin_left - 3), 214);

  doc.line(margin_left - 3, 215, docWidth - (margin_left - 3), 215);

  doc.setFontSize(6);
  doc.text(
    "En caso de que hubiese cambiado cualquier información de este formulario, me",
    margin_left,
    218
  );
  doc.text("comprometo en actualizarla.", margin_left, 221);
  doc.text(
    "Declaro que toda la información arriba detallada es verdadera.",
    margin_left,
    224
  );

  doc.line(margin_left, 235, margin_left + 80, 235);
  doc.setFontSize(8);
  doc.text("FIRMA DEL CLIENTE", margin_left + 10, 238);

  doc.setFont("helvetica", "bold");
  doc.text("USO EXCLUSIVO DE", margin_left + 115, 218);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(6);
  doc.text(
    "Certifico/certificamos que la firma que antecede concuerda con la registrada en",
    margin_left + 90,
    223
  );
  doc.text(
    "nuestros documentos y fue registrada en mi presencia.",
    margin_left + 90,
    226
  );
  //doc.line(margin_left + 90, 235, docWidth - margin_left, 235);
  doc.setFontSize(8);
  doc.text(_asesor, 148, 233, { align: "center" });
  doc.text("ASESOR", 148, 238, { align: "center" });

  doc.line(margin_left - 3, 240, docWidth - (margin_left - 3), 240);

  doc.line(margin_left - 3, 215, margin_left - 3, 240);
  doc.line(
    docWidth - (margin_left - 3),
    215,
    docWidth - (margin_left - 3),
    240
  );

  ///////////////////////// HOJA 3 ////////////////////////////////////

  doc.setFont("helvetica", "bold");
  doc.text("COPIA CÉDULA + PAPELETA VOTACIÓN", margin_left, 250);
  doc.text("PLANILLA SERVICIO BASICO", margin_left, 260);

  //ejemplo 45 es 255
  //doc.line(inicia, altura, termina, altura);

  //horizontales
  doc.line(margin_left + 65, 246, margin_left + 70, 246);
  doc.line(margin_left + 65, 251, margin_left + 70, 251);

  doc.line(margin_left + 65, 256, margin_left + 70, 256);
  doc.line(margin_left + 65, 261, margin_left + 70, 261);

  //verticales
  doc.line(margin_left + 65, 246, margin_left + 65, 251);
  doc.line(margin_left + 70, 246, margin_left + 70, 251);

  doc.line(margin_left + 65, 256, margin_left + 65, 261);
  doc.line(margin_left + 70, 256, margin_left + 70, 261);

  doc.text("ANEXO A LA HOJA DE NEGOCIO N°", margin_left + 85, 250);
  doc.text(
    "F.I.: " + new Date(Date.now()).toISOString().substring(0, 10),
    margin_left + 85,
    260
  );

  //let base64Image = ('#qr_code img').attr('src');

  //doc.addImage(base64Image, 'png', 0, 0, 40, 40);

  doc.text("Página 2 de 2", 168, 270);
  doc.text("0110IXVI24", 171, 275);

  var qrImg = new Image();
  qrImg.src = qrImagen; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(qrImg, "PNG", margin_left + 145, 241, 25, 25); // Agregar la imagen al PDF (X, Y, Width, Height)

  // doc.save(`${data && data.cedulaCliente}` + ".pdf");
  doc.save("CON_CLI_" + _cedulaCli + ".pdf");
}

function guardaPDF_Juridica(
  _idTabla: number,
  _cedulaCli: string,
  _nombreCli: string,
  _modelo: string,
  _asesor: string,
  _vFacturar: number,

  fpropios_usd: number,
  fpropios_porce: number,
  fpropios_detalle: string,
  ffinanciera_usd: number,
  ffinanciera_porce: number,
  ffinanciera_detalle: string,
  cinterno_usd: number,
  cinterno_porce: number,
  cinterno_detalle: string,
  vusado_usd: number,
  vusado_porce: number,
  vusado_detalle: string,
  fterceros_usd: number,
  fterceros_porce: number,
  fterceros_detalle: string,
  es_beneficiario: number,
  es_pep1: number,
  es_pep2: number,
  beneficiario_nombre: string,
  beneficiario_ci: string,
  beneficiario_sexo: string,
  beneficiario_pais: string,
  beneficiario_parentesco: string,
  pep1_cargo: string,
  pep1_fecha: string,
  pep2_nombre: string,
  pep2_cargo: string,
  pep2_fecha: string,

  estadoCivil: string,
  ciudad: string,
  correoCliente: string,
  telefonoCliente: string,
  direccionCliente: string,
  lugarTrabajo: string,
  sexoCliente: string,
  nombreConyuge: string,
  provincia: string,
  cargoCliente: string,
  direccionEmpresa: string,
  telefonoTrabajo: string,

  cliIngresos: number,
  cliEgresos: number,
  cliActivos: number,
  cliPasivos: number,
  patrimonio: number,

  actividadCliente: string,

  repre_cedula: string,
  repre_nombres: string,
  repre_sexo: string,
  repre_nacionalidad: string,
  repre_correo: string,
  repre_civil: string,
  repre_cedula_cony: string,
  repre_nombres_cony: string,

  repre_provincia: string,
  repre_ciudad: string,
  repre_telefono: string,
  repre_direccion: string,

  acc_cedula1: string,
  acc_nombres1: string,
  acc_participacion1: number,
  acc_nacionalidad1: string,
  acc_actividad1: string,
  acc_cargo1: string,
  acc_cedula2: string,
  acc_nombres2: string,
  acc_participacion2: number,
  acc_nacionalidad2: string,
  acc_actividad2: string,
  acc_cargo2: string,
  acc_cedula3: string,
  acc_nombres3: string,
  acc_participacion3: number,
  acc_nacionalidad3: string,
  acc_actividad3: string,
  acc_cargo3: string,

  es_juridica: number
) {
  const doc = new jsPDF();
  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();
  const margin_left = 20;
  const inicio_pdf = 50;

  //doc.addImage()

  doc.setFont("helvetica", "bold");

  var logo = new Image();
  logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

  doc.setFontSize(10);
  doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
  doc.text("FORMULARIO CONOZCA A SU CLIENTE", docWidth / 2, 19, {
    align: "center",
  });
  doc.text("PERSONA JURIDICA", docWidth / 2, 24, { align: "center" });
  doc.setFontSize(8);
  doc.text("Código", 170, 19);
  doc.text("RG-GCC-VC-01", 166, 23);
  doc.text("Versión: 1.0", 168, 27);
  doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

  doc.line(margin_left - 3, 14, margin_left - 3, 29);
  doc.line(docWidth - (margin_left - 3), 14, docWidth - (margin_left - 3), 29);

  doc.line(margin_left - 3, 30, docWidth - (margin_left - 3), 30);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(
    "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
    margin_left,
    35,
    { maxWidth: 170, align: "justify" }
  );

  doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 30, margin_left - 3, 49);
  doc.line(docWidth - (margin_left - 3), 30, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 55, docWidth - (margin_left - 3), 55);
  doc.setFont("helvetica", "bold");
  doc.text("DATOS DE LA EMPRESA", docWidth / 2, 60, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("RUC:", margin_left, 65);
  doc.setFont("helvetica", "bold");
  doc.text(_cedulaCli, margin_left + 40, 65);
  doc.setFont("helvetica", "normal");
  doc.text("NACIONALIDAD:", margin_left + 110, 65);
  doc.setFont("helvetica", "bold");
  doc.text("ECUADOR", margin_left + 135, 65);
  doc.setFont("helvetica", "normal");
  doc.text("RAZON SOCIAL:", margin_left, 70);
  doc.setFont("helvetica", "bold");
  doc.text(_nombreCli, margin_left + 40, 70);
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRE COMERCIAL:", margin_left, 75);
  doc.setFont("helvetica", "bold");
  doc.text(_nombreCli, margin_left + 40, 75);
  doc.setFont("helvetica", "normal");
  doc.text("ACTIVIDAD ECONOMICA:", margin_left, 80);
  doc.setFont("helvetica", "bold");
  doc.text(actividadCliente, margin_left + 40, 80);
  doc.setFont("helvetica", "normal");
  doc.text("CORREO ELECTRONICO:", margin_left, 85);
  doc.setFont("helvetica", "bold");
  doc.text(correoCliente, margin_left + 40, 85);
  doc.setFont("helvetica", "normal");
  doc.text("PROVINCIA:", margin_left, 90);
  doc.setFont("helvetica", "bold");
  doc.text(provincia, margin_left + 40, 90);
  doc.setFont("helvetica", "normal");
  doc.text("CIUDAD:", margin_left, 95);
  doc.setFont("helvetica", "bold");
  doc.text(ciudad, margin_left + 40, 95);
  doc.setFont("helvetica", "normal");
  doc.text("TELÉFONOS:", margin_left + 110, 95);
  doc.setFont("helvetica", "bold");
  doc.text(telefonoCliente, margin_left + 135, 95);
  doc.setFont("helvetica", "normal");
  doc.text("DIRECCIÓN:", margin_left, 100);
  doc.setFont("helvetica", "bold");
  doc.text(direccionCliente, margin_left + 40, 100);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 104, docWidth - (margin_left - 3), 104);

  doc.line(margin_left - 3, 55, margin_left - 3, 104);
  doc.line(docWidth - (margin_left - 3), 55, docWidth - (margin_left - 3), 104);

  doc.line(margin_left - 3, 105, docWidth - (margin_left - 3), 105);
  doc.setFont("helvetica", "bold");
  doc.text("INFORMACIÓN DEL PRESENTANTE LEGAL", docWidth / 2, 110, {
    align: "center",
  });
  doc.setFont("helvetica", "normal");
  doc.text("CI / PASAPORTE:", margin_left, 115);
  doc.setFont("helvetica", "bold");
  doc.text(repre_cedula, margin_left + 40, 115);
  doc.setFont("helvetica", "normal");
  doc.text("NACIONALIDAD", margin_left + 110, 115);
  doc.setFont("helvetica", "bold");
  doc.text(repre_nacionalidad, margin_left + 135, 115);
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRES:", margin_left, 120);
  doc.setFont("helvetica", "bold");
  doc.text(repre_nombres, margin_left + 40, 120);
  doc.setFont("helvetica", "normal");
  doc.text("CORREO ELECTRÓNICO:", margin_left, 125);
  doc.setFont("helvetica", "bold");
  doc.text(repre_correo, margin_left + 40, 125);
  doc.setFont("helvetica", "normal");
  doc.text("ESTADO CIVÍL:", margin_left, 130);
  doc.setFont("helvetica", "bold");
  doc.text(repre_civil, margin_left + 40, 130);
  doc.setFont("helvetica", "normal");
  doc.text("SEXO", margin_left + 110, 130);
  doc.setFont("helvetica", "bold");
  doc.text(sexoCliente, margin_left + 135, 130);
  doc.setFont("helvetica", "normal");
  doc.text("CI/PASAPORTE CONYUGE:", margin_left, 135);
  doc.setFont("helvetica", "bold");
  doc.text(repre_cedula_cony, margin_left + 40, 135);
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRE CONYUGE:", margin_left, 140);
  doc.setFont("helvetica", "bold");
  doc.text(repre_cedula_cony, margin_left + 40, 140);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 144, docWidth - (margin_left - 3), 144);

  doc.line(margin_left - 3, 105, margin_left - 3, 144);
  doc.line(
    docWidth - (margin_left - 3),
    105,
    docWidth - (margin_left - 3),
    144
  );

  doc.line(margin_left - 3, 145, docWidth - (margin_left - 3), 145);
  doc.setFont("helvetica", "bold");
  doc.text("DOMICILIO DEL REPRESENTANTE LEGAL", docWidth / 2, 150, {
    align: "center",
  });
  doc.setFont("helvetica", "normal");
  doc.text("PROVINCIA:", margin_left, 155);
  doc.setFont("helvetica", "bold");
  doc.text(repre_provincia, margin_left + 40, 155);
  doc.setFont("helvetica", "normal");
  doc.text("CIUDAD:", margin_left, 160);
  doc.setFont("helvetica", "bold");
  doc.text(repre_ciudad, margin_left + 40, 160);
  doc.setFont("helvetica", "normal");
  doc.text("TELÉFONO:", margin_left + 110, 160);
  doc.setFont("helvetica", "bold");
  doc.text(repre_telefono, margin_left + 135, 160);
  doc.setFont("helvetica", "normal");
  doc.text("DIRECCIÓN:", margin_left, 165);
  doc.setFont("helvetica", "bold");
  doc.text(repre_direccion, margin_left + 40, 165);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 169, docWidth - (margin_left - 3), 169);

  doc.line(margin_left - 3, 145, margin_left - 3, 169);
  doc.line(
    docWidth - (margin_left - 3),
    145,
    docWidth - (margin_left - 3),
    169
  );

  doc.line(margin_left - 3, 170, docWidth - (margin_left - 3), 170);
  doc.setFont("helvetica", "bold");
  doc.text("INFORMACIÓN ECONÓMICA", docWidth / 2, 175, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("Estimados mensuales:", margin_left, 180);
  doc.text("INGRESOS:", margin_left + 10, 185);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliIngresos), margin_left + 40, 185);
  doc.setFont("helvetica", "normal");
  doc.text("ACTIVOS:", margin_left + 110, 185);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliActivos), margin_left + 135, 185);
  doc.setFont("helvetica", "normal");
  doc.text("EGRESOS:", margin_left + 10, 190);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliEgresos), margin_left + 40, 190);
  doc.setFont("helvetica", "normal");
  doc.text("PASIVOS:", margin_left + 110, 190);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(cliPasivos), margin_left + 135, 190);
  doc.setFont("helvetica", "normal");
  doc.text("PATRIMONIO:", margin_left + 110, 195);
  doc.setFont("helvetica", "bold");
  doc.text("$ " + String(patrimonio), margin_left + 135, 195);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 199, docWidth - (margin_left - 3), 199);

  doc.line(margin_left - 3, 170, margin_left - 3, 199);
  doc.line(
    docWidth - (margin_left - 3),
    170,
    docWidth - (margin_left - 3),
    199
  );

  doc.line(margin_left - 3, 200, docWidth - (margin_left - 3), 200);

  doc.setFont("helvetica", "bold");
  doc.text("ACCIONISTAS Y/O APODERADOS", docWidth / 2, 203, {
    align: "center",
  });
  doc.setFontSize(6);
  // cabecera
  doc.text("CI / RUC", margin_left, 208);
  doc.text("NOMBRES", margin_left + 20, 208);
  doc.text("%PARTICIP", margin_left + 70, 208);
  doc.text("NACIONALIDAD", margin_left + 95, 208);
  doc.text("ACTIVIDAD ECONÓMICA", margin_left + 115, 208);
  doc.text("CARGO", margin_left + 150, 208);
  doc.setFont("helvetica", "normal");

  //CI / RUC
  doc.text(acc_cedula1, margin_left, 212);
  doc.text(acc_cedula2, margin_left, 216);
  doc.text(acc_cedula3, margin_left, 220);

  //NOMBRES
  doc.text(acc_nombres1, margin_left + 20, 212);
  doc.text(acc_nombres2, margin_left + 20, 216);
  doc.text(acc_nombres3, margin_left + 20, 220);

  //PARTICIPACION
  doc.text(String(acc_participacion1) + " %", margin_left + 70, 212);
  doc.text(String(acc_participacion2) + " %", margin_left + 70, 216);
  doc.text(String(acc_participacion3) + " %", margin_left + 70, 220);

  //NACIONALIDAD
  doc.text(acc_nacionalidad1, margin_left + 95, 212);
  doc.text(acc_nacionalidad2, margin_left + 95, 216);
  doc.text(acc_nacionalidad3, margin_left + 95, 220);

  //ACTIVIDAD
  doc.text(acc_actividad1, margin_left + 115, 212);
  doc.text(acc_actividad2, margin_left + 115, 216);
  doc.text(acc_actividad3, margin_left + 115, 220);

  //CARGO
  doc.text(acc_cargo1, margin_left + 150, 212);
  doc.text(acc_cargo2, margin_left + 150, 216);
  doc.text(acc_cargo3, margin_left + 150, 220);

  doc.line(margin_left - 3, 222, docWidth - (margin_left - 3), 222);
  doc.line(margin_left - 3, 200, margin_left - 3, 222);
  doc.line(
    docWidth - (margin_left - 3),
    200,
    docWidth - (margin_left - 3),
    222
  );

  /////

  doc.line(margin_left - 3, 223, docWidth - (margin_left - 3), 223);
  doc.text(
    "Declaración: Con pleno valor vinculante y consciente de las responsabilidades que ello genera, EL CLIENTE declara (n) bajo juramento que los recursos y fondos que emplea en sus operaciones y transacciones comerciales, así como aquellos que utilizó (aron) para pagar el valor de del bien o servicio, han tenido, tienen y tendrán fuente y origen lícito, y que no han provenido, provienen o provendrán de operaciones o actividades reprimidas por la ley, especialmente no de aquellas reprimidas por la legislación sobre sustancias estupefacientes y psicotrópicas",
    margin_left,
    226,
    { maxWidth: 170, align: "justify" }
  );

  doc.setFontSize(8);
  doc.text("Los fondos de esta transacción proceden de:", margin_left, 243);
  doc.setFont("helvetica", "bold");
  doc.text("AHORROS TRABAJO / PERSONAL", margin_left + 70, 243);
  doc.setFont("helvetica", "normal");
  doc.text(
    "Los fondos de esta transacción serán usados para:",
    margin_left,
    247
  );
  doc.setFont("helvetica", "bold");
  doc.text(" COMPRA DE VEHÍCULO NUEVO", margin_left + 70, 247);
  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 250, docWidth - (margin_left - 3), 250);

  doc.line(margin_left - 3, 223, margin_left - 3, 250);
  doc.line(
    docWidth - (margin_left - 3),
    223,
    docWidth - (margin_left - 3),
    250
  );

  doc.line(margin_left - 3, 251, docWidth - (margin_left - 3), 251);
  doc.setFont("helvetica", "bold");
  doc.text("TRANSACCIÓN", docWidth / 2, 254, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("TIPO DE TRANSACCIÓN:", margin_left, 258);
  doc.setFont("helvetica", "bold");
  doc.text("COMPRA DE VEHÍCULO", margin_left + 40, 258);
  doc.setFont("helvetica", "normal");
  doc.text("MONEDA:", margin_left + 110, 258);
  doc.setFont("helvetica", "bold");
  doc.text("DOLAR", margin_left + 135, 258);
  doc.setFont("helvetica", "normal");
  doc.text("VALOR TRANSACCIÓN:", margin_left, 262);
  doc.setFont("helvetica", "bold");
  doc.text(String(_vFacturar), margin_left + 40, 262);
  doc.setFont("helvetica", "normal");
  doc.text("FECHA DE TRANSACCIÓN:", margin_left + 110, 262);
  doc.setFont("helvetica", "bold");
  doc.text(
    new Date(Date.now()).toISOString().substring(0, 10),
    margin_left + 150,
    262
  );
  doc.setFont("helvetica", "normal");
  doc.text("DOCUMENTO:", margin_left, 266);
  doc.setFont("helvetica", "bold");
  doc.text("TABLA DE RENTABILIDAD", margin_left + 40, 266);
  doc.setFont("helvetica", "normal");
  doc.text("N° DOCUMENTO:", margin_left + 110, 266);
  doc.setFont("helvetica", "bold");
  doc.text(String(_idTabla), margin_left + 135, 266);
  doc.setFont("helvetica", "normal");
  doc.text("DETALLE DEL PRODUCTO*:", margin_left, 270);
  doc.setFont("helvetica", "bold");
  doc.text(_modelo, margin_left + 40, 270);
  doc.setFont("helvetica", "normal");
  doc.text("VIGENCIA:", margin_left + 110, 270);
  doc.setFont("helvetica", "bold");
  doc.text("MENSUAL", margin_left + 135, 270);
  doc.setFont("helvetica", "normal");
  doc.text(
    "*Detalle del modelo del vehículo o el repuesto y/o servicio.",
    margin_left,
    274
  );

  doc.line(margin_left - 3, 277, docWidth - (margin_left - 3), 277);

  doc.line(margin_left - 3, 251, margin_left - 3, 277);
  doc.line(
    docWidth - (margin_left - 3),
    251,
    docWidth - (margin_left - 3),
    277
  );

  ///////////////////////// HOJA 2 ////////////////////////////////////
  doc.addPage("a4");
  doc.line(margin_left - 3, 15, docWidth - (margin_left - 3), 15);

  doc.setFont("helvetica", "bold");
  doc.text("PROCEDENCIA DE FONDOS", docWidth / 2, 18, { align: "center" });
  doc.text("FONDOS", margin_left, 23);
  doc.text("VALOR", margin_left + 60, 23);
  doc.text("% VALOR", margin_left + 90, 23);
  doc.text("DETALLE", margin_left + 130, 23);
  doc.setFont("helvetica", "normal");

  //FONDOS
  doc.text("FONDOS PROPIOS", margin_left, 27);
  doc.text("FONDOS FINANCIERA", margin_left, 31);
  doc.text("CREDITO INTERNO", margin_left, 35);
  doc.text("TOMA DE VEHÍCULO", margin_left, 39);
  doc.text("FONDOS DE TERCEROS", margin_left, 43);
  doc.setFont("helvetica", "bold");
  doc.text("TOTAL", margin_left, 47);
  doc.setFont("helvetica", "normal");

  //FONDOS
  doc.text(
    "$ " + String(Number(fpropios_usd).toFixed(2)),
    margin_left + 60,
    27
  );
  doc.text(
    "$ " + String(Number(ffinanciera_usd).toFixed(2)),
    margin_left + 60,
    31
  );
  doc.text(
    "$ " + String(Number(cinterno_usd).toFixed(2)),
    margin_left + 60,
    35
  );
  doc.text("$ " + String(vusado_usd), margin_left + 60, 39);
  doc.text("$ " + String(fterceros_usd), margin_left + 60, 43);
  doc.setFont("helvetica", "bold");
  doc.text(
    "$ " +
      String(
        Number(fpropios_usd) +
          Number(ffinanciera_usd) +
          Number(cinterno_usd) +
          Number(vusado_usd) +
          Number(fterceros_usd)
      ),
    margin_left + 60,
    47
  );
  doc.setFont("helvetica", "normal");

  //VALOR
  doc.text(String(fpropios_porce) + " %", margin_left + 90, 27);
  doc.text(String(ffinanciera_porce) + " %", margin_left + 90, 31);
  doc.text(String(cinterno_porce) + " %", margin_left + 90, 35);
  doc.text(String(vusado_porce) + " %", margin_left + 90, 39);
  doc.text(String(fterceros_porce) + " %", margin_left + 90, 43);
  doc.setFont("helvetica", "bold");
  doc.text("100 %", margin_left + 90, 47);
  doc.setFont("helvetica", "normal");

  //DETALLE
  doc.text(fpropios_detalle, margin_left + 130, 27);
  doc.text(ffinanciera_detalle, margin_left + 130, 31);
  doc.text(cinterno_detalle, margin_left + 130, 35);
  doc.text(vusado_detalle, margin_left + 130, 39);
  doc.text(fterceros_detalle, margin_left + 130, 43);

  doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 15, margin_left - 3, 49);
  doc.line(docWidth - (margin_left - 3), 15, docWidth - (margin_left - 3), 49);

  doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

  doc.setFont("helvetica", "bold");
  doc.text("BENEFICIARIO FINAL", docWidth / 2, 55, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text(
    "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también las personas naturales",
    margin_left,
    60,
    { maxWidth: 170, align: "justify" }
  );
  doc.text(
    "¿Es la usted el beneficiario final (propietario) del vehículo?",
    margin_left,
    70
  );
  doc.setFont("helvetica", "bold");
  doc.text(es_beneficiario === 1 ? "SI" : "NO", margin_left + 100, 70);
  doc.setFont("helvetica", "normal");
  doc.setFont("helvetica", "bold");
  doc.text(
    "Si coloco NO en la pregunta anterior por favor detalle los datos del beneficiario final (propietario) del vehículo:",
    margin_left,
    75
  );
  doc.setFont("helvetica", "normal");
  doc.text("NOMBRE:", margin_left, 80);
  doc.text("N° IDENTIFICACIÓN:", margin_left + 100, 80);
  doc.text("SEXO:", margin_left, 85);
  doc.text("NACIONALIDAD:", margin_left + 100, 85);
  doc.text("PARENTESCO:", margin_left, 90);

  doc.setFont("helvetica", "bold");
  doc.text(beneficiario_nombre, margin_left + 30, 80);
  doc.text(beneficiario_ci, margin_left + 135, 80);
  doc.text(beneficiario_sexo, margin_left + 30, 85);
  doc.text(beneficiario_pais, margin_left + 135, 85);
  doc.text(beneficiario_parentesco, margin_left + 30, 90);

  doc.setFont("helvetica", "normal");

  doc.line(margin_left - 3, 94, docWidth - (margin_left - 3), 94);

  doc.line(margin_left - 3, 50, margin_left - 3, 94);
  doc.line(docWidth - (margin_left - 3), 50, docWidth - (margin_left - 3), 94);

  doc.line(margin_left - 3, 95, docWidth - (margin_left - 3), 95);
  doc.setFont("helvetica", "bold");
  doc.text(
    "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
    docWidth / 2,
    100,
    { align: "center" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "Yo, " +
      _nombreCli +
      " con documento de identificación Nº " +
      _cedulaCli +
      " declaro que ( " +
      (es_pep1 === 1 ? "SI" : "NO") +
      " ) soy una Persona Expuesta Políticamente (PEP'S).",
    margin_left,
    105,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "bold");
  doc.text(
    "Nota: De acuerdo con el Reglamento de la Ley Orgánica de Prevención Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, Personas Expuestas Políticamente (PEP´s) Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.",
    margin_left,
    115,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "En el caso que la respuesta sea positiva, indicar:",
    margin_left,
    135
  );
  doc.text("Cargo o Función:", margin_left, 140);
  doc.text("Fecha Nombramiento o Finalización:", margin_left, 145);

  doc.setFont("helvetica", "bold");
  doc.text(pep1_cargo, margin_left + 50, 140);
  doc.text(pep1_fecha, margin_left + 50, 145);

  doc.setFont("helvetica", "normal");

  doc.text(
    "Adicionalmente, declaro que ( " +
      (es_pep2 === 1 ? "SI" : "NO") +
      " ) mantengo algun tipo de relación Familiar y con Personas Relacionadas de las Personas Expuestas Políticamente:",
    margin_left,
    150,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "bold");
  doc.text(
    "Se consideran como familiares y personas relacionadas de los PEPS que tengan relaciones comerciales o contractuales con los sujetos obligados, al cónyuge o las personas unidas bajo el régimen de unión de hecho reconocido legalmente o, los familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad, o las personas con las cuales se encuentre asociada o vinculada societariamente, o sus colaboradores cercanos.",
    margin_left,
    160,
    { maxWidth: 170, align: "justify" }
  );
  doc.setFont("helvetica", "normal");
  doc.text(
    "En el caso que la respuesta sea afirmativa, indicar el tipo de relación:",
    margin_left,
    175
  );
  doc.text("Tipo de Relación con el Cliente:", margin_left, 180);
  doc.text("que mantiene con:", margin_left, 185);
  doc.text("Nombre:", margin_left, 190);
  doc.text("Cargo o Función:", margin_left, 195);
  doc.text("Fecha Nombramiento o Finalización:", margin_left, 200);

  doc.setFont("helvetica", "bold");
  doc.text("", margin_left + 50, 180);
  doc.text("", margin_left + 50, 185);
  doc.text(pep2_nombre, margin_left + 50, 190);
  doc.text(pep2_cargo, margin_left + 50, 195);
  doc.text(pep2_fecha, margin_left + 50, 200);

  doc.setFont("helvetica", "normal");
  doc.text(
    "Nota: La presente declaración no constituye una autoincriminación de ninguna clase, ni conlleva ninguna clase de responsabilidad administrativa, civil o penal. La empresa solicita la presente declaración en cumplimiento de la normativa vigente en Materia de Prevención, Detección, Erradicación del",
    margin_left,
    205,
    { maxWidth: 170, align: "justify" }
  );

  doc.line(margin_left - 3, 214, docWidth - (margin_left - 3), 214);

  doc.line(margin_left - 3, 95, margin_left - 3, 214);
  doc.line(docWidth - (margin_left - 3), 95, docWidth - (margin_left - 3), 214);

  doc.line(margin_left - 3, 215, docWidth - (margin_left - 3), 215);

  doc.setFontSize(6);
  doc.text(
    "En caso de que hubiese cambiado cualquier información de este formulario, me",
    margin_left,
    218
  );
  doc.text("comprometo en actualizarla.", margin_left, 221);
  doc.text(
    "Declaro que toda la información arriba detallada es verdadera.",
    margin_left,
    224
  );

  doc.line(margin_left, 235, margin_left + 80, 235);
  doc.setFontSize(8);
  doc.text("FIRMA DEL CLIENTE", margin_left + 10, 238);

  doc.setFont("helvetica", "bold");
  doc.text("USO EXCLUSIVO DE", margin_left + 115, 218);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(6);
  doc.text(
    "Certifico/certificamos que la firma que antecede concuerda con la registrada en",
    margin_left + 90,
    223
  );
  doc.text(
    "nuestros documentos y fue registrada en mi presencia.",
    margin_left + 90,
    226
  );
  //doc.line(margin_left + 90, 235, docWidth - margin_left, 235);

  // doc.text(
  //   "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
  //   docWidth / 2,
  //   100,
  //   { align: "center" }
  // );

  doc.setFontSize(8);
  doc.text(_asesor, 148, 233, { align: "center" });
  doc.text("ASESOR", 148, 238, { align: "center" });

  doc.line(margin_left - 3, 240, docWidth - (margin_left - 3), 240);

  doc.line(margin_left - 3, 215, margin_left - 3, 240);
  doc.line(
    docWidth - (margin_left - 3),
    215,
    docWidth - (margin_left - 3),
    240
  );

  ///////////////////////// HOJA 3 ////////////////////////////////////

  doc.setFont("helvetica", "bold");
  doc.text("COPIA CÉDULA + PAPELETA VOTACIÓN", margin_left, 250);
  doc.text("PLANILLA SERVICIO BASICO", margin_left, 260);

  //ejemplo 45 es 255
  //doc.line(inicia, altura, termina, altura);

  //horizontales
  doc.line(margin_left + 65, 246, margin_left + 70, 246);
  doc.line(margin_left + 65, 251, margin_left + 70, 251);

  doc.line(margin_left + 65, 256, margin_left + 70, 256);
  doc.line(margin_left + 65, 261, margin_left + 70, 261);

  //verticales
  doc.line(margin_left + 65, 246, margin_left + 65, 251);
  doc.line(margin_left + 70, 246, margin_left + 70, 251);

  doc.line(margin_left + 65, 256, margin_left + 65, 261);
  doc.line(margin_left + 70, 256, margin_left + 70, 261);

  doc.text("ANEXO A LA HOJA DE NEGOCIO N°", margin_left + 85, 250);
  doc.text(
    "F.I.: " + new Date(Date.now()).toISOString().substring(0, 10),
    margin_left + 85,
    260
  );

  doc.text("Página 2 de 2", 168, 270);
  doc.text("0110IXVI24", 171, 275);

  var qrImg = new Image();
  qrImg.src = qrImagen; //"imagen.jpg"; /// URL de la imagen
  doc.addImage(qrImg, "PNG", margin_left + 145, 241, 25, 25); // Agregar la imagen al PDF (X, Y, Width, Height)

  // doc.save(`${data && data.cedulaCliente}` + ".pdf");
  doc.save("CON_CLI_" + _cedulaCli + ".pdf");
}

export const VentaDocumentoPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClickClose = () => {
    setOpenDialog(false);
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      console.log("el username es >>>", username);
      getvTablaRentaModelo(String(params.idcrm));
    } else {
      setName("");
    }
  }, [account]);

  type autorizacionType = {
    tauId: number;
    tarId: number;
    tauEsOtroCanton: number;
    tauOtroCanton: string;
    tauFinanciera: string;
    tauFinanciamientoValor: number;
    tauFinanciamientoReferencia: string;
    tauFinanciamientoFecha: string;
    tauEntradaValor: number;
    tauEntradaReferencia: string;
    tauEntradaFecha: string;
    tauNcValor: number;
    tauNcReferencia: string;
    tauNcFecha: string;
    tauSaldoValor: number;
    tauSaldoReferencia: string;
    tauSaldoFecha: string;
    tauUsadoValor: number;
    tauUsadoReferencia: string;
    tauUsadoFecha: string;
    tauTotalValor: number;
    tauDispositivo: number;
    tauDispositivoValor: number;
    tauDispositivoDetalle: string;
    tauDispositivoNotas: string;
    tauSeguro: number;
    tauSeguroValor: number;
    tauSeguroDetalle: string;
    tauSeguroNotas: string;
    tauAcc: number;
    tauAccValor: number;
    tauAccDetalle: string;
    tauAccNotas: string;
    tauObsequio: number;
    tauObsequioValor: number;
    tauObsequioDetalle: string;
    tauObsequioNotas: string;
  };

  type autorizacionNuevaType = {
    tarId: number;
    tauEsOtroCanton: number;
    tauOtroCanton: string;
    tauFinanciera: string;
    tauFinanciamientoValor: number;
    tauFinanciamientoReferencia: string;
    tauFinanciamientoFecha: string;
    tauEntradaValor: number;
    tauEntradaReferencia: string;
    tauEntradaFecha: string;
    tauNcValor: number;
    tauNcReferencia: string;
    tauNcFecha: string;
    tauSaldoValor: number;
    tauSaldoReferencia: string;
    tauSaldoFecha: string;
    tauUsadoValor: number;
    tauUsadoReferencia: string;
    tauUsadoFecha: string;
    tauTotalValor: number;
    tauDispositivo: number;
    tauDispositivoValor: number;
    tauDispositivoDetalle: string;
    tauDispositivoNotas: string;
    tauSeguro: number;
    tauSeguroValor: number;
    tauSeguroDetalle: string;
    tauSeguroNotas: string;
    tauAcc: number;
    tauAccValor: number;
    tauAccDetalle: string;
    tauAccNotas: string;
    tauObsequio: number;
    tauObsequioValor: number;
    tauObsequioDetalle: string;
    tauObsequioNotas: string;
  };

  const [datosAutorizaInit, setDatosAutorizaInit] =
    React.useState<autorizacionType>({
      tauId: 0,
      tarId: 0,
      tauEsOtroCanton: 0,
      tauOtroCanton: "",
      tauFinanciera: "",
      tauFinanciamientoValor: 0,
      tauFinanciamientoReferencia: "",
      tauFinanciamientoFecha: "",
      tauEntradaValor: 0,
      tauEntradaReferencia: "",
      tauEntradaFecha: "",
      tauNcValor: 0,
      tauNcReferencia: "",
      tauNcFecha: "",
      tauSaldoValor: 0,
      tauSaldoReferencia: "",
      tauSaldoFecha: "",
      tauUsadoValor: 0,
      tauUsadoReferencia: "",
      tauUsadoFecha: "",
      tauTotalValor: 0,
      tauDispositivo: 0,
      tauDispositivoValor: 0,
      tauDispositivoDetalle: "",
      tauDispositivoNotas: "",
      tauSeguro: 0,
      tauSeguroValor: 0,
      tauSeguroDetalle: "",
      tauSeguroNotas: "",
      tauAcc: 0,
      tauAccValor: 0,
      tauAccDetalle: "",
      tauAccNotas: "",
      tauObsequio: 0,
      tauObsequioValor: 0,
      tauObsequioDetalle: "",
      tauObsequioNotas: "",
    });

  const [datosAutoriza, setDatosAutoriza] = React.useState<autorizacionType>({
    tauId: 0,
    tarId: 0,
    tauEsOtroCanton: 0,
    tauOtroCanton: "",
    tauFinanciera: "",
    tauFinanciamientoValor: 0,
    tauFinanciamientoReferencia: "",
    tauFinanciamientoFecha: "",
    tauEntradaValor: 0,
    tauEntradaReferencia: "",
    tauEntradaFecha: "",
    tauNcValor: 0,
    tauNcReferencia: "",
    tauNcFecha: "",
    tauSaldoValor: 0,
    tauSaldoReferencia: "",
    tauSaldoFecha: "",
    tauUsadoValor: 0,
    tauUsadoReferencia: "",
    tauUsadoFecha: "",
    tauTotalValor: 0,
    tauDispositivo: 0,
    tauDispositivoValor: 0,
    tauDispositivoDetalle: "",
    tauDispositivoNotas: "",
    tauSeguro: 0,
    tauSeguroValor: 0,
    tauSeguroDetalle: "",
    tauSeguroNotas: "",
    tauAcc: 0,
    tauAccValor: 0,
    tauAccDetalle: "",
    tauAccNotas: "",
    tauObsequio: 0,
    tauObsequioValor: 0,
    tauObsequioDetalle: "",
    tauObsequioNotas: "",
  });

  const [registroTabla, setRegistroTabla] = React.useState<tablaRentaType>({
    tarId: 0,
    tarFecha: "",
    tarNumeroCotizacion: "",
    tarAgencia: "",
    tarAsesor: "",
    tarCedulaCliente: "",
    tarNombreCliente: "",
    tarModelo: "",
    tarModeloAnio: 0,
    tarColor: "",
    tarDiasInv: 0,
    tarEscenarioFlota: "",
    tarEscenarioFlotaPorcentaje: 0,
    tarFormaPago: "",
    tarAnticipos: 0,
    tarPvpMarca: 0,
    tarDealerNet: 0,
    tarDealerNetValor: 0,
    tarMargenBruto: 0,
    tarMargenBrutoValor: 0,
    tarDescCirular: 0,
    tarDescCirularValor: 0,
    tarDescConcecionario: 0,
    tarDescConcecionarioValor: 0,
    tarDescTotal: 0,
    tarDescTotalValor: 0,
    tarRebate: 0,
    tarRebateValor: 0,
    tarUtilidadBruta: 0,
    tarUtilidadBrutaValor: 0,
    tarTotalFacturar: 0,
    tarNcConcesionario: 0,
    tarNcMarca: 0,
    tarValorObsequios: 0,
    tarAccDescripcion: "",
    tarAccIncluyeFac: 0,
    tarAccValor: 0,
    tarDispDescripcion: "",
    tarDispIncluyeFac: 0,
    tarDispValor: 0,
    tarObservaciones: "",
    tarDestinatario: "",
    tarCorreo: "",
    tarCorreoRemitente: "",
    tarEnviado: 0,
    tarNotificado: 0,
    tarEstado: 0,
    tarMantenimientosPrepa: 0,

    fpropiosUsd: 0,
    fpropiosPorce: 0,
    fpropiosDetalle: "",
    ffinancieraUsd: 0,
    ffinancieraPorce: 0,
    ffinancieraDetalle: "",
    cinternoUsd: 0,
    cinternoPorce: 0,
    cinternoDetalle: "",
    vusadoUsd: 0,
    vusadoPorce: 0,
    vusadoDetalle: "",
    ftercerosUsd: 0,
    ftercerosPorce: 0,
    ftercerosDetalle: "",
    esBeneficiario: 0,
    esPep1: 0,
    esPep2: 0,
    beneficiarioNombre: "",
    beneficiarioCi: "",
    beneficiarioSexo: "",
    beneficiarioPais: "",
    beneficiarioParentesco: "",
    pep1Cargo: "",
    pep1Fecha: "",
    pep2Nombre: "",
    pep2Cargo: "",
    pep2Fecha: "",

    estadoCivil: "",
    ciudad: "",
    correoCliente: "",
    telefonoCliente: "",
    direccionCliente: "",
    lugarTrabajo: "",
    sexoCliente: "",
    nombreConyuge: "",
    provincia: "",
    cargoCliente: "",
    direccionEmpresa: "",
    telefonoTrabajo: "",

    cliIngresos: 0,
    cliEgresos: 0,
    cliActivos: 0,
    cliPasivos: 0,
    patrimonio: 0,
    actividadCliente: "",

    repre_cedula: "",
    repre_nombres: "",
    repre_sexo: "",
    repre_nacionalidad: "",
    repre_correo: "",
    repre_civil: "",
    repre_cedula_cony: "",
    repre_nombres_cony: "",

    repre_provincia: "",
    repre_ciudad: "",
    repre_telefono: "",
    repre_direccion: "",

    acc_cedula1: "",
    acc_nombres1: "",
    acc_participacion1: 0,
    acc_nacionalidad1: "",
    acc_actividad1: "",
    acc_cargo1: "",
    acc_cedula2: "",
    acc_nombres2: "",
    acc_participacion2: 0,
    acc_nacionalidad2: "",
    acc_actividad2: "",
    acc_cargo2: "",
    acc_cedula3: "",
    acc_nombres3: "",
    acc_participacion3: 0,
    acc_nacionalidad3: "",
    acc_actividad3: "",
    acc_cargo3: "",

    es_juridica: 0,
  });

  const dataAutorizacion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosAutoriza({
      ...datosAutoriza,
      [e.target.name]: e.target.value,
    });
  };
  const [cheked, setCheked] = React.useState({
    dispositivo: datosAutoriza.tauDispositivo === 1 ? true : false,
    seguro: datosAutoriza.tauSeguro === 1 ? true : false,
    accesorio: datosAutoriza.tauAcc === 1 ? true : false,
    obsequio: datosAutoriza.tauObsequio === 1 ? true : false,
    otro_canton: datosAutoriza.tauEsOtroCanton === 1 ? true : false,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
    cheked.dispositivo
      ? (datosAutoriza.tauDispositivo = 1)
      : (datosAutoriza.tauDispositivo = 0);
    cheked.seguro
      ? (datosAutoriza.tauSeguro = 1)
      : (datosAutoriza.tauSeguro = 0);
    cheked.accesorio ? (datosAutoriza.tauAcc = 1) : (datosAutoriza.tauAcc = 0);
    cheked.obsequio
      ? (datosAutoriza.tauObsequio = 1)
      : (datosAutoriza.tauObsequio = 0);
    cheked.otro_canton
      ? (datosAutoriza.tauEsOtroCanton = 1)
      : (datosAutoriza.tauEsOtroCanton = 0);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };

  async function getvTablaRentaModelo(idcrm: string) {
    try {
      await axios
        .get<tablaRentaType>(
          "https://apisatelite.azurewebsites.net/v-tabla-renta/idcrm/" + idcrm
        )
        .then((response) => {
          setRegistroTabla(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      console.log("data es >>", JSON.stringify(registroTabla, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [parametroBusqueda, setParametroBusqueda] =
    React.useState<parametroBuqueda>({
      parametro: "",
    });

  type parametroBuqueda = {
    parametro: string;
  };

  const dataBusqueda = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParametroBusqueda({
      ...parametroBusqueda,
      [e.target.name]: e.target.value,
    });
  };

  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  async function setNuevaAutorizacion(
    registro: autorizacionType,
    idTabla: number
  ) {
    console.log("registro a guardar >> ");

    const nuevoRegistro: autorizacionNuevaType = {
      tarId: idTabla,
      tauEsOtroCanton: registro.tauEsOtroCanton,
      tauOtroCanton: registro.tauOtroCanton,
      tauFinanciera: registro.tauFinanciera,
      tauFinanciamientoValor: registro.tauFinanciamientoValor,
      tauFinanciamientoReferencia: registro.tauFinanciamientoReferencia,
      tauFinanciamientoFecha: registro.tauFinanciamientoFecha,
      tauEntradaValor: registro.tauEntradaValor,
      tauEntradaReferencia: registro.tauEntradaReferencia,
      tauEntradaFecha: registro.tauEntradaFecha,
      tauNcValor: registro.tauNcValor,
      tauNcReferencia: registro.tauNcReferencia,
      tauNcFecha: registro.tauNcFecha,
      tauSaldoValor: registro.tauSaldoValor,
      tauSaldoReferencia: registro.tauSaldoReferencia,
      tauSaldoFecha: registro.tauSaldoFecha,
      tauUsadoValor: registro.tauUsadoValor,
      tauUsadoReferencia: registro.tauUsadoReferencia,
      tauUsadoFecha: registro.tauUsadoFecha,
      tauTotalValor: registro.tauTotalValor,
      tauDispositivo: registro.tauDispositivo,
      tauDispositivoValor: registro.tauDispositivoValor,
      tauDispositivoDetalle: registro.tauDispositivoDetalle,
      tauDispositivoNotas: registro.tauDispositivoNotas,
      tauSeguro: registro.tauSeguro,
      tauSeguroValor: registro.tauSeguroValor,
      tauSeguroDetalle: registro.tauSeguroDetalle,
      tauSeguroNotas: registro.tauSeguroNotas,
      tauAcc: registro.tauAcc,
      tauAccValor: registro.tauAccValor,
      tauAccDetalle: registro.tauAccDetalle,
      tauAccNotas: registro.tauAccNotas,
      tauObsequio: registro.tauObsequio,
      tauObsequioValor: registro.tauObsequioValor,
      tauObsequioDetalle: registro.tauObsequioDetalle,
      tauObsequioNotas: registro.tauObsequioNotas,
    };
    registro.tauId === 0
      ? await postNuevaAutorizacion(nuevoRegistro)
      : await postGuardarAutorizacion(registro);
    await guardarAutorizacion(datosAutoriza, registroTabla);
  }

  async function getRegistroAutoriza(idTabla: number) {
    try {
      await axios
        .get<autorizacionType>(
          "https://apisatelite.azurewebsites.net/tablarentaautfac/" +
            String(idTabla)
        )
        .then((response) => {
          response.data.tauId > 0
            ? setDatosAutoriza(response.data)
            : setDatosAutoriza(datosAutorizaInit);
          cheked.dispositivo =
            response.data.tauDispositivo === 1 ? true : false;
          cheked.seguro = response.data.tauSeguro === 1 ? true : false;
          cheked.accesorio = response.data.tauAcc === 1 ? true : false;
          cheked.obsequio = response.data.tauObsequio === 1 ? true : false;
          cheked.otro_canton =
            response.data.tauEsOtroCanton === 1 ? true : false;
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  const params = useParams();

  async function postNuevaAutorizacion(registroGuardar: autorizacionNuevaType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaautfac",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarAutorizacion(registroGuardar: autorizacionType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaautfac",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "& > *": {
                    m: 1,
                  },
                }}
              >
                <ButtonGroup size="small" aria-label="small button group">
                  <Button
                    href={`/clienterpa/${params.idcrm}`}
                    variant="contained"
                    key="one"
                  >
                    Datos del cliente
                  </Button>
                  <Button
                    href={`/creartablarenta/${params.idcrm}`}
                    variant="contained"
                    key="two"
                  >
                    Tabla de Rentabilidad
                  </Button>
                  <Button
                    href={`/conozca/${params.idcrm}`}
                    variant="contained"
                    key="seven"
                  >
                    Conozca a su Cliente
                  </Button>
                  <Button key="three">Documentos</Button>
                  <Button
                    href={`/uafe/${params.idcrm}`}
                    variant="contained"
                    key="five"
                  >
                    Revisión UAFE
                  </Button>
                  <Button
                    href={`/ventas/cliente/crear/${params.idcrm}`}
                    variant="contained"
                    key="six"
                  >
                    Crear Cliente
                  </Button>
                </ButtonGroup>
              </Box> */}
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                Documentos
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Box>
                <Grid>
                  {registroTabla.esPep1 == null ? (
                    <></>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 1.5,
                        width: "20%",
                        marginLeft: "25%",
                      }}
                      onClick={function (event) {
                        registroTabla.es_juridica === 1
                          ? guardaPDF_Juridica(
                              registroTabla.tarId,
                              registroTabla.tarCedulaCliente,
                              registroTabla.tarNombreCliente,
                              registroTabla.tarModelo,
                              registroTabla.tarAsesor,
                              registroTabla.tarTotalFacturar,
                              registroTabla.fpropiosUsd,
                              registroTabla.fpropiosPorce,
                              registroTabla.fpropiosDetalle,
                              registroTabla.ffinancieraUsd,
                              registroTabla.ffinancieraPorce,
                              registroTabla.ffinancieraDetalle,
                              registroTabla.cinternoUsd,
                              registroTabla.cinternoPorce,
                              registroTabla.cinternoDetalle,
                              registroTabla.vusadoUsd,
                              registroTabla.vusadoPorce,
                              registroTabla.vusadoDetalle,
                              registroTabla.ftercerosUsd,
                              registroTabla.ftercerosPorce,
                              registroTabla.ftercerosDetalle,
                              registroTabla.esBeneficiario,
                              registroTabla.esPep1,
                              registroTabla.esPep2,
                              registroTabla.beneficiarioNombre,
                              registroTabla.beneficiarioCi,
                              registroTabla.beneficiarioSexo,
                              registroTabla.beneficiarioPais,
                              registroTabla.beneficiarioParentesco,
                              registroTabla.pep1Cargo,
                              registroTabla.pep1Fecha,
                              registroTabla.pep2Nombre,
                              registroTabla.pep2Cargo,
                              registroTabla.pep2Fecha,
                              registroTabla.estadoCivil,
                              registroTabla.ciudad,
                              registroTabla.correoCliente,
                              registroTabla.telefonoCliente,
                              registroTabla.direccionCliente,
                              registroTabla.lugarTrabajo,
                              registroTabla.sexoCliente,
                              registroTabla.nombreConyuge,
                              registroTabla.provincia,
                              registroTabla.cargoCliente,
                              registroTabla.direccionEmpresa,
                              registroTabla.telefonoTrabajo,
                              registroTabla.cliIngresos,
                              registroTabla.cliEgresos,
                              registroTabla.cliActivos,
                              registroTabla.cliPasivos,
                              registroTabla.patrimonio,
                              registroTabla.actividadCliente,
                              registroTabla.repre_cedula,
                              registroTabla.repre_nombres,
                              registroTabla.repre_sexo,
                              registroTabla.repre_nacionalidad,
                              registroTabla.repre_correo,
                              registroTabla.repre_civil,
                              registroTabla.repre_cedula_cony,
                              registroTabla.repre_nombres_cony,
                              registroTabla.repre_provincia,
                              registroTabla.repre_ciudad,
                              registroTabla.repre_telefono,
                              registroTabla.repre_direccion,
                              registroTabla.acc_cedula1,
                              registroTabla.acc_nombres1,
                              registroTabla.acc_participacion1,
                              registroTabla.acc_nacionalidad1,
                              registroTabla.acc_actividad1,
                              registroTabla.acc_cargo1,
                              registroTabla.acc_cedula2,
                              registroTabla.acc_nombres2,
                              registroTabla.acc_participacion2,
                              registroTabla.acc_nacionalidad2,
                              registroTabla.acc_actividad2,
                              registroTabla.acc_cargo2,
                              registroTabla.acc_cedula3,
                              registroTabla.acc_nombres3,
                              registroTabla.acc_participacion3,
                              registroTabla.acc_nacionalidad3,
                              registroTabla.acc_actividad3,
                              registroTabla.acc_cargo3,
                              registroTabla.es_juridica
                            )
                          : guardaPDF(
                              registroTabla.tarId,
                              registroTabla.tarCedulaCliente,
                              registroTabla.tarNombreCliente,
                              registroTabla.tarModelo,
                              registroTabla.tarAsesor,
                              registroTabla.tarTotalFacturar,
                              registroTabla.fpropiosUsd,
                              registroTabla.fpropiosPorce,
                              registroTabla.fpropiosDetalle,
                              registroTabla.ffinancieraUsd,
                              registroTabla.ffinancieraPorce,
                              registroTabla.ffinancieraDetalle,
                              registroTabla.cinternoUsd,
                              registroTabla.cinternoPorce,
                              registroTabla.cinternoDetalle,
                              registroTabla.vusadoUsd,
                              registroTabla.vusadoPorce,
                              registroTabla.vusadoDetalle,
                              registroTabla.ftercerosUsd,
                              registroTabla.ftercerosPorce,
                              registroTabla.ftercerosDetalle,
                              registroTabla.esBeneficiario,
                              registroTabla.esPep1,
                              registroTabla.esPep2,
                              registroTabla.beneficiarioNombre,
                              registroTabla.beneficiarioCi,
                              registroTabla.beneficiarioSexo,
                              registroTabla.beneficiarioPais,
                              registroTabla.beneficiarioParentesco,
                              registroTabla.pep1Cargo,
                              registroTabla.pep1Fecha,
                              registroTabla.pep2Nombre,
                              registroTabla.pep2Cargo,
                              registroTabla.pep2Fecha,
                              registroTabla.estadoCivil,
                              registroTabla.ciudad,
                              registroTabla.correoCliente,
                              registroTabla.telefonoCliente,
                              registroTabla.direccionCliente,
                              registroTabla.lugarTrabajo,
                              registroTabla.sexoCliente,
                              registroTabla.nombreConyuge,
                              registroTabla.provincia,
                              registroTabla.cargoCliente,
                              registroTabla.direccionEmpresa,
                              registroTabla.telefonoTrabajo,
                              registroTabla.cliIngresos,
                              registroTabla.cliEgresos,
                              registroTabla.cliActivos,
                              registroTabla.cliPasivos,
                              registroTabla.patrimonio,
                              registroTabla.actividadCliente
                            );
                      }}
                    >
                      Conozca a su cliente
                    </Button>
                  )}

                  {registroTabla.esPep1 == null ? (
                    <></>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 1.5,
                        width: "20%",
                        marginLeft: "10%",
                      }}
                      //onClick={function (event) {}}
                      onClick={function (event) {
                        setOpenDialog(true);
                        setRegistroTabla(registroTabla);
                        getRegistroAutoriza(registroTabla.tarId);
                      }}
                    >
                      Autorizacion de Facturación
                    </Button>
                  )}
                </Grid>
              </Box>

              <Box>
                <Modal
                  open={openDialog}
                  onClose={handleClickClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Grid>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 1, textAlign: "center" }}
                      >
                        <strong>Autorizacion de facturación</strong>
                      </Typography>
                    </Grid>

                    <Box>
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "10%",
                          marginLeft: "3%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Financiera:
                      </Typography>
                      <TextField
                        name="tauFinanciera"
                        size="small"
                        margin="normal"
                        value={datosAutoriza.tauFinanciera}
                        onChange={dataAutorizacion}
                        sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                      />

                      {cheked.otro_canton ? (
                        <Typography
                          sx={{
                            mt: 1.5,
                            mb: 1,
                            width: "21%",
                            marginLeft: "15%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Matriculación en otro cantón
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            mt: 1.5,
                            mb: 1,
                            width: "21%",
                            marginLeft: "15%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Matriculación local
                        </Typography>
                      )}
                      <Switch
                        checked={cheked.otro_canton}
                        onChange={handleCheked}
                        name="otro_canton"
                        sx={{ mt: 0.5, mb: 0.5 }}
                      />

                      {cheked.otro_canton ? (
                        <TextField
                          name="tauOtroCanton"
                          size="small"
                          margin="normal"
                          label="Canton"
                          value={datosAutoriza.tauOtroCanton}
                          onChange={dataAutorizacion}
                          sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box>
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          marginLeft: "3%",
                          width: "7%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Seguro:
                      </Typography>
                      <Switch
                        checked={cheked.seguro}
                        onChange={handleCheked}
                        name="seguro"
                        sx={{ mt: 0.5, mb: 0.5 }}
                      />
                      <TextField
                        name="tauSeguroValor"
                        size="small"
                        margin="normal"
                        label="$"
                        type="number"
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={datosAutoriza.tauSeguroValor}
                        onChange={dataAutorizacion}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "1%",
                          width: "15%",
                        }}
                      />

                      <TextField
                        name="tauSeguroDetalle"
                        size="small"
                        margin="normal"
                        label="Detalle del seguro"
                        value={datosAutoriza.tauSeguroDetalle}
                        onChange={dataAutorizacion}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "1%",
                          width: "62%",
                        }}
                      />
                    </Box>

                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Box>
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Financiamiento:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(registroTabla.ffinancieraUsd)
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginRight: "2%",
                          width: "16%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Entrada/Anticipo:
                      </Typography>

                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(registroTabla.fpropiosUsd)
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "16%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Saldo:
                      </Typography>

                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(
                            Number(registroTabla.cinternoUsd) +
                              Number(registroTabla.ftercerosUsd)
                          )
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "16%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Usado como parte de pago:
                      </Typography>

                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(registroTabla.vusadoUsd)
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "16%",
                        }}
                      />

                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        <strong>Total a facturar:</strong>
                      </Typography>

                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(registroTabla.tarTotalFacturar)
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "16%",
                        }}
                      />
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <Typography
                        sx={{
                          mt: 1.5,
                          mb: 1,
                          width: "25%",
                          marginLeft: "28%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Nota de Credito:
                      </Typography>

                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                        value={formatter
                          .format(
                            Number(registroTabla.tarNcMarca) +
                              Number(registroTabla.tarNcConcesionario)
                          )
                          .replace("$", "$  ")}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "16%",
                        }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        width: "20%",
                        marginLeft: "40%",
                        backgroundColor: "rgba(21,57,105,0.75)",
                      }}
                      onClick={function (event) {
                        console.log(
                          "estos son mi datos de autorizacion",
                          datosAutoriza
                        );
                        setNuevaAutorizacion(
                          datosAutoriza,
                          registroTabla.tarId
                        );
                      }}
                    >
                      Imprimir
                    </Button>
                  </Box>
                </Modal>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid
          //container
          alignItems={"center"}
          justifyContent={"center"}
          //sx={{ minHeight: "90vh" }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 1.5,

              width: "20%",
              marginLeft: "40%",
            }}
            onClick={() => handleLogin("redirect")}
            //href="/listadotablarenta"
          >
            Inicia sesión
          </Button>
        </Grid>
      </Container>
    );
  }
};
