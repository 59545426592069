import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

export const TrackingParametrosPage: React.FC<{}> = () => {
  useEffect(() => {
   // getvTablaBodegas();
    getZonas();
  }, []);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();

  const dataListaAutoriza: autorizaType[] = [];
  const [listaAutorizaRows, setListaAutorizaRows] =
    useState<autorizaType[]>(dataListaAutoriza);

  type autorizaType = {
    id: number;
    zona: string;
    bodega: string;
    repuesto: string;
  };


  async function getZonas() {
      try {
        await axios
          .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
          .then((response) => {
            setDataZona(response.data);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(dataZona, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }
  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  type zonaType = {
      zonId: number;
      zonNombre: string;
    };
    type bodegaType = {
      bodId: number;
      agencia: string;
      descripcion: string;
    };

    const listadoZonas: zonaType[] = [];
const listadoBodegas: bodegaType[] = [];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [zona, setZona] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);

  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
  const [dataBodegaRep, setDataBodegaRep] = useState<bodegaType[]>(listadoBodegas);
  const [dataBodegaAll, setDataBodegaAll] = useState<bodegaType[]>(listadoBodegas);

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
      setZona(Number(event.target.value));
      getBodegasRep(event.target.value);
      getBodegasAll(event.target.value);
    };
    const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgencia(Number(event.target.value));
    };


    async function getBodegasRep(idZona: string) {
      try {
        await axios
          .get<bodegaType[]>(
            "https://apisatelite.azurewebsites.net/dimbodega/zona/rep/" + idZona
          )
          .then((response) => {
            setDataBodegaRep(response.data);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(dataBodegaRep, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }

    async function getBodegasAll(idZona: string) {
      try {
        await axios
          .get<bodegaType[]>(
            "https://apisatelite.azurewebsites.net/dimbodega/zona/all/" + idZona
          )
          .then((response) => {
            setDataBodegaAll(response.data);
          })
          .catch((error) => {
            console.log("algo paso >> ", error);
          });
  
        console.log("data es >>", JSON.stringify(dataBodegaAll, null, 4));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("algo paso unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    }

  async function getvTablaBodegas() {
    try {
      await axios
        .get<autorizaType[]>(
          "https://apisatelite.azurewebsites.net/vclasificacionbodega"
        )
        .then((response) => {
          setListaAutorizaRows(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper sx={{ padding: "1.2em", borderRadius: "0.5em" }}>
              <Box>
                <Typography
                  //id="modal-modal-title"
                  sx={{ mt: 0.5, mb: 0.5 }}
                  variant="h6"
                  textAlign={"center"}
                >
                  Parametros de Tracking de Vehículos
                </Typography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <TableContainer sx={{ maxHeight: 700 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Zona</TableCell>
                        <TableCell>Bodega de facturacion</TableCell>
                        <TableCell>Bodega de entrega</TableCell>
                        <TableCell>Tiempo ideal</TableCell>
                        <TableCell>Editar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaAutorizaRows.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.zona}
                          </TableCell>
                          <TableCell>{row.bodega}</TableCell>
                          <TableCell>{row.repuesto}</TableCell>                       
                          <TableCell>
                            {" "}
                            <Button onClick={function (event) {}}>
                              🗑
                            </Button>{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "85%",
                  }}
                  onClick={handleOpen}
                >
                  Nuevo
                </Button>
                
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, textAlign : "center" }}>
                      Ingrese un nuevo parametro de bodegas
                    </Typography>    
                    <Box>
                    <TextField
                  id="outlined-select-currency"
                  select
                  label="Zona"
                  //defaultValue={data?.idZona}
                  //helperText="Please select your currency"
                  size="small"
                  margin="normal"
                  value={zona}
                  onChange={handleChangeZona}
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%"}}
                >
                  {dataZona.map((option) => (
                    <MenuItem key={option.zonId} value={option.zonId}>
                      {option.zonNombre}
                    </MenuItem>
                  ))}
                </TextField>
                    </Box>                    
                    <Box>
                    <TextField
                  id="outlined-select-currency"
                  select
                  label="Bodega de facturacion"
                  //defaultValue= {data?.idMarca}
                  //helperText="Please select your currency"
                  onChange={handleChangeAgencia}
                  size="small"
                  margin="normal"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                >
                  {dataBodegaAll.map((option) => (
                    <MenuItem key={option.bodId} value={option.bodId}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
                    </Box>                             
                    <Box>
                    <TextField
                  id="outlined-select-currency"
                  select
                  label="Bodega de despachos"
                  //defaultValue= {data?.idMarca}
                  //helperText="Please select your currency"
                  onChange={handleChangeAgencia}
                  size="small"
                  margin="normal"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                >
                  {dataBodegaRep.map((option) => (
                    <MenuItem key={option.bodId} value={option.bodId}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-currency"
                  label="Tiempo ideal"
                  //defaultValue= {data?.idMarca}
                  //helperText="Please select your currency"
                  size="small"
                  margin="normal"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                >
                 
                </TextField>
                    </Box>  
                   
                  
                    <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "30%",
                    marginLeft: "35%",
                  }}
                  onClick={handleOpen}
                >
                  Guardar
                </Button>
                  </Box>      
                  </Modal>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Paper>
      <Grid
        //container
        alignItems={"center"}
        justifyContent={"center"}
      >

        <Typography sx={{ mt: 2, mb: 1.5 }} variant="h6" textAlign={"center"}>
          Acceso denegado
        </Typography>
      </Grid>
      </Paper>
    </Container>
    );
  }
};
