import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { SateliteModeloKmatCrmType, SateliteParametroCotizadorType } from "../../types";

export const ParametrosCotizadorPage: React.FC<{}> = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (account && account.name) {
            console.log("este son los atributos de msal >>>>>>", account);
            setName(account.name);
            setUsername(account.username);
            console.log(account);
            getModelosKmat();
        } else {
            setName("");
        }
    }, [account]);


    const listaModelos: SateliteModeloKmatCrmType[] = [];
    const [dataModeloKmat, setDataModeloKmat] = useState<SateliteModeloKmatCrmType[]>(listaModelos);
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [mostrar, setMostrar] = React.useState(false);
    const [idModeloKmat, setIdModeloKmat] = React.useState(0);
    const [parametroCotizador, setParametroCotizador] = useState<SateliteParametroCotizadorType>({
        spcId: 0,
        mkcId: 0,
        spcImagenHeader: "",
        spcImagenColores: "",
        spcImagen_1: "",
        spcImagen_2: "",
        spcNombre: "",
        spcText2: "",
        spcMarcaModeloVersion: "",
        spcCaracteristica1: "",
        spcDetalle1: "",
        spcCaracteristica2: "",
        spcDetalle2: "",
        spcCaracteristica3: "",
        spcDetalle3: "",
        spcCaracteristica4: "",
        spcDetalle4: "",
        spcCaracteristica5: "",
        spcDetalle5: "",
        spcCaracteristica6: "",
        spcDetalle6: "",
        spcCaracteristica7: "",
        spcDetalle7: "",
        spcCaracteristica8: "",
        spcDetalle8: "",
        spcCaracteristica9: "",
        spcDetalle9: "",
        spcCaracteristica10: "",
        spcDetalle10: "",
        spcCaracteristica11: "",
        spcDetalle11: "",
        spcCaracteristica12: "",
        spcDetalle12: "",
        spcCaracteristica13: "",
        spcDetalle13: "",
        spcCaracteristica14: "",
        spcDetalle14: "",
        spcCaracteristica15: "",
        spcDetalle15: "",
    })

    const styleModal = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const HandleChangeParametroCotizador = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParametroCotizador({
            ...parametroCotizador,
            [e.target.name]: e.target.value,
            mkcId: idModeloKmat,
        });
        
    };

    async function getModelosKmat() {
        try {
            await axios
                .get<SateliteModeloKmatCrmType[]>("https://apisatelite.azurewebsites.net/satelitemodelokmat/todo")
                .then((response) => {
                    setDataModeloKmat(response.data);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function getParametros(_id: number) {
        try {
            await axios
                .get<SateliteParametroCotizadorType>("https://apisatelite.azurewebsites.net/sateliteparametrocotizador/" + String(_id))
                .then((response) => {
                    setParametroCotizador(response.data);
                    setMostrar(true);
                })
                .catch((error) => {
                    console.log("algo paso >> ", error);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }

    async function postGuardarRegistro(registro: SateliteParametroCotizadorType) {
        const headers = {
            "Content-Type": "application/json",
        };
        try {
            axios
                .post(
                    "https://apisatelite.azurewebsites.net/sateliteparametrocotizador",
                    registro,
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log("id guardado es: ", response);
                    setOpenDialog(true);
                });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log("error message: ", error.message);
                return error.message;
            } else {
                console.log("algo paso unexpected error: ", error);
                return "An unexpected error occurred";
            }
        }
    }



    if (isAuthenticated) {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Grid alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 2,
                            }}
                        >
                            <Typography variant="h5" textAlign={"center"}>
                                Parametros de Cotizador
                            </Typography>
                        </Paper>
                        <Paper
                            sx={{
                                padding: "1.2em",
                                borderRadius: "0.5em",
                                backgroundColor: "rgb(255,255,255,0.95)",
                                mt: 1,
                            }}
                        >

                            <TextField
                                id="outlined-select-currency"
                                select
                                size="small"
                                margin="normal"
                                value={idModeloKmat}
                                onChange={(e) => {setIdModeloKmat(Number(e.target.value)); setParametroCotizador({
                                    spcId: 0,
                                    mkcId: 0,
                                    spcImagenHeader: "",
                                    spcImagenColores: "",
                                    spcImagen_1: "",
                                    spcImagen_2: "",
                                    spcNombre: "",
                                    spcText2: "",
                                    spcMarcaModeloVersion: "",
                                    spcCaracteristica1: "",
                                    spcDetalle1: "",
                                    spcCaracteristica2: "",
                                    spcDetalle2: "",
                                    spcCaracteristica3: "",
                                    spcDetalle3: "",
                                    spcCaracteristica4: "",
                                    spcDetalle4: "",
                                    spcCaracteristica5: "",
                                    spcDetalle5: "",
                                    spcCaracteristica6: "",
                                    spcDetalle6: "",
                                    spcCaracteristica7: "",
                                    spcDetalle7: "",
                                    spcCaracteristica8: "",
                                    spcDetalle8: "",
                                    spcCaracteristica9: "",
                                    spcDetalle9: "",
                                    spcCaracteristica10: "",
                                    spcDetalle10: "",
                                    spcCaracteristica11: "",
                                    spcDetalle11: "",
                                    spcCaracteristica12: "",
                                    spcDetalle12: "",
                                    spcCaracteristica13: "",
                                    spcDetalle13: "",
                                    spcCaracteristica14: "",
                                    spcDetalle14: "",
                                    spcCaracteristica15: "",
                                    spcDetalle15: "",
                                }); }}
                                sx={{ mt: 0.5, mb: 0.5, ml: "30%", width: "40%" }}
                                SelectProps={{
                                    MenuProps: {
                                        disableScrollLock: true,
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                            >
                                {dataModeloKmat.map((option) => (
                                    <MenuItem key={option.mkcId} value={option.mkcId}>
                                        {option.mkcModelo}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 1,
                                    width: "12%",
                                    marginLeft: "44%",
                                }}
                                onClick={(e) => getParametros(idModeloKmat)}
                            >
                                Consultar
                            </Button>
                        </Paper>
                        <Dialog
                            open={openDialog}
                            //onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Datos guardados correctamente
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    //href="/politicacomercial/lista"
                                    onClick={function (event) {
                                        setOpenDialog(false);
                                        setMostrar(false);
                                    }}
                                //autoFocus
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {mostrar ? (
                            <Paper
                                sx={{
                                    padding: "1.2em",
                                    borderRadius: "0.5em",
                                    backgroundColor: "rgb(255,255,255,0.95)",
                                    mt: 1,
                                    mb: 4,
                                }}
                            >                             
                                <TextField
                                label= "Título"
                                    size="small"
                                    margin="normal"
                                    name="spcNombre"
                                    value={parametroCotizador.spcNombre}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "10%", width: "39%" }}
                                />
                                
                                <TextField
                                label= "Subtítulo"
                                    size="small"
                                    margin="normal"
                                    name="spcText2"
                                    value={parametroCotizador.spcText2}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                
                                <TextField
                                label= "Marca/Modelo/Version/Año"
                                    size="small"
                                    margin="normal"
                                    name="spcMarcaModeloVersion"
                                    value={parametroCotizador.spcMarcaModeloVersion}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "10%", width: "80%" }}
                                />
                                <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 1:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica1"
                                    value={parametroCotizador.spcCaracteristica1}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle1"
                                    value={parametroCotizador.spcDetalle1}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 2:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica2"
                                    value={parametroCotizador.spcCaracteristica2}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle2"
                                    value={parametroCotizador.spcDetalle2}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 3:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica3"
                                    value={parametroCotizador.spcCaracteristica3}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle3"
                                    value={parametroCotizador.spcDetalle3}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 4:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica4"
                                    value={parametroCotizador.spcCaracteristica4}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle4"
                                    value={parametroCotizador.spcDetalle4}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 5:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica5"
                                    value={parametroCotizador.spcCaracteristica5}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle5"
                                    value={parametroCotizador.spcDetalle5}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 6:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica6"
                                    value={parametroCotizador.spcCaracteristica6}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle6"
                                    value={parametroCotizador.spcDetalle6}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 7:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica7"
                                    value={parametroCotizador.spcCaracteristica7}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle7"
                                    value={parametroCotizador.spcDetalle7}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 8:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica8"
                                    value={parametroCotizador.spcCaracteristica8}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle8"
                                    value={parametroCotizador.spcDetalle8}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 9:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica9"
                                    value={parametroCotizador.spcCaracteristica9}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle9"
                                    value={parametroCotizador.spcDetalle9}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 10:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica10"
                                    value={parametroCotizador.spcCaracteristica10}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle10"
                                    value={parametroCotizador.spcDetalle10}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 11:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica11"
                                    value={parametroCotizador.spcCaracteristica11}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle11"
                                    value={parametroCotizador.spcDetalle11}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 12:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica12"
                                    value={parametroCotizador.spcCaracteristica12}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle12"
                                    value={parametroCotizador.spcDetalle12}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 13:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica13"
                                    value={parametroCotizador.spcCaracteristica13}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle13"
                                    value={parametroCotizador.spcDetalle13}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 14:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica14"
                                    value={parametroCotizador.spcCaracteristica14}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle14"
                                    value={parametroCotizador.spcDetalle14}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                    <Typography
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        width: "7%",
                                        ml: "10%"
                                    }}
                                    style={{ display: "inline-block" }}
                                >
                                    Dato 15:
                                </Typography>
                                <TextField
                                label= "Caracteristica"
                                    size="small"
                                    margin="normal"
                                    name="spcCaracteristica15"
                                    value={parametroCotizador.spcCaracteristica15}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, width: "32%" }}
                                />
                                <TextField
                                label= "Detalle"
                                    size="small"
                                    margin="normal"
                                    name="spcDetalle15"
                                    value={parametroCotizador.spcDetalle15}
                                    onChange={HandleChangeParametroCotizador}
                                    sx={{ mt: 1, mb: 1, ml: "2%", width: "39%" }}
                                />
                                
                                
                                <Button 
                                onClick={(e) => postGuardarRegistro(parametroCotizador)}
                                variant="contained" sx={{ 
                                    mt: 3,
                                    mb: 1,
                                    width: "12%",
                                    marginLeft: "44%", }}>
                                    Guardar
                                </Button>
                            </Paper>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container maxWidth="lg" sx={{ mt: 10 }}>
                <Paper>
                    <Grid
                        //container
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{ mt: 2, mb: 1.5 }}
                            variant="h6"
                            textAlign={"center"}
                        >
                            Acceso denegado
                        </Typography>
                    </Grid>
                </Paper>
            </Container>
        );
    }
};
