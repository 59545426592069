import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  ListItemAvatar,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import logo from "../../assets/logochevy.png";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import ViewIcon from "@mui/icons-material/ViewAgenda";

export const ConsultaTraackingVehPage: React.FC<{}> = () => {
  useEffect(() => {
    loadCombos();
  }, []);

  async function loadCombos() {
    await getZonas();
    await getMarcas();
    await setDataZona(dataZona => [...dataZona, {zonId: 0, zonNombre:"Todas"}]);
    await setDataMarca(dataMarca => [...dataMarca, {marId: 0, marNombre:"Todas"}]);    
  }

  const color = "rgba(21,57,105,0.75)";
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const listadoInicial: trackingConsultaType[] = [];
  const [rowsLista, setRowsLista] =
    useState<trackingConsultaType[]>(listadoInicial);

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickClose = () => {
    setOpenDialog(false);
  };

  type trackingConsultaType = {
    fechaCorte: string;
    marca: string;
    descripcion: string;
    fechaFac: string;
    tipoVehiculo: string;
    idDocumento: string;
    numeroHn: string;
    tipoNegocio: string;
    nombFlota: string;
    chevyPlan: string;
    vin: string;
    modelo: string;
    color: string;
    accesorios: string;
    accesoriosCliente: string;
    apiRebate: string;
    valRebate: string;
    cliente: string;
    vendedor: string;
    formaPago: string;
    aseguradora: string;
    financiera: string;
    fecAproFinan: string;
    tipoCredito: string;
    facturador: string;
    valorAnti: string;
    valorFinan: string;
    valorNotaCredito: string;
    valorCuotaDiferido: string;
    valorCreditoDirecto: string;
    valorVhUs: string;
    saldoFinan: string;
    saldoCliente: string;
    estado: string;
    notaStatusCartera: string;
    fechaDocs: string;
    diasFacts: string;
    fecNotaria: string;
    diasEnvNot: string;
    fechaFirCont: string;
    diasFirmCont: string;
    ubicVeh: string;
    dispositivo: string;
    estadoDispositivo: string;
    fechaInstalacion: string;
    promesaEntrega: string;
    fecTentaEntre: string;
    totDiasEntre: string;
    fechaEntregaVh: string;
    anulado: string;
  };

  const columnsResumen: GridColDef[] = [
    {
      field: "action",
      headerName: "Detalle",
      sortable: false,
      renderCell: (params) => {
        // const onClick = (e) => {
        //   e.stopPropagation(); // don't select this row after clicking
  
        //   const api: GridApi = params.api;
        //   const thisRow: Record<string, GridCellValue> = {};
  
        //   api
        //     .getAllColumns()
        //     .filter((c) => c.field !== "__check__" && !!c)
        //     .forEach(
        //       (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
        //     );
  
        //   return alert(JSON.stringify(thisRow, null, 4));
        // };

        //{params.row.vin} 
  
        return <Button onClick={function (event) {setOpenDialog(true)}} >Abrir </Button>;
      }
    },
    { field: "marca", headerName: "Marca", minWidth: 150 },
    { field: "fechaFac", headerName: "F. Factura", minWidth: 150 },
    { field: "vin", headerName: "Vin", minWidth: 150 },
    { field: "modelo", headerName: "Modelo", minWidth: 250 },
    { field: "color", headerName: "Color", minWidth: 150 },
    { field: "cliente", headerName: "Cliente", minWidth: 250 },
    { field: "vendedor", headerName: "Cendedor", minWidth: 150 },
    { field: "estado", headerName: "Estado", minWidth: 150 },
    { field: "diasFacts", headerName: "Dias Facts", minWidth: 150 },
    { field: "ubicVeh", headerName: "Ubicación Veh", minWidth: 150 },
    { field: "promesaEntrega", headerName: "Promesa Entrega", minWidth: 150 },
    {
      field: "fecTentaEntre",
      headerName: "F.Tentativa Entrega",
      minWidth: 150,
    },
    { field: "totDiasEntre", headerName: "Total Dias Entrega", minWidth: 150 },
    { field: "fechaEntregaVh", headerName: "F. Entrega Veh", minWidth: 150 },
  ];

  const columnsDetalle: GridColDef[] = [
    // {
    //   field: "actions",
    //   type: "actions",
    //   headerName: "Acciones",
    //   width: 100,
    //   cellClassName: "actions",

    //   getActions: ({ row }) => {
    //     return [
    //       <GridActionsCellItem
    //         //icon={<ViewIcon />}

    //         label="Save"
    //         sx={{
    //           color: "primary.main",
    //         }}
    //         //onClick= {setOpenDialog(true)}//{handleSaveClickRetail(row)}
    //       />,
    //     ];
    //   },
    // },
    { field: "marca", headerName: "marca", minWidth: 150 },
    { field: "descripcion", headerName: "descripcion", minWidth: 150 },
    { field: "fechaFac", headerName: "fechaFac", minWidth: 150 },
    { field: "tipoVehiculo", headerName: "tipoVehiculo", minWidth: 150 },
    { field: "idDocumento", headerName: "idDocumento", minWidth: 150 },
    { field: "numeroHn", headerName: "numeroHn", minWidth: 150 },
    { field: "tipoNegocio", headerName: "tipoNegocio", minWidth: 150 },
    { field: "nombFlota", headerName: "nombFlota", minWidth: 150 },
    { field: "chevyPlan", headerName: "chevyPlan", minWidth: 150 },
    { field: "vin", headerName: "vin", minWidth: 150 },
    { field: "modelo", headerName: "modelo", minWidth: 150 },
    { field: "color", headerName: "color", minWidth: 150 },
    { field: "accesorios", headerName: "accesorios", minWidth: 150 },
    {
      field: "accesoriosCliente",
      headerName: "accesoriosCliente",
      minWidth: 150,
    },
    { field: "apiRebate", headerName: "apiRebate", minWidth: 150 },
    { field: "valRebate", headerName: "valRebate", minWidth: 150 },
    { field: "cliente", headerName: "cliente", minWidth: 150 },
    { field: "vendedor", headerName: "vendedor", minWidth: 150 },
    { field: "formaPago", headerName: "formaPago", minWidth: 150 },
    { field: "aseguradora", headerName: "aseguradora", minWidth: 150 },
    { field: "financiera", headerName: "financiera", minWidth: 150 },
    { field: "fecAproFinan", headerName: "fecAproFinan", minWidth: 150 },
    { field: "tipoCredito", headerName: "tipoCredito", minWidth: 150 },
    { field: "facturador", headerName: "facturador", minWidth: 150 },
    { field: "valorAnti", headerName: "valorAnti", minWidth: 150 },
    { field: "valorFinan", headerName: "valorFinan", minWidth: 150 },
    {
      field: "valorNotaCredito",
      headerName: "valorNotaCredito",
      minWidth: 150,
    },
    {
      field: "valorCuotaDiferido",
      headerName: "valorCuotaDiferido",
      minWidth: 150,
    },
    {
      field: "valorCreditoDirecto",
      headerName: "valorCreditoDirecto",
      minWidth: 150,
    },
    { field: "valorVhUs", headerName: "valorVhUs", minWidth: 150 },
    { field: "saldoFinan", headerName: "saldoFinan", minWidth: 150 },
    { field: "saldoCliente", headerName: "saldoCliente", minWidth: 150 },
    { field: "estado", headerName: "estado", minWidth: 150 },
    {
      field: "notaStatusCartera",
      headerName: "notaStatusCartera",
      minWidth: 150,
    },
    { field: "fechaDocs", headerName: "fechaDocs", minWidth: 150 },
    { field: "diasFacts", headerName: "diasFacts", minWidth: 150 },
    { field: "fecNotaria", headerName: "fecNotaria", minWidth: 150 },
    { field: "diasEnvNot", headerName: "diasEnvNot", minWidth: 150 },
    { field: "fechaFirCont", headerName: "fechaFirCont", minWidth: 150 },
    { field: "diasFirmCont", headerName: "diasFirmCont", minWidth: 150 },
    { field: "ubicVeh", headerName: "ubicVeh", minWidth: 150 },
    { field: "dispositivo", headerName: "dispositivo", minWidth: 150 },
    {
      field: "estadoDispositivo",
      headerName: "estadoDispositivo",
      minWidth: 150,
    },
    {
      field: "fechaInstalacion",
      headerName: "fechaInstalacion",
      minWidth: 150,
    },
    { field: "promesaEntrega", headerName: "promesaEntrega", minWidth: 150 },
    { field: "fecTentaEntre", headerName: "fecTentaEntre", minWidth: 150 },
    { field: "totDiasEntre", headerName: "totDiasEntre", minWidth: 150 },
    { field: "fechaEntregaVh", headerName: "fechaEntregaVh", minWidth: 150 },
    { field: "anulado", headerName: "anulado", minWidth: 150 },
  ];

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const theme = useTheme();

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function getListaSimulaciones() {
    try {
      await axios
        .get<trackingConsultaType[]>(
          "https://apisatelite.azurewebsites.net/vtrackingvehiculo"
        )
        .then((response) => {
          setRowsLista(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(rowsLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getZonas() {
    try {
      await axios
        .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
        .then((response) => {
          setDataZona(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataZona, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodegas(idZona: string) {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega/zona/rep/" + idZona
        )
        .then((response) => {
          setDataBodega(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [cheked, setCheked] = React.useState({
    detalle1: false,
    detalle2: false,
    detalle3: false,
    detalle4: false,
    detalle5: false,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  type zonaType = {
    zonId: number;
    zonNombre: string;
  };
  type bodegaType = {
    bodId: number;
    agencia: string;
    descripcion: string;
  };
  type marcaType = {
    marId: number;
    marNombre: string;
  };

  const listadoZonas: zonaType[] = [];
  const listadoBodegas: bodegaType[] = [];
  const listadoMarcas: marcaType[] = [];

  const [zona, setZona] = React.useState(0);
  const [bodega, setBodega] = React.useState(0);
  const [marca, setMarca] = React.useState(0);

  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
  const [dataBodega, setDataBodega] = useState<bodegaType[]>(listadoBodegas);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZona(Number(event.target.value));
    getBodegas(event.target.value);
    
    setDataBodega(dataBodega => [...dataBodega, {bodId: 0, agencia:"Todas", descripcion : ""}]);
  };
  const handleChangeBodega = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodega(Number(event.target.value));
  };
  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
                width: "!00%",
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Tracking de Vehículos
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
                <Grid>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "13%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>F. Facturacion corte:</strong>
                  </Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    type="date"
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "15%",
                      backgroundColor: "rgb(242,242,242)",
                    }}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Marca"
                    size="small"
                    margin="normal"
                    onChange={handleChangeMarca}
                    sx={{ mt: 0.5, mb: 0.5, marginLeft: "2%", width: "11%" }}
                  >
                    {dataMarca.map((option) => (
                      <MenuItem key={option.marId} value={option.marId}>
                        {option.marNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Zona"
                    size="small"
                    margin="normal"
                    onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginLeft: "2%", width: "11%" }}
                  >
                    {dataZona.map((option) => (
                      <MenuItem key={option.zonId} value={option.zonId}>
                        {option.zonNombre}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Bodega"
                    onChange={handleChangeBodega}
                    size="small"
                    margin="normal"
                    sx={{ mt: 0.5, mb: 0.5, marginLeft: "2%", width: "27%" }}
                  >
                    {dataBodega.map((option) => (
                      <MenuItem key={option.bodId} value={option.bodId}>
                        {option.descripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "15%",
                      marginLeft: "2%",
                      backgroundColor: "rgba(21,57,105,1)",
                    }}
                    onClick={getListaSimulaciones}
                  >
                    Filtrar
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "15%",
                      marginLeft: "85%",
                      backgroundColor: "rgba(21,57,105,1)",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Parametros
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem>
                      <Typography
                        component="a"
                        href="/trackingveh/parametros_fases"
                        sx={{
                          mr: 2,
                          display: { xs: "none", md: "flex" },
                          fontWeight: 500,
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        Fases
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <Typography
                        component="a"
                        href="/trackingveh/parametros_rutas"
                        sx={{
                          mr: 2,
                          display: { xs: "none", md: "flex" },
                          fontWeight: 500,
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        Rutas
                      </Typography>
                    </MenuItem>
                    <MenuItem>
                      <Typography
                        component="a"
                        href="/trackingveh/parametros_acc"
                        sx={{
                          mr: 2,
                          display: { xs: "none", md: "flex" },
                          fontWeight: 500,
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        Accesorización
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Box>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                width: "!00%",
              }}
            >
              <Box>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    style={{ backgroundColor: "rgba(21,57,105,1)" }}
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "rgb(255,255,255,0.95)",
                      },
                    }}
                  >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Por fases" {...a11yProps(1)} />
                    <Tab label="Por agencia" {...a11yProps(2)} />
                    {/* <Tab label="Item Three" {...a11yProps(2)} />
                    <Tab label="Item Three" {...a11yProps(2)} /> */}
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "10%",
                      marginLeft: "80%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Mostrar detalle
                  </Typography>
                  <Switch
                    checked={cheked.detalle1}
                    onChange={handleCheked}
                    name="detalle1"
                    sx={{ mt: 0.5, mb: 0.5 }}
                  />
                  <DataGrid
                    sx={{ height: 400 }}
                    editMode="row"
                    rows={rowsLista}
                    columns={cheked.detalle1 ? columnsDetalle : columnsResumen}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  Listado por agencia
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  Item Three
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  Item Three
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Modal
          open={openDialog}
          onClose={handleClickClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Grid>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 1, mb: 2, textAlign: "center" }}
              >
                <strong>Detalle por VIN</strong>
              </Typography>
            </Grid>

            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Fase:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Fecha ideal:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Fecha facturacion:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Marca:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Modelo:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Vin:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
            <Grid>
            <Typography
                        sx={{
                          mt: 1.5,
                          mb: 0.5,
                          width: "30%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Cliente:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        style={{ backgroundColor: "rgb(242,242,242)" }}
                        InputProps={{
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}                       
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "70%",
                        }}
                      />
                     
            </Grid>
          </Box>
        </Modal>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
