import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { StrictMode, useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import {
  ClienteRespuestaDMSType,
  ClienteRpaApiType,
  CrmUafeType,
  DMSClienteType,
  TablaRentaDocumentoType,
  TokenDMSType,
  TokenRespuestaDMSType,
  clienterpaType,
} from "../../types";

export const ClienteRPAPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const params = useParams();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getDatos();
      getDataClienteRpaApi();
    } else {
      setName("");
    }
  }, [account]);
  
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  const [mostrar, setMostrar] = React.useState(false);
  const [dataInfoNegocio, setDataInfoNegocio] =
    React.useState<TablaRentaDocumentoType>({
      id: 0,
      idCrm: "",
      modelo: "",
      agencia: "",
      asesor: "",
      cedula: "",
      nombreCliente: "",
      genero: "",
      civilstatus: "",
      nombreConyuge: "",
      provincia: "",
      ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
      lugarTrabajo: "",
      cargo: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      familia: "",
      nacionalidad: "",
      tipoPersonaCliente: "",
      zona: "",
      ciudadAgencia: "",
      esBloqueado: 0,
      ciudadEmpresa: "",
      financiera: ""
    });

  type respuestaUafeType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleUafeType;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    Mensaje: string;
    UrlEvidencia: string;
  };
  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    Estado: string;
  };
  type tipoPersonaResumenType = {
    Id: number;
  };

  type personaType = {
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaResumenType;
  };
  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    Usuario: string;
    Password: string;
    UrlBot: string;
    Estado: string;
  };
  type respuestaDetalleUafeType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  async function getRegistrarPeticion(
    parametro: string,
    user: string,
    esRepresentante: boolean
  ) {
    try {
      await axios
        .get<respuestaUafeType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud" +
            parametro
        )
        .then((response) => {
          console.log(
            "respuesta del registro peticion >>>>>>>>>>>>>> :",
            response.data
          );
          console.log(
            "id de respuesta del registro peticion es >>>>>>>>>>>>>> :",
            response.data.Objeto.Id
          );
          guardarCrmUafe(
            response.data.Objeto.Id,
            esRepresentante
              ? String(params.idcrm) + "_representante"
              : String(params.idcrm),
            response.data.Objeto.Fecha,
            user
          );
        })
        .catch((error) => {
          console.log("algo paso 1 >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarCrmUafe(
    iduafe: number,
    idcrm: string,
    fecha: string,
    user: string
  ) {
    const nuevoRegistro: CrmUafeType = {
      idCrm: idcrm,
      idUafe: iduafe,
      scuFecha: fecha,
      scuSolicitante: user,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistroUafe(nuevoRegistro);
  }

  async function postGuardarRegistroUafe(parametroGuardar: CrmUafeType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postObtenerTokenDMS(elemento: TokenDMSType, cliente: DMSClienteType[]) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post<TokenRespuestaDMSType>(
          "https://advance.corporacionproauto.com.ec/api/token",
          elemento,
          {
            headers: headers,
          }
        )
        .then((response) => {
          postGuardarClienteDMS(cliente, response.data.token)
          console.log("mi token DMS es >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function postGuardarClienteDMS(cliente: DMSClienteType[], token: string) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+ token,
    };
    try {
      axios
        .post<ClienteRespuestaDMSType[]>(
          "https://advance.corporacionproauto.com.ec/api/Tercero",
          cliente,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setBotonDeshabilitado(false);
          setOpenDialog(true);
          console.log("mi respuesta de crear cliente dms: ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function principal(
    cedula: string,
    primerNombre: string,
    segundoNombre: string,
    primerApellido: string,
    segundoApellido: string,
    user: string,
    tipoPersona: number,
    esRepresentante: boolean
  ) {
    await getRegistrarPeticion(
      "?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
        String(1) +
        "%26quot;},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
        user +
        "%26quot;},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
        cedula +
        "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
        primerNombre +
        "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
        segundoNombre +
        "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
        primerApellido +
        "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
        segundoApellido +
        "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
        String(tipoPersona) +
        "}}}",
      user,
      esRepresentante
    );
  }

  async function getPeticionUafe(
    user: string,
    cedula: string,
    primer_nombre: string,
    segundo_nombre: string,
    primer_apellido: string,
    segundo_apellido: string,
    tipo_persona: string,
    esRepresentante: boolean
  ) {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
            params.idcrm
        )
        .then((response) => {
          console.log("mi respuesta de la peticion UAFE: ", response.data);
          {
            response.data.idUafe > 0
              ? console.log("")
              : principal(
                  cedula,
                  primer_nombre,
                  segundo_nombre,
                  primer_apellido,
                  segundo_apellido,
                  user,
                  tipo_persona === "Persona Natural"
                    ? 1
                    : tipo_persona === "Persona Jurídica"
                    ? 3
                    : 2,
                  esRepresentante
                );
          }
        })
        .catch((error) => {
          principal(
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            user,
            tipo_persona === "Persona Natural"
              ? 1
              : tipo_persona === "Persona Jurídica"
              ? 3
              : 2,
            esRepresentante
          );
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [data, setData] = React.useState<clienterpaType>({
    id: 0,
    idCrm: "",
    cedula: "",
    primerApellido: "",
    segundoApellido: "",
    primerNombre: "",
    segundoNombre: "",
    direccion: "",
    correo: "",
    telefono: "",
    celular: "",
    zona: "",
    subzona: "",
    actividad: "",
    origen: "",
    tipoFacts: "",
    tipoPerfil: "",
    identificación: "",
    tributario: "",
    activiadadEconomica: "",
    notas: "",
    pais: "",
    departamento: "",
    ciudad: "",
    barrio: "",
    fechaNacimiento: "",
    sexo: "",
    estadoCivil: "",

    hdAutorizaDatos: "",
    hdMedioContacto: "",
    hdInformarProductosServicios: "",
    hdInscritoComunidadMarca: "",
    hdAutorizaViaTelefonica: "",
    hdAutorizaPorEscrito: "",

    identificacionConyuge: "",
    tipoDocumentoConyuge: "",
    apellidoConyuge: "",
    nombreConyuge: "",

    uafFuncionJudicial: "",
    uafMinisterioInterior: "",
    uafCritOfiCump: "",
    uafSriRentaCausada: "",
    uafAntComercialNegativo: "",
    uafCoincidenciaListados: "",

    nombreComercial: "",
    empresaLabora: "",
    ingresosMes: 0,
    otrosIngresos: 0,
    totalActivos: 0,
    egresos: 0,
    totalPasivos: 0,
    callePrincipal: "",
    calleSecundaria: "",
    refDeUbc: "",
    mailEmpresarial: "",
    telefonoEmpresa: "",
    celularEmpresa: "",
    personaJuridica: 1,
    empresaNombre: "",
    empresaRepresentante: "",
    dms: 0,
  });

  const [dataApi, setDataApi] = React.useState<ClienteRpaApiType>({
    id: 0,
    id_crm: "",
    usu: 0,
    idCli: 0,
    zona: 0,
    id_cot_estado: 0,
    raz: "",
    nom1: "",
    nom2: "",
    apell1: "",
    apell2: "",
    vend: 0,
    nit: "",
    tipo_ide: "",
    activ_eco: 0,
    habeas_data: 0,
    id_medio_habeas: 0,
    defecto_habeas: 0,
    origen: 0,
    prov: 0,
    tipo_perfil: 0,
    idTipoTributario: 0,
    id_cot_cliente_pais: 0,
    tel1: "",
    tel2: "",
    direccion: "",
    url: "",
    privado: 0,
    digito: 0,
    mailContacto: "",
    codigo: "",
    apellido_conyuge: "",
    nombre_conyuge: "",
    empresa_labora: "",
    ingresos_mes: 0,
    total_activos: 0,
    total_pasivos: 0,
    otros_ingresos: 0,
    acepta_decla: "",
    desemp: "",
    id_cot_cliente_paisUafe: 0,
    id_cot_uaf1: 0,
    id_cot_uaf2: 0,
    id_cot_uaf3: 0,
    id_cot_uaf4: 0,
    id_cot_uaf5: 0,
    id_cot_uaf6: 0,
    id_cot_uaf7: 0,
    id_cot_uaf8: 0,
    id_cot_uaf9: 0,
    tipo_functipo_func: 0,
    id_tes_bancos1: 0,
    id_tes_bancos2: 0,
    nombre1: "",
    direccion1: "",
    contacto1: "",
    telefono1: "",
    antiguedad1: "",
    nombre2: "",
    direccion2: "",
    contacto2: "",
    telefono2: "",
    antiguedad2: "",
    nombre3: "",
    documento3: "",
    tipo3: 0,
    direccion3: "",
    telefono3: "",
    telefono3_2: "",
    relacion: 0,
    desc_relacion: "",
    observaciones: "",
    info_adicional: "",
    docs_requeridos: "",
    SoyCliente: 0,
    id_usuario: 0,
    egresos: 0,
    id_cot_uaf10: 0,
    id_cot_uaf11: 0,
    id_cot_uaf12: 0,
    id_cot_uaf13: 0,
    id_cot_uaf14: 0,
    id_cot_uaf15: 0,
    id_cot_uaf16: 0,
    id_cot_uaf17: 0,
    id_cot_uaf18: 0,
    tipo_identificacion_conyuge: "",
    identificacion_conyuge: "",
    nombre_comercial: "",
    calle_principal: "",
    calle_secundaria: "",
    ref_ubicacion: "",
    mail_emp: "",
    telefono_emp: "",
    cel_emp: "",
    ingresos_mes_p: 0,
    total_activos_p: 0,
    total_pasivos_p: 0,
    otros_ingresos_p: 0,
    egresos_p: 0,
    id_cot_uaf19: 0,
    nombre_comercial_p: "",
    calle_principal_p: "",
    calle_secundaria_p: "",
    ref_ubicacion_p: "",
    mail_emp_p: "",
    telefono_emp_p: "",
    cel_emp_p: "",
    id_cot_uaf20: 0,
    servicios: "",
    id_cot_uaf21: 0,
    egresos_mes_p: 0,
    desemp1: "",
    desemp3: "",
    des_desemp1: "",
    fecha_desemp_1: "",
    relacion_2: "",
    nombres_rel_2: "",
    cargo_2: "",
    fecha_desemp_2: "",
    des_desemp3: "",
    fecha_desemp_3: "",
    sexo: 0,
fechaCumple: "",
estadoCivil: 0,
  });

  async function getDatos() {
    try {
      await axios
        .get<clienterpaType>(
          "https://apisatelite.azurewebsites.net/clienterpa/" + params.idcrm
        )
        .then((response) => {
          setData(response.data);
          setMostrar(true);
          console.log("mi respuesta: ", response.data);
        })
        .catch((error) => {
          console.log("algo paso 2>> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDataClienteRpaApi() {
    try {
      await axios
        .get<ClienteRpaApiType>(
          "https://apisatelite.azurewebsites.net/vclienterpaapi/" + params.idcrm
        )
        .then((response) => {
          setDataApi(response.data);
          //setMostrar(true);
          console.log("mi respuesta del api para dms: ", response);
        })
        .catch((error) => {
          console.log("algo paso 2>> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [cheked, setCheked] = React.useState({
    es_reserva: false,
    valor_reserva: 0,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  const [valorReserva, setValorReserva] = React.useState(0);
  const dataReserva = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValorReserva(Number(e.target.value));
  };

  type respuestaCrearCliType = {
    Codigo: string;
    Descripcion: string;
    Objeto: string;
  };

  const [respuestaGet, setRespuestaGet] = useState<respuestaCrearCliType>({
    Codigo: "",
    Descripcion: "",
    Objeto: "",
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogErrorUafe, setOpenDialogErrorUafe] = React.useState(false);
  const [openModalUafe, setOpenModalUafe] = React.useState(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [openDialogErrorTipoPersona, setOpenDialogErrorTipoPersona] =
    React.useState(false);

  async function enviarEmpresa() {
    await getPeticionUafe(
      username,
      data.cedula,
      "",
      "",
      data.empresaNombre,
      "",
      "Persona Juridica",
      false
    );
    await getPeticionUafe(
      username,
      "",
      "",
      "",
      data.empresaRepresentante,
      "",
      "Persona Natural",
      true
    );
  }

  function enviarUafe() {
    if (data.personaJuridica === 0) {
      if (data.primerApellido.length > 2 && data.primerNombre.length > 2) {
        getPeticionUafe(
          username,
          data.cedula,
          data.primerNombre,
          data.segundoNombre,
          data.primerApellido,
          data.segundoApellido,
          "Persona Natural",
          false
        );
        setOpenModalUafe(false);
      } else {
        setOpenDialogErrorUafe(true);
      }
    } else {
      if (
        data.empresaNombre.length > 2 &&
        data.empresaRepresentante.length > 2
      ) {
        enviarEmpresa();
        setOpenModalUafe(false);
      } else {
        setOpenDialogErrorUafe(true);
      }
    }
  }

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const validarCedula = (cedula: string): boolean => {
    // Verificar que la cédula tenga 10 dígitos
    if (cedula.length !== 10) {
      return false;
    }

    // Verificar que los dos primeros dígitos correspondan a una provincia válida (de 01 a 24)
    const provincia = parseInt(cedula.substring(0, 2), 10);
    if (provincia < 1 || provincia > 24) {
      return false;
    }

    // Verificar que el tercer dígito sea válido para el tipo de documento
    const tipoDocumento = parseInt(cedula.charAt(2), 10);
    if (tipoDocumento < 0 || tipoDocumento > 5) {
      return false;
    }

    // Aplicar un algoritmo de validación para los últimos siete dígitos (puede variar)
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    let suma = 0;
    for (let i = 0; i < 9; i++) {
      let multiplicacion = parseInt(cedula.charAt(i), 10) * coeficientes[i];
      if (multiplicacion > 9) {
        multiplicacion -= 9;
      }
      suma += multiplicacion;
    }
    const ultimoDigitoCalculado = suma % 10 === 0 ? 0 : 10 - (suma % 10);
    const ultimoDigito = parseInt(cedula.charAt(9), 10);

    return ultimoDigito === ultimoDigitoCalculado;
  };

  enum TipoIdentificacion {
    cero,
    Cedula,
    RUCPersonaNatural,
    RUCJuridica,
    CedulaExtranjeria,
    Otro,
  }

  const validarNumeroIdentificacion = (numero: string): TipoIdentificacion => {
    // Verificar que el número tenga una longitud válida
    // if (numero.length < 10) {
    //   return TipoIdentificacion.Otro;
    // }

    // Verificar si es cédula de ciudadanía
    if (numero.length === 10) {
      const esCedulaValida = validarCedula(numero);
      return esCedulaValida
        ? TipoIdentificacion.Cedula
        : TipoIdentificacion.CedulaExtranjeria;
    }

    // Verificar si es RUC de persona natural
    if (
      numero.length === 13 &&
      numero.substring(10, 13) === "001" &&
      validarCedula(numero.substring(0, 10))
    ) {
      return TipoIdentificacion.RUCPersonaNatural;
    }

    // Verificar si es RUC de empresa
    if (numero.length === 13 && Number(numero.substring(2, 3)) > 5) {
      return TipoIdentificacion.RUCJuridica;
    }

    // Verificar si es cédula de extranjero
    if (numero.length === 13 && numero.substring(10, 13) === "002") {
      return TipoIdentificacion.CedulaExtranjeria;
    }

    // Otros casos
    return TipoIdentificacion.CedulaExtranjeria;
  };

  async function getRegistrarPeticionCrearCliente(parametro: string) {
    console.log(
      "Ejemplo de peticion >>>>>>>>>>>>>>>>",
      "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:2},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
        username +
        "%26quot;},%26quot;CrearCliente%26quot;:{%26quot;Crm%26quot;:%26quot;" +
        parametro +
        "%26quot;,%26quot;Tipo%26quot;:%26quot;Basico%26quot;},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
        String(1) +
        "%26quot;}}"
    );
    try {
      await axios
        .get<respuestaCrearCliType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:2},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
            username +
            "%26quot;},%26quot;CrearCliente%26quot;:{%26quot;Crm%26quot;:%26quot;" +
            parametro +
            "%26quot;,%26quot;Tipo%26quot;:%26quot;Basico%26quot;},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
            String(1) +
            "%26quot;}}"
        )
        .then((response) => {
          setRespuestaGet(response.data);
          console.log("respuesta del registro peticion >>> :", response.data);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function setRegistroNuevo(
    empresas: number[],
    usu: number,
    idCli: number,
    zona: number,
    id_cot_estado: number,
    raz: string,
    nom1: string,
    nom2: string,
    apell1: string,
    apell2: string,
    vend: number,
    nit: string,
    tipo_ide: string,
    activ_eco: number,
    habeas_data: number,
    id_medio_habeas: number,
    defecto_habeas: number,
    origen: number,
    prov: number,
    tipo_perfil: number,
    idTipoTributario: number,
    id_cot_cliente_pais: number,
    tel1: string,
    tel2: string,
    direccion: string,
    url: string,
    privado: number,
    digito: number,
    mailContacto: string,
    codigo: string,
    apellido_conyuge: string,
    nombre_conyuge: string,
    empresa_labora: string,
    ingresos_mes: number,
    total_activos: number,
    total_pasivos: number,
    otros_ingresos: number,
    acepta_decla: string,
    desemp: string,
    id_cot_cliente_paisUafe: number,
    id_cot_uaf1: number,
    id_cot_uaf2: number,
    id_cot_uaf3: number,
    id_cot_uaf4: number,
    id_cot_uaf5: number,
    id_cot_uaf6: number,
    id_cot_uaf7: number,
    id_cot_uaf8: number,
    id_cot_uaf9: number,
    tipo_func: number,
    id_tes_bancos1: number,
    id_tes_bancos2: number,
    nombre1: string,
    direccion1: string,
    contacto1: string,
    telefono1: string,
    antiguedad1: string,
    nombre2: string,
    direccion2: string,
    contacto2: string,
    telefono2: string,
    antiguedad2: string,
    nombre3: string,
    documento3: string,
    tipo3: number,
    direccion3: string,
    telefono3: string,
    telefono3_2: string,
    relacion: number,
    desc_relacion: string,
    observaciones: string,
    info_adicional: string,
    docs_requeridos: string,
    SoyCliente: number,
    id_usuario: number,
    egresos: number,
    id_cot_uaf10: number,
    id_cot_uaf11: number,
    id_cot_uaf12: number,
    id_cot_uaf13: number,
    id_cot_uaf14: number,
    id_cot_uaf15: number,
    id_cot_uaf16: number,
    id_cot_uaf17: number,
    id_cot_uaf18: number,
    tipo_identificacion_conyuge: string,
    identificacion_conyuge: string,
    nombre_comercial: string,
    calle_principal: string,
    calle_secundaria: string,
    ref_ubicacion: string,
    mail_emp: string,
    telefono_emp: string,
    cel_emp: string,
    ingresos_mes_p: number,
    total_activos_p: number,
    total_pasivos_p: number,
    otros_ingresos_p: number,
    egresos_p: number,
    id_cot_uaf19: number,
    nombre_comercial_p: string,
    calle_principal_p: string,
    calle_secundaria_p: string,
    ref_ubicacion_p: string,
    mail_emp_p: string,
    telefono_emp_p: string,
    cel_emp_p: string,
    id_cot_uaf20: number,
    servicios: string,
    id_cot_uaf21: number,
    egresos_mes_p: number,
    desemp1: string,
    desemp3: string,
    des_desemp1: string,
    fecha_desemp_1: string,
    relacion_2: string,
    nombres_rel_2: string,
    cargo_2: string,
    fecha_desemp_2: string,
    des_desemp3: string,
    fecha_desemp_3: string,
    sexo: number,
    cechaCumple: string,
    estCivil: number
  ) {
    setBotonDeshabilitado(true);
    console.log("tabla a guardar >> ");
    const token: TokenDMSType = {
      usuario: "dms",
      password: "DMS12345",
    }
    const nuevoRegistro: DMSClienteType = {
      empresas: empresas,
      usu: usu,
      idCli: idCli,
      zona: zona,
      id_cot_estado: id_cot_estado,
      raz: raz,
      nom1: nom1,
      nom2: nom2,
      apell1: apell1,
      apell2: apell2,
      vend: vend,
      nit: nit,
      tipo_ide: tipo_ide,
      activ_eco: activ_eco,
      habeas_data: habeas_data,
      id_medio_habeas: id_medio_habeas,
      defecto_habeas: defecto_habeas,
      origen: origen,
      prov: prov,
      tipo_perfil: tipo_perfil,
      idTipoTributario: idTipoTributario,
      id_cot_cliente_pais: id_cot_cliente_pais,
      tel1: tel1,
      tel2: tel2,
      direccion: direccion,
      url: url,
      privado: privado,
      digito: digito,
      mailContacto: mailContacto,
      codigo: codigo,
      apellido_conyuge: apellido_conyuge,
      nombre_conyuge: nombre_conyuge,
      empresa_labora: empresa_labora,
      ingresos_mes: ingresos_mes,
      total_activos: total_activos,
      total_pasivos: total_pasivos,
      otros_ingresos: otros_ingresos,
      acepta_decla: true,
      desemp: true,
      id_cot_cliente_paisUafe: id_cot_cliente_paisUafe,
      id_cot_uaf1: id_cot_uaf1,
      id_cot_uaf2: id_cot_uaf2,
      id_cot_uaf3: id_cot_uaf3,
      id_cot_uaf4: id_cot_uaf4,
      id_cot_uaf5: id_cot_uaf5,
      id_cot_uaf6: id_cot_uaf6,
      id_cot_uaf7: id_cot_uaf7,
      id_cot_uaf8: id_cot_uaf8,
      id_cot_uaf9: id_cot_uaf9,
      tipo_func: tipo_func,
      id_tes_bancos1: id_tes_bancos1,
      id_tes_bancos2: id_tes_bancos2,
      nombre1: nombre1,
      direccion1: direccion1,
      contacto1: contacto1,
      telefono1: telefono1,
      antiguedad1: antiguedad1,
      nombre2: nombre2,
      direccion2: direccion2,
      contacto2: contacto2,
      telefono2: telefono2,
      antiguedad2: antiguedad2,
      nombre3: nombre3,
      documento3: documento3,
      tipo3: tipo3,
      direccion3: direccion3,
      telefono3: telefono3,
      telefono3_2: telefono3_2,
      relacion: relacion,
      desc_relacion: desc_relacion,
      observaciones: observaciones,
      info_adicional: info_adicional,
      docs_requeridos: docs_requeridos,
      SoyCliente: SoyCliente,
      id_usuario: id_usuario,
      egresos: egresos,
      id_cot_uaf10: id_cot_uaf10,
      id_cot_uaf11: id_cot_uaf11,
      id_cot_uaf12: id_cot_uaf12,
      id_cot_uaf13: id_cot_uaf13,
      id_cot_uaf14: id_cot_uaf14,
      id_cot_uaf15: id_cot_uaf15,
      id_cot_uaf16: id_cot_uaf16,
      id_cot_uaf17: id_cot_uaf17,
      id_cot_uaf18: id_cot_uaf18,
      tipo_identificacion_conyuge: tipo_identificacion_conyuge,
      identificacion_conyuge: identificacion_conyuge,
      nombre_comercial: nombre_comercial,
      calle_principal: calle_principal,
      calle_secundaria: calle_secundaria,
      ref_ubicacion: ref_ubicacion,
      mail_emp: mail_emp,
      telefono_emp: telefono_emp,
      cel_emp: cel_emp,
      ingresos_mes_p: ingresos_mes_p,
      total_activos_p: total_activos_p,
      total_pasivos_p: total_pasivos_p,
      otros_ingresos_p: otros_ingresos_p,
      egresos_p: egresos_p,
      id_cot_uaf19: id_cot_uaf19,
      nombre_comercial_p: nombre_comercial_p,
      calle_principal_p: calle_principal_p,
      calle_secundaria_p: calle_secundaria_p,
      ref_ubicacion_p: ref_ubicacion_p,
      mail_emp_p: mail_emp_p,
      telefono_emp_p: telefono_emp_p,
      cel_emp_p: cel_emp_p,
      id_cot_uaf20: id_cot_uaf20,
      servicios: servicios,
      id_cot_uaf21: id_cot_uaf21,
      egresos_mes_p: egresos_mes_p,
      desemp1: true,
      desemp3: true,
      des_desemp1: des_desemp1,
      fecha_desemp_1: fecha_desemp_1,
      relacion_2: relacion_2,
      nombres_rel_2: nombres_rel_2,
      cargo_2: cargo_2,
      fecha_desemp_2: fecha_desemp_2,
      des_desemp3: des_desemp3,
      fecha_desemp_3: fecha_desemp_3,
      sexo: sexo,
      cechaCumple: cechaCumple,
      estCivil: estCivil
    };
    const listaobjetos: DMSClienteType[] = [nuevoRegistro];
    console.log("datos a guardar >> ", JSON.stringify(listaobjetos, null, 4))
    {idCli === 0 ?  postObtenerTokenDMS(token,listaobjetos) : 
      setBotonDeshabilitado(false);
      setOpenDialog(true);      
    }
      
   
  }



  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Dialog
              open={openDialog}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Cliente guardado correctamente!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href={`/ventas/index/${params.idcrm}`}
                  onClick={function (event) {
                    setOpenDialog(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogError}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Petición no enviada, revisar alertas
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas"
                  onClick={function (event) {
                    setOpenDialogError(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorTipoPersona}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Tipo de persona incorrecto
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas"
                  onClick={function (event) {
                    setOpenDialogError(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorUafe}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Petición no enviada, datos incompletos.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas"
                  onClick={function (event) {
                    setOpenDialogErrorUafe(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                Datos del cliente
              </Typography>
            </Paper>

            {mostrar ? (
              <>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 1,
                    mb: 4,
                  }}
                >
                  
                  
                  <Modal
                    open={openModalUafe}
                    onClose={function (event) {
                      setOpenModalUafe(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModal}>
                      <TextField
                        name="nombre"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Identificación"
                        style={{
                          backgroundColor: "rgb(242,242,242)",
                        }}
                        value={data.cedula}
                        //onChange={handleData}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "39%",
                        }}
                      />
                      <TextField
                        name="nombre"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Tipo cliente"
                        style={{
                          backgroundColor: "rgb(242,242,242)",
                        }}
                        value={
                          data.personaJuridica != 1
                            ? "Persona natural"
                            : "Empresa"
                        }
                        //onChange={handleData}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "2%",
                          width: "39%",
                        }}
                      />

                      {data.personaJuridica != 1 ? (
                        <>
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Apellido 1"
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            value={data.primerApellido}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Apellido 2"
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            value={data.segundoApellido}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Nombre 1"
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            value={data.primerNombre}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Nombre 2"
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            value={data.segundoNombre}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Empresa"
                            value={data.empresaNombre}
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />
                          <TextField
                            name="nombre"
                            size="small"
                            margin="normal"
                            fullWidth
                            label="Representante legal"
                            value={data.empresaRepresentante}
                            style={{
                              backgroundColor: "rgb(242,242,242)",
                            }}
                            //onChange={handleData}
                            sx={{
                              mt: 0.5,
                              mb: 0.5,
                              marginLeft: "10%",
                              width: "80%",
                            }}
                          />
                        </>
                      )}

                      <Button
                        variant="contained"
                        sx={{ mt: 1, mb: 1, marginLeft: "42%", width: "16" }}
                        onClick={function (event) {
                          enviarUafe();
                        }}
                      >
                        Enviar
                      </Button>
                    </Box>
                  </Modal>
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                    variant="h6"
                  >
                    <strong>Información del Cliente</strong>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cédula:
                  </Typography>
                  <TextField
                    id="cedula"
                    size="small"
                    margin="normal"
                    value={data.cedula}
                    //onChange={handleChangeZona}
                    style={{
                      backgroundColor:
                        String(data.cedula).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    ID CRM:
                  </Typography>
                  <TextField
                    id="id_crm"
                    size="small"
                    margin="normal"
                    name="idCrm"
                    value={data.idCrm}
                    style={{
                      backgroundColor:
                        String(data.idCrm).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  {data.personaJuridica != 0 ? (
                    <>
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                          marginLeft: "10%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Representante legal:
                      </Typography>
                      <TextField
                        id="repre_legal"
                        size="small"
                        margin="normal"
                        value={data.empresaRepresentante}
                        style={{
                          backgroundColor:
                            String(data.empresaRepresentante).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginRight: "4%",
                          width: "65%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                          marginLeft: "10%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Empresa:
                      </Typography>
                      <TextField
                        id="empresa"
                        size="small"
                        margin="normal"
                        value={data.empresaNombre}
                        style={{
                          backgroundColor:
                            String(data.empresaNombre).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        //onChange={handleChangeZona}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginRight: "4%",
                          width: "65%",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                          marginLeft: "10%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Primer apellido:
                      </Typography>
                      <TextField
                        id="primer_apellido"
                        size="small"
                        margin="normal"
                        name="primerApellido"
                        value={data.primerApellido}
                        style={{
                          backgroundColor:
                            String(data.primerApellido).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        //onChange={handleChangeZona}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginRight: "4%",
                          width: "23%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Segundo apellido:
                      </Typography>
                      <TextField
                        id="segundo_apellido"
                        size="small"
                        margin="normal"
                        name="segundoApellido"
                        value={data.segundoApellido}
                        style={{
                          backgroundColor:
                            String(data.segundoApellido).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        //onChange={handleChangeZona}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "23%",
                          marginRight: "10%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                          marginLeft: "10%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Primer nombre:
                      </Typography>
                      <TextField
                        id="primer_nombre"
                        size="small"
                        margin="normal"
                        value={data.primerNombre}
                        style={{
                          backgroundColor:
                            String(data.primerNombre).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        //onChange={handleChangeZona}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginRight: "4%",
                          width: "23%",
                        }}
                      />
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Segundo nombre:
                      </Typography>
                      <TextField
                        id="segundo_nombre"
                        size="small"
                        margin="normal"
                        value={data.segundoNombre}
                        style={{
                          backgroundColor:
                            String(data.segundoNombre).length > 0
                              ? "rgb(242,242,242)"
                              : "rgb(247,202,202)",
                        }}
                        //onChange={handleChangeZona}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "23%",
                          marginRight: "10%",
                        }}
                      />
                    </>
                  )}
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Direccion:
                  </Typography>
                  <TextField
                    id="direccion"
                    size="small"
                    margin="normal"
                    value={data.direccion}
                    style={{
                      backgroundColor:
                        String(data.direccion).length > 29
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Correo:
                  </Typography>
                  <TextField
                    id="correo"
                    size="small"
                    margin="normal"
                    value={data.correo}
                    //onChange={handleChangeZona}
                    style={{
                      backgroundColor:
                        String(data.correo).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Teléfono:
                  </Typography>
                  <TextField
                    id="telefono"
                    size="small"
                    margin="normal"
                    value={data.telefono}
                    style={{
                      backgroundColor:
                        String(data.telefono).length > 6
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Celular:
                  </Typography>
                  <TextField
                    id="celular"
                    size="small"
                    margin="normal"
                    value={data.celular}
                    style={{
                      backgroundColor:
                        String(data.celular).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Zona:
                  </Typography>
                  <TextField
                    id="zona"
                    size="small"
                    margin="normal"
                    value={data.zona}
                    //onChange={handleChangeZona}
                    style={{
                      backgroundColor:
                        String(data.zona).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Subzona:
                  </Typography>
                  <TextField
                    id="subzona"
                    size="small"
                    margin="normal"
                    value={data.subzona}
                    //onChange={handleChangeZona}
                    style={{
                      backgroundColor:
                        String(data.subzona).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Actividad:
                  </Typography>
                  <TextField
                    id="actividad"
                    size="small"
                    margin="normal"
                    value={data.actividad}
                    style={{
                      backgroundColor:
                        String(data.activiadadEconomica).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Origen:
                  </Typography>
                  <TextField
                    id="origen"
                    size="small"
                    margin="normal"
                    value={data.origen}
                    style={{
                      backgroundColor:
                        String(data.origen).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Tipo FACTS:
                  </Typography>
                  <TextField
                    id="tipo_facts"
                    size="small"
                    margin="normal"
                    value={data.tipoFacts}
                    style={{
                      backgroundColor:
                        String(data.tipoFacts).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Tipo perfil:
                  </Typography>
                  <TextField
                    id="tipo_perfil"
                    size="small"
                    margin="normal"
                    value={data.tipoPerfil}
                    style={{
                      backgroundColor:
                        String(data.tipoPerfil).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Identificación:
                  </Typography>
                  <TextField
                    id="identificacion"
                    size="small"
                    margin="normal"
                    value={data.identificación}
                    style={{
                      backgroundColor:
                        String(data.identificación).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "12%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Aplica reserva:
                  </Typography>
                  <Switch
                    checked={cheked.es_reserva}
                    onChange={handleCheked}
                    name="es_reserva"
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      marginLeft: "2%",
                      marginRight: "2%",
                    }}
                  />
                  {cheked.es_reserva ? (
                    <>
                      <TextField
                        id="valorReserva"
                        size="small"
                        margin="normal"
                        value={valorReserva}
                        onChange={dataReserva}
                        sx={{ mt: 0.5, mb: 0.5, width: "8%" }}
                      />
                      <Button
                      disabled={botonDeshabilitado}
                        onClick={function (event) { 
                          console.log("validacion de cedula >> ", validarNumeroIdentificacion(data.cedula))
                          console.log("validacion de juridica >> ", data.personaJuridica)
                          console.log("validadcion direccion (>29) >>", String(data.direccion).length)
                          console.log("validadcion 1er nombre (>0) >>", String(data.primerNombre).length)
                          console.log("validadcion 1er apellido (>0) >>", String(data.primerApellido).length)
                          console.log("validadcion telefono (>2) >>", String(data.telefono).length)
                          console.log("validadcion correo (>5) >>", String(data.correo).length)
                          if (
                            Number(validarNumeroIdentificacion(data.cedula)) === 3 &&
                            Number(data.personaJuridica) === 0
                          ) {
                            setOpenDialogErrorTipoPersona(true);
                          } else {
                            String(data.direccion).length > 29 &&
                            //data.primerNombre.length > 0 &&
                            data.primerApellido.length > 0 &&
                            data.telefono.length > 2 &&
                            data.correo.length > 5
                              ? 
                              // getRegistrarPeticionCrearCliente(
                              //     String(params.idcrm)
                              //   )                              
                      
                              setRegistroNuevo(
                                [605],
                                dataApi.usu,
                                dataApi.idCli,
                                dataApi.zona,
                                dataApi.id_cot_estado,
                                dataApi.raz,
                                dataApi.nom1,
                                dataApi.nom2,
                                dataApi.apell1,
                                dataApi.apell2,
                                dataApi.vend,
                                dataApi.nit,
                                dataApi.tipo_ide,
                                dataApi.activ_eco,
                                dataApi.habeas_data,
                                dataApi.id_medio_habeas,
                                dataApi.defecto_habeas,
                                dataApi.origen,
                                dataApi.prov,
                                dataApi.tipo_perfil,
                                dataApi.idTipoTributario,
                                dataApi.id_cot_cliente_pais,
                                dataApi.tel1,
                                dataApi.tel2,
                                dataApi.direccion,
                                dataApi.url,
                                dataApi.privado,
                                dataApi.digito,
                                dataApi.mailContacto,
                                dataApi.codigo,
                                dataApi.apellido_conyuge,
                                dataApi.nombre_conyuge,
                                dataApi.empresa_labora,
                                dataApi.ingresos_mes,
                                dataApi.total_activos,
                                dataApi.total_pasivos,
                                dataApi.otros_ingresos,
                                dataApi.acepta_decla,
                                dataApi.desemp,
                                dataApi.id_cot_cliente_paisUafe,
                                dataApi.id_cot_uaf1,
                                dataApi.id_cot_uaf2,
                                dataApi.id_cot_uaf3,
                                dataApi.id_cot_uaf4,
                                dataApi.id_cot_uaf5,
                                dataApi.id_cot_uaf6,
                                dataApi.id_cot_uaf7,
                                dataApi.id_cot_uaf8,
                                dataApi.id_cot_uaf9,
                                dataApi.tipo_functipo_func,
                                dataApi.id_tes_bancos1,
                                dataApi.id_tes_bancos2,
                                dataApi.nombre1,
                                dataApi.direccion1,
                                dataApi.contacto1,
                                dataApi.telefono1,
                                dataApi.antiguedad1,
                                dataApi.nombre2,
                                dataApi.direccion2,
                                dataApi.contacto2,
                                dataApi.telefono2,
                                dataApi.antiguedad2,
                                dataApi.nombre3,
                                dataApi.documento3,
                                dataApi.tipo3,
                                dataApi.direccion3,
                                dataApi.telefono3,
                                dataApi.telefono3_2,
                                dataApi.relacion,
                                dataApi.desc_relacion,
                                dataApi.observaciones,
                                dataApi.info_adicional,
                                dataApi.docs_requeridos,
                                dataApi.SoyCliente,
                                dataApi.id_usuario,
                                dataApi.egresos,
                                dataApi.id_cot_uaf10,
                                dataApi.id_cot_uaf11,
                                dataApi.id_cot_uaf12,
                                dataApi.id_cot_uaf13,
                                dataApi.id_cot_uaf14,
                                dataApi.id_cot_uaf15,
                                dataApi.id_cot_uaf16,
                                dataApi.id_cot_uaf17,
                                dataApi.id_cot_uaf18,
                                dataApi.tipo_identificacion_conyuge,
                                dataApi.identificacion_conyuge,
                                dataApi.nombre_comercial,
                                dataApi.calle_principal,
                                dataApi.calle_secundaria,
                                dataApi.ref_ubicacion,
                                dataApi.mail_emp,
                                dataApi.telefono_emp,
                                dataApi.cel_emp,
                                dataApi.ingresos_mes_p,
                                dataApi.total_activos_p,
                                dataApi.total_pasivos_p,
                                dataApi.otros_ingresos_p,
                                dataApi.egresos_p,
                                dataApi.id_cot_uaf19,
                                dataApi.nombre_comercial_p,
                                dataApi.calle_principal_p,
                                dataApi.calle_secundaria_p,
                                dataApi.ref_ubicacion_p,
                                dataApi.mail_emp_p,
                                dataApi.telefono_emp_p,
                                dataApi.cel_emp_p,
                                dataApi.id_cot_uaf20,
                                dataApi.servicios,
                                dataApi.id_cot_uaf21,
                                dataApi.egresos_mes_p,
                                dataApi.desemp1,
                                dataApi.desemp3,
                                dataApi.des_desemp1,
                                dataApi.fecha_desemp_1,
                                dataApi.relacion_2,
                                dataApi.nombres_rel_2,
                                dataApi.cargo_2,
                                dataApi.fecha_desemp_2,
                                dataApi.des_desemp3,
                                dataApi.fecha_desemp_3,
                                dataApi.sexo,
                                dataApi.fechaCumple,
                                dataApi.estadoCivil
                              )

                              : setOpenDialogError(true);

                            String(data.direccion).length > 29 &&
                            data.primerNombre.length > 0 &&
                            data.primerApellido.length > 0 &&
                            data.telefono.length > 2 &&
                            data.correo.length > 5
                              ? enviarUafe()
                              : console.log("no se envio la uafe");
                          }
                        }}
                        variant="contained"
                        sx={{ marginLeft: "2%" }}
                      >
                        Enviar
                      </Button>
                    </>
                  ) : (
                    <>               
                


                    
                    </>
                  )}
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Tributario:
                  </Typography>
                  <TextField
                    id="tributario"
                    size="small"
                    margin="normal"
                    value={data.tributario}
                    style={{
                      backgroundColor:
                        String(data.tributario).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "65%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Activiadad Economica:
                  </Typography>
                  <TextField
                    id="actividad_economica"
                    size="small"
                    margin="normal"
                    value={data.activiadadEconomica}
                    style={{
                      backgroundColor:
                        String(data.activiadadEconomica).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "65%", marginRight: "4%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Notas:
                  </Typography>
                  <TextField
                    id="notas"
                    size="small"
                    margin="normal"
                    value={data.notas}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "65%" }}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Pais:
                  </Typography>
                  <TextField
                    id="pais"
                    size="small"
                    margin="normal"
                    value={data.pais}
                    style={{
                      backgroundColor:
                        String(data.pais).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Departamento:
                  </Typography>
                  <TextField
                    id="departamento"
                    size="small"
                    margin="normal"
                    value={data.departamento}
                    style={{
                      backgroundColor:
                        String(data.departamento).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />{" "}
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Ciudad:
                  </Typography>
                  <TextField
                    id="ciudad"
                    size="small"
                    margin="normal"
                    value={data.ciudad}
                    style={{
                      backgroundColor:
                        String(data.ciudad).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Barrio:
                  </Typography>
                  <TextField
                    id="barrio"
                    size="small"
                    margin="normal"
                    value={data.barrio}
                    style={{
                      backgroundColor:
                        String(data.barrio).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Fecha Nacimiento:
                  </Typography>
                  <TextField
                    id="fecha_nacimiento"
                    size="small"
                    margin="normal"
                    value={data.fechaNacimiento}
                    style={{
                      backgroundColor:
                        String(data.fechaNacimiento).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Sexo:
                  </Typography>
                  <TextField
                    id="sexo"
                    size="small"
                    margin="normal"
                    value={data.sexo}
                    style={{
                      backgroundColor:
                        String(data.sexo).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Estado civil:
                  </Typography>
                  <TextField
                    id="estado_civil"
                    size="small"
                    margin="normal"
                    value={data.estadoCivil}
                    style={{
                      backgroundColor:
                        String(data.estadoCivil).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                    variant="h6"
                  >
                    <strong>Habeas Data</strong>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Utilización de datos:
                  </Typography>
                  <TextField
                    id="hdAutorizaDatos"
                    size="small"
                    margin="normal"
                    value={data.hdAutorizaDatos}
                    style={{
                      backgroundColor:
                        String(data.hdAutorizaDatos).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "13%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Contactar por:
                  </Typography>
                  <TextField
                    id="hdMedioContacto"
                    size="small"
                    margin="normal"
                    value={data.hdMedioContacto}
                    style={{
                      backgroundColor:
                        String(data.hdMedioContacto).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "13%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Informar productos/servicios:
                  </Typography>
                  <TextField
                    id="hdInformarProductosServicios"
                    size="small"
                    margin="normal"
                    value={data.hdInformarProductosServicios}
                    style={{
                      backgroundColor:
                        String(data.hdInformarProductosServicios).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "13%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Inscrito comunidad de la marca:
                  </Typography>
                  <TextField
                    id="hdInscritoComunidadMarca"
                    size="small"
                    margin="normal"
                    value={data.hdInscritoComunidadMarca}
                    style={{
                      backgroundColor:
                        String(data.hdInscritoComunidadMarca).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "13%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Autoriza vía telefónica:
                  </Typography>
                  <TextField
                    id="hdAutorizaViaTelefonica"
                    size="small"
                    margin="normal"
                    value={data.hdAutorizaViaTelefonica}
                    style={{
                      backgroundColor:
                        String(data.hdAutorizaViaTelefonica).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "13%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "25%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Autoriza por escrito:
                  </Typography>
                  <TextField
                    id="hdAutorizaPorEscrito"
                    size="small"
                    margin="normal"
                    value={data.hdAutorizaPorEscrito}
                    style={{
                      backgroundColor:
                        String(data.hdAutorizaPorEscrito).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "13%", marginRight: "10%" }}
                  />
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                    variant="h6"
                  >
                    <strong>Unidad de Analisis Financiero</strong>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Identificación Conyuge:
                  </Typography>
                  <TextField
                    id="identificacionConyuge"
                    size="small"
                    margin="normal"
                    value={data.identificacionConyuge}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Tipo documento:
                  </Typography>
                  <TextField
                    id="tipoDocumentoConyuge"
                    size="small"
                    margin="normal"
                    value={data.tipoDocumentoConyuge}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />{" "}
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Apellido Conyuge:
                  </Typography>
                  <TextField
                    id="apellidoConyuge"
                    size="small"
                    margin="normal"
                    value={data.apellidoConyuge}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Nombre Conyuge:
                  </Typography>
                  <TextField
                    id="nombreConyuge"
                    size="small"
                    margin="normal"
                    value={data.nombreConyuge}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Funcion Judicial:
                  </Typography>
                  <TextField
                    id="uafFuncionJudicial"
                    size="small"
                    margin="normal"
                    value={data.uafFuncionJudicial}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Ministerio Interior:
                  </Typography>
                  <TextField
                    id="uafMinisterioInterior"
                    size="small"
                    margin="normal"
                    value={data.uafMinisterioInterior}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />{" "}
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cri Ofi Cump:
                  </Typography>
                  <TextField
                    id="uafCritOfiCump"
                    size="small"
                    margin="normal"
                    value={data.uafCritOfiCump}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    SRI Renta Causada:
                  </Typography>
                  <TextField
                    id="uafSriRentaCausada"
                    size="small"
                    margin="normal"
                    value={data.uafSriRentaCausada}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Ant Comercial Negativo:
                  </Typography>
                  <TextField
                    id="uafAntComercialNegativo"
                    size="small"
                    margin="normal"
                    value={data.uafAntComercialNegativo}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Coincidencia Listados:
                  </Typography>
                  <TextField
                    id="uafCoincidenciaListados"
                    size="small"
                    margin="normal"
                    value={data.uafCoincidenciaListados}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />{" "}
                  <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                    variant="h6"
                  >
                    <strong>Información Laboral</strong>
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Nombre Comercial:
                  </Typography>
                  <TextField
                    id="nombreComercial"
                    size="small"
                    margin="normal"
                    value={data.nombreComercial}
                    style={{
                      backgroundColor:
                        String(data.nombreComercial).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Empresa Labora:
                  </Typography>
                  <TextField
                    id="empresaLabora"
                    size="small"
                    margin="normal"
                    value={data.empresaLabora}
                    style={{
                      backgroundColor:
                        String(data.empresaLabora).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Ingresos Mes:
                  </Typography>
                  <TextField
                    id="ingresosMes"
                    size="small"
                    margin="normal"
                    value={data.ingresosMes}
                    style={{
                      backgroundColor:
                        Number(data.ingresosMes) > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Otros Ingresos:
                  </Typography>
                  <TextField
                    id="otrosIngresos"
                    size="small"
                    margin="normal"
                    value={data.otrosIngresos}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Total activos:
                  </Typography>
                  <TextField
                    id="totalActivos"
                    size="small"
                    margin="normal"
                    value={data.totalActivos}
                    style={{
                      backgroundColor:
                        Number(data.totalActivos) > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Egresos:
                  </Typography>
                  <TextField
                    id="egresos"
                    size="small"
                    margin="normal"
                    value={data.egresos}
                    style={{
                      backgroundColor:
                        Number(data.egresos) > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Total Pasivos:
                  </Typography>
                  <TextField
                    id="totalPasivos"
                    size="small"
                    margin="normal"
                    value={data.totalPasivos}
                    style={{
                      backgroundColor:
                        Number(data.totalPasivos) > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Calle Principal:
                  </Typography>
                  <TextField
                    id="callePrincipal"
                    size="small"
                    margin="normal"
                    value={data.callePrincipal}
                    style={{
                      backgroundColor:
                        String(data.callePrincipal).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Calle Secundaria:
                  </Typography>
                  <TextField
                    id="calleSecundaria"
                    size="small"
                    margin="normal"
                    value={data.calleSecundaria}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Ref de Ubic:
                  </Typography>
                  <TextField
                    id="refDeUbc"
                    size="small"
                    margin="normal"
                    value={data.refDeUbc}
                    style={{
                      backgroundColor:
                        String(data.refDeUbc).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Mail empresarial:
                  </Typography>
                  <TextField
                    id="mailEmpresarial"
                    size="small"
                    margin="normal"
                    value={data.mailEmpresarial}
                    style={{
                      backgroundColor:
                        String(data.mailEmpresarial).length > 0
                          ? "rgb(242,242,242)"
                          : "rgb(247,202,202)",
                    }}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "23%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Teléfono Empresa:
                  </Typography>
                  <TextField
                    id="telefonoEmpresa"
                    size="small"
                    margin="normal"
                    value={data.telefonoEmpresa}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, width: "23%", marginRight: "10%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "15%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Celular Empresa:
                  </Typography>
                  <TextField
                    id="celularEmpresa"
                    size="small"
                    margin="normal"
                    value={data.celularEmpresa}
                    //onChange={handleChangeZona}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "50%", width: "23%" }}
                  />
                  <Button href={`/ventas/index/${params.idcrm}`}>Atras</Button>
                </Paper>
              </>
            ) : (
              <>
                <Paper>
                  <Grid
                    //container
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      sx={{ mt: 2, mb: 1.5 }}
                      variant="h6"
                      textAlign={"center"}
                    >
                      Cargando, espera por favor.
                    </Typography>
                  </Grid>
                </Paper>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
