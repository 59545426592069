import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  BodegaParametroTrasladoType,
  vBodegaParametroTrasladoType,
} from "../../types";

export const TrackingParametrosRutasPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      getListado();
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();
  const dataListaAutoriza: vBodegaParametroTrasladoType[] = [];
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [listaRows, setListaRows] =
    useState<vBodegaParametroTrasladoType[]>(dataListaAutoriza);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [datosRuta, setDatosRuta] =
    React.useState<vBodegaParametroTrasladoType>({
      bptId: 0,
      bodOrigen: 0,
      bodOrigenDesc: "",
      bodDestino: 0,
      bodDestinoDesc: "",
      bptMonto: 0,
      bptTiempo: 0,
      bptUnidadTiempo: "",
      bptObservacion: "",
    });

    const handleChangeDatosRuta = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDatosRuta({
        ...datosRuta,
        [event.target.name]: event.target.value,
      });
    };

  async function getListado() {
    try {
      await axios
        .get<vBodegaParametroTrasladoType[]>(
          "https://apisatelite.azurewebsites.net/vbodegaparametrotraslado/listado"
        )
        .then((response) => {
          setListaRows(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRuta(
    parametroGuardar: BodegaParametroTrasladoType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/bodegaparametrotraslado",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function setRegistro(
    bptId: number,
    bodOrigen: number,
    bodDestino: number,
    bptMonto: number,
    bptTiempo: number,
    bptUnidadTiempo: string,
    bptObservacion: string
  ) {
    const nuevoRegistro: BodegaParametroTrasladoType = {
      bptId: bptId,
      bodOrigen: bodOrigen,
      bodDestino: bodDestino,
      bptMonto: bptMonto,
      bptTiempo: bptTiempo,
      bptUnidadTiempo: bptUnidadTiempo,
      bptObservacion: bptObservacion,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRuta(nuevoRegistro);
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "bodOrigenDesc",
      headerName: "Origen",
      editable: false,
      width: 250,
    },
    {
      headerClassName: "theme-header",
      field: "bodDestinoDesc",
      headerName: "Destino",
      editable: false,
      width: 250,
    },
    {
      headerClassName: "theme-header",
      field: "bptMonto",
      headerName: "Monto",
      editable: false,
      width: 100,
    },

    {
      headerClassName: "theme-header",
      field: "bptTiempo",
      headerName: "Tiempo",
      editable: false,
      width: 200,
      valueGetter: ({ row }) => {
        return String(row.bptTiempo) + " " + row.bptUnidadTiempo;
      },
    },

    {
      headerClassName: "theme-header",
      field: "bptObservacion",
      headerName: "Observacion",
      editable: false,
      width: 200,
    },
    // {headerClassName: "theme-header",
    //   field: "usuJefeAgencia",
    //   headerName: "Jefe Agencia",
    //   editable: false,
    //   width: 70,
    //   valueGetter: ({ row }) => {
    //     return row.usuJefeAgencia === 1 ? "Si" : "No";
    //   },
    // },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/ventas/index/${params.row.idcrm}`}
            onClick={function (event) {
              setDatosRuta(params.row);
              setOpen(true);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Parametros de Tracking de Vehículos - Rutas
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Box>
                <Box
                  sx={{
                    "& .theme-header": {
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "rgba(255,255,255,1)",
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.bptId}
                    sx={{ height: 650 }}
                    editMode="row"
                    rows={listaRows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={{
                      footerTotalRows: false,
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                      toolbarQuickFilterPlaceholder: "Buscar",
                    }}
                  />
                </Box>

                <Modal
                  open={open}
                  onClose={function (event) {
                    setOpen(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      Modificar ruta
                    </Typography>
                    <Box></Box>
                    <Box>
                      <TextField
                        name="nombre"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Origen"
                        value={datosRuta.bodOrigenDesc}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />
                      <TextField
                        name="nombre"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Destino"
                        value={datosRuta.bodDestinoDesc}
                       // onChange={handleChangeDatosRuta}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />

                      <TextField
                        name="bptMonto"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Monto"
                        onChange={handleChangeDatosRuta}
                        value={datosRuta.bptMonto}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />
                       <TextField
                        name="bptTiempo"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Tiempo"
                        onChange={handleChangeDatosRuta}
                        value={datosRuta.bptTiempo}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />       
                      <TextField
                        name="bptObservacion"
                        size="small"
                        margin="normal"
                        fullWidth
                        label="Observaciones"
                        onChange={handleChangeDatosRuta}
                        value={datosRuta.bptObservacion}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />
                    </Box>            
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        width: "30%",
                        marginLeft: "35%",
                      }}
                      onClick={function (event) {
                         setRegistro(
                           datosRuta.bptId,
                           datosRuta.bodOrigen,
                           datosRuta.bodDestino,
                           Number(datosRuta.bptMonto),
                           datosRuta.bptTiempo,
                           'D',
                           datosRuta.bptObservacion,
                         );
                      }}
                    >
                      Guardar
                    </Button>

                    <Dialog
                      open={openDialog}
                      //onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Correcto"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Datos guardados correctamente
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          href="/trackingveh/parametros_rutas"
                          onClick={function (event) {
                            setOpenDialog(false);
                            setOpen(false);
                            getListado();
                          }}
                          autoFocus
                        >
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Modal>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
