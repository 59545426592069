import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

export const TrackingParametrosFasesPage: React.FC<{}> = () => {
  useEffect(() => {
    getvTablaBodegas();
    getZonas();
  }, []);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();

  const dataListaAutoriza: parametroFaseType[] = [];
  const [listaAutorizaRows, setListaAutorizaRows] =
    useState<parametroFaseType[]>(dataListaAutoriza);

  type parametroFaseType = {
    tpfId: number;
    tpfFase: string;
    tpfTiempoIdeal: number;
    tpfResponsable: string;
    tpfOrden: number;
  };

  type parametroFaseActualizarType = {
    tpfFase: string;
    tpfTiempoIdeal: number;
    tpfResponsable: string;
    tpfOrden: number;
  };

  async function getZonas() {
    try {
      await axios
        .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
        .then((response) => {
          setDataZona(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataZona, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  type zonaType = {
    zonId: number;
    zonNombre: string;
  };
  type bodegaType = {
    bodId: number;
    agencia: string;
    descripcion: string;
  };

  const listadoZonas: zonaType[] = [];
  const listadoBodegas: bodegaType[] = [];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [zona, setZona] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);

  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZonas);
  const [dataBodegaRep, setDataBodegaRep] =
    useState<bodegaType[]>(listadoBodegas);
  const [dataBodegaAll, setDataBodegaAll] =
    useState<bodegaType[]>(listadoBodegas);

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZona(Number(event.target.value));
    getBodegasRep(event.target.value);
    getBodegasAll(event.target.value);
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
  };

  async function getBodegasRep(idZona: string) {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega/zona/rep/" + idZona
        )
        .then((response) => {
          setDataBodegaRep(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodegaRep, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodegasAll(idZona: string) {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega/zona/all/" + idZona
        )
        .then((response) => {
          setDataBodegaAll(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodegaAll, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getvTablaBodegas() {
    try {
      await axios
        .get<parametroFaseType[]>(
          "https://apisatelite.azurewebsites.net/trackingparametrosfase"
        )
        .then((response) => {
          setListaAutorizaRows(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [datosFase, setDatosFase] = React.useState<parametroFaseActualizarType>(
    {
      tpfFase: "",
      tpfTiempoIdeal: 0,
      tpfResponsable: "",
      tpfOrden: 0,
    }
  );

  const dataFase = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosFase({
      ...datosFase,
      [e.target.name]: e.target.value,
    });
  };

  function setRegistro(
    tpfFase: string,
    tpfTiempoIdeal: number,
    tpfResponsable: string,
    tpfOrden: number
  ) {
    console.log("tabla a guardar >> ");
    const nuevoRegistro: parametroFaseActualizarType = {
      tpfFase: tpfFase,
      tpfTiempoIdeal: tpfTiempoIdeal,
      tpfResponsable: tpfResponsable,
      tpfOrden: tpfOrden,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarFase(nuevoRegistro);
  }

  const [openDialog, setOpenDialog] = React.useState(false);

  async function postGuardarFase(
    parametroGuardar: parametroFaseActualizarType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/trackingparametrosfase",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
          <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
              Parametros de Tracking de Vehículos - Fases
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
                <TableContainer sx={{ maxHeight: 700 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Orden</TableCell>
                        <TableCell>Fase</TableCell>
                        <TableCell>Tiempo ideal (días)</TableCell>
                        <TableCell>Responsable</TableCell>
                        <TableCell>Editar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaAutorizaRows.map((row) => (
                        <TableRow key={row.tpfId}>
                          <TableCell>{row.tpfOrden}</TableCell>
                          <TableCell component="th" scope="row">
                            {row.tpfFase}
                          </TableCell>
                          <TableCell>{row.tpfTiempoIdeal}</TableCell>
                          <TableCell>{row.tpfResponsable}</TableCell>
                          <TableCell>
                            {" "}
                            <Button onClick={function (event) {}}>
                              🗑
                            </Button>{" "}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "85%",
                  }}
                  onClick={handleOpen}
                >
                  Nuevo
                </Button>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      Fase de Tracking de Vehículos
                    </Typography>
                    <Box>
                      <TextField
                        label="Nombre de fase"
                        size="small"
                        margin="normal"
                        name="tpfFase"
                        value={datosFase.tpfFase}
                        onChange={dataFase}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      />
                    </Box>
                    <Box>
                      <TextField
                        label="Tiempo ideal"
                        size="small"
                        margin="normal"
                        name="tpfTiempoIdeal"
                        value={datosFase.tpfTiempoIdeal}
                        onChange={dataFase}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "38%",
                        }}
                      />
                      <TextField
                        label="Orden"
                        size="small"
                        margin="normal"
                        name="tpfOrden"
                        value={datosFase.tpfOrden}
                        onChange={dataFase}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "4%",
                          width: "38%",
                        }}
                      />
                    </Box>

                    <Box>
                      <TextField
                        label="Responsable"
                        name="tpfResponsable"
                        value={datosFase.tpfResponsable}
                        onChange={dataFase}
                        size="small"
                        margin="normal"
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          marginLeft: "10%",
                          width: "80%",
                        }}
                      ></TextField>
                    </Box>

                    <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        width: "30%",
                        marginLeft: "35%",
                      }}
                      onClick={function (event) {
                        setRegistro(
                          datosFase.tpfFase,
                          datosFase.tpfTiempoIdeal,
                          datosFase.tpfResponsable,
                          datosFase.tpfOrden
                        );
                      }}
                    >
                      Guardar
                    </Button>

                    <Dialog
                      open={openDialog}
                      //onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Correcto"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Datos guardados correctamente
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          href="/trackingveh/parametros_fases"
                          onClick={function (event) {
                            setOpenDialog(false);
                          }}
                          autoFocus
                        >
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Modal>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
