import React, { useState } from 'react';
import './ButtonGroup.css'; // Importa tu archivo CSS

interface ButtonGroupProps {
  options: string[]; // Opciones para los botones
  onButtonClick: (option: string, index: number) => void; // Función de devolución de llamada para el clic en el botón
}

const CustomButtonGroup: React.FC<ButtonGroupProps> = ({ options, onButtonClick }) => {
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [clickedButton, setClickedButton] = useState<string | null>(null);

  const handleButtonClick = (option: string, index: number) => {
    console.log(`Clicked ${option}, Index: ${index}`);
    setClickedButton(option);
    onButtonClick(option, index);
  };

  return (
    <div className="button-group-container">
      <div className="button-group">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(option, index)}
            className={`button ${hoveredButton === option ? 'hovered' : ''} ${clickedButton === option ? 'clicked' : ''}`}
            onMouseEnter={() => setHoveredButton(option)}
            onMouseLeave={() => setHoveredButton(null)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomButtonGroup;
