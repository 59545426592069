import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  ClienteRespuestaDMSType,
  ClienteRpaApiType,
  DMSClienteType,
  TokenDMSType,
  TokenRespuestaDMSType,
  bodegaType,
  clienteRpaValidacion,
  marcaType,
  zonaType,
} from "../../types";
import { useParams } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { green, red } from "@mui/material/colors";

export const CrearClientePage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getDataClienteRpaApi();
      getDatos();
    } else {
      setName("");
    }
  }, [account]);
  const [mostrar, setMostrar] = React.useState(false);
  const params = useParams();

  type respuestaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: string;
  };

  const [respuestaGet, setRespuestaGet] = useState<respuestaType>({
    Codigo: "",
    Descripcion: "",
    Objeto: "",
  });

  const [openDialog, setOpenDialog] = React.useState(false);

  const [data, setData] = React.useState<clienteRpaValidacion>({
    id: 0,
    idcrm: "",
    tablarenta: 0,
    autfact: 0,
    concliente: 0,
    revuafe: 0,
    esJuridica: 0,
  });

  async function getDataClienteRpaApi() {
    try {
      await axios
        .get<ClienteRpaApiType>(
          "https://apisatelite.azurewebsites.net/vclienterpaapi/" + params.idcrm
        )
        .then((response) => {
          setDataApi(response.data);
          //setMostrar(true);
          console.log("mi respuesta del api para dms: ", response);
        })
        .catch((error) => {
          console.log("algo paso 2>> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDatos() {
    try {
      await axios
        .get<clienteRpaValidacion>(
          "https://apisatelite.azurewebsites.net/clienterpavalidacion/" +
            params.idcrm
        )
        .then((response) => {
          setData(response.data);
          if (response.data.esJuridica === 1) {
            getDataClienteRpaApiRepresentante()
          }else{
            setMostrar(true);
          }          
          console.log("mi respuesta: ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDataClienteRpaApiRepresentante() {
    try {
      await axios
        .get<ClienteRpaApiType>(
          "https://apisatelite.azurewebsites.net/vclienterpaapirepresentante/" + params.idcrm
        )
        .then((response) => {
          setDataApiRepresentante(response.data);
          setMostrar(true);
          console.log("mi respuesta del api para representante para dms: ", response);
        })
        .catch((error) => {
          console.log("algo paso 2>> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRegistrarPeticion(parametro: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:2},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
            username +
            "%26quot;},%26quot;CrearCliente%26quot;:{%26quot;Crm%26quot;:%26quot;" +
            String(data.idcrm) +
            "%26quot;,%26quot;Tipo%26quot;:%26quot;Completo%26quot;},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
            String(1) +
            "%26quot;}}"
        )
        .then((response) => {
          setRespuestaGet(response.data);
          console.log("respuesta del registro peticion >>> :", response.data);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [dataApi, setDataApi] = React.useState<ClienteRpaApiType>({
    id: 0,
    id_crm: "",
    usu: 0,
    idCli: 0,
    zona: 0,
    id_cot_estado: 0,
    raz: "",
    nom1: "",
    nom2: "",
    apell1: "",
    apell2: "",
    vend: 0,
    nit: "",
    tipo_ide: "",
    activ_eco: 0,
    habeas_data: 0,
    id_medio_habeas: 0,
    defecto_habeas: 0,
    origen: 0,
    prov: 0,
    tipo_perfil: 0,
    idTipoTributario: 0,
    id_cot_cliente_pais: 0,
    tel1: "",
    tel2: "",
    direccion: "",
    url: "",
    privado: 0,
    digito: 0,
    mailContacto: "",
    codigo: "",
    apellido_conyuge: "",
    nombre_conyuge: "",
    empresa_labora: "",
    ingresos_mes: 0,
    total_activos: 0,
    total_pasivos: 0,
    otros_ingresos: 0,
    acepta_decla: "",
    desemp: "",
    id_cot_cliente_paisUafe: 0,
    id_cot_uaf1: 0,
    id_cot_uaf2: 0,
    id_cot_uaf3: 0,
    id_cot_uaf4: 0,
    id_cot_uaf5: 0,
    id_cot_uaf6: 0,
    id_cot_uaf7: 0,
    id_cot_uaf8: 0,
    id_cot_uaf9: 0,
    tipo_functipo_func: 0,
    id_tes_bancos1: 0,
    id_tes_bancos2: 0,
    nombre1: "",
    direccion1: "",
    contacto1: "",
    telefono1: "",
    antiguedad1: "",
    nombre2: "",
    direccion2: "",
    contacto2: "",
    telefono2: "",
    antiguedad2: "",
    nombre3: "",
    documento3: "",
    tipo3: 0,
    direccion3: "",
    telefono3: "",
    telefono3_2: "",
    relacion: 0,
    desc_relacion: "",
    observaciones: "",
    info_adicional: "",
    docs_requeridos: "",
    SoyCliente: 0,
    id_usuario: 0,
    egresos: 0,
    id_cot_uaf10: 0,
    id_cot_uaf11: 0,
    id_cot_uaf12: 0,
    id_cot_uaf13: 0,
    id_cot_uaf14: 0,
    id_cot_uaf15: 0,
    id_cot_uaf16: 0,
    id_cot_uaf17: 0,
    id_cot_uaf18: 0,
    tipo_identificacion_conyuge: "",
    identificacion_conyuge: "",
    nombre_comercial: "",
    calle_principal: "",
    calle_secundaria: "",
    ref_ubicacion: "",
    mail_emp: "",
    telefono_emp: "",
    cel_emp: "",
    ingresos_mes_p: 0,
    total_activos_p: 0,
    total_pasivos_p: 0,
    otros_ingresos_p: 0,
    egresos_p: 0,
    id_cot_uaf19: 0,
    nombre_comercial_p: "",
    calle_principal_p: "",
    calle_secundaria_p: "",
    ref_ubicacion_p: "",
    mail_emp_p: "",
    telefono_emp_p: "",
    cel_emp_p: "",
    id_cot_uaf20: 0,
    servicios: "",
    id_cot_uaf21: 0,
    egresos_mes_p: 0,
    desemp1: "",
    desemp3: "",
    des_desemp1: "",
    fecha_desemp_1: "",
    relacion_2: "",
    nombres_rel_2: "",
    cargo_2: "",
    fecha_desemp_2: "",
    des_desemp3: "",
    fecha_desemp_3: "",
    sexo: 0,
    fechaCumple: "",
    estadoCivil: 0
  });

  const [dataApiRepresentante, setDataApiRepresentante] = React.useState<ClienteRpaApiType>({
    id: 0,
    id_crm: "",
    usu: 0,
    idCli: 0,
    zona: 0,
    id_cot_estado: 0,
    raz: "",
    nom1: "",
    nom2: "",
    apell1: "",
    apell2: "",
    vend: 0,
    nit: "",
    tipo_ide: "",
    activ_eco: 0,
    habeas_data: 0,
    id_medio_habeas: 0,
    defecto_habeas: 0,
    origen: 0,
    prov: 0,
    tipo_perfil: 0,
    idTipoTributario: 0,
    id_cot_cliente_pais: 0,
    tel1: "",
    tel2: "",
    direccion: "",
    url: "",
    privado: 0,
    digito: 0,
    mailContacto: "",
    codigo: "",
    apellido_conyuge: "",
    nombre_conyuge: "",
    empresa_labora: "",
    ingresos_mes: 0,
    total_activos: 0,
    total_pasivos: 0,
    otros_ingresos: 0,
    acepta_decla: "",
    desemp: "",
    id_cot_cliente_paisUafe: 0,
    id_cot_uaf1: 0,
    id_cot_uaf2: 0,
    id_cot_uaf3: 0,
    id_cot_uaf4: 0,
    id_cot_uaf5: 0,
    id_cot_uaf6: 0,
    id_cot_uaf7: 0,
    id_cot_uaf8: 0,
    id_cot_uaf9: 0,
    tipo_functipo_func: 0,
    id_tes_bancos1: 0,
    id_tes_bancos2: 0,
    nombre1: "",
    direccion1: "",
    contacto1: "",
    telefono1: "",
    antiguedad1: "",
    nombre2: "",
    direccion2: "",
    contacto2: "",
    telefono2: "",
    antiguedad2: "",
    nombre3: "",
    documento3: "",
    tipo3: 0,
    direccion3: "",
    telefono3: "",
    telefono3_2: "",
    relacion: 0,
    desc_relacion: "",
    observaciones: "",
    info_adicional: "",
    docs_requeridos: "",
    SoyCliente: 0,
    id_usuario: 0,
    egresos: 0,
    id_cot_uaf10: 0,
    id_cot_uaf11: 0,
    id_cot_uaf12: 0,
    id_cot_uaf13: 0,
    id_cot_uaf14: 0,
    id_cot_uaf15: 0,
    id_cot_uaf16: 0,
    id_cot_uaf17: 0,
    id_cot_uaf18: 0,
    tipo_identificacion_conyuge: "",
    identificacion_conyuge: "",
    nombre_comercial: "",
    calle_principal: "",
    calle_secundaria: "",
    ref_ubicacion: "",
    mail_emp: "",
    telefono_emp: "",
    cel_emp: "",
    ingresos_mes_p: 0,
    total_activos_p: 0,
    total_pasivos_p: 0,
    otros_ingresos_p: 0,
    egresos_p: 0,
    id_cot_uaf19: 0,
    nombre_comercial_p: "",
    calle_principal_p: "",
    calle_secundaria_p: "",
    ref_ubicacion_p: "",
    mail_emp_p: "",
    telefono_emp_p: "",
    cel_emp_p: "",
    id_cot_uaf20: 0,
    servicios: "",
    id_cot_uaf21: 0,
    egresos_mes_p: 0,
    desemp1: "",
    desemp3: "",
    des_desemp1: "",
    fecha_desemp_1: "",
    relacion_2: "",
    nombres_rel_2: "",
    cargo_2: "",
    fecha_desemp_2: "",
    des_desemp3: "",
    fecha_desemp_3: "",
    sexo: 0,
    fechaCumple: "",
    estadoCivil: 0
  });

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  function setRegistroNuevo(
    empresas: number[],
    usu: number,
    idCli: number,
    zona: number,
    id_cot_estado: number,
    raz: string,
    nom1: string,
    nom2: string,
    apell1: string,
    apell2: string,
    vend: number,
    nit: string,
    tipo_ide: string,
    activ_eco: number,
    habeas_data: number,
    id_medio_habeas: number,
    defecto_habeas: number,
    origen: number,
    prov: number,
    tipo_perfil: number,
    idTipoTributario: number,
    id_cot_cliente_pais: number,
    tel1: string,
    tel2: string,
    direccion: string,
    url: string,
    privado: number,
    digito: number,
    mailContacto: string,
    codigo: string,
    apellido_conyuge: string,
    nombre_conyuge: string,
    empresa_labora: string,
    ingresos_mes: number,
    total_activos: number,
    total_pasivos: number,
    otros_ingresos: number,
    acepta_decla: string,
    desemp: string,
    id_cot_cliente_paisUafe: number,
    id_cot_uaf1: number,
    id_cot_uaf2: number,
    id_cot_uaf3: number,
    id_cot_uaf4: number,
    id_cot_uaf5: number,
    id_cot_uaf6: number,
    id_cot_uaf7: number,
    id_cot_uaf8: number,
    id_cot_uaf9: number,
    tipo_func: number,
    id_tes_bancos1: number,
    id_tes_bancos2: number,
    nombre1: string,
    direccion1: string,
    contacto1: string,
    telefono1: string,
    antiguedad1: string,
    nombre2: string,
    direccion2: string,
    contacto2: string,
    telefono2: string,
    antiguedad2: string,
    nombre3: string,
    documento3: string,
    tipo3: number,
    direccion3: string,
    telefono3: string,
    telefono3_2: string,
    relacion: number,
    desc_relacion: string,
    observaciones: string,
    info_adicional: string,
    docs_requeridos: string,
    SoyCliente: number,
    id_usuario: number,
    egresos: number,
    id_cot_uaf10: number,
    id_cot_uaf11: number,
    id_cot_uaf12: number,
    id_cot_uaf13: number,
    id_cot_uaf14: number,
    id_cot_uaf15: number,
    id_cot_uaf16: number,
    id_cot_uaf17: number,
    id_cot_uaf18: number,
    tipo_identificacion_conyuge: string,
    identificacion_conyuge: string,
    nombre_comercial: string,
    calle_principal: string,
    calle_secundaria: string,
    ref_ubicacion: string,
    mail_emp: string,
    telefono_emp: string,
    cel_emp: string,
    ingresos_mes_p: number,
    total_activos_p: number,
    total_pasivos_p: number,
    otros_ingresos_p: number,
    egresos_p: number,
    id_cot_uaf19: number,
    nombre_comercial_p: string,
    calle_principal_p: string,
    calle_secundaria_p: string,
    ref_ubicacion_p: string,
    mail_emp_p: string,
    telefono_emp_p: string,
    cel_emp_p: string,
    id_cot_uaf20: number,
    servicios: string,
    id_cot_uaf21: number,
    egresos_mes_p: number,
    desemp1: string,
    desemp3: string,
    des_desemp1: string,
    fecha_desemp_1: string,
    relacion_2: string,
    nombres_rel_2: string,
    cargo_2: string,
    fecha_desemp_2: string,
    des_desemp3: string,
    fecha_desemp_3: string,
    sexo: number,
    fechaCumple: string,
    estadoCivil: number,
  ) {
    setBotonDeshabilitado(true);
    console.log("tabla a guardar >> ");
    const token: TokenDMSType = {
      usuario: "dms",
      password: "DMS12345",
    };
    const nuevoRegistro: DMSClienteType = {
      empresas: empresas,
      usu: usu,
      idCli: idCli,
      zona: zona,
      id_cot_estado: id_cot_estado,
      raz: raz,
      nom1: nom1,
      nom2: nom2,
      apell1: apell1,
      apell2: apell2,
      vend: vend,
      nit: nit,
      tipo_ide: tipo_ide,
      activ_eco: activ_eco,
      habeas_data: habeas_data,
      id_medio_habeas: id_medio_habeas,
      defecto_habeas: defecto_habeas,
      origen: origen,
      prov: prov,
      tipo_perfil: tipo_perfil,
      idTipoTributario: idTipoTributario,
      id_cot_cliente_pais: id_cot_cliente_pais,
      tel1: tel1,
      tel2: tel2,
      direccion: direccion,
      url: url,
      privado: privado,
      digito: digito,
      mailContacto: mailContacto,
      codigo: codigo,
      apellido_conyuge: apellido_conyuge,
      nombre_conyuge: nombre_conyuge,
      empresa_labora: empresa_labora,
      ingresos_mes: ingresos_mes,
      total_activos: total_activos,
      total_pasivos: total_pasivos,
      otros_ingresos: otros_ingresos,
      acepta_decla: true,
      desemp: true,
      id_cot_cliente_paisUafe: id_cot_cliente_paisUafe,
      id_cot_uaf1: id_cot_uaf1,
      id_cot_uaf2: id_cot_uaf2,
      id_cot_uaf3: id_cot_uaf3,
      id_cot_uaf4: id_cot_uaf4,
      id_cot_uaf5: id_cot_uaf5,
      id_cot_uaf6: id_cot_uaf6,
      id_cot_uaf7: id_cot_uaf7,
      id_cot_uaf8: id_cot_uaf8,
      id_cot_uaf9: id_cot_uaf9,
      tipo_func: tipo_func,
      id_tes_bancos1: id_tes_bancos1,
      id_tes_bancos2: dataApiRepresentante.idCli,
      nombre1: nombre1,
      direccion1: direccion1,
      contacto1: contacto1,
      telefono1: telefono1,
      antiguedad1: antiguedad1,
      nombre2: nombre2,
      direccion2: direccion2,
      contacto2: contacto2,
      telefono2: telefono2,
      antiguedad2: antiguedad2,
      nombre3: nombre3,
      documento3: documento3,
      tipo3: tipo3,
      direccion3: direccion3,
      telefono3: telefono3,
      telefono3_2: telefono3_2,
      relacion: relacion,
      desc_relacion: desc_relacion,
      observaciones: observaciones,
      info_adicional: info_adicional,
      docs_requeridos: docs_requeridos,
      SoyCliente: SoyCliente,
      id_usuario: id_usuario,
      egresos: egresos,
      id_cot_uaf10: id_cot_uaf10,
      id_cot_uaf11: id_cot_uaf11,
      id_cot_uaf12: id_cot_uaf12,
      id_cot_uaf13: id_cot_uaf13,
      id_cot_uaf14: id_cot_uaf14,
      id_cot_uaf15: id_cot_uaf15,
      id_cot_uaf16: id_cot_uaf16,
      id_cot_uaf17: id_cot_uaf17,
      id_cot_uaf18: id_cot_uaf18,
      tipo_identificacion_conyuge: tipo_identificacion_conyuge,
      identificacion_conyuge: identificacion_conyuge,
      nombre_comercial: nombre_comercial,
      calle_principal: calle_principal,
      calle_secundaria: calle_secundaria,
      ref_ubicacion: ref_ubicacion,
      mail_emp: mail_emp,
      telefono_emp: telefono_emp,
      cel_emp: cel_emp,
      ingresos_mes_p: ingresos_mes_p,
      total_activos_p: total_activos_p,
      total_pasivos_p: total_pasivos_p,
      otros_ingresos_p: otros_ingresos_p,
      egresos_p: egresos_p,
      id_cot_uaf19: id_cot_uaf19,
      nombre_comercial_p: nombre_comercial_p,
      calle_principal_p: calle_principal_p,
      calle_secundaria_p: calle_secundaria_p,
      ref_ubicacion_p: ref_ubicacion_p,
      mail_emp_p: mail_emp_p,
      telefono_emp_p: telefono_emp_p,
      cel_emp_p: cel_emp_p,
      id_cot_uaf20: id_cot_uaf20,
      servicios: servicios,
      id_cot_uaf21: id_cot_uaf21,
      egresos_mes_p: egresos_mes_p,
      desemp1: true,
      desemp3: true,
      des_desemp1: des_desemp1,
      fecha_desemp_1: fecha_desemp_1,
      relacion_2: relacion_2,
      nombres_rel_2: nombres_rel_2,
      cargo_2: cargo_2,
      fecha_desemp_2: fecha_desemp_2,
      des_desemp3: des_desemp3,
      fecha_desemp_3: fecha_desemp_3,
      sexo: sexo,
      cechaCumple: fechaCumple,
      estCivil: estadoCivil
    };
    const listaobjetos: DMSClienteType[] = [nuevoRegistro];
    postObtenerTokenDMS(token, listaobjetos);
    console.log("datos a guardar >> ", JSON.stringify(listaobjetos, null, 4));
  }


  function setRegistroRepresentante(
    empresas: number[],
    usu: number,
    idCli: number,
    zona: number,
    id_cot_estado: number,
    raz: string,
    nom1: string,
    nom2: string,
    apell1: string,
    apell2: string,
    vend: number,
    nit: string,
    tipo_ide: string,
    activ_eco: number,
    habeas_data: number,
    id_medio_habeas: number,
    defecto_habeas: number,
    origen: number,
    prov: number,
    tipo_perfil: number,
    idTipoTributario: number,
    id_cot_cliente_pais: number,
    tel1: string,
    tel2: string,
    direccion: string,
    url: string,
    privado: number,
    digito: number,
    mailContacto: string,
    codigo: string,
    apellido_conyuge: string,
    nombre_conyuge: string,
    empresa_labora: string,
    ingresos_mes: number,
    total_activos: number,
    total_pasivos: number,
    otros_ingresos: number,
    acepta_decla: string,
    desemp: string,
    id_cot_cliente_paisUafe: number,
    id_cot_uaf1: number,
    id_cot_uaf2: number,
    id_cot_uaf3: number,
    id_cot_uaf4: number,
    id_cot_uaf5: number,
    id_cot_uaf6: number,
    id_cot_uaf7: number,
    id_cot_uaf8: number,
    id_cot_uaf9: number,
    tipo_func: number,
    id_tes_bancos1: number,
    id_tes_bancos2: number,
    nombre1: string,
    direccion1: string,
    contacto1: string,
    telefono1: string,
    antiguedad1: string,
    nombre2: string,
    direccion2: string,
    contacto2: string,
    telefono2: string,
    antiguedad2: string,
    nombre3: string,
    documento3: string,
    tipo3: number,
    direccion3: string,
    telefono3: string,
    telefono3_2: string,
    relacion: number,
    desc_relacion: string,
    observaciones: string,
    info_adicional: string,
    docs_requeridos: string,
    SoyCliente: number,
    id_usuario: number,
    egresos: number,
    id_cot_uaf10: number,
    id_cot_uaf11: number,
    id_cot_uaf12: number,
    id_cot_uaf13: number,
    id_cot_uaf14: number,
    id_cot_uaf15: number,
    id_cot_uaf16: number,
    id_cot_uaf17: number,
    id_cot_uaf18: number,
    tipo_identificacion_conyuge: string,
    identificacion_conyuge: string,
    nombre_comercial: string,
    calle_principal: string,
    calle_secundaria: string,
    ref_ubicacion: string,
    mail_emp: string,
    telefono_emp: string,
    cel_emp: string,
    ingresos_mes_p: number,
    total_activos_p: number,
    total_pasivos_p: number,
    otros_ingresos_p: number,
    egresos_p: number,
    id_cot_uaf19: number,
    nombre_comercial_p: string,
    calle_principal_p: string,
    calle_secundaria_p: string,
    ref_ubicacion_p: string,
    mail_emp_p: string,
    telefono_emp_p: string,
    cel_emp_p: string,
    id_cot_uaf20: number,
    servicios: string,
    id_cot_uaf21: number,
    egresos_mes_p: number,
    desemp1: string,
    desemp3: string,
    des_desemp1: string,
    fecha_desemp_1: string,
    relacion_2: string,
    nombres_rel_2: string,
    cargo_2: string,
    fecha_desemp_2: string,
    des_desemp3: string,
    fecha_desemp_3: string,
    sexo: number,
    fechaCumple: string,
    estadoCivil: number,
  ) {
    setBotonDeshabilitado(true);
    console.log("tabla a guardar >> ");
    const token: TokenDMSType = {
      usuario: "dms",
      password: "DMS12345",
    };
    const nuevoRegistro: DMSClienteType = {
      empresas: empresas,
      usu: usu,
      idCli: idCli,
      zona: zona,
      id_cot_estado: id_cot_estado,
      raz: raz,
      nom1: nom1,
      nom2: nom2,
      apell1: apell1,
      apell2: apell2,
      vend: vend,
      nit: nit,
      tipo_ide: tipo_ide,
      activ_eco: activ_eco,
      habeas_data: habeas_data,
      id_medio_habeas: id_medio_habeas,
      defecto_habeas: defecto_habeas,
      origen: origen,
      prov: prov,
      tipo_perfil: tipo_perfil,
      idTipoTributario: idTipoTributario,
      id_cot_cliente_pais: id_cot_cliente_pais,
      tel1: tel1,
      tel2: tel2,
      direccion: direccion,
      url: url,
      privado: privado,
      digito: digito,
      mailContacto: mailContacto,
      codigo: codigo,
      apellido_conyuge: apellido_conyuge,
      nombre_conyuge: nombre_conyuge,
      empresa_labora: empresa_labora,
      ingresos_mes: ingresos_mes,
      total_activos: total_activos,
      total_pasivos: total_pasivos,
      otros_ingresos: otros_ingresos,
      acepta_decla: true,
      desemp: true,
      id_cot_cliente_paisUafe: id_cot_cliente_paisUafe,
      id_cot_uaf1: id_cot_uaf1,
      id_cot_uaf2: id_cot_uaf2,
      id_cot_uaf3: id_cot_uaf3,
      id_cot_uaf4: id_cot_uaf4,
      id_cot_uaf5: id_cot_uaf5,
      id_cot_uaf6: id_cot_uaf6,
      id_cot_uaf7: id_cot_uaf7,
      id_cot_uaf8: id_cot_uaf8,
      id_cot_uaf9: id_cot_uaf9,
      tipo_func: tipo_func,
      id_tes_bancos1: id_tes_bancos1,
      id_tes_bancos2: id_tes_bancos2,
      nombre1: nombre1,
      direccion1: direccion1,
      contacto1: contacto1,
      telefono1: telefono1,
      antiguedad1: antiguedad1,
      nombre2: nombre2,
      direccion2: direccion2,
      contacto2: contacto2,
      telefono2: telefono2,
      antiguedad2: antiguedad2,
      nombre3: nombre3,
      documento3: documento3,
      tipo3: tipo3,
      direccion3: direccion3,
      telefono3: telefono3,
      telefono3_2: telefono3_2,
      relacion: relacion,
      desc_relacion: desc_relacion,
      observaciones: observaciones,
      info_adicional: info_adicional,
      docs_requeridos: docs_requeridos,
      SoyCliente: SoyCliente,
      id_usuario: id_usuario,
      egresos: egresos,
      id_cot_uaf10: id_cot_uaf10,
      id_cot_uaf11: id_cot_uaf11,
      id_cot_uaf12: id_cot_uaf12,
      id_cot_uaf13: id_cot_uaf13,
      id_cot_uaf14: id_cot_uaf14,
      id_cot_uaf15: id_cot_uaf15,
      id_cot_uaf16: id_cot_uaf16,
      id_cot_uaf17: id_cot_uaf17,
      id_cot_uaf18: id_cot_uaf18,
      tipo_identificacion_conyuge: tipo_identificacion_conyuge,
      identificacion_conyuge: identificacion_conyuge,
      nombre_comercial: nombre_comercial,
      calle_principal: calle_principal,
      calle_secundaria: calle_secundaria,
      ref_ubicacion: ref_ubicacion,
      mail_emp: mail_emp,
      telefono_emp: telefono_emp,
      cel_emp: cel_emp,
      ingresos_mes_p: ingresos_mes_p,
      total_activos_p: total_activos_p,
      total_pasivos_p: total_pasivos_p,
      otros_ingresos_p: otros_ingresos_p,
      egresos_p: egresos_p,
      id_cot_uaf19: id_cot_uaf19,
      nombre_comercial_p: nombre_comercial_p,
      calle_principal_p: calle_principal_p,
      calle_secundaria_p: calle_secundaria_p,
      ref_ubicacion_p: ref_ubicacion_p,
      mail_emp_p: mail_emp_p,
      telefono_emp_p: telefono_emp_p,
      cel_emp_p: cel_emp_p,
      id_cot_uaf20: id_cot_uaf20,
      servicios: servicios,
      id_cot_uaf21: id_cot_uaf21,
      egresos_mes_p: egresos_mes_p,
      desemp1: true,
      desemp3: true,
      des_desemp1: des_desemp1,
      fecha_desemp_1: fecha_desemp_1,
      relacion_2: relacion_2,
      nombres_rel_2: nombres_rel_2,
      cargo_2: cargo_2,
      fecha_desemp_2: fecha_desemp_2,
      des_desemp3: des_desemp3,
      fecha_desemp_3: fecha_desemp_3,
      sexo: sexo,
      cechaCumple: fechaCumple,
      estCivil: estadoCivil
    };
    const listaobjetos: DMSClienteType[] = [nuevoRegistro];
    postObtenerTokenDMSRepresentante(token, listaobjetos);
    console.log("datos a guardar >> ", JSON.stringify(listaobjetos, null, 4));
  }

  async function postObtenerTokenDMS(
    elemento: TokenDMSType,
    cliente: DMSClienteType[]
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post<TokenRespuestaDMSType>(
          "https://advance.corporacionproauto.com.ec/api/token",
          elemento,
          {
            headers: headers,
          }
        )
        .then((response) => {
          postGuardarClienteDMS(cliente, response.data.token);
          console.log("mi token DMS es >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postObtenerTokenDMSRepresentante(
    elemento: TokenDMSType,
    representante: DMSClienteType[]
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post<TokenRespuestaDMSType>(
          "https://advance.corporacionproauto.com.ec/api/token",
          elemento,
          {
            headers: headers,
          }
        )
        .then((response) => {
          postGuardarRepresentanteDMS(representante, response.data.token);
          console.log("mi token DMS es >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  type RespuestaCrear = {    
response: ClienteRespuestaDMSType[]
  }

  async function postGuardarClienteDMS(
    cliente: DMSClienteType[],
    token: string
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    try {
      axios
        .post<RespuestaCrear>(
          "https://advance.corporacionproauto.com.ec/api/Tercero",
          cliente,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("mi respuesta de crear cliente dms: ", response);
          if (data.esJuridica === 1 && String(dataApiRepresentante.nit).length === 10 && Number(dataApiRepresentante.idCli === 0) ){
            console.log("mi id cliente creado" , response.data.response[0].id ); // , response.data[0]?.id  );
            setRegistroRepresentante(
              [605],
              dataApiRepresentante.usu,
              dataApiRepresentante.idCli,
              dataApiRepresentante.zona,
              dataApiRepresentante.id_cot_estado,
              dataApiRepresentante.raz,
              dataApiRepresentante.nom1,
              dataApiRepresentante.nom2,
              dataApiRepresentante.apell1,
              dataApiRepresentante.apell2,
              dataApiRepresentante.vend,
              dataApiRepresentante.nit,
              dataApiRepresentante.tipo_ide,
              dataApiRepresentante.activ_eco,
              dataApiRepresentante.habeas_data,
              dataApiRepresentante.id_medio_habeas,
              dataApiRepresentante.defecto_habeas,
              dataApiRepresentante.origen,
              dataApiRepresentante.prov,
              dataApiRepresentante.tipo_perfil,
              dataApiRepresentante.idTipoTributario,
              dataApiRepresentante.id_cot_cliente_pais,
              dataApiRepresentante.tel1,
              dataApiRepresentante.tel2,
              dataApiRepresentante.direccion,
              dataApiRepresentante.url,
              dataApiRepresentante.privado,
              dataApiRepresentante.digito,
              dataApiRepresentante.mailContacto,
              dataApiRepresentante.codigo,
              dataApiRepresentante.apellido_conyuge,
              dataApiRepresentante.nombre_conyuge,
              dataApiRepresentante.empresa_labora,
              dataApiRepresentante.ingresos_mes,
              dataApiRepresentante.total_activos,
              dataApiRepresentante.total_pasivos,
              dataApiRepresentante.otros_ingresos,
              dataApiRepresentante.acepta_decla,
              dataApiRepresentante.desemp,
              dataApiRepresentante.id_cot_cliente_paisUafe,
              dataApiRepresentante.id_cot_uaf1,
              dataApiRepresentante.id_cot_uaf2,
              dataApiRepresentante.id_cot_uaf3,
              dataApiRepresentante.id_cot_uaf4,
              dataApiRepresentante.id_cot_uaf5,
              dataApiRepresentante.id_cot_uaf6,
              dataApiRepresentante.id_cot_uaf7,
              dataApiRepresentante.id_cot_uaf8,
              dataApiRepresentante.id_cot_uaf9,
              dataApiRepresentante.tipo_functipo_func,
              response.data.response[0].id,//response.data[0]?.id, //id tes bancos 1
              dataApiRepresentante.id_tes_bancos2,
              dataApiRepresentante.nombre1,
              dataApiRepresentante.direccion1,
              dataApiRepresentante.contacto1,
              dataApiRepresentante.telefono1,
              dataApiRepresentante.antiguedad1,
              dataApiRepresentante.nombre2,
              dataApiRepresentante.direccion2,
              dataApiRepresentante.contacto2,
              dataApiRepresentante.telefono2,
              dataApiRepresentante.antiguedad2,
              dataApiRepresentante.nombre3,
              dataApiRepresentante.documento3,
              dataApiRepresentante.tipo3,
              dataApiRepresentante.direccion3,
              dataApiRepresentante.telefono3,
              dataApiRepresentante.telefono3_2,
              dataApiRepresentante.relacion,
              dataApiRepresentante.desc_relacion,
              dataApiRepresentante.observaciones,
              dataApiRepresentante.info_adicional,
              dataApiRepresentante.docs_requeridos,
              dataApiRepresentante.SoyCliente,
              dataApiRepresentante.id_usuario,
              dataApiRepresentante.egresos,
              dataApiRepresentante.id_cot_uaf10,
              dataApiRepresentante.id_cot_uaf11,
              dataApiRepresentante.id_cot_uaf12,
              dataApiRepresentante.id_cot_uaf13,
              dataApiRepresentante.id_cot_uaf14,
              dataApiRepresentante.id_cot_uaf15,
              dataApiRepresentante.id_cot_uaf16,
              dataApiRepresentante.id_cot_uaf17,
              dataApiRepresentante.id_cot_uaf18,
              dataApiRepresentante.tipo_identificacion_conyuge,
              dataApiRepresentante.identificacion_conyuge,
              dataApiRepresentante.nombre_comercial,
              dataApiRepresentante.calle_principal,
              dataApiRepresentante.calle_secundaria,
              dataApiRepresentante.ref_ubicacion,
              dataApiRepresentante.mail_emp,
              dataApiRepresentante.telefono_emp,
              dataApiRepresentante.cel_emp,
              dataApiRepresentante.ingresos_mes_p,
              dataApiRepresentante.total_activos_p,
              dataApiRepresentante.total_pasivos_p,
              dataApiRepresentante.otros_ingresos_p,
              dataApiRepresentante.egresos_p,
              dataApiRepresentante.id_cot_uaf19,
              dataApiRepresentante.nombre_comercial_p,
              dataApiRepresentante.calle_principal_p,
              dataApiRepresentante.calle_secundaria_p,
              dataApiRepresentante.ref_ubicacion_p,
              dataApiRepresentante.mail_emp_p,
              dataApiRepresentante.telefono_emp_p,
              dataApiRepresentante.cel_emp_p,
              dataApiRepresentante.id_cot_uaf20,
              dataApiRepresentante.servicios,
              dataApiRepresentante.id_cot_uaf21,
              dataApiRepresentante.egresos_mes_p,
              dataApiRepresentante.desemp1,
              dataApiRepresentante.desemp3,
              dataApiRepresentante.des_desemp1,
              dataApiRepresentante.fecha_desemp_1,
              dataApiRepresentante.relacion_2,
              dataApiRepresentante.nombres_rel_2,
              dataApiRepresentante.cargo_2,
              dataApiRepresentante.fecha_desemp_2,
              dataApiRepresentante.des_desemp3,
              dataApiRepresentante.fecha_desemp_3,
              dataApiRepresentante.sexo,
              dataApiRepresentante.fechaCumple,
              dataApiRepresentante.estadoCivil
            )
          }else{
            setOpenDialog(true);
          setBotonDeshabilitado(false);
          }
          
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRepresentanteDMS(
    cliente: DMSClienteType[],
    token: string
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    try {
      axios
        .post<ClienteRespuestaDMSType[]>(
          "https://advance.corporacionproauto.com.ec/api/Tercero",
          cliente,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("mi respuesta de crear cliente dms: ", response);
          setOpenDialog(true);
          setBotonDeshabilitado(false);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                Crear Cliente en Advance
              </Typography>
            </Paper>

            {mostrar ? (
              <>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "20%",
                      marginLeft: "35%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Datos del Cliente:
                  </Typography>
                  {data.revuafe > 0 ? (
                    <CheckBoxIcon
                      sx={{
                        color: green[500],
                        marginRight: "35%",
                        width: "10%",
                      }}
                    ></CheckBoxIcon>
                  ) : (
                    <DisabledByDefaultIcon
                      sx={{ color: red[500], marginRight: "35%", width: "10%" }}
                    ></DisabledByDefaultIcon>
                  )}

                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "20%",
                      marginLeft: "35%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Tabla de rentabilidad:
                  </Typography>
                  {data.tablarenta > 0 ? (
                    <CheckBoxIcon
                      sx={{
                        color: green[500],
                        marginRight: "35%",
                        width: "10%",
                      }}
                    ></CheckBoxIcon>
                  ) : (
                    <DisabledByDefaultIcon
                      sx={{ color: red[500], marginRight: "35%", width: "10%" }}
                    ></DisabledByDefaultIcon>
                  )}

                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "20%",
                      marginLeft: "35%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Conozca a su cliente:
                  </Typography>
                  {data.concliente > 0 ? (
                    <CheckBoxIcon
                      sx={{
                        color: green[500],
                        marginRight: "35%",
                        width: "10%",
                      }}
                    ></CheckBoxIcon>
                  ) : (
                    <DisabledByDefaultIcon
                      sx={{ color: red[500], marginRight: "35%", width: "10%" }}
                    ></DisabledByDefaultIcon>
                  )}
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "20%",
                      marginLeft: "35%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Autorizacion de facturacion:
                  </Typography>

                  {data.autfact > 0 ? (
                    <CheckBoxIcon
                      sx={{
                        color: green[500],
                        marginRight: "35%",
                        width: "10%",
                      }}
                    ></CheckBoxIcon>
                  ) : (
                    <DisabledByDefaultIcon
                      sx={{ color: red[500], marginRight: "35%", width: "10%" }}
                    ></DisabledByDefaultIcon>
                  )}

                  <Divider
                    sx={{ mt: 2, mb: 2, marginLeft: "30%", marginRight: "30%" }}
                  />

                  {data.tablarenta > 0 &&
                  data.autfact > 0 &&
                  data.concliente > 0 &&
                  data.revuafe > 0 ? (
                    <Button
                      sx={{
                        mt: 3,
                        mb: 3,
                        width: "15%",
                        marginLeft: "42%",
                        marginRight: "43%",
                      }}
                      variant="contained"
                      disabled={botonDeshabilitado}
                      onClick={
                        function (event) {
                          setRegistroNuevo(
                            [605],
                            dataApi.usu,
                            dataApi.idCli,
                            dataApi.zona,
                            dataApi.id_cot_estado,
                            dataApi.raz,
                            dataApi.nom1,
                            dataApi.nom2,
                            dataApi.apell1,
                            dataApi.apell2,
                            dataApi.vend,
                            dataApi.nit,
                            dataApi.tipo_ide,
                            dataApi.activ_eco,
                            dataApi.habeas_data,
                            dataApi.id_medio_habeas,
                            dataApi.defecto_habeas,
                            dataApi.origen,
                            dataApi.prov,
                            dataApi.tipo_perfil,
                            dataApi.idTipoTributario,
                            dataApi.id_cot_cliente_pais,
                            dataApi.tel1,
                            dataApi.tel2,
                            dataApi.direccion,
                            dataApi.url,
                            dataApi.privado,
                            dataApi.digito,
                            dataApi.mailContacto,
                            dataApi.codigo,
                            dataApi.apellido_conyuge,
                            dataApi.nombre_conyuge,
                            dataApi.empresa_labora,
                            dataApi.ingresos_mes,
                            dataApi.total_activos,
                            dataApi.total_pasivos,
                            dataApi.otros_ingresos,
                            dataApi.acepta_decla,
                            dataApi.desemp,
                            dataApi.id_cot_cliente_paisUafe,
                            dataApi.id_cot_uaf1,
                            dataApi.id_cot_uaf2,
                            dataApi.id_cot_uaf3,
                            dataApi.id_cot_uaf4,
                            dataApi.id_cot_uaf5,
                            dataApi.id_cot_uaf6,
                            dataApi.id_cot_uaf7,
                            dataApi.id_cot_uaf8,
                            dataApi.id_cot_uaf9,
                            dataApi.tipo_functipo_func,
                            dataApi.id_tes_bancos1,
                            dataApi.id_tes_bancos2,
                            dataApi.nombre1,
                            dataApi.direccion1,
                            dataApi.contacto1,
                            dataApi.telefono1,
                            dataApi.antiguedad1,
                            dataApi.nombre2,
                            dataApi.direccion2,
                            dataApi.contacto2,
                            dataApi.telefono2,
                            dataApi.antiguedad2,
                            dataApi.nombre3,
                            dataApi.documento3,
                            dataApi.tipo3,
                            dataApi.direccion3,
                            dataApi.telefono3,
                            dataApi.telefono3_2,
                            dataApi.relacion,
                            dataApi.desc_relacion,
                            dataApi.observaciones,
                            dataApi.info_adicional,
                            dataApi.docs_requeridos,
                            dataApi.SoyCliente,
                            dataApi.id_usuario,
                            dataApi.egresos,
                            dataApi.id_cot_uaf10,
                            dataApi.id_cot_uaf11,
                            dataApi.id_cot_uaf12,
                            dataApi.id_cot_uaf13,
                            dataApi.id_cot_uaf14,
                            dataApi.id_cot_uaf15,
                            dataApi.id_cot_uaf16,
                            dataApi.id_cot_uaf17,
                            dataApi.id_cot_uaf18,
                            dataApi.tipo_identificacion_conyuge,
                            dataApi.identificacion_conyuge,
                            dataApi.nombre_comercial,
                            dataApi.calle_principal,
                            dataApi.calle_secundaria,
                            dataApi.ref_ubicacion,
                            dataApi.mail_emp,
                            dataApi.telefono_emp,
                            dataApi.cel_emp,
                            dataApi.ingresos_mes_p,
                            dataApi.total_activos_p,
                            dataApi.total_pasivos_p,
                            dataApi.otros_ingresos_p,
                            dataApi.egresos_p,
                            dataApi.id_cot_uaf19,
                            dataApi.nombre_comercial_p,
                            dataApi.calle_principal_p,
                            dataApi.calle_secundaria_p,
                            dataApi.ref_ubicacion_p,
                            dataApi.mail_emp_p,
                            dataApi.telefono_emp_p,
                            dataApi.cel_emp_p,
                            dataApi.id_cot_uaf20,
                            dataApi.servicios,
                            dataApi.id_cot_uaf21,
                            dataApi.egresos_mes_p,
                            dataApi.desemp1,
                            dataApi.desemp3,
                            dataApi.des_desemp1,
                            dataApi.fecha_desemp_1,
                            dataApi.relacion_2,
                            dataApi.nombres_rel_2,
                            dataApi.cargo_2,
                            dataApi.fecha_desemp_2,
                            dataApi.des_desemp3,
                            dataApi.fecha_desemp_3,
                            dataApi.sexo,
                            dataApi.fechaCumple,
                            dataApi.estadoCivil
                          );
                        }

                        // {
                        //   console.log( "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:2},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
                        //   username +
                        //   "%26quot;},%26quot;CrearCliente%26quot;:{%26quot;Crm%26quot;:%26quot;" +
                        //   String(data.idcrm) +
                        //   "%26quot;,%26quot;Tipo%26quot;:%26quot;Completo%26quot;},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;"+String(1)+"%26quot;}}")
                        // getRegistrarPeticion( String(data.idcrm));
                        // }
                      }
                    >
                      Crear Cliente
                    </Button>
                  ) : (
                    // <Button
                    //   variant="contained"
                    //   sx={{
                    //     mt: 3,
                    //     mb: 3,
                    //     width: "15%",
                    //     marginLeft: "42%",
                    //     marginRight: "43%",
                    //   }}
                    //   onClick={function (event) {
                    //     console.log( "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:2},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
                    //     username +
                    //     "%26quot;},%26quot;CrearCliente%26quot;:{%26quot;Crm%26quot;:%26quot;" +
                    //     String(data.idcrm) +
                    //     "%26quot;,%26quot;Tipo%26quot;:%26quot;Completo%26quot;},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;"+String(1)+"%26quot;}}")
                    //   getRegistrarPeticion( String(data.idcrm));
                    //   }}
                    // >
                    //   Crear Cliente
                    // </Button>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "40%",
                        marginLeft: "30%",
                      }}
                      style={{ display: "inline-block", textAlign: "center" }}
                    >
                      <strong>
                        ¡Complete el proceso para crear el cliente!
                      </strong>
                    </Typography>
                  )}
                  {/* <Button href={`/ventas/index/${params.idcrm}`}>Atras</Button> */}
                </Paper>
              </>
            ) : (
              <>
                {" "}
                <Paper>
                  <Grid
                    //container
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      sx={{ mt: 2, mb: 1.5 }}
                      variant="h6"
                      textAlign={"center"}
                    >
                      Cargando, espera por favor.
                    </Typography>
                  </Grid>
                </Paper>
              </>
            )}

            <Dialog
              open={openDialog}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Cliente creado correctamente!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href="/ventas"
                  onClick={function (event) {
                    setOpenDialog(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
