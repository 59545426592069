import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SateliteModeloFamiliaType, TablaRentaAutorizaType, VSateliteModeloFamiliaNivelType, marcaType } from "../../types";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { blue, green, grey, orange, red, yellow } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export const AutorizacionNivelesPage: React.FC<{}> = () => {
 
  const listadoNivel: nivelType[] = [
    { nivId: 1, nivel: "ROJO" },
    { nivId: 2, nivel: "NARANJA" },
    { nivId: 3, nivel: "VERDE" },
  ];
  const listadoMarcas: marcaType[] = [
    { marId: 1, marNombre: "CHEVROLET" },
    { marId: 2, marNombre: "GAC" },
    { marId: 3, marNombre: "VOLKSWAGEN" },
  ];
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();


  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getFamilias();
    } else {
      setName("");
    }
  }, [account]);

   
  type nivelType = {
    nivId: number;
    nivel: string;
  };

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    getFamiliasCombo(Number(event.target.value), 0);
  };

  const handleChangeFamilia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamilia(Number(event.target.value));
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  const [mostrar, setMostar] = React.useState(false); 
  const [openDialog, setOpenDialog] = React.useState(false); 
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [marca, setMarca] = React.useState(0);
  const [nivel, setNivel] = React.useState(0);  
  const [familia, setFamilia] = React.useState(0);
  const listadoFamilia: VSateliteModeloFamiliaNivelType[] = [];
  const listadosFamilias: SateliteModeloFamiliaType[] = [];
  const [dataFamilia, setDataFamilia] = useState<SateliteModeloFamiliaType[]>(listadosFamilias);
  const [listaNiveles, setListaNiveles] =
    useState<VSateliteModeloFamiliaNivelType[]>(listadoFamilia);
  const [cheked, setCheked] = React.useState({
    toda_zona: false,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });  
  }; 
  
  const handleChangeNivel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNivel(Number(event.target.value));
  }; 


  async function getFamiliasCombo(idmarca: number, id:number) {
    try {
      await axios
        .get<SateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/satelitemodelofamilia/marca/" + String(idmarca)
        )
        .then((response) => {
          setDataFamilia(response.data);
          setFamilia(id);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
 
  async function getFamilias() {
    try {
      await axios
        .get<VSateliteModeloFamiliaNivelType[]>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilianivel"
        )
        .then((response) => {
          setListaNiveles(response.data);
          setMostar(true)
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    { field: "marca", headerName: "Marca", editable: false, width: 175 },
    { field: "familia", headerName: "Familia", editable: false, width: 300 },
    { field: "texto", headerName: "Condición", editable: false, width: 250 },
   
    {
      field: "nivel",
      headerName: "Nivel",
      editable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <div>
            {params.row.nivel === "VERDE" ? (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            ) : params.row.nivel === "NARANJA" ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/ventas/index/${params.row.idcrm}`}
            onClick={function (event) {
              //getRegistroAutoriza(params.row.traId);
              setOpenDialog(true);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];
 
  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Niveles de Rentabilidad 
              </Typography>
            </Paper>
            {/* insertar validacion para mostrar */}
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Button variant="contained" sx={{ mt: 1, mb: 1, marginLeft: "42%", width: "16%" }} onClick={function (event) {setOpenDialog(true)}}>
                Nuevo
              </Button>
              {mostrar ? (
                <>
                
                   <DataGrid
                    getRowId={(row) => row.sfaId}
                    sx={{ height: 650 }}
                    editMode="row"
                    rows={listaNiveles}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={{
                      footerTotalRows: false,
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </Paper>

            {/* modal */}

            <Modal
              open={openDialog}
              onClose={function (event) {
                setOpenDialog(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>              
               
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Marca"
                  value={marca}
                  //helperText="Please select your currency"
                  onChange={handleChangeMarca}
                  size="small"
                  margin="normal"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                >
                  {listadoMarcas.map((option) => (
                    <MenuItem key={option.marId} value={option.marId}>
                      {option.marNombre}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Familia"
                  value={familia}
                  //helperText="Please select your currency"
                  onChange={handleChangeFamilia}
                  size="small"
                  margin="normal"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                >
                  {dataFamilia.map((option) => (
                    <MenuItem key={option.smfId} value={option.smfId}>
                      {option.smfFamiliaDms}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="nombre"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Límite Rojo"
                //  value={sateliteUsuario.bodega}
                  //onChange={handleData}
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "39%" }}
                />
                <TextField
                  name="nombre"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Límite Naranja"
                //  value={sateliteUsuario.bodega}
                  //onChange={handleData}
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "2%", width: "39%" }}
                />
                
                <Button
                  variant="contained"
                  sx={{ mt: 1, mb: 1, marginLeft: "42%", width: "16" }}
                  onClick={function (event) {}}
                >
                  Guardar
                </Button>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
