import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { cantidad_cargas, clienterpaType, datos_en_relacion_dependencia, datos_relacion_independencia, datos_separados_nombres, empleador_1, empleador_2, medios, medios_2, medios_contacto, socio_demografico } from "../../types";

export const RevisionUafePage: React.FC<{}> = () => {
 
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      String(params.idcrm) === "0" ? console.log("Es un nuevo"): getDatos();
    } else {
      setName("");
    }
  }, [account]);

  const params = useParams();

  const [data, setData] = React.useState<clienterpaType>({
    id: 0,
    idCrm: "",
    cedula: "",
    primerApellido: "",
    segundoApellido: "",
    primerNombre: "",
    segundoNombre: "",
    direccion: "",
    correo: "",
    telefono: "",
    celular: "",
    zona: "",
    subzona: "",
    actividad: "",
    origen: "",
    tipoFacts: "",
    tipoPerfil: "",
    identificación: "",
    tributario: "",
    activiadadEconomica: "",
    notas: "",
    pais: "",
    departamento: "",
    ciudad: "",
    barrio: "",
    fechaNacimiento: "",
    sexo: "",
    estadoCivil: "",

    hdAutorizaDatos: "",
    hdMedioContacto: "",
    hdInformarProductosServicios: "",
    hdInscritoComunidadMarca: "",
    hdAutorizaViaTelefonica: "",
    hdAutorizaPorEscrito: "",

    identificacionConyuge: "",
    tipoDocumentoConyuge: "",
    apellidoConyuge: "",
    nombreConyuge: "",

    uafFuncionJudicial: "",
    uafMinisterioInterior: "",
    uafCritOfiCump: "",
    uafSriRentaCausada: "",
    uafAntComercialNegativo: "",
    uafCoincidenciaListados: "",

    nombreComercial: "",
    empresaLabora: "",
    ingresosMes: 0,
    otrosIngresos: 0,
    totalActivos: 0,
    egresos: 0,
    totalPasivos: 0,
    callePrincipal: "",
    calleSecundaria: "",
    refDeUbc: "",
    mailEmpresarial: "",
    telefonoEmpresa: "",
    celularEmpresa: "",
    personaJuridica: 0,
    empresaNombre: "",
    empresaRepresentante: "",
    dms: 0,
  });

  async function getDatos() {
    try {
      await axios
        .get<clienterpaType>(
          "https://apisatelite.azurewebsites.net/clienterpa/" + params.idcrm
        )
        .then((response) => {
          setData(response.data);
          console.log("mi respuesta: ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const isAuthenticated = useIsAuthenticated();
  const [openDialog, setOpenDialog] = React.useState(false);

  type personaType = {
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaResumenType;
  };

  type tipoSolicitudType = {
    Id: number;
  };

  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    Estado: string;
  };

  type solicitudType = {
    TipoSolicitud: tipoSolicitudType;
    Persona: personaType;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    Mensaje: string;
    UrlEvidencia: string;
  };

  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    Usuario: string;
    Password: string;
    UrlBot: string;
    Estado: string;
  };

  type respuestaDetalleType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  type respuestaType = {
    Codigo: number;
    Descripcion: string;
    Objeto: objetoRespuestaType;
  };



  type tipoPersonaType = {
    Id: number;
    Descripcion: string;
  };

  type tipoPersonaResumenType = {
    Id: number;
  };

  const listaTipoPersona: tipoPersonaType[] = [
    { Id: 1, Descripcion: "Persona Natural" },
    { Id: 2, Descripcion: "Extranjero" },
    { Id: 3, Descripcion: "Persona Jurídica" },
    { Id: 5, Descripcion: "Empresa extranjera" },
  ];
  const [tipoPersonaSolicitud, setTipoPersonaSolicitud] =
    React.useState<tipoPersonaResumenType>({
      Id: 1,
    });

  const [parametrosBusqueda, setParametrosBusqueda] =
    React.useState<personaType>({
      CI: "",
      PrimerNombre: "",
      SegundoNombre: "",
      PrimerApellido: "",
      SegundoApellido: "",
      TipoPersona: tipoPersonaSolicitud,
    });

  const [parametrosSolicitudTipo, setParametrosSolicitudTipo] =
    React.useState<tipoSolicitudType>({
      Id: 1,
    });

  const [parametrosSolicitud, setParametrosSolicitud] =
    React.useState<solicitudType>({
      TipoSolicitud: parametrosSolicitudTipo,
      Persona: parametrosBusqueda,
    });

  const dataBusqueda = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    if  (String(params.idcrm) === "0" && tipo === 1) {
      if (e.target.name == "cedula") {
        if (e.target.value.length === 10 && username.substring(username.indexOf("@"), 100 ) != "@assa.com.ec") {
          //consulta data book
          console.log("consula databook")
          getDataBook(e.target.value)
        }
      }
    } 
    
  };

  const handle_datos_separados_nombres = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRespuesta0Objeto({
      ...respuesta0Objeto,
      [e.target.name]: e.target.value,
    });
  };


  const [respuesta1Objeto, setRespuesta1Objeto] = useState<socio_demografico>({
    nut: "",
    nombre: "",
    sexo: "",
    fecha_1: "",
    nacionalidad: "",
    civil: "",
    fecha_2: "",
    fecha_3: "",
    profesion: "",
  });
  const [respuesta2Objeto, setRespuesta2Objeto] =
    useState<datos_en_relacion_dependencia>({
      ruc1: "",
      nombre1: "",
      direccion1: "",
      telefono1: "",
      descripcion1: "",
      actividad1: "",
      provincia1: "",
      canton1: "",
      parroquia1: "",
      rango1: "",
      rango2: "",
      rango3: "",
      rango4: "",
      fecha_4: "",
      fecha_5: "",
      ocupacion: "",
    });
  const [respuesta3Objeto, setRespuesta3Objeto] =
    useState<datos_relacion_independencia>({
      RUC: "",
      RAZON: "",
      FANTASIA: "",
      INICIO: "",
      CANCELACION: "",
      SUSPENSION: "",
      REINICIO: "",
      DIRECCION_RUC: "",
      REFERENCIA: "",
      ACTIVIDAD_RUC: "",
      PROVINCIA_RUC: "",
      CANTON_RUC: "",
      PARROQUIA_RUC: "",
    });
  const [respuesta4Objeto, setRespuesta4Objeto] = useState<medios_contacto>({
    medio1: "",
    medio2: "",
    medio3: "",
    medio4: "",
    medio5: "",
    medio6: "",
    DIRECCION_ADICIONAL: "",
    TELEFONO_ADICIONAL: "",
    PROVINCIA_ADICIONAL: "",
    CANTON_ADICIONAL: "",
    PARROQUIA_ADICIONAL: "",
    EMAIL1: "",
    EMAIL2: "",
  });
  const [respuesta5Objeto, setRespuesta5Objeto] = useState<empleador_1>({
    empleador1ruc1: "",
    empleador1nombre1: "",
    empleador1direccion1: "",
    empleador1telefono1: "",
    empleador1descripcion1: "",
    empleador1actividad1: "",
    empleador1cargo1: "",
    empleador1salario1: "",
    empleador1ingreso1: "",
    empleador1salida1: "",
    empleador1provincia1: "",
    empleador1canton1: "",
    empleador1parroquia1: "",
  });
  const [respuesta6Objeto, setRespuesta6Objeto] = useState<empleador_2>({
    empleador2ruc2: "",
    empleador2nombre2: "",
    empleador2direccion2: "",
    empleador2telefono2: "",
    empleador2descripcion2: "",
    empleador2actividad2: "",
    empleador2cargo2: "",
    empleador2salario2: "",
    empleador2ingreso2: "",
    empleador2salida2: "",
    empleador2provincia2: "",
    empleador2canton2: "",
    empleador2parroquia2: "",
  });
  const [respuesta7Objeto, setRespuesta7Objeto] = useState<medios>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta8Objeto, setRespuesta8Objeto] = useState<medios_2>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta9Objeto, setRespuesta9Objeto] = useState<cantidad_cargas>({
    cargas: "",
  });
  const [respuesta0Objeto, setRespuesta0Objeto] =
    useState<datos_separados_nombres>({
      primer_apellido: "",
      segundo_apellido: "",
      primer_nombre: "",
      segundo_nombre: "",
    });


  
  type objetoRespuestaType = {
    socio_demografico: socio_demografico;
    datos_en_relacion_dependencia: datos_en_relacion_dependencia;
    datos_relacion_independencia: datos_relacion_independencia;
    medios_contacto: medios_contacto;
    empleador_1: empleador_1;
    empleador_2: empleador_2;
    medios: medios;
    medios_2: medios_2;
    cantidad_cargas: cantidad_cargas;
    datos_separados_nombres: datos_separados_nombres;
  };

  const [respuestaObjeto, setRespuestaObjeto] = useState<objetoRespuestaType>({
    socio_demografico: respuesta1Objeto,
    datos_en_relacion_dependencia: respuesta2Objeto,
    datos_relacion_independencia: respuesta3Objeto,
    medios_contacto: respuesta4Objeto,
    empleador_1: respuesta5Objeto,
    empleador_2: respuesta6Objeto,
    medios: respuesta7Objeto,
    medios_2: respuesta8Objeto,
    cantidad_cargas: respuesta9Objeto,
    datos_separados_nombres: respuesta0Objeto,
  });

  const [dataDB, setDataDB] = useState<respuestaType>({
    Codigo: 0,
    Descripcion: "",
    Objeto: respuestaObjeto,
  });

  async function getDataBook(cedula: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/DataBook?ci=" +
            cedula
        )
        .then((response) => {
          setDataDB(response.data);
          setRespuesta0Objeto(response.data.Objeto.datos_separados_nombres);
          setRespuesta4Objeto(response.data.Objeto.medios_contacto);
          console.log("respuesta databook>>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  const [respuestaTipoSolicitud, setRespuestaTipoSolicitud] =
    useState<tipoSolicitudRespuestaType>({
      Id: 0,
      Nombre: "",
      Estado: "",
    });
  const [respuestaPersona, setRespuestaPersona] = useState<personaType>({
    CI: "",
    PrimerNombre: "",
    SegundoNombre: "",
    PrimerApellido: "",
    SegundoApellido: "",
    TipoPersona: tipoPersonaSolicitud,
  });

  const respuestaPaginas: paginaType[] = [];

  const [respuestaDetalle, setRespuestaDetalle] =
    useState<respuestaDetalleType>({
      Id: 0,
      TipoSolicitud: respuestaTipoSolicitud,
      Fecha: "",
      IpOrigen: "",
      Estado: "",
      Persona: respuestaPersona,
      RespuestaPaginasPublicas: respuestaPaginas,
    });

    type respuestaGetType = {
      Codigo: "",
      Descripcion: "",
      Objeto: respuestaDetalleType
    }

  const [respuestaGet, setRespuestaGet] = useState<respuestaGetType>({
    Codigo: "",
    Descripcion: "",
    Objeto: respuestaDetalle,
  });

  async function getRegistrarPeticion(parametro: string) {
    try {
      await axios
        .get<respuestaGetType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud" +
            parametro
        )
        .then((response) => {
          setRespuestaGet(response.data);
          console.log("respuesta del registro peticion >>>>>>>>>>>> :", response.data);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function consultarPrincipal() {
    await console.log(
      "MI PETICION ES >>>> ",
      JSON.stringify(parametrosSolicitud, null, 4)
    );
    await getRegistrarPeticion(
      "?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
        String(1) +
        "%26quot;},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
        username +
        "%26quot;},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
        data.cedula +
        "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
        respuesta0Objeto.primer_nombre.replace("&","%26") +
        "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
        respuesta0Objeto.segundo_nombre.replace("&","%26") +
        "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
        respuesta0Objeto.primer_apellido.replace("&","%26") +
        "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
        respuesta0Objeto.segundo_apellido.replace("&","%26") +
        "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
        String(tipo) +
        "}}}"
    );
    console.log(
      "mi url es >>>>>>>",
      "?token=\n{%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
        parametrosBusqueda.CI +
        "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
        parametrosBusqueda.PrimerNombre +
        "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
        parametrosBusqueda.SegundoNombre +
        "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
        parametrosBusqueda.PrimerApellido +
        "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
        parametrosBusqueda.SegundoApellido +
        "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
        String(tipo) +
        "}}}"
    );
  }

  const [tipo, setTipo] = React.useState(1);
  const handleChangeTipo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipo(Number(event.target.value));
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >                          
              <Typography sx={{ mt: 1 }} variant="h5" textAlign={"center"}>
                Páginas Externas - UAFE
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Dialog
                open={openDialog}
                //onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Peticion realizada correctamente
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    href="/uafe/listado"
                    onClick={function (event) {
                      setOpenDialog(false);
                    }}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "15%",
                    width: "15%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  {tipo === 3 || tipo === 5 ? "RUC/Pasaporte:" : "Cédula/Pasaporte:"}                  
                </Typography>
                <TextField
                  name="cedula"
                  size="small"
                  margin="normal"
                  value={data.cedula}
                  onChange={dataBusqueda}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                ></TextField>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "15%",
                    marginLeft: "4%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tipo de persona:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  margin="normal"
                  value={tipo}
                  onChange={handleChangeTipo}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                >
                  {listaTipoPersona.map((option) => (
                    <MenuItem key={option.Id} value={option.Id}>
                      {option.Descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              
                {tipo === 3 || tipo === 5 ? (<>
                
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "15%",
                    width: "15%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Nombre empresa:
                </Typography>
                <TextField
                  name="primer_apellido"
                  size="small"
                  margin="normal"
                  value={respuesta0Objeto.primer_apellido}
                  onChange={handle_datos_separados_nombres}
                  sx={{ mt: 0.5, mb: 0.5, width: "55%" }}
                ></TextField>
                
                </>):(<>
                  <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "15%",
                    width: "15%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Primer Apellido:
                </Typography>
                <TextField
                  name="primer_apellido"
                  size="small"
                  margin="normal"
                  value={respuesta0Objeto.primer_apellido}
                  onChange={handle_datos_separados_nombres}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                ></TextField>

                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "15%",
                    marginLeft: "4%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Segundo Apellido:
                </Typography>
                <TextField
                  name="segundo_apellido"
                  size="small"
                  margin="normal"
                  value={respuesta0Objeto.segundo_apellido}
                  onChange={handle_datos_separados_nombres}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "15%",
                    width: "15%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Primer nombre:
                </Typography>
                <TextField
                  name="primer_nombre"
                  size="small"
                  margin="normal"
                  value={respuesta0Objeto.primer_nombre}
                  onChange={handle_datos_separados_nombres}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                />

                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "4%",
                    width: "15%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Segundo nombre:
                </Typography>
                <TextField
                  name="segundo_nombre"
                  size="small"
                  margin="normal"
                  value={respuesta0Objeto.segundo_nombre}
                  onChange={handle_datos_separados_nombres}
                  sx={{ mt: 0.5, mb: 0.5, width: "18%" }}
                />
              </Box>
              </>) }
              {data.cedula.length > 0 ? (
                <Button
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "43%",
                  }}
                  onClick={function (event) {
                    consultarPrincipal();
                  }}
                >
                  Registrar petición
                </Button>
              ) : (
                <></>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
