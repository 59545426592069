import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CrearSateliteNegocioFIType,
  SateliteNegocioFIType,
  TipoFinanciamientoType,
  VentasCRMFIType,
  marcaType,
} from "../../types";
import { useParams } from "react-router-dom";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export const FINegocioPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getRegistro();
    } else {
      setName("");
    }
  }, [account]);

  const [data, setData] = React.useState<VentasCRMFIType>({
    id: 0,
    idcrm: "",
    fechaFactura: "",
    cedula: "",
    cliente: "",
    asesor: "",
    dealname: "",
    agencia: "",
    modelo: "",
    correo: "",
    idBodega: 0,
    ano: 0,
    mes: 0,
    marca: "",
    formaPago: "",
    chasis: "",
    nfiFecha: "",
    idAseguradora: 0,
    aseguradora: "",
    nfiLiviano: 0,
    nfiPrima: 0,
    nfiComision: 0,
    nfiPorcentajeComision: 0,
    nfiEstado: 0,
    nfiId: 0,
    nfiFechaPago: "",
  });

  const params = useParams();

  async function getRegistro() {
    try {
      await axios
        .get<VentasCRMFIType>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/buscar/" +
            String(params.id)
        )
        .then((response) => {
          console.log("esta es mi respuesta >>>>>>>>", response.data);
          setData(response.data);
          setEstado(response.data.nfiEstado);
          if (response.data.nfiId > 0) {
            initCheket(
              String(response.data.nfiLiviano) === "true" ? true : false
            );
          }
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function setRegistroNuevo(
    idCrm: string,
    idBodega: number,
    idMarca: number,
    nfiFecha: string,
    idAseguradora: number,
    nfiNitCliente: string,
    nfiNombreCliente: string,
    nfiNitAsesor: string,
    nfiNombreAsesor: string,
    nfiModelo: string,
    nfiChasis: string,
    nfiLiviano: number,
    nfiPrima: number,
    nfiComision: number,
    nfiPorcentajeComision: number,
    nfiEstado: number,
    nfiFechaPago: string
  ) {
    console.log("tabla a guardar >> ");
    const nuevaTabla: CrearSateliteNegocioFIType = {
      idCrm: idCrm,
      idBodega: idBodega,
      idMarca: idMarca,
      nfiFecha: nfiFecha,
      idAseguradora: idAseguradora,
      nfiNitCliente: nfiNitCliente,
      nfiNombreCliente: nfiNombreCliente,
      nfiNitAsesor: nfiNitAsesor,
      nfiNombreAsesor: nfiNombreAsesor,
      nfiModelo: nfiModelo,
      nfiChasis: nfiChasis,
      nfiLiviano: nfiLiviano,
      nfiPrima: nfiPrima,
      nfiComision: nfiComision,
      nfiPorcentajeComision: nfiPorcentajeComision,
      nfiEstado: nfiEstado,
      nfiFechaPago: nfiFechaPago,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevaTabla, null, 4));
    postGuardarNuevoRegistro(nuevaTabla);
  }

  function setRegistroUpdate(
    nfiId: number,
    idCrm: string,
    idBodega: number,
    idMarca: number,
    nfiFecha: string,
    idAseguradora: number,
    nfiNitCliente: string,
    nfiNombreCliente: string,
    nfiNitAsesor: string,
    nfiNombreAsesor: string,
    nfiModelo: string,
    nfiChasis: string,
    nfiLiviano: number,
    nfiPrima: number,
    nfiComision: number,
    nfiPorcentajeComision: number,
    nfiEstado: number,
    nfiFechaPago: string
  ) {
    console.log("tabla a guardar >> ");
    const nuevaTabla: SateliteNegocioFIType = {
      nfiId: nfiId,
      idCrm: idCrm,
      idBodega: idBodega,
      idMarca: idMarca,
      nfiFecha: nfiFecha,
      idAseguradora: idAseguradora,
      nfiNitCliente: nfiNitCliente,
      nfiNombreCliente: nfiNombreCliente,
      nfiNitAsesor: nfiNitAsesor,
      nfiNombreAsesor: nfiNombreAsesor,
      nfiModelo: nfiModelo,
      nfiChasis: nfiChasis,
      nfiLiviano: nfiLiviano,
      nfiPrima: nfiPrima,
      nfiComision: nfiComision,
      nfiPorcentajeComision: nfiPorcentajeComision,
      nfiEstado: nfiEstado,
      nfiFechaPago: nfiFechaPago,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevaTabla, null, 4));
    postGuardarUpdateRegistro(nuevaTabla);
  }

  const [openDialog, setOpenDialog] = React.useState(false);

  async function postGuardarNuevoRegistro(
    registroGuardar: CrearSateliteNegocioFIType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitenegociofi",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarUpdateRegistro(
    registroGuardar: SateliteNegocioFIType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitenegociofi",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const dataDatos = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    
  };

  const listaAnos: TipoFinanciamientoType[] = [
    { id: 1, nombre: "Zurich" },
    { id: 2, nombre: "Unidos" },
    { id: 3, nombre: "Aseguradora del Sur" },
    { id: 4, nombre: "Chubb" },
    { id: 5, nombre: "Latina" },
    { id: 6, nombre: "Sweaden" },
    { id: 7, nombre: "AIG" },
  ];
  const [dataAseguradora, setDataAseguradora] =
    useState<TipoFinanciamientoType[]>(listaAnos);
  const [aseguradora, setAseguradora] = React.useState(0);
  const handleChangeAseguradora = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAseguradora(Number(event.target.value));
    setData({
      ...data,
      idAseguradora: Number(event.target.value),
    });
  };

  const listaEstado: TipoFinanciamientoType[] = [
    { id: 0, nombre: "Pendiente" },
    { id: 1, nombre: "Por pagar" },
    { id: 2, nombre: "Pagado" },
    { id: 3, nombre: "Anulado" },
  ];

  const [dataEstado, setDataEstado] =
    useState<TipoFinanciamientoType[]>(listaEstado);

  const [estado, setEstado] = React.useState(0);

  const handleChangeEstado = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstado(Number(event.target.value));
  };
  const [cheked, setCheked] = React.useState({
    liviano: true,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  function initCheket(estadoCheked: boolean) {
    setCheked({
      ...cheked,
      liviano: estadoCheked,
    });
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Dialog
                open={openDialog}
                //onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Guardado correctamente!
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    href="/fi/index"
                    onClick={function (event) {
                      setOpenDialog(false);
                    }}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Typography variant="h5" textAlign={"center"}>
                Seguros - F&I
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Fecha:
              </Typography>
              <TextField
                id="primer_apellido"
                size="small"
                margin="normal"
                name="primerApellido"
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
                onChange={dataDatos}
                value={String(data.nfiFecha).substring(0, 10)}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="segundo_apellido"
                size="small"
                margin="normal"
                name="segundoApellido"
                //onChange={handleChangeZona}
                value={data.agencia}
                sx={{ mt: 0.5, mb: 0.5, width: "35%", marginRight: "10%" }}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Chasis:
              </Typography>
              <TextField
                id="primer_nombre"
                size="small"
                margin="normal"
                value={data.chasis}
                // style={{
                //   backgroundColor:
                //     String(data.primerNombre).length > 0
                //       ? "rgb(242,242,242)"
                //       : "rgb(247,202,202)",
                // }}
                //onChange={handleChangeZona}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                }}
                style={{ display: "inline-block" }}
              >
                Modelo:
              </Typography>
              <TextField
                id="segundo_nombre"
                size="small"
                margin="normal"
                sx={{ mt: 0.5, mb: 0.5, width: "35%", marginRight: "10%" }}
                value={data.modelo}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Cédula:
              </Typography>
              <TextField
                size="small"
                margin="normal"
                value={data.cedula}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                }}
                style={{ display: "inline-block" }}
              >
                Cliente:
              </Typography>
              <TextField
                size="small"
                margin="normal"
                value={data.cliente}
                sx={{ mt: 0.5, mb: 0.5, width: "35%", marginRight: "10%" }}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Aseguradora:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={data.idAseguradora}
                onChange={handleChangeAseguradora}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
              >
                {dataAseguradora.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "12%",
                }}
                style={{ display: "inline-block" }}
              >
                Liviano / Pesado:
              </Typography>
              <Switch
                checked={cheked.liviano}
                onChange={handleCheked}
                name="liviano"
                sx={{ mt: 0.5, mb: 0.5, marginRight: "3%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                {cheked.liviano ? "Liviano" : "Pesado"}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Prima:
              </Typography>
              <TextField
                name="nfiPrima"
                size="small"
                margin="normal"
                type="number"
                value={data.nfiPrima}
                onChange={dataDatos}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  //marginLeft: "2%",
                }}
                style={{ display: "inline-block" }}
              >
                Comision:
              </Typography>
              <TextField
                size="small"
                margin="normal"
                value={Number(
                  data.nfiPrima * (data.nfiPorcentajeComision / 100)
                ).toFixed(2)}
                //value={data.primerNombre}
                style={{
                  backgroundColor: "rgb(242,242,242)",
                }}
                //onChange={handleChangeZona}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  //marginLeft: "2%",
                }}
                style={{ display: "inline-block" }}
              >
                % Comision:
              </Typography>
              <TextField
                name="nfiPorcentajeComision"
                size="small"
                margin="normal"
                value={data.nfiPorcentajeComision}
                // style={{
                //   backgroundColor:
                //     String(data.primerNombre).length > 0
                //       ? "rgb(242,242,242)"
                //       : "rgb(247,202,202)",
                // }}
                onChange={dataDatos}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "8%" }}
              />

              <Typography
                sx={{
                  mt: 2,
                  mb: 1,
                  width: "8%",
                  marginLeft: "15%",
                }}
                style={{ display: "inline-block" }}
              >
                Estado:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={estado}
                onChange={handleChangeEstado}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "4%", width: "15%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataEstado.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.id === 0 ? (
                      <RadioButtonCheckedIcon sx={{ color: blue[500] }} />
                    ) : option.id === 1 ? (
                      <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
                    ) : option.id === 2 ? (
                      <RadioButtonCheckedIcon sx={{ color: green[500] }} />
                    ) : option.id === 3 ? (
                      <RadioButtonCheckedIcon sx={{ color: red[500] }} />
                    ) : option.id === 4 ? (
                      <RadioButtonCheckedIcon sx={{ color: red[500] }} />
                    ) : (
                      <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
                    )}
                    {<>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                sx={{
                  mt: 2,
                  mb: 1,
                  width: "8%",
                }}
                style={{ display: "inline-block" }}
              >
                Fecha pago:
              </Typography>
              <TextField
              name="nfiFechaPago"
                size="small"
                margin="normal"
                type="date"
                value={data.nfiFechaPago}
                onChange={dataDatos}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "15%" }}
              />
              <Button
                variant="contained"
                sx={{ mt: 1, mb: 1, width: "15%", marginLeft: "42%" }}
                onClick={function (event) {
                  data.nfiId === 0
                    ? setRegistroNuevo(
                        data.idcrm,
                        data.idBodega,
                        1,
                        data.nfiFecha,
                        data.idAseguradora,
                        data.cedula,
                        data.cliente,
                        "",
                        data.asesor,
                        data.modelo,
                        data.chasis,
                        cheked.liviano === true ? 1 : 0,
                        data.nfiPrima,
                        data.nfiPrima * (data.nfiPorcentajeComision / 100),
                        data.nfiPorcentajeComision,
                        estado,
                        data.nfiFechaPago
                      )
                    : setRegistroUpdate(
                        data.nfiId,
                        data.idcrm,
                        data.idBodega,
                        1,
                        data.nfiFecha,
                        data.idAseguradora,
                        data.cedula,
                        data.cliente,
                        "",
                        data.asesor,
                        data.modelo,
                        data.chasis,
                        cheked.liviano === true ? 1 : 0,
                        data.nfiPrima,
                        data.nfiPrima * (data.nfiPorcentajeComision / 100),
                        data.nfiPorcentajeComision,
                        estado,
                        data.nfiFechaPago
                      );
                }}
              >
                Guardar
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
