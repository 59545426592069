import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";

export const RevisionUafeListadoPage: React.FC<{}> = () => {
  useEffect(() => {   
    getvListado();
  }, []);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const columns: GridColDef[] = [
    {headerClassName: "theme-header", 
      field: "Fecha",
      headerName: "Fecha",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return String(row.Fecha).substring(0, 10);
      },
    },
    {headerClassName: "theme-header", 
      field: "hora",
      headerName: "Hora",
      editable: false,
      width: 70,
      valueGetter: ({ row }) => {
        return String(row.Fecha).substring(11, 16);
      },
    },
    {headerClassName: "theme-header", 
      field: "solicita",
      headerName: "Solicita",
      editable: false,
      width: 240,
      valueGetter: ({ row }) => {
        return row.Solicitante.Email;
      },
    },
    {headerClassName: "theme-header", 
      field: "cedula",
      headerName: "Cédula/Pasaporte",
      editable: false,
      width: 125,
      valueGetter: ({ row }) => {
        return row.Persona.CI;
      },
    },
    {headerClassName: "theme-header", 
      field: "cliente",
      headerName: "Cliente",
      editable: false,
      width: 350,
      valueGetter: ({ row }) => {
        return (
          row.Persona.PrimerApellido +
          " " +
          row.Persona.SegundoApellido +
          " " +
          row.Persona.PrimerNombre +
          " " +
          row.Persona.SegundoNombre
        );
      },
    },
    {headerClassName: "theme-header", 
      field: "estado",
      headerName: "Estado petición",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return row.Estado;
      },
    },
    {headerClassName: "theme-header", 
      field: "action",
      headerName: "Detalle",
      sortable: false,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
          href={`/uafe/${params.id}`} 
            onClick={function (event) {
            //  setRespuestaDetalleRow(params.row);
            //  handleOpen();
            }}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
  ];

  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    UrlBot: string;
    Estado: string;
  };

  type tipoPersonaType = {
    Id: number;
  };

  type personaType = {
    Id: number;
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaType;
  };

  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    UrlBot: string;
    Estado: string;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    UrlEvidencia: string;
    Mensaje: string;
  };

  type solicitanteType = {
    Email: string;
  };

  type esmpresaType = {
    Id: number,
    Nombre: string,
  }

  type respuestaDetalleType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Empresa: esmpresaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Solicitante: solicitanteType;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  type respuestaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleType[];
  };

  const respuestaDetalle: respuestaDetalleType[] = []; 

  const [respuestaGet, setRespuestaGet] = useState<respuestaType>({
    Codigo: "",
    Descripcion: "",
    Objeto: respuestaDetalle,
  });
  async function getvListado() {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/BuscarCI"
        )
        .then((response) => {
          setRespuestaGet(response.data);
          console.log("respuesta >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [parametroBusqueda, setParametroBusqueda] =
    React.useState<parametroBuqueda>({
      parametro: "",
    });

  type parametroBuqueda = {
    parametro: string;
  };

  const dataBusqueda = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParametroBusqueda({
      ...parametroBusqueda,
      [e.target.name]: e.target.value,
    });
  };

  async function getvListadoBusqueda(_cedula: string) {
    try {
      await axios
        .get<respuestaType>(
          " https://rpa.corporacionproauto.com.ec/TEST/Solicitud/BuscarCI?ci=" +
            _cedula
        )
        .then((response) => {
          setRespuestaGet(response.data);
          console.log("respuesta >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Páginas Externas - UAFE
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
                <TextField
                  name="parametro"
                  size="small"
                  margin="normal"
                  label="Cédula / Pasaporte"
                  onChange={dataBusqueda}
                  sx={{ mt: 1.5, mb: 1.5, width: "30%", marginLeft: "5%" }}
                />
                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "2%",
                    backgroundColor: "rgba(21,57,105,0.75)"
                  }}
                  onClick={function (event) {
                    getvListadoBusqueda(parametroBusqueda.parametro);
                  }}
                >
                  Buscar
                </Button>
              
                      
                      <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        width: "15%",
                        marginLeft: "25%",
                        backgroundColor: "rgba(21,57,105,0.75)"
                      }}
                      href="/uafe/index/0"
                      //onClick={handleOpen}
                    >
                      Nuevo
                    </Button>
                    
              
                    <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                <DataGrid
                  getRowId={(row) => row.Id}
                  sx={{ height: 600 }}
                  editMode="row"
                  rows={ respuestaGet.Objeto.filter((obj) => {
                    return obj.Solicitante.Email === username;
                  })}
                 
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: "Registros por página:",
                    },
                  }}
                  localeText={{
                    noRowsLabel: "No se ha encontrado datos.",
                    noResultsOverlayLabel:
                      "No se ha encontrado ningún resultado",
                    toolbarColumns: "Columnas",
                    toolbarColumnsLabel: "Seleccionar columnas",
                    toolbarFilters: "Filtros",
                    toolbarFiltersLabel: "Ver filtros",
                    toolbarFiltersTooltipHide: "Quitar filtros",
                    toolbarFiltersTooltipShow: "Ver filtros",
                  }}
                />
              </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
