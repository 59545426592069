import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

export const ParametroPage: React.FC<{}> = () => {
  useEffect(() => {
    getvParametros();
  }, []);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();

  const dataListaParametro: parametrosType[] = [];
  const [listaParametroRows, setListaParametroRows] =
    useState<parametrosType[]>(dataListaParametro);

  type parametrosType = {
    id: number;
    categoria: string;
    anio: number;
    mes: string;
    formula: string;
  };

  type parametroNuevoType = {
    cipAnio: number;
    cipMes: number;
    cipCategoria: string;
    cipParametroMes: number;
    cipParametroSigno: string;
    cipParametroCantidad: number;
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  async function getvParametros() {
    try {
      await axios
        .get<parametrosType[]>(
          "https://apisatelite.azurewebsites.net/vclasificacionparametros"
        )
        .then((response) => {
          setListaParametroRows(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(listaParametroRows, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function setParametro(
    cipAnio: number,
    cipMes: number,
    cipCategoria: string,
    cipParametroMes: number,
    cipParametroSigno: string,
    cipParametroCantidad: number
  ) {
    console.log("tabla a guardar >> ");
    const nuevaTabla: parametroNuevoType = {
      cipAnio: cipAnio,
      cipMes: cipMes,
      cipCategoria: cipCategoria,
      cipParametroMes: cipParametroMes,
      cipParametroSigno: cipParametroSigno,
      cipParametroCantidad: cipParametroCantidad,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevaTabla, null, 4));
    postGuardarParametro(nuevaTabla);
  }

  async function postGuardarParametro(parametroGuardar: parametroNuevoType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/clasificacioninvparametros",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
          <Paper sx={{ padding: "1.2em", borderRadius: "0.5em", backgroundColor: "rgb(255,255,255,0.85)", mt: 2 }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Parametros de Clasificación de Inventario
              </Typography>
            </Paper>
            <Paper sx={{ padding: "1.2em", borderRadius: "0.5em", backgroundColor: "rgb(255,255,255,0.7)", mt: 2, mb: 4 }}>
            <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "85%",
                  }}
                  onClick={handleOpen}
                >
                  Nuevo
                </Button>
              <Box>
                {listaParametroRows.map((row) => (
                  <Card sx={{ mt: 1, mb: 1 }}>
                    <CardActionArea>
                      <CardContent>
                        <Grid>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 0.2,
                              mb: 0.2,
                              width: "20%",
                              marginLeft: "5%"
                            }}
                            style={{ display: "inline-block" }}
                          >
                            <strong>Categoria: </strong> {row.categoria}
                          </Typography>
                         
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 0.2,
                              mb: 0.2,
                              marginLeft: "5%",
                              width: "50%",
                            }}
                            style={{ display: "inline-block" }}
                          >
                            <strong>Formula: </strong> {row.formula}
                          </Typography>
                          <Button
                            sx={{
                              mt: 0.2,
                              mb: 0.2,
                              marginLeft: "5%",
                              width: "15%",
                            }}
                          >
                            Eliminar
                          </Button>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}             

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      Ingrese un nuevo parametro
                    </Typography>
                    <Box>
                      <TextField
                        name="anio"
                        size="small"
                        margin="normal"
                        label="Año"
                        type="number"
                        //value={tablaValoresData && tablaValoresData.pvp_iva_gm}
                        //onChange={dataBusqueda}
                        //handleClose();
                        sx={{ mt: 1, mb: 1, marginLeft: "20%", width: "60%" }}
                      ></TextField>
                    </Box>
                    <Box>
                      <TextField
                        name="categoria"
                        size="small"
                        margin="normal"
                        label="Categoria"
                        //onChange={dataBusqueda}
                        sx={{ mt: 1, mb: 1, marginLeft: "20%", width: "60%" }}
                      ></TextField>
                    </Box>
                    <Box>
                      <TextField
                        name="categoria"
                        size="small"
                        margin="normal"
                        label="Número de ultimos meses"
                        type="number"
                        //onChange={dataBusqueda}
                        sx={{ mt: 1, mb: 1, marginLeft: "20%", width: "60%" }}
                      ></TextField>
                    </Box>
                    <Box>
                      <TextField
                        name="categoria"
                        size="small"
                        margin="normal"
                        label="Comparación (>, =, <)"
                        //onChange={dataBusqueda}
                        sx={{ mt: 1, mb: 1, marginLeft: "20%", width: "60%" }}
                      ></TextField>
                    </Box>
                    <Box>
                      <TextField
                        name="categoria"
                        size="small"
                        margin="normal"
                        label="Cantidad"
                        type="number"
                        //onChange={dataBusqueda}
                        sx={{ mt: 1, mb: 1, marginLeft: "20%", width: "60%" }}
                      ></TextField>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1.5,
                        mb: 1.5,
                        marginLeft: "40%",
                        width: "20%",
                      }}
                      //  onClick={
                      //    data.correo.length > 5 &&
                      //    data.nombre.length > 5 &&
                      //    zona > 0 &&
                      //    marca > 0 &&
                      //    nivel > 0
                      //      ? function (event) {setParametro(zona,marca,data.nombre,data.correo,nivel,(cheked.toda_zona?0:agencia))}:handleClickOpenError}
                      onClick={function (event) {
                        setParametro(2023, 9, "B", 12, ">=", 100);
                      }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Modal>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
