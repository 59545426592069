import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  CrmAsesorHomologaType,
  CrmHomologarAsesorType,
  SateliteUserProgramaType,
  SateliteUserType,
  VSateliteProgramaType,
  VSateliteUsuarioType,
  marcaType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@emotion/styled";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-content">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const ScrollableFormGroup = styled(FormGroup)({
  maxHeight: "355px",
  overflowY: "auto",
});

const StyledFormControlLabel = styled(FormControlLabel)<{ critico: number }>(
  ({ critico }) => ({
    color: critico === 1 ? "red" : "inherit",
  })
);

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const listadoMarcas: marcaType[] = [
  { marId: 1, marNombre: "CHEVROLET" },
  { marId: 2, marNombre: "GAC" },
  { marId: 3, marNombre: "VOLKSWAGEN" },
];

export const UsuarioPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getUsuarioLogin(account.username);
      getUsuarios();
      getProgramas();
    } else {
      setName("");
    }
  }, [account]);
  const [sateliteUsuarios, setSateliteUsuarios] = useState<
    VSateliteUsuarioType[]
  >([]);
  const [sateliteProgramas, setSateliteProgramas] = useState<
    VSateliteProgramaType[]
  >([]);

  type respuestaHomologadosType = {
    result: CrmAsesorHomologaType[];
  }

  const [homologaciones, setHomologaciones] =useState<respuestaHomologadosType>({
    result: [],})
  const [sateliteUsuarioProgramas, setSateliteUsuarioProgramas] = useState<
    SateliteUserProgramaType[]
  >([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [mostrar, setMostrar] = React.useState(false);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [marca, setMarca] = React.useState(0);
  const [marcaEquipo, setMarcaEquipo] = React.useState(0);
  const [idUsuario, setIdUsuario] = React.useState(0);
  const [usuario, setUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });
  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });

  const [crmAsesor, setCrmAsesor] = useState<CrmAsesorHomologaType>({
    id: "",
    nombre: "",
    correo: "",
    marca: "",
  });

  const HandleChangeCrmAsesor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrmAsesor({
      ...crmAsesor,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
  };

  const handleChangeMarcaEquipo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMarcaEquipo(Number(event.target.value));
  };
  const [cheked, setCheked] = React.useState({
    es_supervisor: false,
    es_jefe_agencia: false,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  function seteoUsuario(
    usuId: number,
    idMarca: number,
    usuSupervisor: number,
    usuJefeAgencia: number
  ) {
    const nuevoRegistro: SateliteUserType = {
      usuId: usuId,
      idMarca: idMarca,
      usuSupervisor: usuSupervisor,
      usuJefeAgencia: usuJefeAgencia,
    };
    console.log(
      "usuario a guardar >> ",
      JSON.stringify(nuevoRegistro, null, 4)
    );
    postGuardarRegistro(nuevoRegistro);
  }

  async function postGuardarRegistro(registro: SateliteUserType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post("https://apisatelite.azurewebsites.net/sateliteuser", registro, {
          headers: headers,
        })
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          eliminarRegistro(registro.usuId);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarProgramas(registro: SateliteUserProgramaType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteuserprograma",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postHomologarAsesor(registro: CrmHomologarAsesorType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteuser/homologacrm",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          getHomologaciones(registro.correo);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuarioLogin(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
            correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setUsuario(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(usuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuarioEdit(_id: number) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/" +
            String(_id)
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuario(response.data);
          setMarca(response.data.idMarca);
          response.data.usuJefeAgencia
            ? (cheked.es_jefe_agencia = true)
            : (cheked.es_jefe_agencia = false);
          response.data.usuSupervisor
            ? (cheked.es_supervisor = true)
            : (cheked.es_supervisor = false);
          getUsuarioProgramas(_id);
          getHomologaciones(response.data.correo);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(usuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function eliminarRegistro(_id: number) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .delete(
          "https://apisatelite.azurewebsites.net/sateliteuserprograma/eliminar/" +
            String(_id)
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          handleGetSelected();
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuarios() {
    try {
      await axios
        .get<VSateliteUsuarioType[]>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario"
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          handleUncheckAll();
          setSateliteUsuarios(response.data);
          setMostrar(true);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(usuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getHomologaciones(_correo: string) {
    try {
      await axios
        .get<respuestaHomologadosType>(
          "https://apisatelite.azurewebsites.net/sateliteuser/consultar/homologaciones/"+_correo
        )
        .then((response) => {
          setHomologaciones(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(usuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getProgramas() {
    try {
      await axios
        .get<VSateliteProgramaType[]>(
          "https://apisatelite.azurewebsites.net/vsateliteprograma"
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteProgramas(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuarioProgramas(_id: number) {
    try {
      await axios
        .get<SateliteUserProgramaType[]>(
          "https://apisatelite.azurewebsites.net/sateliteuserprograma/consultar/" +
            String(_id)
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuarioProgramas(response.data);
          markSelectedProgramas(response.data);
          setOpenDialog(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "id",
      headerName: "ID",
      editable: false,
      width: 70,
    },
    {
      headerClassName: "theme-header",
      field: "nombre",
      headerName: "Nombre",
      editable: false,
      width: 300,
    },
    {
      headerClassName: "theme-header",
      field: "correo",
      headerName: "Correo",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "marca",
      headerName: "Marca",
      editable: false,
      width: 100,
    },
    {
      headerClassName: "theme-header",
      field: "bodega",
      headerName: "Bodega",
      editable: false,
      width: 200,
    },

    {
      headerClassName: "theme-header",
      field: "usuSupervisor",
      headerName: "Supervisor",
      editable: false,
      width: 70,
      valueGetter: ({ row }) => {
        return row.usuSupervisor === 1 ? "Si" : "No";
      },
    },
    {
      headerClassName: "theme-header",
      field: "usuJefeAgencia",
      headerName: "Jefe Agencia",
      editable: false,
      width: 70,
      valueGetter: ({ row }) => {
        return row.usuJefeAgencia === 1 ? "Si" : "No";
      },
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/ventas/index/${params.row.idcrm}`}
            onClick={function (event) {
              setValue(0);
              setIdUsuario(params.row.id);
              getUsuarioEdit(params.row.id);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  const columnsCrm: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "id",
      headerName: "Owner ID",
      editable: false,
      width: 150,
    },
    {
      headerClassName: "theme-header",
      field: "marca",
      headerName: "Marca",
      editable: false,
      width: 200,
    },

    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Desactivar",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            onClick={function (event) {
              // setIdUsuario(params.row.id);
              // getUsuarioEdit(params.row.id);
            }}
          >
            Desactivar
          </Button>
        );
      },
    },
  ];

  const [value, setValue] = useState(0);
  const handleChangePanel = (index: number) => {
    setValue(index);
  };

  const [checkedProgramas, setCheckedProgramas] = useState<{
    [key: number]: boolean;
  }>({});

  const handleChange =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedProgramas({ ...checkedProgramas, [id]: event.target.checked });
      console.log("datos seleccionados >>", checkedProgramas);
    };

  const handleUncheckAll = () => {
    const newCheckedProgramas = Object.keys(checkedProgramas).reduce(
      (acc, key) => {
        acc[parseInt(key)] = false;
        return acc;
      },
      {} as { [key: number]: boolean }
    );
    setCheckedProgramas(newCheckedProgramas);
  };

  const getSelectedProgramaIds = (): number[] => {
    return Object.keys(checkedProgramas)
      .filter((key) => checkedProgramas[parseInt(key)])
      .map((key) => parseInt(key));
  };

  const generarUsuarioPrograma = (
    programas: number[]
  ): SateliteUserProgramaType[] => {
    return programas.map((idPrograma) => ({
      usuId: idUsuario,
      prgId: idPrograma,
    }));
  };

  const handleGetSelected = () => {
    const selectedIds = getSelectedProgramaIds();
    console.log("Selected Programas IDs:", selectedIds);
    const nuevosRegistro = generarUsuarioPrograma(selectedIds);
    nuevosRegistro.forEach((registro) => {
      postGuardarProgramas(registro);
    });
    getUsuarios();
  };

  function setHomologacion ( idcrm: string, nombre: string, apellido: string, correo: string, marca: string){
    const newRegistro: CrmHomologarAsesorType = {
      idcrm: idcrm,
      nombre: nombre,
      apellido: apellido,
      correo: correo,
      marca: marca,
    }
    postHomologarAsesor(newRegistro);
  }

  const markSelectedProgramas = (selectedProgramas: { prgId: number }[]) => {
    const newCheckedProgramas = { ...checkedProgramas };
    selectedProgramas.forEach((programa) => {
      newCheckedProgramas[programa.prgId] = true;
    });
    setCheckedProgramas(newCheckedProgramas);
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Usuarios
              </Typography>
            </Paper>

            {/* insertar validacion para mostrar */}
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              {mostrar ? (
                <>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DataGrid
                      //getRowId={(row) => row.usuId}
                      sx={{ height: 650 }}
                      editMode="row"
                      rows={sateliteUsuarios}
                      columns={columns}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      slots={{ toolbar: GridToolbarQuickFilter }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[10, 25, 50]}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarQuickFilterPlaceholder: "Buscar",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Paper>

            {/* modal */}
            <Modal
              open={openDialog}
              onClose={function (event) {
                setOpenDialog(false);
                handleUncheckAll();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <div className="tabs">
                  <div className="tab-list">
                    <button
                      className={`tab-button ${value === 0 ? "active" : ""}`}
                      onClick={() => handleChangePanel(0)}
                      {...a11yProps(0)}
                    >
                      Datos
                    </button>
                    <button
                      className={`tab-button ${value === 1 ? "active" : ""}`}
                      onClick={() => handleChangePanel(1)}
                      {...a11yProps(1)}
                    >
                      Accesos
                    </button>
                    <button
                      className={`tab-button ${value === 2 ? "active" : ""}`}
                      onClick={() => handleChangePanel(2)}
                      {...a11yProps(1)}
                    >
                      CRM HubSpot
                    </button>
                  </div>
                  {/* Datos */}
                  <TabPanel value={value} index={0}>
                    <TextField
                      name="nombre"
                      size="small"
                      margin="normal"
                      fullWidth
                      label="Nombre"
                      value={sateliteUsuario.nombre}
                      sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      name="nombre"
                      size="small"
                      margin="normal"
                      fullWidth
                      label="Correo"
                      value={sateliteUsuario.correo}
                      sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      name="nombre"
                      size="small"
                      margin="normal"
                      fullWidth
                      label="Bodega"
                      value={sateliteUsuario.bodega}
                      sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Marca"
                      value={marca}
                      //helperText="Please select your currency"
                      onChange={handleChangeMarca}
                      size="small"
                      margin="normal"
                      sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "80%" }}
                    >
                      {dataMarca.map((option) => (
                        <MenuItem key={option.marId} value={option.marId}>
                          {option.marNombre}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography
                      sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                      style={{ display: "inline-block" }}
                    >
                      Supervisor:
                    </Typography>
                    <Switch
                      checked={cheked.es_supervisor}
                      onChange={handleCheked}
                      name="es_supervisor"
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "8%",
                        marginRight: "40%",
                      }}
                    />
                    <Typography
                      sx={{ mt: 1, mb: 1, marginLeft: "10%" }}
                      style={{ display: "inline-block" }}
                    >
                      Jefe Agencia:
                    </Typography>
                    <Switch
                      checked={cheked.es_jefe_agencia}
                      onChange={handleCheked}
                      name="es_jefe_agencia"
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "5%",
                        marginRight: "40%",
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 1,
                        marginLeft: "40%",
                        width: "20%",
                        backgroundColor: "rgba(21,57,105,0.75)",
                      }}
                      onClick={function (event) {
                        seteoUsuario(
                          Number(sateliteUsuario.id),
                          marca,
                          cheked.es_supervisor ? 1 : 0,
                          cheked.es_jefe_agencia ? 1 : 0
                        );
                      }}
                    >
                      Guardar
                    </Button>
                  </TabPanel>
                </div>
                {/* Accesos */}
                <TabPanel value={value} index={1}>
                  <div>
                    <ScrollableFormGroup>
                      {sateliteProgramas.map((option) => (
                        <StyledFormControlLabel
                          critico={option.critico}
                          key={option.prgId}
                          control={
                            <Checkbox
                              checked={!!checkedProgramas[option.prgId]}
                              onChange={handleChange(option.prgId)}
                              name={option.programa}
                              //color="primary"
                              sx={{
                                color: option.critico === 1 ? "red" : "inherit",
                              }}
                            />
                          }
                          label={option.programa}
                          color={option.critico === 1 ? "red" : "black"}
                        />
                      ))}
                    </ScrollableFormGroup>
                  </div>
                  <Button sx={{ mt: 2 }} onClick={handleUncheckAll}>
                    Desmarcar todo
                  </Button>
                </TabPanel>
                {/* CRM Hubspot*/}
                <TabPanel value={value} index={2}>
                  <TextField
                    name="id"
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Owner ID"
                    value={crmAsesor.id}
                    onChange={HandleChangeCrmAsesor}
                    sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "35%" }}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Marca"
                    value={marcaEquipo}
                    onChange={handleChangeMarcaEquipo}
                    size="small"
                    margin="normal"
                    sx={{ mt: 1, mb: 1, marginLeft: "10%", width: "35%" }}
                  >
                    {dataMarca.map((option) => (
                      <MenuItem key={option.marId} value={option.marId}>
                        {option.marNombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 1,
                      marginLeft: "40%",
                      width: "20%",
                      backgroundColor: "rgba(21,57,105,0.75)",
                    }}
                    onClick={function (event) {setHomologacion(crmAsesor.id,sateliteUsuario.nombre,'',sateliteUsuario.correo,marcaEquipo === 1 ? "CH": marcaEquipo === 2 ? "GAC": marcaEquipo === 3 ? "VW":"")}}
                  >
                    Homologar
                  </Button>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DataGrid
                      //getRowId={(row) => row.usuId}
                      sx={{ height: 295 }}
                      editMode="row"
                      rows={homologaciones.result}
                      columns={columnsCrm}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[3, 5, 10]}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                      }}
                    />
                  </Box>
                </TabPanel>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
