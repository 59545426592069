// src/services/zonaService.ts

import axios from 'axios';
import { SateliteAuditoriaType } from '../types';

export const getAuditoria = async (id: number, id2: string): Promise<SateliteAuditoriaType[]> => {
  try {
    const url = `https://apisatelite.azurewebsites.net/sateliteauditoria/${id}/${id2}` 
    const response = await axios.get<SateliteAuditoriaType[]>(url);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error message:', error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error; // Puedes decidir cómo manejar el error en la capa superior
  }
};

export const postGuardarAuditoria = async (parametroGuardar: SateliteAuditoriaType): Promise<void> => {
    const headers = {
      'Content-Type': 'application/json',
    };  
    try {
      const response = await axios.post(
        'http://localhost:443/sateliteauditoria',
        parametroGuardar,
        { headers: headers }
      );
      console.log('id guardado es: >>>>>>>> ', response);
      // Opcionalmente, puedes devolver la respuesta si es necesario
      // return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error message:', error.message);
        throw new Error(error.message); // Puedes lanzar el error para que lo maneje el componente
      } else {
        console.error('Unexpected error:', error);
        throw new Error('An unexpected error occurred'); // Puedes lanzar un error genérico
      }
    }
  };
