import {
    Container,
    Grid,
    Paper,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
  
  export const ProgramaPage: React.FC<{}> = () => {
    useEffect(() => {
      
    }, []);
  
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
  
    useEffect(() => {
      if (account && account.name) {
        console.log("este son los atributos de msal >>>>>>", account);
        setName(account.name);
        setUsername(account.username);
        console.log(account);
      } else {
        setName("");
      }
    }, [account]);
  
    const isAuthenticated = useIsAuthenticated();
  
  
  
    // async function getvParametros() {
    //   try {
    //     await axios
    //       .get<parametrosType[]>(
    //         "https://apisatelite.azurewebsites.net/vclasificacionparametros"
    //       )
    //       .then((response) => {
    //         setListaParametroRows(response.data);
    //       })
    //       .catch((error) => {
    //         console.log("algo paso >> ", error);
    //       });
  
    //     console.log("data es >>", JSON.stringify(listaParametroRows, null, 4));
    //   } catch (error) {
    //     if (axios.isAxiosError(error)) {
    //       console.log("error message: ", error.message);
    //       return error.message;
    //     } else {
    //       console.log("algo paso unexpected error: ", error);
    //       return "An unexpected error occurred";
    //     }
    //   }
    // }
  
  
  
    if (isAuthenticated) {
      return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
          <Grid alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Paper sx={{ padding: "1.2em", borderRadius: "0.5em", mt: 2, backgroundColor: "rgb(255,255,255,0.75)" }}>
                Asignación de accesos
              </Paper>
              <Paper sx={{ padding: "1.2em", borderRadius: "0.5em", mt: 2 }}>
                Detalle
              </Paper>
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return (
        <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
        <Grid
          //container
          alignItems={"center"}
          justifyContent={"center"}
        >
  
          <Typography sx={{ mt: 2, mb: 1.5 }} variant="h6" textAlign={"center"}>
            Acceso denegado
          </Typography>
        </Grid>
        </Paper>
      </Container>
      );
    }
  };
  