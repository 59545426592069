import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundImage: 'url("/path/to/your/background-image.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with opacity
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1),
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    checkbox: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      backgroundColor: '#FFD700', // Gold
      color: 'black', // Adjust text color as needed
      '&:hover': {
        backgroundColor: '#FFD700', // Darken or adjust hover state
      },
    },
  })
);

export const LandingReferidosPage: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Welcome to Our Landing Page
      </Typography>
      <div className={classes.formContainer}>
        <div className={classes.column}>
          <TextField
            label="Field 1"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 1"
          />
          <TextField
            label="Field 2"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 2"
          />
          <TextField
            label="Field 3"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 3"
          />
          <TextField
            label="Field 4"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 4"
          />
        </div>
        <div className={classes.column}>
          <TextField
            label="Field 5"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 5"
          />
          <TextField
            label="Field 6"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 6"
          />
          <TextField
            label="Field 7"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 7"
          />
          <TextField
            label="Field 8"
            variant="outlined"
            className={classes.textField}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
            InputLabelProps={{ style: { color: 'gray' } }}
            placeholder="Enter Field 8"
          />
        </div>
      </div>
      <div className={classes.column}>
        <Checkbox
          className={classes.checkbox}
          color="default"
          inputProps={{ 'aria-label': 'Checkbox to accept privacy policy' }}
        />
        <Typography variant="body2" gutterBottom>
          I accept the privacy policy
        </Typography>
        <Checkbox
          className={classes.checkbox}
          color="default"
          inputProps={{ 'aria-label': 'Checkbox to accept terms and conditions' }}
        />
        <Typography variant="body2" gutterBottom>
          I accept the terms and conditions
        </Typography>
      </div>
      <Button variant="contained" className={classes.button}>
        Save Information
      </Button>
    </div>
  );
};