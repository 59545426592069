import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { styled } from "@mui/system";
import { Delete } from "@mui/icons-material";

import onix_turbo from "../../assets/vh autos/onix_turbo_rs.png";
import onix_sedan from "../../assets/vh autos/onix_turbo_sedan.png";
import joy_hb from "../../assets/vh autos/joy_hb_black.png";
import joy_sedan from "../../assets/vh autos/joy_sedan_black.png";

import chevrolet from "../../assets/marcas/chevrolet.png";
import volkswagen from "../../assets/marcas/vw.png";
import gac from "../../assets/marcas/gac.png";

import groove from "../../assets/vh suvs/groove.png";
import tracker from "../../assets/vh suvs/tracker_turbo.png";
import captiva from "../../assets/vh suvs/captiva_turbo.png";
import trailblazer from "../../assets/vh suvs/trailblazer.png";
import bolt from "../../assets/vh suvs/bolt_euv.png";
import tahoe from "../../assets/vh suvs/tahoe.png";

import montana from "../../assets/vh camionetas/montana.png";
import dmax_high_power from "../../assets/vh camionetas/dmax_high_power.png";
import dmax_high_ride from "../../assets/vh camionetas/dmax_high_ride.png";
import dmax_premier from "../../assets/vh camionetas/dmax_premier.png";
import colorado from "../../assets/vh camionetas/colorado.png";
import silverado from "../../assets/vh camionetas/silverado.png";

import joy_sedan_1 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_1.png";
import joy_sedan_2 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_2.png";
import joy_sedan_3 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_3.png";
import joy_sedan_4 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_4.png";
import joy_sedan_5 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_5.png";
import joy_sedan_6 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_6.png";
import joy_sedan_7 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_7.png";
import joy_sedan_8 from "../../assets/vh autos/joy_sedan_black/joy_sedan_black_8.png";

import joy_hb_1 from "../../assets/vh autos/joy_hb_black/joy_hb_black_1.png";
import joy_hb_2 from "../../assets/vh autos/joy_hb_black/joy_hb_black_2.png";
import joy_hb_3 from "../../assets/vh autos/joy_hb_black/joy_hb_black_3.png";
import joy_hb_4 from "../../assets/vh autos/joy_hb_black/joy_hb_black_4.png";
import joy_hb_5 from "../../assets/vh autos/joy_hb_black/joy_hb_black_5.png";
import joy_hb_6 from "../../assets/vh autos/joy_hb_black/joy_hb_black_6.png";
import joy_hb_7 from "../../assets/vh autos/joy_hb_black/joy_hb_black_7.png";
import joy_hb_8 from "../../assets/vh autos/joy_hb_black/joy_hb_black_8.png";

import onix_sedan_1 from "../../assets/vh autos/onix_turbo_sedan/onix_turbo_sedan_1.png";
import onix_sedan_2 from "../../assets/vh autos/onix_turbo_sedan/onix_turbo_sedan_2.png";
import onix_sedan_3 from "../../assets/vh autos/onix_turbo_sedan/onix_turbo_sedan_3.png";

import onix_rs_1 from "../../assets/vh autos/onix_turbo_rs/onix_turbo_rs_1.png";
import onix_rs_2 from "../../assets/vh autos/onix_turbo_rs/onix_turbo_rs_2.png";
import onix_rs_3 from "../../assets/vh autos/onix_turbo_rs/onix_turbo_rs_3.png";

import bolt_1 from "../../assets/vh suvs/bolt/bolt_euv_1.png";
import bolt_2 from "../../assets/vh suvs/bolt/bolt_euv_2.png";
import bolt_3 from "../../assets/vh suvs/bolt/bolt_euv_3.png";
import bolt_4 from "../../assets/vh suvs/bolt/bolt_euv_4.png";
import bolt_5 from "../../assets/vh suvs/bolt/bolt_euv_5.png";
import bolt_6 from "../../assets/vh suvs/bolt/bolt_euv_6.png";

import tahoe_1 from "../../assets/vh suvs/tahoe/tahoe_1.png";
import tahoe_2 from "../../assets/vh suvs/tahoe/tahoe_2.png";
import tahoe_3 from "../../assets/vh suvs/tahoe/tahoe_3.png";
import tahoe_4 from "../../assets/vh suvs/tahoe/tahoe_4.png";
import tahoe_5 from "../../assets/vh suvs/tahoe/tahoe_5.png";
import tahoe_6 from "../../assets/vh suvs/tahoe/tahoe_6.png";
import tahoe_7 from "../../assets/vh suvs/tahoe/tahoe_7.png";
import tahoe_8 from "../../assets/vh suvs/tahoe/tahoe_8.png";

import silverado_1 from "../../assets/vh camionetas/silverado/silverado_1.png";
import silverado_2 from "../../assets/vh camionetas/silverado/silverado_2.png";
import silverado_3 from "../../assets/vh camionetas/silverado/silverado_3.png";
import silverado_4 from "../../assets/vh camionetas/silverado/silverado_4.png";
import silverado_5 from "../../assets/vh camionetas/silverado/silverado_5.png";
import silverado_6 from "../../assets/vh camionetas/silverado/silverado_6.png";
import silverado_7 from "../../assets/vh camionetas/silverado/silverado_7.png";
import silverado_8 from "../../assets/vh camionetas/silverado/silverado_8.png";

import joy_hb_acc1 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc1.png";
import joy_hb_acc2 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc2.png";
import joy_hb_acc3 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc3.png";
import joy_hb_acc4 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc4.png";
import joy_hb_acc5 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc5.png";
import joy_hb_acc6 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc6.png";
import joy_hb_acc7 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc7.png";
import joy_hb_acc9 from "../../assets/vh autos/joy_hb_black/acc/joy_hb_black_acc9.png";

import axios from "axios";
import Carousel from "../../components/Carousel/CarouselUno";
import Carousel3 from "../../components/Carousel/CarouselTres";
import CustomButtonGroup from "../../components/ButtonGroup/ButtonGroup";

import "../../components/Carousel/CarouselTres.css"; // Importa tu archivo CSS
import {
  AnoType,
  CrmAsesorType,
  MesType,
  ModeloAccesorioType,
  VSateliteUsuarioType,
  VehCotizadorAccesoriosType,
  vventassatelitecrmType,
} from "../../types";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { ShoppingCart } from "@mui/icons-material";

export const HomeAccPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isHorizontal, setIsHorizontal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      getUsuario(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  useEffect(() => {
    const checkOrientation = () => {
      const newIsHorizontal = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      setIsHorizontal(newIsHorizontal);
    };
    // Verifica la orientación al cargar la página
    checkOrientation();
    // Agrega un listener para verificar cambios de orientación
    window.addEventListener("orientationchange", checkOrientation);
    // Limpia el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("orientationchange", checkOrientation);
    };
  });

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
      "Mobile",
      "Android",
      "iPhone",
      "iPad",
      "Windows Phone",
    ];

    const checkDeviceType = () => {
      setIsMobile(
        mobileKeywords.some((keyword) => userAgent.includes(keyword))
      );
    };
    // Verifica el tipo de dispositivo al cargar la página
    checkDeviceType();
    // No necesitamos limpiar el listener en este caso
  }, []);

  const isAuthenticated = useIsAuthenticated();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [registroAccesorioIndex, setRegistroAccesorioIndex] = React.useState(0);

  const [registroAccesorio, setRegistroAccesorio] =
    useState<ModeloAccesorioType>({
    
      id: 0,
      idMarca: 0,
      familia: "",
      idCotItemAcc: 0,
      accCodigo: "",
      accDescripcion: "",
      costoCompra: 0,
      precio: 0,
      esObligatorio: 0,
      cantidad: 0,
      imagen: "",
      esAgregado: 0,
      nota: "",

    });
  const [listaAccesorios, setListaAccesorios] = useState<ModeloAccesorioType[]>(
    [
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "CAM0147921",
        accDescripcion: "CAMARA DE REVERSA",
        costoCompra: 0,
        precio: 159,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc1,
        esAgregado: 0,
        nota:
          "Un dispositivo de seguridad que se monta en la parte trasera del vehículo y muestra una vista en tiempo real del área detrás del automóvil cuando se pone en marcha atrás, ayudando al conductor a evitar colisiones con objetos u otros vehículos.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "EET2144900",
        accDescripcion: "ELEVAVIDRIOS ELÉCTRICOS TRASEROS",
        costoCompra: 0,
        precio: 125,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc2,
        esAgregado: 0,
        nota:
          "Sistema que permite subir y bajar las ventanas traseras del vehículo con solo presionar un botón, proporcionando comodidad y conveniencia para los pasajeros en la parte posterior del automóvil.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "PRC0227029",
        accDescripcion: "PROTECTOR DE CARTER",
        costoCompra: 0,
        precio: 240,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc3,
        esAgregado: 0,
        nota:
          "Una pieza diseñada para proteger el cárter del motor de daños causados por golpes, rocas u otros objetos que puedan encontrarse en la carretera, ayudando a prolongar la vida útil del vehículo.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "NEB1278456",
        accDescripcion: "NEBLINEROS",
        costoCompra: 0,
        precio: 80,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc4,
        esAgregado: 0,
        nota:
          "Luces adicionales montadas en la parte delantera del vehículo, diseñadas para proporcionar una mayor visibilidad en condiciones de niebla, lluvia intensa o neblina, mejorando la seguridad al conducir en situaciones de baja visibilidad.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "MOCJOY0003",
        accDescripcion: "MOQUETAS JOY",
        costoCompra: 0,
        precio: 59,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc5,
        esAgregado: 0,
        nota:
          "Alfombrillas diseñadas específicamente para el modelo del vehículo, que ofrecen protección y estilo al interior del automóvil, manteniendo limpio el piso y proporcionando un aspecto personalizado.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "RTOUCH0047",
        accDescripcion: "RADIO TOUCHSCREEN  ANDROID AUTO & APPLE CAR PLAY",
        costoCompra: 0,
        precio: 329,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc6,
        esAgregado: 0,
        nota:
          "Un sistema de entretenimiento y conectividad que incorpora una pantalla táctil de alta resolución, compatible con los sistemas operativos Android Auto y Apple CarPlay, permitiendo a los usuarios acceder a aplicaciones, música, navegación y otras funciones de sus teléfonos inteligentes de forma integrada en el sistema del automóvil.",
      },
      {
        id: 1, 
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "PTA0006440",
        accDescripcion: "PORTABICICLETAS DE TECHO",
        costoCompra: 0,
        precio: 119,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc7,
        esAgregado: 0,
        nota:
          "Un accesorio que se monta en el techo del vehículo y permite transportar bicicletas de forma segura y conveniente, ideal para los amantes del ciclismo que desean llevar sus bicicletas en sus viajes.",
      },
      {
        id: 1,
        idMarca: 0,
        familia: "",
        idCotItemAcc: 1,
        accCodigo: "AV015479EJ",
        accDescripcion: "ALARMA VOLUMÉTRICA",
        costoCompra: 0,
        precio: 260,
        esObligatorio: 0,
        cantidad: 1,
        imagen: joy_hb_acc9,
        esAgregado: 0,
        nota:
          "Un sistema de seguridad que detecta movimientos dentro del vehículo y emite una fuerte señal sonora en caso de intento de robo o intrusión, disuadiendo a los ladrones y protegiendo el automóvil y sus pertenencias.",
      },
    ]
  );

  const imagesMarcas = [chevrolet, volkswagen, gac];

  const imagesAutos = [
    joy_sedan,
    joy_hb,
    onix_sedan,
    onix_turbo,
    joy_sedan,
    joy_hb,
  ];
  const imagesSuvs = [groove, tracker, captiva, trailblazer, bolt, tahoe];
  const imagesCamionetas = [
    montana,
    dmax_high_power,
    dmax_high_ride,
    dmax_premier,
    colorado,
    silverado,
  ];
  const imagesJoySedan = [
    joy_sedan_1,
    joy_sedan_2,
    joy_sedan_3,
    joy_sedan_4,
    joy_sedan_5,
    joy_sedan_6,
    joy_sedan_7,
    joy_sedan_8,
  ];
  const imagesJoyHB = [
    joy_hb_1,
    joy_hb_2,
    joy_hb_3,
    joy_hb_4,
    joy_hb_5,
    joy_hb_6,
    joy_hb_7,
    joy_hb_8,
  ];

  const imagesOnixSedan = [onix_sedan_1, onix_sedan_2, onix_sedan_3];

  const imagesOnixRs = [onix_rs_1, onix_rs_2, onix_rs_3];

  const imagesBolt = [bolt_1, bolt_2, bolt_3, bolt_4, bolt_5, bolt_6];

  const imagesTahoe = [
    tahoe_1,
    tahoe_2,
    tahoe_3,
    tahoe_4,
    tahoe_5,
    tahoe_6,
    tahoe_7,
    tahoe_8,
  ];

  const imagesSilverado = [
    silverado_1,
    silverado_2,
    silverado_3,
    silverado_4,
    silverado_5,
    silverado_6,
    silverado_7,
    silverado_8,
  ];

  const listaVeh: VehCotizadorAccesoriosType[] = [
    {
      id_vh: 1,
      nombre: "JOY SEDÁN BLACK",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 1,
      imagenes: imagesJoySedan,
    },
    {
      id_vh: 2,
      nombre: "JOY HB BLACK",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 2,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 3,
      nombre: "ONIX TURBO SEDÁN",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 3,
      imagenes: imagesOnixSedan,
    },
    {
      id_vh: 4,
      nombre: "ONIX TURBO RS",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 4,
      imagenes: imagesOnixRs,
    },
    {
      id_vh: 1,
      nombre: "JOY SEDÁN BLACK",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 5,
      imagenes: imagesJoySedan,
    },
    {
      id_vh: 2,
      nombre: "JOY HB BLACK",
      id_marca: 1,
      id_tipo_vh: 1,
      id_sub_tipo_vh: 6,
      imagenes: imagesJoyHB,
    },
    //CHEVROLET SUV
    {
      id_vh: 5,
      nombre: "GROOVE",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 1,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 6,
      nombre: "TRACKER TURBO",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 2,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 7,
      nombre: "CAPTIVA TURBO",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 3,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 8,
      nombre: "TRAILBLAZER",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 4,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 9,
      nombre: "VOLT EUV",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 5,
      imagenes: imagesBolt,
    },
    {
      id_vh: 10,
      nombre: "TAHOE",
      id_marca: 1,
      id_tipo_vh: 2,
      id_sub_tipo_vh: 6,
      imagenes: imagesTahoe,
    },
    //CHEVROLET CAMIONETAS
    {
      id_vh: 11,
      nombre: "NUEVA MONTANA",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 1,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 12,
      nombre: "D-MAX HIGH POWER",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 2,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 13,
      nombre: "D-MAX HIGH RIDE",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 3,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 14,
      nombre: "D-MAX PREMIER",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 4,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 15,
      nombre: "COLORADO",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 5,
      imagenes: imagesJoyHB,
    },
    {
      id_vh: 16,
      nombre: "SILVERADO",
      id_marca: 1,
      id_tipo_vh: 3,
      id_sub_tipo_vh: 6,
      imagenes: imagesSilverado,
    },
  ];

  const obtenerImegenes360PorId = (
    idMarca: number,
    idTipoVh: number,
    idSubTipoVh: number
  ): string[] => {
    const objetoEncontrado = listaVeh.find(
      (objeto) =>
        objeto.id_marca === idMarca &&
        objeto.id_tipo_vh === idTipoVh &&
        objeto.id_sub_tipo_vh == idSubTipoVh
    );
    return objetoEncontrado ? objetoEncontrado.imagenes : [""];
  };

  const obtenerVhPorId = (
    idMarca: number,
    idTipoVh: number,
    idSubTipoVh: number
  ): string => {
    const objetoEncontrado = listaVeh.find(
      (objeto) =>
        objeto.id_marca === idMarca &&
        objeto.id_tipo_vh === idTipoVh &&
        objeto.id_sub_tipo_vh == idSubTipoVh
    );
    return objetoEncontrado ? objetoEncontrado.nombre : "";
  };

  const handleClickVhTipo = (index: number) => {
    console.log("Se hizo clic en la imagen número:", index + 1);
    setVehiculo(index + 1);
  };
  const dataBuscarId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscarId(e.target.value);
  };

  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;

  const [openNegocio, setOpenNegocio] = React.useState(false);
  const [openBuscarPorId, setOpenBuscarPorId] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCarrito, setOpenCarrito] = React.useState(false);

  const [idMarca, setIdMarca] = React.useState(0);
  const [vhTipo, setVhTipo] = React.useState(0);
  const [vehiculo, setVehiculo] = React.useState(0);
  const [anoConsulta, setAnoConsulta] = React.useState(anioActual);
  const [mesConsulta, setMesConsulta] = React.useState(mesActual);
  const [asesorConsulta, setAsesorConsulta] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const [buscarId, setBuscarId] = React.useState("");

  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnoConsulta(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMesConsulta(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAsesor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsesorConsulta(Number(event.target.value));
    getAsesor(Number(event.target.value));
    setMostrar(false);
  };

  const handleMarca = (option: string, index: number) => {
    setIdMarca(index + 1);
    setVhTipo(0);
    setVehiculo(0);
  };

  const handleTipoVh = (option: string, index: number) => {
    setVhTipo(index + 1);
    setVehiculo(0);
  };
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];
  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });
  const [datosNegocio, setDatosNegocio] = useState<vventassatelitecrmType>({
    id: 0,
    idcrm: "",
    createdate: "",
    cedula: "",
    cliente: "",
    asesor: "",
    dealname: "",
    agencia: "",
    modelo: "",
    correo: "",
  });

  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const listaListado: vventassatelitecrmType[] = [];
  const listaAsesor: CrmAsesorType[] = [];

  const [dataLista, setDataLista] =
    useState<vventassatelitecrmType[]>(listaListado);

  const [dataAsesores, setDataAsesores] =
    useState<CrmAsesorType[]>(listaAsesor);
  const [dataAsesor, setDataAsesor] = useState<CrmAsesorType>({
    id: 0,
    nit: "",
    nombre: "",
    grupo: "",
    estado: 0,
    idCotBodega: 0,
    cargo: "",
    anulado: 0,
    ownerId: "",
    correo: "",
  });

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const styleModalNegocio = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styleModalCarrito = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const ScrollableListContainer = styled("div")({
    maxHeight: "75%", // Altura máxima (ajusta según tus necesidades)
    overflowY: "auto", // Agrega desplazamiento vertical cuando es necesario
  });

  async function getAsesor(id: number) {
    try {
      await axios
        .get<CrmAsesorType>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/" + String(id)
        )
        .then((response) => {
          setDataAsesor(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  async function getAsesores() {
    try {
      await axios
        .get<CrmAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/todo"
        )
        .then((response) => {
          setDataAsesores(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
            correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          if (response.data.usuSupervisor > 0) {
            getAsesores();
          }
          setSateliteUsuario(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListado(usuario: string) {
    try {
      await axios
        .get<vventassatelitecrmType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrm/consulta/mes/" +
            usuario +
            "/" +
            String(anoConsulta) +
            "/" +
            String(mesConsulta)
        )
        .then((response) => {
          setDataLista(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaID() {
    try {
      await axios
        .get<vventassatelitecrmType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrm/idcrm/" +
            buscarId
        )
        .then((response) => {
          setDataLista(response.data);
          setMostrar(true);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoAgencia(id_bodega: number) {
    try {
      await axios
        .get<vventassatelitecrmType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrm/agencia/mes/" +
            String(id_bodega) +
            "/" +
            String(anoConsulta) +
            "/" +
            String(mesConsulta)
        )
        .then((response) => {
          setDataLista(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "idcrm",
      headerName: "ID CRM",
      editable: false,
      width: 95,
    },
    {
      headerClassName: "theme-header",
      field: "createdate",
      headerName: "Fecha",
      editable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.createdate).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header",
      field: "cedula",
      headerName: "Cédula",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "cliente",
      headerName: "Cliente",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "asesor",
      headerName: "Asesor",
      editable: false,
      width: 130,
    },
    {
      headerClassName: "theme-header",
      field: "dealname",
      headerName: "Negocio",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "agencia",
      headerName: "Agencia",
      editable: false,
      width: 200,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Seleccionar",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            onClick={function (event) {
              setDatosNegocio(params.row);
              setOpenNegocio(false);
            }}
          >
            ✔{" "}
          </Button>
        );
      },
    },
  ];

  const quitarAccCarrito = (index: number, valor: number) => {
    const newArray = [...listaAccesorios];
    newArray[index].esAgregado = valor;
    setListaAccesorios(newArray);
  };

  const agregarAcc = (index: number, valor: number) => {
    const newArray = [...listaAccesorios];
    newArray[index].esAgregado = valor;
    setListaAccesorios(newArray);
  };

  const sumarEdadesMasculinas = (accesorios: ModeloAccesorioType[]): number => {
    return accesorios.reduce((suma, accesorio) => {
      if (accesorio.esAgregado === 1) {
        return suma + accesorio.precio;
      } else {
        return suma;
      }
    }, 0);
  };

  const totalPVP = sumarEdadesMasculinas(listaAccesorios);


  if (isAuthenticated) {
    return (
      <>
        <Container maxWidth={false} sx={{ mt: 0, backgroundColor: "white" }}>
          <Grid alignItems={"center"} justifyContent={"center"} sx={{ mt: 15 }}>
            <Grid item>
              <CustomButtonGroup
                options={["CHEVROLET", "VOLKSWAGEN", "GAC"]}
                onButtonClick={handleMarca}
              />
              <Divider sx={{ marginLeft: "35%", marginRight: "35%" }} />
              {idMarca > 0 ? (
                <>
                  <CustomButtonGroup
                    options={["Autos", "SUVs", "Camionetas"]}
                    onButtonClick={handleTipoVh}
                  />
                </>
              ) : (
                <></>
              )}

              {vhTipo === 1 && idMarca === 1 ? (
                <Carousel3
                  images={imagesAutos}
                  onImageClick={handleClickVhTipo}
                />
              ) : vhTipo === 2 && idMarca === 1 ? (
                <Carousel3
                  images={imagesSuvs}
                  onImageClick={handleClickVhTipo}
                />
              ) : vhTipo === 3 && idMarca === 1 ? (
                <Carousel3
                  images={imagesCamionetas}
                  onImageClick={handleClickVhTipo}
                />
              ) : (
                <></>
              )}

              {vehiculo > 0 ? (
                <>
                  <Divider
                    sx={{ marginLeft: "10%", marginRight: "10%", mt: 4, mb: 1 }}
                  />
                  <Typography
                    textAlign={"center"}
                    sx={{ mt: 4, mb: 1, fontSize: 34 }}
                  >
                    <strong>{obtenerVhPorId(idMarca, vhTipo, vehiculo)}</strong>
                  </Typography>
                  <Carousel
                    images={obtenerImegenes360PorId(idMarca, vhTipo, vehiculo)}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Container>
        <Container
          maxWidth={false}
          sx={{ mt: 0, backgroundColor: "rgba(188,188,188,0.85)" }}
        >
          {vehiculo > 0 ? (
            <>
              <Grid
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ mt: 5 }}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{ mt: 1, mb: 1, color: "rgba(21,57,105)" }}
                  >
                    <strong>ACCESORIOS</strong>
                  </Typography>
                  <Divider sx={{ marginLeft: "15%", marginRight: "15%" }} />
                  {datosNegocio.id > 0 ? (
                    <>
                      <Typography
                        variant="h5"
                        textAlign={"center"}
                        sx={{ mt: 1, mb: 1, color: "rgba(21,57,105)" }}
                      >
                        <strong>
                          {datosNegocio.idcrm} {" - "} {datosNegocio.cliente}
                        </strong>
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={function (event) {
                          setOpenNegocio(true);
                        }}
                        sx={{ marginLeft: "40%", width: "9%", backgroundColor: "rgba(21,57,105)"}}
                      >
                        Cambiar
                      </Button>
                      <Button
                        variant="contained"
                        onClick={function (event) {
                          setOpenCarrito(true);
                        }}
                        sx={{ marginLeft: "2%", width: "9%", backgroundColor: "rgba(21,57,105)" }}
                      >
                        Ver carrito
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* <Grid
              // alignItems={"center"}
              // alignContent={"center"}
              justifyContent={"center"}
              display={"flex"}
              sx={{ marginLeft: 12,  }}
            > */}

                  <Grid
                    container
                    spacing={3}
                    direction={"row"}
                    justifyContent={"center"}
                  >
                    {listaAccesorios.map((item, index) => (
                      <Grid item>
                        <Card
                          sx={{
                            width: 375,
                            height: 375,
                            //display: "inline-block",
                            //marginRight: 8,
                            mt: 2,
                            mb: 2,
                          }}
                        >
                          <CardMedia
                            onClick={function (event) {
                              setRegistroAccesorio(item);
                              setRegistroAccesorioIndex(index);
                              setOpenDialog(true);
                            }}
                            sx={{ height: 250 }}
                            image={item.imagen}
                            title="Corporacion Proauto"
                          />
                          <CardContent>
                            <Typography
                              textAlign={"center"}
                              gutterBottom
                              component="div"
                              sx={{ height: 50, mt: -1, fontSize: 20 }}
                            >
                              <strong>{item.accDescripcion}</strong>
                            </Typography>
                            <Typography
                              sx={{
                                marginRight: "25%",
                                width: "50%",
                                fontSize: 20,
                                mt: 2,
                              }}
                              style={{ display: "inline-block" }}
                              variant="body2"
                              color="text.secondary"
                            >
                              <strong>
                                PVP:
                                {formatter
                                  .format(
                                    Number(item.precio) * Number(item.cantidad)
                                  )
                                  .replace("$", "$  ")}{" "}
                              </strong>
                            </Typography>
                            <Button
                              variant={
                                item.esAgregado === 1 ? "outlined" : "contained"
                              }
                              sx={{
                                width: "25%",
                                backgroundColor: item.esAgregado === 1 ? "rgba(255,255,255)" : "rgba(21,57,105)"  ,
                              }}
                              onClick={function (event) {
                                if (datosNegocio.id === 0) {
                                  setOpenNegocio(true);
                                } else {
                                  agregarAcc(index, item.esAgregado === 1 ? 0 : 1);
                                }
                              }}
                            >
                              {item.esAgregado === 1 ? "Quitar" : "Agregar"}
                            </Button>
                          </CardContent>
                          {/* <CardActions>
                    <Grid
                      sx={{
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Button
                        variant={
                          item.esAgregado === 1 ? "outlined" : "contained"
                        }
                        // sx={{ marginLeft: item.esAgregado === 1 ? 10 : 9 }}
                        // sx={{ marginLeft: "100%",  }}
                        onClick={function (event) {
                          setRegistroAccesorio(item);
                          setOpenDialog(true);
                          // agregarAcc(index, item.esAgregado === 1 ? 0 : 1);
                        }}
                      >
                        {item.esAgregado === 1 ? "Quitar" : "Agregar"}
                        Ver
                      </Button>
                    </Grid>
                  </CardActions> */}
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Modal
                    open={openDialog}
                    onClose={function (event) {
                      setOpenDialog(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModal}>
                      {isHorizontal ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <img
                                width={"90%"}
                                // key={index}
                                src={registroAccesorio.imagen}
                                // alt={`slide-${startIndex + index}`}
                                // className={`image ${activeIndex === index ? 'active' : ''}`}// Aplica siempre la clase 'active' a todas las imágenes visibles
                                // onClick={() => handleImageClick(index)}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                textAlign={"center"}
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  fontSize: isMobile ? 15 : 28,
                                }}
                              >
                                <strong>
                                  {registroAccesorio.accDescripcion}
                                </strong>
                              </Typography>
                              <Typography
                                textAlign={"justify"}
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "100%",
                                  fontSize: isMobile ? 14 : 22,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                {registroAccesorio.nota}
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "25%",
                                  fontSize: isMobile ? 14 : 30,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>PVP:</strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "60%",
                                  fontSize: isMobile ? 18 : 30,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>
                                  {" "}
                                  {formatter
                                    .format(registroAccesorio.precio)
                                    .replace("$", "$  ")}
                                </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "25%",
                                  fontSize: isMobile ? 14 : 20,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>Codigo:</strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "60%",
                                  fontSize: isMobile ? 14 : 20,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                {registroAccesorio.accCodigo}
                              </Typography>
                              <Button
                                variant={
                                  registroAccesorio.esAgregado === 1 ? "outlined" : "contained"
                                }
                                sx={{
                                  mt: 4,
                                  mb: 2,
                                  width: "30%",
                                  marginLeft: "35%",
                                  backgroundColor: registroAccesorio.esAgregado === 1 ? "rgba(255,255,255)" : "rgba(21,57,105)"  ,
                                }}       
                                onClick={function (event) {
                                  if (datosNegocio.id === 0) {
                                    setOpenNegocio(true);
                                  } else {
                                    agregarAcc(registroAccesorioIndex, registroAccesorio.esAgregado === 1 ? 0 : 1);
                                  }
                                }}


                              >
                                {registroAccesorio.esAgregado === 0
                                  ? "Agregar"
                                  : "Quitar"}
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid>
                            <img
                              width={"100%"}
                              src={registroAccesorio.imagen}
                            />

                            <Grid>
                              <Typography
                                textAlign={"center"}
                                sx={{ mt: 1, mb: 1, fontSize: 28 }}
                              >
                                <strong>
                                  {registroAccesorio.accDescripcion}
                                </strong>
                              </Typography>
                              <Typography
                                textAlign={"justify"}
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "100%",
                                  fontSize: 22,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                {registroAccesorio.nota}
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "25%",
                                  fontSize: 30,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>PVP:</strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "60%",
                                  fontSize: 30,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>
                                  {" "}
                                  {formatter
                                    .format(registroAccesorio.precio)
                                    .replace("$", "$  ")}
                                </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "25%",
                                  fontSize: 20,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                <strong>Codigo:</strong>
                              </Typography>
                              <Typography
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "60%",
                                  fontSize: 20,
                                }}
                                style={{ display: "inline-block" }}
                              >
                                {registroAccesorio.accCodigo}
                              </Typography>
                              <Button
                                variant={
                                  registroAccesorio.esAgregado === 1 ? "outlined" : "contained"
                                }
                                sx={{
                                  mt: 4,
                                  mb: 2,
                                  width: "30%",
                                  marginLeft: "35%",
                                  backgroundColor: registroAccesorio.esAgregado === 1 ? "rgba(255,255,255)" : "rgba(21,57,105)" ,
                                }}
                                onClick={function (event) {
                                  if (datosNegocio.id === 0) {
                                    setOpenNegocio(true);
                                  } else {
                                    agregarAcc(registroAccesorioIndex, registroAccesorio.esAgregado === 1 ? 0 : 1);
                                  }
                                }}
                              >
                                {registroAccesorio.esAgregado === 0
                                  ? "Agregar"
                                  : "Quitar"}
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Box>
                  </Modal>
                  <Divider
                    sx={{
                      marginLeft: "35%",
                      marginRight: "35%",
                      mt: 2,
                      mb: 2,
                      borderColor: "white",
                    }}
                  />
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    sx={{ mt: 3, mb: 1, color: "rgba(21,57,105)" }}
                  >
                    © Corporación Proauto 2024
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Modal
            open={openNegocio}
            onClose={function (event) {
              setOpenNegocio(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalNegocio}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, textAlign: "center" }}
              >
                <strong>Negocios</strong>
              </Typography>

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "5%",
                  marginLeft: "27%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={anoConsulta}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "15%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mesConsulta}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "15%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>

              {sateliteUsuario.usuSupervisor === 1 ? (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "5%",
                      marginLeft: "27%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Asesor:
                  </Typography>
                  <TextField
                    id="outlined-select-currency"
                    select
                    size="small"
                    margin="normal"
                    value={asesorConsulta}
                    onChange={handleChangeAsesor}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "41%" }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 450,
                        },
                      },
                    }}
                  >
                    {dataAsesores.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              ) : (
                <></>
              )}

              <Button
                variant="contained"
                sx={{
                  marginLeft: "42%",
                  width: "16%",
                  mt: 2,
                  mb: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  {
                    sateliteUsuario.usuSupervisor === 1 &&
                    dataAsesor.correo.length > 0
                      ? getListado(dataAsesor.correo)
                      : sateliteUsuario.usuJefeAgencia === 1
                      ? getListadoAgencia(sateliteUsuario.idBodega)
                      : getListado(username);
                  }
                }}
              >
                Consultar
              </Button>
              <Divider sx={{ marginLeft: "35%", marginRight: "35%" }} />
              <Button
                variant="contained"
                sx={{
                  marginLeft: "42%",
                  width: "16%",
                  mt: 2,
                  mb: 1,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  setOpenBuscarPorId(true);
                }}
              >
                Buscar por ID
              </Button>

              <Dialog
                open={openBuscarPorId}
                onClose={function (event) {
                  setOpenBuscarPorId(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Buscar"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <TextField
                      name="nuevoPVP"
                      size="small"
                      margin="normal"
                      type="number"
                      fullWidth
                      onChange={dataBuscarId}
                      label="ID CRM"
                      defaultValue={buscarId}
                      sx={{ mt: 1, mb: 1, width: "100%" }}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={function (event) {
                      getListaID();
                    }}
                    autoFocus
                  >
                    Consultar
                  </Button>
                </DialogActions>
              </Dialog>

              {mostrar ? (
                <>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DataGrid
                      //getRowId={(row) => row.id}
                      sx={{ height: 300 }}
                      editMode="row"
                      rows={dataLista}
                      columns={columns}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      slots={{ toolbar: GridToolbarQuickFilter }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarQuickFilterPlaceholder: "Buscar",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Modal>
          {/* modal de carrito  */}
          <Modal
            open={openCarrito}
            onClose={function (event) {
              setOpenCarrito(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalCarrito}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, textAlign: "center", fontSize: 20 }}
              >
                <strong>
                  {datosNegocio.idcrm} {" - "} {datosNegocio.cliente}
                </strong>
              </Typography>
              <Divider />
              <ScrollableListContainer>
                <List
                  sx={{
                    width: "100%",
                    maxheight: "80%",
                    bgcolor: "background.paper",
                  }}
                >
                  {listaAccesorios.map((item, index) => (

                    <>
                    {item.esAgregado === 1 ? <>
                    
                      <ListItem key={item.id}>
                        <ListItemAvatar>
                          <Avatar alt={item.accCodigo} src={item.imagen} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ fontSize: 20 }}
                          primary={
                            <div style={{ fontSize: 18 }}>
                              <span>{item.accDescripcion} </span>
                            </div>
                          }
                          secondary={
                            <div
                              style={{
                                fontSize: 18,
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "10%",
                                width: "90%",
                              }}
                            >
                              <span>{item.accCodigo}</span>
                              <span>
                                Precio:{" "}
                                <strong>
                                  {formatter
                                    .format(item.precio)
                                    .replace("$", "$  ")}
                                </strong>
                                {"    "}
                              </span>
                            </div>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="Agregar al carrito"
                            onClick={function (event) {agregarAcc(index, 0)}}
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <Divider
                          sx={{ marginLeft: "10%", marginRight: "10%" }}
                        />
                      </ListItem>
                      <Divider sx={{ marginLeft: "10%", marginRight: "10%" }} />
                    </>:<></>}
                     
                    </>
                  ))}
                </List>
              </ScrollableListContainer>
              <Divider />
              <Typography
                textAlign={"center"}
                sx={{ mt: 4, mb: 1, fontSize: 24 }}
              >
                <strong>Total: $ {totalPVP}</strong>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  marginLeft: "40%",
                  marginRight: "40%",
                  width: "20%",
                  mt: 2,
                  mb: 2,
                }}
              >
                Guardar
              </Button>
            </Box>
          </Modal>
        </Container>
      </>
    );
  } else {
    return <></>;
  }
};
