import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  AnoType,
  CrmAsesorType,
  MesType,
  VSateliteUsuarioType,
  vventassatelitecrmType,
} from "../../types";
import { useParams } from "react-router-dom";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { PlaceOutlined } from "@mui/icons-material";

export const CorregirNegosPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [buscarId, setBuscarId] = React.useState("");
  const dataBuscarId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscarId(e.target.value);
  };

  async function postGuardarRegistro(parametroGuardar: corregir) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "http://localhost:443/hubspotdeal/updatePipeEtapa",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;

  const [anoConsulta, setAnoConsulta] = React.useState(anioActual);
  const [mesConsulta, setMesConsulta] = React.useState(mesActual);
  const [formaPago, setFormaPago] = React.useState(0);
  const [instancia, setInstancia] = React.useState(0);
  const [asesorConsulta, setAsesorConsulta] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const listaListado: vventassatelitecrmType[] = [];
  const listaAsesor: CrmAsesorType[] = [];

  type corregir = {
    dealId: string;
    pipeline: string;
    dealstage: string;
    accessToken: string;
  };

  const dataLista: corregir[] = [
    


    
    
    
    
    



  ];

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "pipeline",
      headerName: "pipeline",
      editable: false,
      width: 95,
    },
    {
      headerClassName: "theme-header",
      field: "dealstage",
      headerName: "dealstage",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "dealId",
      headerName: "dealId",
      editable: false,
      width: 90,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button onClick={(e) => postGuardarRegistro(params.row)}>
            Abrir
          </Button>
        );
      },
    },
  ];

  async function enviartodo() {
    const contador: number = 0;
    dataLista.forEach(async function (registro) {
      await postGuardarRegistro(registro);
      await new Promise(resolve => setTimeout(resolve, 2000));
    });
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Lista
              </Typography>
            </Paper>

            {/* insertar validacion para mostrar */}
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Box
                sx={{
                  "& .theme-header": {
                    backgroundColor: "rgba(21,57,105,0.75)",
                    color: "rgba(255,255,255,1)",
                  },
                }}
              >
                <DataGrid
                  getRowId={(row) => row.dealId}
                  sx={{ height: 650 }}
                  editMode="row"
                  rows={dataLista}
                  columns={columns}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{ toolbar: GridToolbarQuickFilter }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                    pagination: {
                      labelRowsPerPage: "Registros por página:",
                    },
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 25, 50]}
                  localeText={{
                    footerTotalRows: false,
                    noRowsLabel: "No se ha encontrado datos.",
                    noResultsOverlayLabel:
                      "No se ha encontrado ningún resultado",
                    toolbarColumns: "Columnas",
                    toolbarColumnsLabel: "Seleccionar columnas",
                    toolbarFilters: "Filtros",
                    toolbarFiltersLabel: "Ver filtros",
                    toolbarFiltersTooltipHide: "Quitar filtros",
                    toolbarFiltersTooltipShow: "Ver filtros",
                    toolbarQuickFilterPlaceholder: "Buscar",
                  }}
                />
              </Box>
              <Button onClick={(e) => enviartodo()}>Enviar</Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
