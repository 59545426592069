import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { loginRequest } from "../authConfig";
import { Typography } from "@mui/material";
// import Cookies from 'js-cookie';

export const SignInButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType: string) => {
        setAnchorEl(null);

        if (loginType === "popup") {
            instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            //const tokenGOW = Cookies.set("COOKIE_AUTH");
            // Cookies.set('tokenGOW', resultado.data.token, { expires: 30 });
            console.log('loginRequest==============>>>',loginRequest)
            instance.loginRedirect(loginRequest);
        }
    }

    return (
        <div>
            <Button
                id="iniciar_sesion"
                onClick={() => handleLogin("redirect")} key="loginPopup"
                color="inherit"
                aria-expanded={open ? 'true' : undefined}
            >
                <Typography variant="h6" sx={{width: "200px",}}>Iniciar sesión</Typography>
            </Button>
           
        </div>
    )
};