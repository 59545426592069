import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {
  DefaultizedPieValueType,
  PieChart,
  PieItemIdentifier,
  pieArcClasses,
  pieArcLabelClasses,
} from "@mui/x-charts";
import { dataTracking } from "../types";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { orange } from "@mui/material/colors";

type Props = {
  fase: string;
  data: dataTracking[];
  handleClickGeneral: (event: any) => void;
  handleClickDetalle0: (event: any) => void;
  handleClickDetalle1: (event: any) => void;
  handleClickDetalle2: (event: any) => void;
  handleClickDetalle3: (event: any) => void;
};

const CardTracking = (props: Props) => {
  const handleClickLocal = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    itemIdentifier: PieItemIdentifier,
    item: DefaultizedPieValueType
  ) => {
    {
      item.id === 0
        ? props.handleClickDetalle0("")
        : item.id === 1
        ? props.handleClickDetalle1("")
        : item.id === 2
        ? props.handleClickDetalle2("")
        : props.handleClickDetalle3("");
    }

    // setId(item.id);
    // setIdentifier(itemIdentifier);
  };

  return (
    <Card
      sx={{
        //height: 400,
        width: 257,
        display: "inline-block",
        marginRight: 1,
        marginLeft: 1,
        mt: 2,
        mb: 2,
      }}
    >
      <CardContent>
        <Typography
          sx={{
            mt: 1,
            mb: 1,
          }}
          style={{ display: "inline-block" }}
        >
          <strong>{props.fase}</strong>
        </Typography>
        <PieChart
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
            [`& .${pieArcClasses.faded}`]: {
              fill: "gray",
            },
          }}
          // colors={["#B80404", "blue", "green"]}
          series={[
            {
              data: props.data,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -5 },
              // arcLabel: (item) => `${item.value}`,
              innerRadius: 15,
              outerRadius: 70,
              paddingAngle: 2,
              cornerRadius: 5,
              cx: 108,
              cy: 80,
            },
          ]}
          onClick={handleClickLocal}
          // onClick={function (event) {props.handleClick()}}
          width={400}
          height={170}
          // slotProps={{
          //   legend: {
          //     direction: "row",
          //     position: { vertical: "bottom", horizontal: "left" },
          //     padding: 0,
          //   },
          // }}
        />
      </CardContent>
      <Typography
        //onClick={handleClickLocal}
        sx={{
          marginLeft: "5%",
        }}
      >
        🟢 A tiempo: <strong>{props.data.filter((obj) =>{return obj.id === 0})[0]?.value}</strong>
      </Typography>
      <Typography
        sx={{
          marginLeft: "5%",
        }}
      >
        🟡 En proceso: <strong>{props.data.filter((obj) =>{return obj.id === 2})[0]?.value}</strong>
      </Typography>
      <Typography
        sx={{
          marginLeft: "5%",
        }}
      >
        🟠 Entrega con retraso: <strong>{props.data.filter((obj) =>{return obj.id === 1})[0]?.value}</strong>
      </Typography>
      <Typography
        sx={{
          marginLeft: "5%",
        }}
      >
        🔴 Retrasado: <strong>{props.data.filter((obj) =>{return obj.id === 3})[0]?.value}</strong>
      </Typography>
      <CardActions>
        <Button
          target="_blank"
          component="a"
          onClick={function (event) {
            props.handleClickGeneral("");
          }}
          size="small"
        >
          Ver detalle
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardTracking;
