import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  ListItemAvatar,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import logo from "../../assets/logochevy.png";
import { PoliticaComercialPage } from ".";

export const ListaPoliticaComercialPage: React.FC<{}> = () => {
  useEffect(() => {
    getListaSimulaciones();
  }, []);

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const listadoInicial: politicaComercialType[] = [];
  const [rowsLista, setRowsLista] =
    useState<politicaComercialType[]>(listadoInicial);

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();

  type politicaComercialType = {
    id: number;
    marca: string;
    zona: string;
    anio: number;
    mes: string;
    semana: number;
    idMarca: number;
    idZona: number;
    mesNum: number;
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  async function getListaSimulaciones() {
    try {
      await axios
        .get<politicaComercialType[]>(
          "https://apisatelite.azurewebsites.net/vpoliticacomercial"
        )
        .then((response) => {
          setRowsLista(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(rowsLista, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }
  
  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Lista de Simulaciones - Política Comercial
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
              <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "65%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  href="/"
                >
                  Menú inicio
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "15%",
                    marginLeft: "5%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  href="/politicacomercial/index"
                >
                  Nuevo
                </Button>

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell>Marca</TableCell>
                        <TableCell>Zona</TableCell>
                        <TableCell>Año</TableCell>
                        <TableCell>Mes</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsLista.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell>{row.marca}</TableCell>
                          <TableCell>{row.zona}</TableCell>
                          <TableCell>{row.anio}</TableCell>
                          <TableCell>{row.mes}</TableCell>
                          <TableCell>
                            <div>
                              <Button
                                href={`/politicacomercial/index/${row.id}/${row.mesNum}/${row.idMarca}/${row.idZona}/${row.semana}`}
                              >
                                Abrir
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
