import React, {useEffect, useState} from "react";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@mui/material";
import { Image, Opacity, Power } from "@mui/icons-material";
import fondo from "../../assets/fon_3_marcas.png"
import imgmesa from "../../assets/mesa.png"
import imgpower from "../../assets/power.png"
import imghubspot from "../../assets/hubspot.png"
import imgmuc from "../../assets/muc.png"
import imgintra from "../../assets/intra.png"
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import imgdigitalizacion from "../../assets/digitalizacion.png"
import imgbuxix from "../../assets/buxis.png"
import imgdevops from "../../assets/devops.png"
import imgelerning from "../../assets/elearning.png"
import landingpage from "../../assets/landingpage.png"
import { styled } from "@mui/system";


export const HomePage: React.FC<{}> = () => {

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const isAuthenticated = useIsAuthenticated();
  const imageURL = "https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg";
  const Background = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    //backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: "50%"
    //marginLeft: -900
  });

type tarjetas = {
  descripcion: string,
  imagen: string,
}

if (isAuthenticated) {
   return (

<>
    {/* <Background /> */}
     
  <Paper sx={{mt: '100px', mb: '50px', backgroundColor: "rgb(255,255,255,0.7)" }}  >
    <Grid >
    <Card sx={{ width: 300, display: "inline-block", marginRight : 2, marginLeft: 2, mt: 2, mb: 2 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={landingpage}
        title="Corporacion Proauto"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Crear Tráfico
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Crea negocios en HubSpot de forma facil y rapida desde Satélite.
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
   href="/ventas/negocio/crear" size="small">Ir al sitio</Button>
      </CardActions>
    </Card>
    <Card sx={{ width: 300, display: "inline-block", marginRight : 2,  mt: 2, mb: 2 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={imghubspot}
        title="Corporacion Proauto"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          HubSpot
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Acceso al CRM de la Corporación, registra aquí tus negocios.
        </Typography>
      </CardContent>
      <CardActions>
        <Button target="_blank"
   component="a" href="https://app.hubspot.com/login" size="small">Ir al sitio</Button>
      </CardActions>
    </Card>
    <Card sx={{ width: 300, display: "inline-block", marginRight : 2, mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgpower}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Power BI
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Analiza los datos de la Corporación de forma simple y objetiva.
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="https://go.microsoft.com/fwlink/?LinkId=2183346&clcid=0x40a&pbi_source=websignin_uNav" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  
 
  <Card sx={{ width: 300, display: "inline-block",  marginRight : 2 , mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgmesa}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Mesa de Servicio
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Reporta tus problemas e incidentes para una solución ágil. 
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="https://maulme.freshdesk.com/support/login" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  </Grid>

<Grid flexDirection= 'row'>
  
  <Card sx={{ width: 300, display: "inline-block" ,  marginRight : 2 , marginLeft: 2, mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgintra}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Intranet
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Podrás encontrar noticias y micrositios de los departamentos que conforman la Corporación.
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="https://corporativomep.sharepoint.com/sites/CorporacinPROAUTO2" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  <Card sx={{ width: 300, display: "inline-block", marginRight : 2 , mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgbuxix}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Buxis
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Gestiona tus procesos con Talento Humano y consulta tus roles de pago.
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="http://buxis.corporacionproauto.com.ec/bxportal/componentes/WEBFORM.HTM?ID=PORTAL" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  <Card sx={{ width: 300, display: "inline-block", marginRight : 2 , mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgdigitalizacion}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        Gestor Documental
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Consulta y almacena en el gestor documental todos los documentos de tu negociación. 
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="https://softexpert.corporacionproauto.com.ec/softexpert/login" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  
  <Card sx={{ width: 300, display: "inline-block" ,  marginRight : 2 , mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgelerning}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
      Elearning
      </Typography>
      <Typography variant="body2" color="text.secondary">
       Encontrarás instructivos y detalle para todos nuestros procesos de Corporación.
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href="https://educa.corporacionproauto.com.ec/moodle/" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  </Grid>

<Grid flexDirection= 'row'>
 
  <Card sx={{ width: 300, display: "inline-block" ,  marginRight : 2 , marginLeft: 2, mt: 2, mb: 2}}>
    <CardMedia
      sx={{ height: 140 }}
      image={imgdevops}
      title="Corporacion Proauto"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        DevOps
      </Typography>
      <Typography variant="body2" color="text.secondary">
      Gestiona tus actividades con una herramienta enfocada en una metodología ágil.
      </Typography>
    </CardContent>
    <CardActions>
      <Button target="_blank"
   component="a" href ="https://dev.azure.com/CORPORACIONPROAUTO/" size="small">Ir al sitio</Button>
    </CardActions>
  </Card>
  </Grid>
   <Typography  variant="h6" textAlign={"center"} sx={{mt: 2, mb:1}}>
    © Corporación Proauto 2024
                 </Typography>
  </Paper>

  
  </>
  );
} else {
  return (
       <></>
  );
}
};
