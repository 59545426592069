import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { SateliteUafePesoType, SateliteUafePesoTypeNuevo, marcaType } from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tab } from "@testing-library/user-event/dist/tab";

export const PesoMatrizRiegoPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);
  const [satelitePesosLista, setSatelitePesosLista] = useState<
    SateliteUafePesoType[]
  >([]);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const listadoTablas: marcaType[] = [
    { marId: 1, marNombre: "ABSTENCIONES PARA INICIAR ACTIVIDAD ECONÓMICA" },
    { marId: 2, marNombre: "INFORMACION ADICIONAL" },
    { marId: 3, marNombre: "PROVINCIA" },
    { marId: 4, marNombre: "CANTON" },
    { marId: 5, marNombre: "PAIS" },
    { marId: 6, marNombre: "FORMA PAGO" },
    { marId: 7, marNombre: "TIPO CLIENTE" },
    { marId: 8, marNombre: "VH MODELO" },
    { marId: 9, marNombre: "ACTIVIDAD ECONOMICA" },
  ];

  const [openDialog, setOpenDialog] = React.useState(false);
  const [esNuevo, setEsNuevo] = React.useState(false);
  const [mostrar, setMostrar] = React.useState(false);
  const [tabla, setTabla] = React.useState(0);

  const [registroPeso, setRegistroPeso] = useState<SateliteUafePesoType>({
    supId: 0,
    supTabla: 0,
    supTablaDesc: "",
    supDescripcion: "",
    supOrden: 0,
    supPeso: 0,
    supReporta: 0,
    supIdAdicional: 0,
    supActivo: 0,
  });

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistroPeso({
      ...registroPeso,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeTabla = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTabla(Number(event.target.value));
    setMostrar(false);
  };

  const [cheked, setCheked] = React.useState({
    es_activo: true,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  function setNuevoRegistro(idTabla: number) {
    const nuevoRegistro: SateliteUafePesoType = {
      supId: 0,
      supTabla: idTabla,
      supTablaDesc:
        idTabla === 1
          ? "ABSTENCIONES PARA INICIAR ACTIVIDAD ECONÓMICA"
          : idTabla === 2
          ? "INFORMACION ADICIONAL"
          : idTabla === 3
          ? "PROVINCIA"
          : idTabla === 4
          ? "CANTON"
          : idTabla === 5
          ? "PAIS"
          : idTabla === 6
          ? "FORMA PAGO"
          : idTabla === 7
          ? "TIPO CLIENTE"
          : idTabla === 8
          ? "VH MODELO"
          : "ACTIVIDAD ECONOMICA",
      supDescripcion: "",
      supOrden: 99,
      supPeso: 0,
      supReporta: 0,
      supIdAdicional: 0,
      supActivo: 0,
    };

    setRegistroPeso(nuevoRegistro);
  }

  function setRegistro(
    actualizarRegistro: SateliteUafePesoType,
    activo: boolean,
    esNuevo: boolean,
  ) {
    if (esNuevo){
      const nuevoRegistro: SateliteUafePesoTypeNuevo = {
        supTabla: actualizarRegistro.supTabla,
        supTablaDesc: actualizarRegistro.supTablaDesc,
        supDescripcion: actualizarRegistro.supDescripcion,
        supOrden: actualizarRegistro.supOrden,
        supPeso: actualizarRegistro.supPeso,
        supReporta: actualizarRegistro.supReporta,
        supIdAdicional: actualizarRegistro.supIdAdicional,
        supActivo: activo ? 1 : 0,
      };
      console.log(
        "usuario a guardar >> ",
        JSON.stringify(nuevoRegistro, null, 4)
      );
      postGuardarRegistroNuevo(nuevoRegistro);
    }else{
      const nuevoRegistro: SateliteUafePesoType = {
        supId: actualizarRegistro.supId,
        supTabla: actualizarRegistro.supTabla,
        supTablaDesc: actualizarRegistro.supTablaDesc,
        supDescripcion: actualizarRegistro.supDescripcion,
        supOrden: actualizarRegistro.supOrden,
        supPeso: actualizarRegistro.supPeso,
        supReporta: actualizarRegistro.supReporta,
        supIdAdicional: actualizarRegistro.supIdAdicional,
        supActivo: activo ? 1 : 0,
      };
      console.log(
        "usuario a guardar >> ",
        JSON.stringify(nuevoRegistro, null, 4)
      );
      postGuardarRegistro(nuevoRegistro);
    }  
  }

  async function postGuardarRegistro(registro: SateliteUafePesoType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteuafepeso",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          getListado(tabla);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRegistroNuevo(registro: SateliteUafePesoTypeNuevo) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteuafepeso",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          getListado(tabla);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListado(idtabla: number) {
    try {
      await axios
        .get<SateliteUafePesoType[]>(
          "https://apisatelite.azurewebsites.net/sateliteuafepeso/tabla/" +
            String(idtabla)
        )
        .then((response) => {
          console.log("mi listado es >>>>>>", response.data);
          setSatelitePesosLista(response.data);
          setMostrar(true);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "supTablaDesc",
      headerName: "Clasificación",
      editable: false,
      width: 320,
    },
    {
      headerClassName: "theme-header",
      field: "supDescripcion",
      headerName: "Descripción",
      editable: false,
      width: 450,
    },
    {
      headerClassName: "theme-header",
      field: "supPeso",
      headerName: "Peso",
      editable: false,
      width: 130,
    },
    {
      headerClassName: "theme-header",
      field: "supActivo",
      headerName: "Activo",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return row.supActivo === true ? "Si" : "No";
      },
    },

    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/ventas/index/${params.row.idcrm}`}
            onClick={function (event) {
              setRegistroPeso(params.row);
              setCheked({
                ...cheked,
                es_activo: params.row.supActivo,
              });
              setEsNuevo(false);
              setOpenDialog(true);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Pesos - Matriz de Riesgo
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "32%",
                }}
                style={{ display: "inline-block" }}
              >
                Clasificación:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={tabla}
                onChange={handleChangeTabla}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "25%" }}
              >
                {listadoTablas.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  setEsNuevo(false);
                  getListado(tabla);
                }}
              >
                Consultar
              </Button>
            </Paper>
            {mostrar ? (
              <>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 1,
                    mb: 4,
                  }}
                >
                  <>
                    <Button
                      sx={{ mt: 1, mb: 3, marginLeft: "44%", width: "12%" }}
                      onClick={function (event) {
                        setNuevoRegistro(tabla);
                        setOpenDialog(true);
                        setEsNuevo(true);
                      }}
                    >
                      Nuevo
                    </Button>
                    <Box
                      sx={{
                        "& .theme-header": {
                          backgroundColor: "rgba(21,57,105,0.75)",
                          color: "rgba(255,255,255,1)",
                        },
                      }}
                    >
                      <DataGrid
                        getRowId={(row) => row.supId}
                        sx={{ height: 650 }}
                        editMode="row"
                        rows={satelitePesosLista}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbarQuickFilter }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        localeText={{
                          footerTotalRows: false,
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                          toolbarQuickFilterPlaceholder: "Buscar",
                        }}
                      />
                    </Box>
                  </>
                </Paper>
              </>
            ) : (
              <></>
            )}

            {/* modal */}

            <Modal
              open={openDialog}
              onClose={function (event) {
                setOpenDialog(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <Typography
                  sx={{ mt: 1, mb: 1, marginLeft: "5%" }}
                  style={{ display: "inline-block" }}
                >
                  Texto:
                </Typography>
                <TextField
                  name="supDescripcion"
                  size="small"
                  margin="normal"
                  value={registroPeso.supDescripcion}
                  onChange={handleDataChange}
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "5%", width: "80%" }}
                />
                <Typography
                  sx={{ mt: 1, mb: 1, marginLeft: "5%" }}
                  style={{ display: "inline-block" }}
                >
                  Peso:
                </Typography>
                <TextField
                  name="supPeso"
                  size="small"
                  margin="normal"
                  value={registroPeso.supPeso}
                  onChange={handleDataChange}
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "5%", width: "30%" }}
                />

                <Typography
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "20%" }}
                  style={{ display: "inline-block" }}
                >
                  Activo:
                </Typography>
                <Switch
                  checked={cheked.es_activo}
                  onChange={handleCheked}
                  name="es_activo"
                  sx={{ mt: 0.5, mb: 0.5, marginLeft: "3%" }}
                />

                <Button
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 1,
                    marginLeft: "40%",
                    width: "20%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    setRegistro(registroPeso, cheked.es_activo, esNuevo);
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
