import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AnoType, MesType, VentasCRMFIType, bodegaType } from "../../types";
import { BarChart } from "@mui/x-charts";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

export const FIDetallePage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    getBodegas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);

      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  type marcaType = {
    marId: number;
    marNombre: string;
  };
  type lineaType = {
    linId: number;
    linNombre: string;
  };

  const listadoMarcas: marcaType[] = [];
  const listadoAgencias: bodegaType[] = [];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const columns: GridColDef[] = [
    // {field: "id", headerName: "id",   editable: false,   width: 100},
    {
      field: "estado",
      headerName: "Estado",
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            {params.row.nfiEstado === 0 ? (
              <RadioButtonCheckedIcon sx={{ color: blue[500] }} />
            ) : params.row.nfiEstado === 1 ? (
              <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
            ) : params.row.nfiEstado === 2 ? (
              <RadioButtonCheckedIcon sx={{ color: green[500] }} />
            ) : params.row.nfiEstado === 3 ? (
              <RadioButtonCheckedIcon sx={{ color: red[500] }} />
            ) : (
              <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
            )}
          </div>
        );
      },
    },
    { field: "idcrm", headerName: "ID CRM", editable: false, width: 95 },
    {
      field: "fechaFactura",
      headerName: "Facturación",
      editable: false,
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.fechaFactura).substring(0, 10);
      },
    },
    { field: "asesor", headerName: "Asesor", editable: false, width: 130 },
    { field: "cedula", headerName: "Cédula", editable: false, width: 90 },
    { field: "cliente", headerName: "Cliente", editable: false, width: 200 },
    //{ field: "dealname", headerName: "Negocio", editable: false, width: 200 },
    { field: "agencia", headerName: "Agencia", editable: false, width: 125 },
    {
      field: "formaPago",
      headerName: "Forma pago",
      editable: false,
      width: 100,
    },
    {
      field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            href={`/fi/negocio/${params.row.idcrm}`}
            onClick={function (event) {}}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
  ];

  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(2023);
  const [mes, setMes] = React.useState(12);
  const [agencia, setAgencia] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
    setMostrar(false);
  };

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<VentasCRMFIType[]>([]);

  async function getListadoTodoTodo(
    anio: number,
    mes: number,
    marca: string,
    idBodega: number
  ) {
    try {
      await axios
        .get<VentasCRMFIType[]>(
          "https://apisatelite.azurewebsites.net/vventassatelitecrmfi/todo/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            marca +
            "/" +
            String(idBodega)
        )
        .then((response) => {
          setListaDatos(response.data);
          sumarTotales(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [totalEstado0, setTotalEstado0] = React.useState(0);
  const [totalEstado1, setTotalEstado1] = React.useState(0);
  const [totalEstado2, setTotalEstado2] = React.useState(0);
  const [totalEstado3, setTotalEstado3] = React.useState(0);

  function sumarTotales(lista: VentasCRMFIType[]) {
    var totalEstad0: number = 0;
    var totalEstad1: number = 0;
    var totalEstad2: number = 0;
    var totalEstad3: number = 0;
    lista.forEach(function (value) {
      if (value.nfiEstado === 0) {
        totalEstad0 = totalEstad0 + 1;
      }
      if (value.nfiEstado === 1) {
        totalEstad1 = totalEstad1 + 1;
      }
      if (value.nfiEstado === 2) {
        totalEstad2 = totalEstad2 + 1;
      }
      if (value.nfiEstado === 3) {
        totalEstad3 = totalEstad3 + 1;
      }
    });
    setTotalEstado0(totalEstad0);
    setTotalEstado1(totalEstad1);
    setTotalEstado2(totalEstad2);
    setTotalEstado3(totalEstad3);
    console.log(totalEstado0, totalEstado1, totalEstado2, totalEstado3);
  }

  const dataset = [
    {
      estado: "Estado",
      estado0: totalEstado0,
      estado1: totalEstado1,
      estado2: totalEstado2,
      estado3: totalEstado3,
    },
  ];

  const data = [
    { name: "A", uv: 400, pv: 2400, amt: 2400 },
    { name: "B", uv: 300, pv: 1398, amt: 2210 },
    { name: "C", uv: 200, pv: 9800, amt: 2290 },
    { name: "D", uv: 278, pv: 3908, amt: 2000 },
    { name: "E", uv: 189, pv: 4800, amt: 2181 },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Seguros F&I - Detalle
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                }}
                onClick={function (event) {
                  getListadoTodoTodo(
                    ano,
                    mes,
                    marca === 1
                      ? "CHEVROLET"
                      : marca === 2
                      ? "GAC"
                      : "VOLKSWAGEN",
                    agencia
                  );
                  setMostrar(true);
                }}
              >
                Consultar
              </Button>
            </Paper>
            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Grid flexDirection="row">
                  <Box sx={{ marginLeft: "32%" }}>
                    <BarChart
                      //sx={{marginLeft: 30}}
                      // series={[{ data: [Number(totalEstado0), Number(totalEstado1), Number(totalEstado2), Number(totalEstado3)] }]}
                      dataset={dataset}
                      series={[
                        {
                          dataKey: "estado0",
                          label: "Pendiente",
                          color: "#0794F6",
                        },
                        {
                          dataKey: "estado1",
                          label: "Por pagar",
                          color: "#F6AE07",
                        },
                        {
                          dataKey: "estado2",
                          label: "Pagado",
                          color: "#00BB08",
                        },
                        {
                          dataKey: "estado3",
                          label: "Anulado",
                          color: "#DC0000",
                        },
                      ]}
                      xAxis={[{ scaleType: "band", dataKey: "estado" }]}
                      //   xAxis={[
                      //     {
                      //       scaleType: "band",
                      //       data: ["Pendiente", "Por pagar", "Pagado", "Anulado"],
                      //     },
                      //   ]}
                      height={300}
                      width={400}
                      leftAxis={null}
                      //layout="horizontal"
                    />
                  </Box>
                  <Box>
                    <DataGrid
                      getRowId={(row) => row.id}
                      sx={{ height: 600 }}
                      editMode="row"
                      rows={listaDatos}
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                      }}
                      pageSizeOptions={[10, 20, 30]}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      localeText={{
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                      }}
                    />
                  </Box>
                </Grid>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
