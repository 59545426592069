import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import axios from "axios";
import React, {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  AnoType,
  ChevyseguroCatalogo,
  MesType,
  VChevyseguro,
  bodegaType,
  marcaType,
} from "../../types";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  ChevySeguro2InsuranceCarrierAgreementDTOs,
  ChevySeguro2ProductPlanDTOList,
  ChevySeguro2ProductSeletecdList,
  ChevySeguro2Respuesta2,
  ChevySeguroAdditionalPartDTO,
  ChevySeguroCity,
  ChevySeguroCustomerInformationDTO,
  ChevySeguroInfoNegocio,
  ChevySeguroListPaymentMethodsDTO,
  ChevySeguroProductDTOList,
  ChevySeguroQuotationAddressDTOList,
  ChevySeguroRespuesta1,
  ChevySeguroServicio2,
  ChevySeguroServicio3,
  ChevySeguroVehicleEmissionDTO,
} from "../../types/externos";
import "./index.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-content">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const ChevySegurosPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getBodegas();
      getBancos();
    } else {
      setName("");
    }
  }, [account]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const listadoAgencias: bodegaType[] = [];
  const listadoBancos: ChevyseguroCatalogo[] = [];
  const listadoTipoCuenta: MesType[] = [
    { id: 1, mes: "Ahorros" },
    { id: 2, mes: "Corriente" },
  ];
  const listadoResp1: ChevySeguroProductDTOList[] = [];
  const listaAnos: AnoType[] = [{ id: 2024, ano: 2024 }];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [openDialog, setOpenDialog] = React.useState(false);
  const [mostrar, setMostrar] = React.useState(false);
  const [mostrarDetalle, setMostrarDetalle] = React.useState(false);
  const [mostrarProductos, setMostrarProductos] = React.useState(false);
  const [cheked, setCheked] = React.useState({
    contado: false,
    cheque: false,
    debito: false,
    visa: false,
    master: false,
    transferencia: false,
  });
  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeCheque = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "accountTypeCode") {
      setChevySeguro3Cheque({
        ...chevySeguro3Cheque,
        bankName: Number(event.target.value) === 0 ? "Ahorros" : "Corriente",
      });
    } else {
      setChevySeguro3Cheque({
        ...chevySeguro3Cheque,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeDebito = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "accountTypeCode") {
      setChevySeguro3Debito({
        ...chevySeguro3Debito,
        bankName: Number(event.target.value) === 0 ? "Ahorros" : "Corriente",
      });
    } else {
      setChevySeguro3Debito({
        ...chevySeguro3Debito,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeVisa = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "accountTypeCode") {
      setChevySeguro3Visa({
        ...chevySeguro3Visa,
        bankName: Number(event.target.value) === 0 ? "Ahorros" : "Corriente",
      });
    } else {
      setChevySeguro3Visa({
        ...chevySeguro3Visa,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeMasterCard = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === "accountTypeCode") {
      setChevySeguro3MasterCard({
        ...chevySeguro3MasterCard,
        bankName: Number(event.target.value) === 0 ? "Ahorros" : "Corriente",
      });
    } else {
      setChevySeguro3MasterCard({
        ...chevySeguro3MasterCard,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeTransferencia = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.name === "accountTypeCode") {
      setChevySeguro3Transferencia({
        ...chevySeguro3Transferencia,
        bankName: Number(event.target.value) === 0 ? "Ahorros" : "Corriente",
      });
    } else {
      setChevySeguro3Transferencia({
        ...chevySeguro3Transferencia,
        [event.target.name]: event.target.value,
      });
    }
  };

  const [ano, setAno] = React.useState(0);
  const [mes, setMes] = React.useState(0);
  const [plan, setPlan] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);
  const [idTipoProductos, setIdTipoProductos] = React.useState(0);
  const [nameTipoProductos, setNameTipoProductos] = React.useState("");
  const [dataChevySeguros, setDataChevySeguros] = useState<VChevyseguro[]>([]);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataBancos, setDataBancos] =
    useState<ChevyseguroCatalogo[]>(listadoBancos);
  const [dataTipoCuenta, setDataTipoCuenta] =
    useState<MesType[]>(listadoTipoCuenta);
  const [dataNegocio, setDataNegocio] = useState<VChevyseguro>({
    id: 0,
    agencia: "",
    fechaFactura: "",
    factura: "",
    nitAsesor: "",
    asesor: "",
    marca: "",
    familia: "",
    modelo: "",
    canal: "",
    identification: "",
    firstName: "",
    secondName: "",
    lastName: "",
    surName: "",
    fullName: "",
    birthDate: "",
    email: "",
    telephoneMobile: "",
    genderId: 0,
    maritalStatusId: 0,
    telephone: "",
    nationalityId: 0,
    streetAddress: "",
    idcity: 0,
    useTypeId: 0,
    newIndicatorId: 0,
    useId: 0,
    modelId: 0,
    colorId: 0,
    year: 0,
    securityDeviceId: 0,
    tonnage: 0,
    insuredValue: 0,
    extrasValue: 0,
    cpn: "",
    engine: "",
    chassis: "",
    firstCirculationDate: "",
    creditOperation: "",
    effectiveStartDate: "",
    effectiveEndDate: "",
    channelIdentification: "",
    branchOfficeId: 0,
    purchaseMethodId: 0,
    creditDuration: 0,
    creditDurationPeriod: "",
    insuranceDuration: 0,
    insuranceDurationPeriod: "",
    circulationAreaId: 0,
  });
  const [dataProductosItems, setDataProductosItems] = useState<
    ChevySeguro2InsuranceCarrierAgreementDTOs[]
  >([
    {
      agreementBaseId: 0,
      name: "",
      insuranceCarrierName: "",
      insuranceCarrieriId: 0,
      netPremium: 0,
      grossPremium: 0,
      commercialRate: 0,
      monthlyPremium: 0,
    },
  ]);
  const [productoSeleccionado, setProductoSeleccinado] =
    useState<ChevySeguro2InsuranceCarrierAgreementDTOs>({
      agreementBaseId: 0,
      name: "",
      insuranceCarrierName: "",
      insuranceCarrieriId: 0,
      netPremium: 0,
      grossPremium: 0,
      commercialRate: 0,
      monthlyPremium: 0,
    });
  const dataProdcutosVacio: ChevySeguro2ProductPlanDTOList[] = [];
  const [dataProductos, setDataProductos] = useState<
    ChevySeguro2ProductPlanDTOList[]
  >([
    {
      productPlanId: 0,
      name: "",
      insuranceCarrierAgreementDTOs: dataProductosItems,
    },
  ]);

  const [chevySeguro3Contado, setChevySeguro3Contado] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      reference: "",
    });

  const [chevySeguro3Cheque, setChevySeguro3Cheque] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      bankId: 0,
      bankName: "",
      accountTypeCode: "",
      accountNumber: "",
      numberPayment: "",
      ownerTypeCode: "",
      identificationOwner: "",
      entityIdentificationTypeCode: "",
      nameOwner: "",
      relationshipWithInsuredPerson: "",
      reference: "",
    });
  const [chevySeguro3Debito, setChevySeguro3Debito] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      bankId: 0,
      bankName: "",
      month: 0,
      accountTypeCode: "",
      accountNumber: "",
      sharesNumber: "",
      debitNumberDays: "",
      numberPayment: "",
      shareInitValue: 0,
      shareLeftoverValue: 0,
      debitDateInit: "",
      ownerTypeCode: "",
      identificationOwner: "",
      entityIdentificationTypeCode: "",
      nameOwner: "",
      relationshipWithInsuredPerson: "",
      reference: "",
    });
  const [chevySeguro3Visa, setChevySeguro3Visa] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      paymentMethodCode: "",
      bankId: 0,
      bankName: "",
      accountTypeCode: "",
      ownerTypeCode: "",
      identificationOwner: "",
      entityIdentificationTypeCode: "",
      nameOwner: "",
      relationshipWithInsuredPerson: "",
      reference: "",
    });
  const [chevySeguro3MasterCard, setChevySeguro3MasterCard] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      paymentMethodCode: "",
      bankId: 0,
      bankName: "",
      accountTypeCode: "",
      ownerTypeCode: "",
      identificationOwner: "",
      entityIdentificationTypeCode: "",
      nameOwner: "",
      relationshipWithInsuredPerson: "",
      reference: "",
    });
  const [chevySeguro3Transferencia, setChevySeguro3Transferencia] =
    useState<ChevySeguroListPaymentMethodsDTO>({
      grossPremium: 0,
      value: 0,
      insuranceAgreementId: 0,
      insuranceCarrierId: 0,
      insuranceCarrierName: "",
      currency: 0,
      paymentTypeCode: "",
      bankId: 0,
      bankName: "",
      accountTypeCode: "",
      accountNumber: "",
      numberPayment: "",
      ownerTypeCode: "",
      identificationOwner: "",
      entityIdentificationTypeCode: "",
      nameOwner: "",
      relationshipWithInsuredPerson: "",
      reference: "",
    });

  // const dataChevySeguro3Contado = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChevySeguro3Contado({
  //     ...chevySeguro3Contado,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const dataChevySeguro3Contado = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setChevySeguro3Contado(prevDatos => ({
  //     ...prevDatos,
  //     [name]: value
  //   }));
  // }, []);

  const nombreRef = useRef<HTMLInputElement | null>(null);
  const apellidoRef = useRef<HTMLInputElement | null>(null);

  const dataChevySeguro3Contado = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setChevySeguro3Contado((prevDatos) => ({
        ...prevDatos,
        [name]: value,
      }));

      if (name === "reference" && nombreRef.current) {
        nombreRef.current.focus();
      } else if (name === "value" && apellidoRef.current) {
        apellidoRef.current.focus();
      }
    },
    []
  );

  const dataChevySeguro3Cheque = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChevySeguro3Cheque({
      ...chevySeguro3Cheque,
      [e.target.name]: e.target.value,
    });
  };
  const dataChevySeguro3Debito = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChevySeguro3Debito({
      ...chevySeguro3Debito,
      [e.target.name]: e.target.value,
    });
  };
  const dataChevySeguro3Visa = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChevySeguro3Visa({
      ...chevySeguro3Visa,
      [e.target.name]: e.target.value,
    });
  };
  const dataChevySeguro3MasterCard = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChevySeguro3MasterCard({
      ...chevySeguro3MasterCard,
      [e.target.name]: e.target.value,
    });
  };
  const dataChevySeguro3Transferencia = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChevySeguro3Transferencia({
      ...chevySeguro3Transferencia,
      [e.target.name]: e.target.value,
    });
  };

  const [dataRespuesta1, setDataRespuesta1] = useState<ChevySeguroRespuesta1>();
  const [quotationId, setQuotationId] = React.useState("");
  const [dataRespuesta2, setDataRespuesta2] =
    useState<ChevySeguro2Respuesta2>();
  const [dataRespuesta1Listado, setDataRespuesta1Listado] =
    useState<ChevySeguroProductDTOList[]>(listadoResp1);

  function setRegistroSrv1(registro: VChevyseguro) {
    const registroChevySeguroCity: ChevySeguroCity = {
      id: registro.idcity,
    };
    const registroChevySeguroQuotationAddressDTOList: ChevySeguroQuotationAddressDTOList[] =
      [
        {
          type: "Home",
          collectionAddress: true,
          streetAddress: registro.streetAddress,
          numberAddress: "",
          intersectionAddress: "",
          telephone: "",
          city: registroChevySeguroCity,
        },
      ];
    const registroChevySeguroCustomerInformationDTO: ChevySeguroCustomerInformationDTO =
      {
        partyType: 1,
        identification: registro.identification,
        firstName: registro.firstName,
        secondName: registro.secondName,
        lastName: registro.lastName,
        surName: registro.surName,
        fullName: registro.fullName,
        birthDate: registro.birthDate,
        email: registro.email,
        telephoneMobile: registro.telephoneMobile,
        genderId: registro.genderId,
        maritalStatusId: registro.maritalStatusId,
        telephone: registro.telephone,
        nationalityId: registro.nationalityId,
        quotationAddressDTOList: registroChevySeguroQuotationAddressDTOList,
      };
    const registroChevySeguroAdditionalPartDTO: ChevySeguroAdditionalPartDTO[] =
      [];
    const registroChevySeguroVehicleEmissionDTO: ChevySeguroVehicleEmissionDTO[] =
      [
        {
          useTypeId: registro.useTypeId,
          newIndicatorId: registro.newIndicatorId,
          useId: registro.useId,
          modelId: registro.modelId,
          colorId: registro.colorId,
          year: registro.year,
          securityDeviceId: registro.securityDeviceId,
          tonnage: registro.tonnage,
          insuredValue: registro.insuredValue,
          extrasValue: registro.extrasValue,
          movableIndicator: 0,
          cpn: registro.cpn,
          engine: registro.engine,
          chassis: registro.chassis,
          firstCirculationDate: registro.firstCirculationDate,
          creditOperation: registro.creditDuration,
          effectiveStartDate: registro.effectiveStartDate,
          effectiveEndDate: registro.effectiveEndDate,
          channelIdentification: registro.channelIdentification,
          branchOfficeId: registro.branchOfficeId,
          purchaseMethodId: registro.purchaseMethodId,
          creditDuration: registro.creditDuration,
          creditDurationPeriod: registro.creditDurationPeriod,
          insuranceDuration: registro.insuranceDuration,
          insuranceDurationPeriod: registro.insuranceDurationPeriod,
          circulationAreaId: registro.circulationAreaId,
          driverName: "",
          driverLastName: "",
          birthDate: registro.effectiveEndDate, //revisar
          genderId: 0,
          maritalStatusId: 0,
          additionalPartDTO: registroChevySeguroAdditionalPartDTO,
        },
      ];
    const nuevoObjeto: ChevySeguroInfoNegocio = {
      login: "1707256788",
      password: "Ecuador2018",
      channelSellerIdentification: "1790978303001",
      nameBeneficiary: "COOP BASHALAN",
      payerType: "CHANNEL",
      brachOfficeId: 191,
      customerInformationDTO: registroChevySeguroCustomerInformationDTO,
      vehicleEmissionDTO: registroChevySeguroVehicleEmissionDTO,
    };
    console.log("este es mi registro >> ", nuevoObjeto);
    postServicio1(nuevoObjeto);
  }

  function setRegistroSrv2(
    _quotationId: string,
    _productId: number,
    _name: string
  ) {
    setMostrarProductos(false);
    setDataProductos(dataProdcutosVacio);
    setNameTipoProductos(_name);
    setIdTipoProductos(_productId);
    const registroProductSeletecdList: ChevySeguro2ProductSeletecdList[] = [
      {
        position: 0,
        productId: _productId,
      },
    ];
    const nuevoObjeto: ChevySeguroServicio2 = {
      quotationId: _quotationId,
      appliesPatrimonialProtection: true,
      productSeletecdList: registroProductSeletecdList,
    };
    console.log("este es mi registro srv2 >> ", nuevoObjeto);
    postServicio2(nuevoObjeto);
  }

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBancos() {
    try {
      await axios
        .get<ChevyseguroCatalogo[]>(
          "https://apisatelite.azurewebsites.net/chevysegurocatalogo/tabla/1"
        )
        .then((response) => {
          setDataBancos(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListado(ano: number, mes: number, idBodega: number) {
    console.log(
      "https://apisatelite.azurewebsites.net/chevyseguro/consultar/" +
        String(ano) +
        "/" +
        String(mes) +
        "/" +
        String(idBodega)
    );
    try {
      await axios
        .get<VChevyseguro[]>(
          "https://apisatelite.azurewebsites.net/chevyseguro/consultar/" +
            String(ano) +
            "/" +
            String(mes) +
            "/" +
            String(idBodega)
        )
        .then((response) => {
          setDataChevySeguros(response.data);
          setMostrar(true);
          console.log("respuesta del registro peticion >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaHSDelegado, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postServicio1(registro: ChevySeguroInfoNegocio) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/chevyseguro/srv1",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setDataRespuesta1(response.data);
          setQuotationId(response.data.objectResponse.quotationId);
          setDataRespuesta1Listado(
            response.data.objectResponse.vehicleProductDTOList[0].productDTOList
          );
          setMostrarDetalle(true);
          console.log("respuesta de chevyseguro >> ", response.data);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postServicio2(registro: ChevySeguroServicio2) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/chevyseguro/srv2",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setDataProductos(
            response.data.objectResponse.vehicleProductDTOList[0]
              .productPlanDTOList
          );
          //setDataRespuesta2(response.data);
          // setDataRespuesta1Listado(
          //   response.data.objectResponse.vehicleProductDTOList[0].productDTOList
          // );
          setMostrarProductos(true);
          console.log("respuesta de chevyseguro srv2 >> ", response.data);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postServicio3(registro: ChevySeguroServicio3) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/chevyseguro/srv3",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("respuesta de chevyseguro srv3 >> ", response.data);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
  };

  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
  };

  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
  };

  const handleChangePlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlan(Number(event.target.value));
    dataProductos.forEach((item) => {
      if (item.productPlanId === Number(event.target.value)) {
        setDataProductosItems(item.insuranceCarrierAgreementDTOs);
      }
    });
  };

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "fechaFactura",
      headerName: "F.Factura",
      editable: false,
      width: 80,
      valueGetter: ({ row }) => {
        return String(row.fechaFactura).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header",
      field: "factura",
      headerName: "Factura",
      editable: false,
      width: 175,
    },
    {
      headerClassName: "theme-header",
      field: "asesor",
      headerName: "Asesor",
      editable: false,
      width: 250,
    },
    {
      headerClassName: "theme-header",
      field: "familia",
      headerName: "Vh",
      editable: false,
      width: 100,
    },
    {
      headerClassName: "theme-header",
      field: "identification",
      headerName: "CI Cliente",
      editable: false,
      width: 110,
    },
    {
      headerClassName: "theme-header",
      field: "fullName",
      headerName: "Cliente",
      editable: false,
      width: 300,
    },
    {
      headerClassName: "theme-header",
      field: "abrir",
      headerName: "",
      editable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              sx={{ width: "100%" }}
              size="small"
              onClick={function (event) {
                setDataNegocio(params.row);
                setRegistroSrv1(params.row);
              }}
            >
              Abrir
            </Button>
          </div>
        );
      },
    },
  ];

  const columnsResp1: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "agreementBaseId",
      headerName: "Id",
      editable: false,
      width: 75,
    },
    // {
    //   headerClassName: "theme-header",
    //   field: "commercialRate",
    //   headerName: "commercialRate",
    //   editable: false,
    //   width: 75,
    // },
    {
      headerClassName: "theme-header",
      field: "name",
      headerName: "Producto",
      editable: false,
      width: 300,
    },
    {
      headerClassName: "theme-header",
      field: "insuranceCarrierName",
      headerName: "Aseguradora",
      editable: false,
      width: 330,
    },
    {
      headerClassName: "theme-header",
      field: "netPremium",
      headerName: "Prima neta",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return formatter.format(row.netPremium).replace("$", "$ ");
      },
    },
    {
      headerClassName: "theme-header",
      field: "monthlyPremium",
      headerName: "Mensual",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return formatter.format(row.monthlyPremium).replace("$", "$ ");
      },
    },
    {
      headerClassName: "theme-header",
      field: "grossPremium",
      headerName: "Total + IVA",
      editable: false,
      width: 100,
      valueGetter: ({ row }) => {
        return formatter.format(row.grossPremium).replace("$", "$ ");
      },
    },
    {
      headerClassName: "theme-header",
      field: "abrir",
      headerName: "",
      editable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              sx={{ width: "100%" }}
              size="small"
              onClick={function (event) {
                setProductoSeleccinado(params.row);
                setOpenDialog(true);
              }}
            >
              Seleccionar
            </Button>
          </div>
        );
      },
    },
  ];

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    height: "30%",
    display: "block",
  };

  const [value, setValue] = useState(0);

  const handleChange = (index: number) => {
    setValue(index);
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                CHEVYSEGUROS
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 0.5,
                  mb: 0.5,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="comboagencia"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, width: "19%", marginRight: "1%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "5%",
                  marginLeft: "3%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="comboagencia"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, width: "14%", marginRight: "1%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {listaAnos.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "5%",
                  marginLeft: "3%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="comboagencia"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, width: "14%", marginRight: "1%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {listaMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  //mt: 3,
                  //mb: 1,
                  width: "12%",
                  marginLeft: "4%",
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  getListado(ano, mes, agencia);
                }}
              >
                Consultar
              </Button>

              {mostrar ? (
                <>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                      mt: 3,
                      mb: 3,
                    }}
                  >
                    <DataGrid
                      //getRowId={(row) => row.supId}
                      sx={{ maxHeight: 420 }}
                      editMode="row"
                      rows={dataChevySeguros}
                      columns={columns}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      slots={{ toolbar: GridToolbarQuickFilter }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                      localeText={{
                        footerTotalRows: false,
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarQuickFilterPlaceholder: "Buscar",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
              {mostrarDetalle ? (
                <>
                  <Divider sx={{ mb: 2, mt: 4 }}>
                    <strong>Datos del Negocio</strong>
                  </Divider>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> F.Factura:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.fechaFactura.substring(0, 10)}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Factura:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.factura}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> Agencia:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.agencia}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Asesor:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.asesor}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> Marca:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.marca}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Familia:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.familia}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> Modelo:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.modelo}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Canal:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.canal}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> CI Cliente:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.identification}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Cliente:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                      marginLeft: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong> Correo:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 0.5, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.email}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "10%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Teléfono:</strong>
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5, mb: 4, width: "30%" }}
                    style={{ display: "inline-block" }}
                  >
                    {dataNegocio.telephoneMobile} {" - "}
                    {dataNegocio.telephone}
                  </Typography>

                  {dataRespuesta1Listado &&
                    dataRespuesta1Listado.map((registro, index) => (
                      <>
                        <Typography
                          sx={{
                            mt: 0.2,
                            mb: 0.2,
                            width: "19%",
                            marginLeft: "38%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          {registro.name}
                        </Typography>
                        <Button
                          variant="contained"
                          disabled={
                            idTipoProductos === registro.productId
                              ? true
                              : false
                          }
                          sx={{
                            width: "5%",
                            backgroundColor: "rgba(21,57,105,0.75)",
                            marginRight: "30%",
                            mb: 1,
                            mt: 1,
                          }}
                          onClick={function (event) {
                            setRegistroSrv2(
                              quotationId,
                              registro.productId,
                              registro.name
                            );
                          }}
                        >
                          ✔
                        </Button>
                      </>
                    ))}

                  {mostrarProductos ? (
                    <>
                      <Divider sx={{ mb: 2, mt: 4 }}>
                        <strong>{nameTipoProductos}</strong>
                      </Divider>
                      <Typography
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "6%",
                          marginLeft: "37%",
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Plan:
                      </Typography>
                      <TextField
                        id="comboagencia"
                        select
                        size="small"
                        margin="normal"
                        value={plan}
                        onChange={handleChangePlan}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "20%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 300,
                            },
                          },
                        }}
                      >
                        {dataProductos.map((option) => (
                          <MenuItem
                            key={option.productPlanId}
                            value={option.productPlanId}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box
                        sx={{
                          "& .theme-header": {
                            backgroundColor: "rgba(21,57,105,0.75)",
                            color: "rgba(255,255,255,1)",
                            mt: 3,
                            mb: 3,
                          },
                        }}
                      >
                        <DataGrid
                          getRowId={(row) => row.agreementBaseId}
                          sx={{
                            height: 400,
                          }}
                          editMode="row"
                          rows={dataProductosItems && dataProductosItems}
                          columns={columnsResp1}
                          disableColumnFilter
                          disableColumnSelector
                          disableDensitySelector
                          disableRowSelectionOnClick
                          // slots={{ toolbar: GridToolbarQuickFilter }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                            pagination: {
                              labelRowsPerPage: "Registros por página:",
                            },
                          }}
                          initialState={{
                            pagination: { paginationModel: { pageSize: 5 } },
                          }}
                          pageSizeOptions={[5, 10, 25]}
                          localeText={{
                            footerTotalRows: false,
                            noRowsLabel: "No se ha encontrado datos.",
                            noResultsOverlayLabel:
                              "No se ha encontrado ningún resultado",
                            toolbarColumns: "Columnas",
                            toolbarColumnsLabel: "Seleccionar columnas",
                            toolbarFilters: "Filtros",
                            toolbarFiltersLabel: "Ver filtros",
                            toolbarFiltersTooltipHide: "Quitar filtros",
                            toolbarFiltersTooltipShow: "Ver filtros",
                            toolbarQuickFilterPlaceholder: "Buscar",
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Paper>

            {/* modal */}
            <Modal
              open={openDialog}
              onClose={function (event) {
                setOpenDialog(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
                <div className="tabs">
                  <div className="tab-list">
                    <button
                      className={`tab-button ${value === 0 ? "active" : ""}`}
                      onClick={() => handleChange(0)}
                      {...a11yProps(0)}
                    >
                      Contado
                    </button>
                    <button
                      className={`tab-button ${value === 1 ? "active" : ""}`}
                      onClick={() => handleChange(1)}
                      {...a11yProps(1)}
                    >
                      Cheque
                    </button>
                    <button
                      className={`tab-button ${value === 2 ? "active" : ""}`}
                      onClick={() => handleChange(2)}
                      {...a11yProps(2)}
                    >
                      Débito
                    </button>
                    <button
                      className={`tab-button ${value === 3 ? "active" : ""}`}
                      onClick={() => handleChange(3)}
                      {...a11yProps(0)}
                    >
                      Visa
                    </button>
                    <button
                      className={`tab-button ${value === 4 ? "active" : ""}`}
                      onClick={() => handleChange(4)}
                      {...a11yProps(1)}
                    >
                      MasterCard
                    </button>
                    <button
                      className={`tab-button ${value === 5 ? "active" : ""}`}
                      onClick={() => handleChange(5)}
                      {...a11yProps(2)}
                    >
                      Transferencia
                    </button>
                    <button
                      className={`tab-button ${value === 6 ? "active" : ""}`}
                      onClick={() => handleChange(6)}
                      {...a11yProps(2)}
                    >
                      Resumen
                    </button>
                  </div>
                  {/* Contado */}
                  <TabPanel value={value} index={0}>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3Contado.reference}
                        onChange={dataChevySeguro3Contado}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 16 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3Contado.value}
                        onChange={dataChevySeguro3Contado}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 1 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Cheque */}
                  <TabPanel value={value} index={1}>
                    <Grid>
                      <TextField
                        label="Banco"
                        select
                        size="small"
                        margin="normal"
                        name="bankId"
                        value={chevySeguro3Cheque.bankId}
                        onChange={handleChangeCheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "45%",
                          marginLeft: "5%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataBancos.map((option) => (
                          <MenuItem key={option.chcId} value={option.chcId}>
                            {option.chcDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        label="Tipo cuenta"
                        select
                        size="small"
                        margin="normal"
                        name="accountTypeCode"
                        value={
                          chevySeguro3Cheque.accountTypeCode === "Ahorros"
                            ? 1
                            : chevySeguro3Cheque.accountTypeCode === "Corriente"
                            ? 2
                            : 0
                        }
                        onChange={handleChangeCheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataTipoCuenta.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.mes}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Cuenta"
                        type="number"
                        name="accountNumber"
                        value={Number(chevySeguro3Cheque.accountNumber)}
                        onChange={dataChevySeguro3Cheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Pago"
                        type="number"
                        name="numberPayment"
                        value={Number(chevySeguro3Cheque.numberPayment)}
                        onChange={dataChevySeguro3Cheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        margin="normal"
                        label="Cédula"
                        type="number"
                        name="identificationOwner"
                        value={chevySeguro3Cheque.identificationOwner}
                        onChange={dataChevySeguro3Cheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "20%",
                          marginLeft: "5%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Nombre"
                        name="nameOwner"
                        value={chevySeguro3Cheque.nameOwner}
                        onChange={dataChevySeguro3Cheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "43%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Relación"
                        name="relationshipWithInsuredPerson"
                        value={chevySeguro3Cheque.relationshipWithInsuredPerson}
                        onChange={dataChevySeguro3Cheque}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "25%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3Cheque.reference}
                        onChange={dataChevySeguro3Cheque}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3Cheque.value}
                        onChange={dataChevySeguro3Cheque}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 2 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Débito */}
                  <TabPanel value={value} index={2}>
                    <Grid>
                      <TextField
                        label="Banco"
                        select
                        size="small"
                        margin="normal"
                        name="bankId"
                        value={chevySeguro3Debito.bankId}
                        onChange={handleChangeDebito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "45%",
                          marginLeft: "5%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataBancos.map((option) => (
                          <MenuItem key={option.chcId} value={option.chcId}>
                            {option.chcDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        label="Tipo cuenta"
                        select
                        size="small"
                        margin="normal"
                        value={
                          chevySeguro3Debito.accountTypeCode === "Ahorros"
                            ? 1
                            : chevySeguro3Debito.accountTypeCode === "Corriente"
                            ? 2
                            : 0
                        }
                        onChange={handleChangeDebito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataTipoCuenta.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.mes}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Cuenta"
                        type="number"
                        name="accountNumber"
                        value={chevySeguro3Debito.accountNumber}
                        onChange={dataChevySeguro3Debito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Pago"
                        type="number"
                        name="numberPayment"
                        value={chevySeguro3Debito.numberPayment}
                        onChange={dataChevySeguro3Debito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        margin="normal"
                        label="Cédula"
                        type="number"
                        name="identificationOwner"
                        value={chevySeguro3Debito.identificationOwner}
                        onChange={dataChevySeguro3Debito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "20%",
                          marginLeft: "5%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Nombre"
                        name="nameOwner"
                        value={chevySeguro3Debito.nameOwner}
                        onChange={dataChevySeguro3Debito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "43%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Relación"
                        name="relationshipWithInsuredPerson"
                        value={chevySeguro3Debito.relationshipWithInsuredPerson}
                        onChange={dataChevySeguro3Debito}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "25%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3Debito.reference}
                        onChange={dataChevySeguro3Debito}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3Debito.value}
                        onChange={dataChevySeguro3Debito}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 2 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Visa */}
                  <TabPanel value={value} index={3}>
                    <Grid>
                      <TextField
                        label="Banco"
                        select
                        size="small"
                        margin="normal"
                        name="bankId"
                        value={chevySeguro3Visa.bankId}
                        onChange={handleChangeVisa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "45%",
                          marginLeft: "5%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataBancos.map((option) => (
                          <MenuItem key={option.chcId} value={option.chcId}>
                            {option.chcDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        label="Tipo cuenta"
                        select
                        size="small"
                        margin="normal"
                        value={
                          chevySeguro3Visa.accountTypeCode === "Ahorros"
                            ? 1
                            : chevySeguro3Visa.accountTypeCode === "Corriente"
                            ? 2
                            : 0
                        }
                        onChange={handleChangeVisa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataTipoCuenta.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.mes}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Cuenta"
                        type="number"
                        name="accountNumber"
                        value={chevySeguro3Visa.accountNumber}
                        onChange={dataChevySeguro3Visa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Pago"
                        type="number"
                        name="numberPayment"
                        value={chevySeguro3Visa.numberPayment}
                        onChange={dataChevySeguro3Visa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "14%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        margin="normal"
                        label="Cédula"
                        type="number"
                        name="identificationOwner"
                        value={chevySeguro3Visa.identificationOwner}
                        onChange={dataChevySeguro3Visa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "20%",
                          marginLeft: "5%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Nombre"
                        name="nameOwner"
                        value={chevySeguro3Visa.nameOwner}
                        onChange={dataChevySeguro3Visa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "43%",
                          marginLeft: "1%",
                        }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Relación"
                        name="relationshipWithInsuredPerson"
                        value={chevySeguro3Visa.relationshipWithInsuredPerson}
                        onChange={dataChevySeguro3Visa}
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "25%",
                          marginLeft: "1%",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3Visa.reference}
                        onChange={dataChevySeguro3Visa}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3Visa.value}
                        onChange={dataChevySeguro3Visa}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 2 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Master */}
                  <TabPanel value={value} index={4}>
                    <Grid>
                      <TextField
                        label="Banco"
                        select
                        size="small"
                        margin="normal"
                        name="bankId"
                        value={chevySeguro3MasterCard.bankId}
                        onChange={handleChangeMasterCard}
                        sx={{ width: "45%", marginLeft: "5%", mt: 1, mb: 1 }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataBancos.map((option) => (
                          <MenuItem key={option.chcId} value={option.chcId}>
                            {option.chcDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        label="Tipo cuenta"
                        select
                        size="small"
                        margin="normal"
                        name="accountTypeCode"
                        value={
                          chevySeguro3MasterCard.accountTypeCode === "Ahorros"
                            ? 1
                            : chevySeguro3MasterCard.accountTypeCode ===
                              "Corriente"
                            ? 2
                            : 0
                        }
                        onChange={handleChangeMasterCard}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataTipoCuenta.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.mes}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Cuenta"
                        type="number"
                        name="accountNumber"
                        value={chevySeguro3MasterCard.accountNumber}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Pago"
                        type="number"
                        name="numberPayment"
                        value={chevySeguro3MasterCard.numberPayment}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        margin="normal"
                        label="Cédula"
                        type="number"
                        name="identificationOwner"
                        value={chevySeguro3MasterCard.identificationOwner}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ width: "20%", marginLeft: "5%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Nombre"
                        name="nameOwner"
                        value={chevySeguro3MasterCard.nameOwner}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ width: "43%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Relación"
                        name="relationshipWithInsuredPerson"
                        value={
                          chevySeguro3MasterCard.relationshipWithInsuredPerson
                        }
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ width: "25%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3MasterCard.reference}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3MasterCard.value}
                        onChange={dataChevySeguro3MasterCard}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 2 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Transfer */}
                  <TabPanel value={value} index={5}>
                    <Grid>
                      <TextField
                        label="Banco"
                        select
                        size="small"
                        margin="normal"
                        name="bankId"
                        value={chevySeguro3Transferencia.bankId}
                        onChange={handleChangeTransferencia}
                        sx={{ width: "45%", marginLeft: "5%", mt: 1, mb: 1 }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataBancos.map((option) => (
                          <MenuItem key={option.chcId} value={option.chcId}>
                            {option.chcDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        label="Tipo cuenta"
                        select
                        size="small"
                        margin="normal"
                        name="accountTypeCode"
                        value={
                          chevySeguro3Transferencia.accountTypeCode ===
                          "Ahorros"
                            ? 1
                            : chevySeguro3Transferencia.accountTypeCode ===
                              "Corriente"
                            ? 2
                            : 0
                        }
                        onChange={handleChangeTransferencia}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 450,
                            },
                          },
                        }}
                      >
                        {dataTipoCuenta.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.mes}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Cuenta"
                        type="number"
                        name="accountNumber"
                        value={chevySeguro3Transferencia.accountNumber}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="N° Pago"
                        type="number"
                        name="numberPayment"
                        value={chevySeguro3Transferencia.numberPayment}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ width: "14%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        margin="normal"
                        label="Cédula"
                        type="number"
                        name="identificationOwner"
                        value={chevySeguro3Transferencia.identificationOwner}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ width: "20%", marginLeft: "5%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Nombre"
                        name="nameOwner"
                        value={chevySeguro3Transferencia.nameOwner}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ width: "43%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        margin="normal"
                        label="Relación"
                        name="relationshipWithInsuredPerson"
                        value={
                          chevySeguro3Transferencia.relationshipWithInsuredPerson
                        }
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ width: "25%", marginLeft: "1%", mt: 1, mb: 1 }}
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        size="small"
                        label="Referencia"
                        name="reference"
                        value={chevySeguro3Transferencia.reference}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ marginLeft: "5%", width: "75%", mt: 1, mb: 1 }}
                      />
                      <TextField
                        size="small"
                        label="Valor"
                        type="number"
                        name="value"
                        value={chevySeguro3Transferencia.value}
                        onChange={dataChevySeguro3Transferencia}
                        sx={{ marginLeft: "1%", width: "14%", mt: 1, mb: 2 }}
                      />
                    </Grid>
                  </TabPanel>
                  {/* Resumen */}
                  <TabPanel value={value} index={6}>
                  <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Contado: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Contado.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cheque: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Cheque.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Débito: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Debito.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Visa: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Visa.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>MasterCard: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3MasterCard.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{width: "35%",marginLeft: "10%"}}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Transferencia: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Transferencia.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>
                    <Divider sx={{mt:1, mb: 2}}/>
                    <Typography
                      sx={{width: "15%",marginLeft: "10%"}}
                    >
                      <strong>Suma: </strong>
                      {formatter
                        .format(
                          Number(chevySeguro3Contado.value) +
                            Number(chevySeguro3Cheque.value) +
                            Number(chevySeguro3Debito.value) +
                            Number(chevySeguro3Visa.value) +
                            Number(chevySeguro3MasterCard.value) +
                            Number(chevySeguro3Transferencia.value)
                        )
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{width: "15%",marginLeft: "10%"}}
                    >
                      <strong>Total: </strong>
                      {formatter
                        .format(productoSeleccionado.grossPremium)
                        .replace("$", "$ ")}
                    </Typography>

                    <Button
                      variant="contained"
                      disabled={
                        Number(chevySeguro3Contado.value) +
                          Number(chevySeguro3Cheque.value) +
                          Number(chevySeguro3Debito.value) +
                          Number(chevySeguro3Visa.value) +
                          Number(chevySeguro3MasterCard.value) +
                          Number(chevySeguro3Transferencia.value) ===
                        Number(productoSeleccionado.grossPremium)
                          ? false
                          : true
                      }
                      sx={{ marginLeft: "44%", width: "12%" }}
                    >
                      Enviar
                    </Button>
                  </TabPanel>
                </div>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
