import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const enum themePalette {
  BG = "#F4F8FE",
  azulcorp = "#174391",
  FONTGLOBAL = 'Arial Narrow',//"'JetBrains Mono', monospace",
  ERROR_MAIN = '#f44336',
  BG_ERROR_MAIN = 'rgba(244,67,54,8.1)'
}

export const theme = createMuiTheme({
  palette: {
    mode: "light",
    background: {
      default: themePalette.BG,
    },
    primary: {
      main: themePalette.azulcorp,
    },
  },
  typography: {
    fontFamily: themePalette.FONTGLOBAL,
  },
  components: {
    MuiButton:{
        defaultProps: {
            style: {
                textTransform: "none",
                borderRadius: "0.5em"
            }
        }
    },
    MuiAlert:{
        defaultProps: {
            style: {
                borderRadius: "0.8em",
                fontSize: "1em"
            },
        },
        styleOverrides:{
            standardError:{
                //border:'1px solid ${theme}'
            }
        }
    }
  }
});