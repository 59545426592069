import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import chevrolet from "../../assets/chevrolet.png";
import vw from "../../assets/vw.png";
import gac from "../../assets/gac.png";
import acc from "../../assets/accesorio.png";
import antena from "../../assets/antena.png";
import barra from "../../assets/barra.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  CrmUafeType,
  ModeloAccesorioType,
  ModeloFamiliaType,
  TablaRentaAccNuevoType,
  TablaRentaAccType,
} from "../../types";

export const CotizacionPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getListadoModeloFamilia();      
    } else {
      setName("");
    }
  }, [account]);
  

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const params = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [listaAccesorios, setListaAccesorios] = useState<ModeloAccesorioType[]>(
    []
  );
  const [listaTablaRentaAcc, setListaTablaRentaAcc] = useState<TablaRentaAccType[]>(
    []
  );


  async function eliminarRegistro() {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .delete(
          "https://apisatelite.azurewebsites.net/tablarentaacc/crm/"+ String(params.idcrm)
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
          guardar();
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRegistro(parametroGuardar: TablaRentaAccNuevoType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaacc",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoTablaRentaAcc(lista: ModeloAccesorioType[]) {
    try {
      await axios
        .get<TablaRentaAccType[]>(
          "https://apisatelite.azurewebsites.net/tablarentaacc/consultar/" +
            String(params.idcrm)
        )
        .then((response) => {
          setListaTablaRentaAcc(response.data);
          setear(response.data, lista)
          console.log("mi respuesta de Tabla renta acc: ", response.data);
        })
        .catch((error) => {
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoModeloFamilia() {
    try {
      await axios
        .get<ModeloAccesorioType[]>(
          "https://apisatelite.azurewebsites.net/vmodeloaccesorio/consultar/" +
            String(params.idcotitem)
        )
        .then((response) => {
          //setListaAccesorios(response.data);
          getListadoTablaRentaAcc(response.data);
          console.log("mi respuesta de modelo familia: ", response.data);
        })
        .catch((error) => {
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const agregarAcc = (index: number, valor: number) => {
    const newArray = [...listaAccesorios]; // Crear una copia del array
    newArray[index].esAgregado = valor; // Incrementar la edad del objeto en la posición index
    setListaAccesorios(newArray); // Actualizar el estado con el nuevo array
  };

  async function setear(listaTablaAcc : TablaRentaAccType[], listaAcc: ModeloAccesorioType[]) { 
    console.log("Accesorios >>> ",listaAccesorios);
    console.log("TablaACC >>> ",listaTablaAcc);   
    listaAcc.forEach(function (general) {
      listaTablaAcc.forEach(function (tabla) {
        if (general.idCotItemAcc === tabla.idCotItem) {
          general.esAgregado = 1;
        }
      })
    })
    setListaAccesorios(listaAcc);
  //  {listaTablaRentaAcc.some((registro) => registro.idCotItem === item.idCotItemAcc) ? "1":"0"}
  }
  

  async function guardar() {
    listaAccesorios.forEach(function (value) {
      if (value.esAgregado === 1 ){
        const nuevoRegistro: TablaRentaAccNuevoType = {
          idCrm: String(params.idcrm),
          idCotItem: value.idCotItemAcc,
          traCodigo: value.accCodigo,
          traDescripcion : value.accDescripcion,
          traPvp: value.precio,
          traCosto: value.costoCompra,
          traCantidad: value.cantidad
        };
        console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
        postGuardarRegistro(nuevoRegistro);
      }      
    });
    setOpenDialog(true);
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                Catalogo de Accesorios {/* ACCESORIOS PARA {params.idcotitem} */}
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.7)",
                mt: 1,
              }}
            >
              <Grid sx={{ marginLeft: 5 }}>
                {listaAccesorios.map((item, index) => (
                  <Card
                    sx={{
                      width: 240,
                      height: 360,
                      display: "inline-block",
                      marginRight: 2,
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140 }}
                      image={
                        item.accCodigo === "26235082" ? antena : item.accCodigo === "98553338" ? barra : acc                        
                        // item.marca === "CHEVROLET"
                        //   ? chevrolet
                        //   : item.marca === "GAC"
                        //   ? gac
                        //   : vw
                        
                      }
                      title="Corporacion Proauto"
                    />
                    <CardContent>
                      <Typography
                        textAlign={"center"}
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{ height: 60 }}
                      >
                        { item.accDescripcion}
                      </Typography>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                       <strong>Código: </strong>{item.accCodigo} 
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Cantidad set:</strong> {item.cantidad}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>PVP:</strong>{" "}
                        <strong>
                          {formatter
                            .format(Number(item.precio) * Number(item.cantidad))
                            .replace("$", "$  ")}{" "}
                        </strong>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Grid
                        sx={{ alignContent: "center", alignItems: "center" }}
                      >
                        <Button
                          variant={
                            item.esAgregado === 1 ? "outlined" : "contained"
                          }
                          sx={{ marginLeft: item.esAgregado === 1 ? 10 : 9 }}
                          onClick={function (event) {
                            agregarAcc(index, item.esAgregado === 1 ? 0 : 1);
                          }}
                        >
                          {item.esAgregado === 1 ? "Quitar" : "Agregar"}
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                ))}
              </Grid>
              <Button
                variant="contained"
                sx={{ marginLeft: "42%", width: "16%" }}
                onClick={function (event) {eliminarRegistro(); console.log("estoy guardando")}}
              >
                Guardar
              </Button>

              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{ mt: 3, mb: 1 }}
              >
                © Corporación Proauto 2024
              </Typography>
            </Paper>
            <Dialog
              open={openDialog}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Datos guardados correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href={`/ventas/index/${params.idcrm}`}
                  onClick={function (event) {
                    setOpenDialog(false);
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <></>;
  }
};
