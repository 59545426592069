import React, { useState } from 'react';
import './CarouselUno.css'; // Importa tu archivo CSS

interface CarouselProps {
  images: string[];
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className="carousel-containerUno">
      <button className="prevUno" onClick={goToPrevSlide}>‹</button>
      <img src={images[currentIndex]} alt="slide" className="imageUno" />
      <button className="nextUno" onClick={goToNextSlide}>›</button>
    </div>
  );
};

export default Carousel;
