import Typography from "@mui/material/Typography";
import NavBar from "./NavBar";
import NavbarNew from "./NavBarNew";

type Props = {
    children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({children}) => {
    return (
        <>
            <NavbarNew />
            {/* <Typography variant="h5" align="center">Welcome to the Microsoft Authentication Library For React Quickstart</Typography>
            <br/>
            <br/> */}
            {children}
        </>
    );
};