import React, { useState } from 'react';
import './CarouselTres.css'; // Importa tu archivo CSS

interface CarouselProps {
  images: string[];
  onImageClick: (index: number) => void;
   // Función de devolución de llamada para el clic en la imagen
}

const Carousel: React.FC<CarouselProps> = ({ images, onImageClick }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const goToNextSlide = () => {
    if (startIndex + 1 <= images.length - 4) {
      setStartIndex(startIndex + 1);
    }
  };

  const goToPrevSlide = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleImageClick = (index: number) => {
    onImageClick(startIndex + index);
    setActiveIndex(index);
  };

  

  return (
    <div className="carousel-container">
      <button className="prev" onClick={goToPrevSlide}>‹</button>
      <div className="carousel-images" >
        {images.slice(startIndex, startIndex + 4).map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`slide-${startIndex + index}`}
            className={`image ${activeIndex === index ? 'active' : ''}`}// Aplica siempre la clase 'active' a todas las imágenes visibles
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>
      <button className="next" onClick={goToNextSlide}>›</button>
    </div>
  );
};

export default Carousel;
