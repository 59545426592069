import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  AnoType,
  EventosCRMType,
  UpdateEventosCRMType,
  VSegurosFI,
  marcaType,
  zonaType,
} from "../../types";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTheme } from "@mui/material/styles";

export const EventosPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getListado()
    getMarcas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);

      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const listadoMarcas: marcaType[] = [];
  const listadoZona: zonaType[] = [];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  function setParametro(
    eveId: number,
    eveDescripcion: string,
    idMarca: number,
    eveActivo: number
  ) {
    console.log("tabla a guardar >> ");
    if (eveId === 0) {
      const nuevaTabla: EventosCRMType = {
        eveDescripcion: eveDescripcion,
        idMarca: idMarca,
        eveActivo: eveActivo,
      };
      console.log("datos a guardar >> ", JSON.stringify(nuevaTabla, null, 4));
      postGuardarRegistro(nuevaTabla);
    } else {
      const nuevaTabla: UpdateEventosCRMType = {
        eveId: eveId,
        eveDescripcion: eveDescripcion,
        idMarca: idMarca,
        eveActivo: eveActivo,
      };
      console.log(
        "datos a actualizar >> ",
        JSON.stringify(nuevaTabla, null, 4)
      );
      postUpdateRegistro(nuevaTabla);
    }
  }

  async function postGuardarRegistro(registroGuardar: EventosCRMType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          getListado();
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postUpdateRegistro(registroGuardar: UpdateEventosCRMType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          getListado()
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns: GridColDef[] = [
   // { field: "eveId", headerName: "ID", editable: false, width: 125 },
    {
      field: "eveDescripcion",
      headerName: "Descripción",
      editable: false,
      width: 450,
    },
    {
      field: "idMarca",
      headerName: "Marca",
      editable: false,
      width: 225,
      valueGetter: ({ row }) => {
        return row.idMarca === 1 ? "Chevrolet" : row.idMarca === 2 ? "GAC": "Volkswagen";
      },
    },
   {
      field: "eve_activo",
      headerName: "Activo",
      editable: false,
      width: 125,
      valueGetter: ({ row }) => {
        return row.eveActivo === true ? "Si" : "No";
      },
    },
    {
      field: "action",
      headerName: "Editar",
      sortable: false,
      width: 125,
      renderCell: (params) => {
        return (
          <Button
            onClick={function (event) {getRegistro(Number(params.row.eveId))}}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
    // {
    //   field: "psto",
    //   headerName: "% Presupuesto",
    //   editable: false,
    //   width: 100,
    //   headerAlign: "right",
    //   align: "right",
    //   valueGetter: ({ row }) => {
    //     return String(row.psto) + "%";
    //   },
    // },
    // {
    //   field: "estado1",
    //   headerName: "",
    //   editable: false,
    //   width: 50,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row.psto < 30 ? (
    //           <RadioButtonCheckedIcon sx={{ color: red[500] }} />
    //         ) : params.row.psto >= 30 && params.row.psto < 60 ? (
    //           <RadioButtonCheckedIcon sx={{ color: orange[500] }} />
    //         ) : params.row.psto >= 60 ? (
    //           <RadioButtonCheckedIcon sx={{ color: green[500] }} />
    //         ) : (
    //           <RadioButtonCheckedIcon sx={{ color: grey[500] }} />
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZona);
  const [marca, setMarca] = React.useState(1);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  const [cheked, setCheked] = React.useState({
    activo: true,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  }; 

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  
  const [data, setData] = React.useState<UpdateEventosCRMType>({
    eveId: 0,
    eveDescripcion: "",
    idMarca: 0,
    eveActivo: 0,
  });

  const dataEvento = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });    
  };

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<UpdateEventosCRMType[]>([]);

  async function getListado() {
    try {
      await axios
        .get<UpdateEventosCRMType[]>(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm" 
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
          handleClose()
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRegistro(id:number) {
    try {
      await axios
        .get<UpdateEventosCRMType>(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm/"+String(id) 
        )
        .then((response) => {
          setData(response.data);
          setMarca(response.data.idMarca);
          handleOpen()
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function reiniciarRegistro (){
    const nuevo: UpdateEventosCRMType = {
      eveId: 0,
      eveDescripcion: "",
      idMarca: 0,
      eveActivo: 1,
    };
    setMarca(0);
    setData(nuevo);
    handleOpen();
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Eventos - Gestión Externa
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Grid flexDirection="row">
                <Box sx={{ bgcolor: "background.paper" }}>
                <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          marginLeft: "80%",
                          width: "12%",
                        }}
                        onClick={function (event) {
                          reiniciarRegistro()
                        }}
                      >
                        Nuevo
                      </Button>
                  <DataGrid
                    getRowId={(row) => row.eveId}
                    sx={{ height: 600 }}
                    editMode="row"
                    rows={listaDatos}
                    columns={columns}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pageSizeOptions={[10, 20, 30]}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    pagination
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                  />

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModal}>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2, mb: 2, textAlign: "center" }}
                      >
                        Detalle del Evento
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Evento:
                        </Typography>
                        <TextField
                          name="eveDescripcion"
                          value={data.eveDescripcion}
                          size="small"
                          margin="normal"
                          onChange={dataEvento}
                          sx={{ mt: 1, mb: 1, width: "60%" }}
                        ></TextField>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Marca:
                        </Typography>
                        <TextField
                          id="outlined-select-currency"
                          select
                          size="small"
                          margin="normal"
                          value={marca}
                          onChange={handleChangeMarca}
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            width: "60%",
                          }}
                          SelectProps={{
                            MenuProps: {
                              disableScrollLock: true,
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                        >
                          {dataMarca.map((option) => (
                            <MenuItem key={option.marId} value={option.marId}>
                              {option.marNombre}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Activo:
                        </Typography>
                        <Switch
                          checked={cheked.activo}
                          onChange={handleCheked}
                          name="activo"
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            marginLeft: "2%",
                            marginRight: "2%",
                          }}
                        />
                         <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          <strong>{cheked.activo === true ? "Activo":"Inactivo"} </strong>
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          marginLeft: "40%",
                          width: "20%",
                        }}
                        onClick={function (event) {
                          setParametro(data.eveId,data.eveDescripcion,marca,cheked.activo === true? 1 : 0);
                        }}
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Modal>
                
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
