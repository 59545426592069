import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import {
  AnoType,
  MesType,
  PlantillaMUCResumenType,
  PlantillaMUCType,
  bodegaType,
  canalNegocioType,
} from "../../types";
import { GridCellParams } from "@mui/x-data-grid";

export const MUCDetallePage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    getBodegas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);

      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  type marcaType = {
    marId: number;
    marNombre: string;
  };
  type lineaType = {
    linId: number;
    linNombre: string;
  };

  const listadoMarcas: marcaType[] = [];
  const listadoAgencias: bodegaType[] = [];
  const listadoLineas: lineaType[] = [
    // { linId: 1, linNombre: "Carc" },
    { linId: 2, linNombre: "Livianos" },
    { linId: 3, linNombre: "Pesados" },
    // { linId: 4, linNombre: "Flotas" },
  ];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];

  const listadoCanales: canalNegocioType[] = [
    { id: 1, descripcion: "SHOWROOM" },
    { id: 2, descripcion: "DIGITAL" },
    { id: 3, descripcion: "BBDD" },
    { id: 4, descripcion: "GESTION EXTERNA" },
    { id: 5, descripcion: "REFERIDOS" },
    { id: 6, descripcion: "RECOMPRA" },
    { id: 7, descripcion: "LLAMADAS" },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [listaPlantilla2, setListaPlantilla2] = useState<PlantillaMUCType[]>([]);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataCanal, setDataCanal] =
    useState<canalNegocioType[]>(listadoCanales);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [dataLinea, setDataLinea] = useState<lineaType[]>(listadoLineas);
  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(2023);
  const [mes, setMes] = React.useState(12);
  const [linea, setLinea] = React.useState(2);
  const [agencia, setAgencia] = React.useState(0);
  const [canal, setCanal] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  var totalVentas: number = 0;
  var totalPac: number = 0;
  var totalProsBruta: number = 0;
  var totalGestion: number = 0;
  var totalProsValida: number = 0;
  var totalCita: number = 0; 
  var totalCitaConfirmada: number = 0;
  var totalTrafico: number = 0;
  var totalTestDrive: number = 0;
  var totalCotizacion: number = 0;
  var totalSolicitud: number = 0;
  var totalSolicitudApro: number = 0;
  var totalCredito: number = 0;

  function sumarTotales(lista: PlantillaMUCType[]) {
    lista.forEach(function (value) {
      const ventasUnit = Number(value.muc_ventas);
      totalVentas = totalVentas + ventasUnit;

      const ventasPac = Number(value.muc_pac);
      totalPac = totalPac + ventasPac;

      const ventasProsBruta = Number(value.muc_prospeccion_bruta);
      totalProsBruta = totalProsBruta + ventasProsBruta;

      const ventasGestion = Number(value.muc_gestion);
      totalGestion = totalGestion + ventasGestion;

      const ventasProsValida = Number(value.muc_prospeccion_valida);
      totalProsValida = totalProsValida + ventasProsValida;

      const ventasCita = Number(value.muc_cita);
      totalCita = totalCita + ventasCita;

      const ventasCitaConfiramda = Number(value.muc_cita_confirmada);
      totalCitaConfirmada = totalCitaConfirmada + ventasCitaConfiramda;

      const ventasTrafico = Number(value.muc_trafico);
      totalTrafico = totalTrafico + ventasTrafico;

      const ventasTestDrive = Number(value.muc_test_drive);
      totalTestDrive = totalTestDrive + ventasTestDrive;

      const ventasCotizacion = Number(value.muc_cotizacion);
      totalCotizacion = totalCotizacion + ventasCotizacion;

      const ventasSolicitud = Number(value.muc_solicitud);
      totalSolicitud = totalSolicitud + ventasSolicitud;

      const ventasSolicitudApro = Number(value.muc_solicitud_aprobada);
      totalSolicitudApro = totalSolicitudApro + ventasSolicitudApro;

      const ventasCredito = Number(value.muc_credito);
      totalCredito = totalCredito + ventasCredito;

      console.log(totalVentas);
    });

    let regitroTotal: PlantillaMUCType = {
      muc_id: 0,
      muc_anio: 0,
      muc_mes: 0,
      marca: "",
      zona: "",
      agencia: "",
      id_asesor: "",
      nombre_asesor: "TOTAL",
      canal: "",
      linea: "",
      muc_pac: totalPac,
      muc_ventas: totalVentas,
      muc_peso_cierre: 0,
      muc_peso_trafico: 0,
      muc_peso_prospeccion_valida: 0,
      muc_peso_gestion: 0,
      muc_peso_cita: 0,
      muc_peso_cita_confirmada: 0,
      muc_peso_test: 0,
      muc_peso_cotizacion: 0,
      muc_peso_solicitud: 0,
      muc_peso_aprobado: 0,
      muc_peso_vta_credito: 0,
      muc_prospeccion_bruta: totalProsBruta,
      muc_gestion: totalGestion,
      muc_prospeccion_valida: totalProsValida,
      muc_cita: totalCita,
      muc_cita_confirmada: totalCitaConfirmada,
      muc_trafico: totalTrafico,
      muc_test_drive: totalTestDrive,
      muc_cotizacion: totalCotizacion,
      muc_solicitud: totalSolicitud,
      muc_solicitud_aprobada: totalSolicitudApro,
      muc_credito: totalCredito,
    };

    lista.push(regitroTotal);

    setListaPlantilla2(lista);
  }

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeLinea = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLinea(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeCanal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanal(Number(event.target.value));
    setMostrar(false);
  };

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columnsAsesor: GridColDef[] = [
    {
      field: "nombre_asesor",
      headerName: "Asesor",
      editable: false,
      width: 225,
    },
    {
      field: "muc_pac",
      headerName: "PAC",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_ventas",
      headerName: "Ventas",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_prospeccion_bruta",
      headerName: "Pros Bruta",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_gestion",
      headerName: "Gestion",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_prospeccion_valida",
      headerName: "Pros Valida",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_cita",
      headerName: "Cita",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_cita_confirmada",
      headerName: "Cita Conf",
      editable: false,
      width: 75,
      align: "right",
    },

    {
      field: "muc_trafico",
      headerName: "Trafico",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_test_drive",
      headerName: "Test Drive",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_cotizacion",
      headerName: "Cotizacion",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_solicitud",
      headerName: "Solicitud",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_solicitud_aprobada",
      headerName: "Solicitud Apro",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      field: "muc_credito",
      headerName: "Credito",
      editable: false,
      width: 75,
      align: "right",
    },
  ];

  const columnsShowRoom: GridColDef[] = [
    {
      field: "nombre_asesor",
      headerName: "Asesor",
      editable: false,
      width: 280,
    },
    {
      field: "muc_pac",
      headerName: "PAC",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_ventas",
      headerName: "Ventas",
      editable: false,
      width: 90,
      align: "right",
    },

    {
      field: "muc_trafico",
      headerName: "Trafico",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_test_drive",
      headerName: "Test Drive",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_cotizacion",
      headerName: "Cotizacion",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_solicitud",
      headerName: "Solicitud",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_solicitud_aprobada",
      headerName: "Solicitud Apro",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      field: "muc_credito",
      headerName: "Credito",
      editable: false,
      width: 90,
      align: "right",
    },
  ];

  async function getListado2(
    marca: string,
    anio: number,
    mes: number,
    linea: string,
    linea2: string,
    idBodega: number,
    idCanal: number
  ) {
    try {
      await axios
        .get<PlantillaMUCType[]>(
          "https://apisatelite.azurewebsites.net/vcrmmetasmuc/vista/" +
            marca +
            "/" +
            String(anio) +
            "/" +
            String(mes) +
            "/" +
            linea +
            "/" +
            linea2 +
            "/" +
            String(idBodega) +
            "/" +
            String(idCanal)
        )
        .then((response) => {
          sumarTotales(response.data);
          //setListaPlantilla(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Detalle de Registros Cargados
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Linea:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={linea}
                onChange={handleChangeLinea}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
              >
                {dataLinea.map((option) => (
                  <MenuItem key={option.linId} value={option.linId}>
                    {option.linNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Canal:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={canal}
                onChange={handleChangeCanal}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
              >
                {dataCanal.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                }}
                onClick={function (event) {
                  getListado2(
                    marca === 1
                      ? "Chevrolet"
                      : marca === 2
                      ? "GAC"
                      : "Volkswagen",
                    2023,
                    12,
                    linea === 2 ? "Livianos" : "Pesados",
                    linea === 2 ? "Flotas" : "Pesados",
                    agencia,
                    canal
                  );
                }}
              >
                Consultar
              </Button>
            </Paper>

            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    //height: 300,
                    width: "100%",
                    "& .verdeoscuro": {
                      backgroundColor: "#BCF39B",
                      color: "#1a3e72",
                    },
                    "& .verde": {
                      backgroundColor: "#CFFFB2",
                      color: "#1a3e72",
                    },
                    "& .naranja": {
                      backgroundColor: "#FEE777",
                      color: "#1a3e72",
                    },
                    "& .amarillo": {
                      backgroundColor: "#FEFFCF",
                      color: "#1a3e72",
                    },
                    "& .gris": {
                      backgroundColor: "#DEDEDE",
                      color: "#1a3e72",
                    },
                    "& .TOTAL": {
                      fontPalette: "dark",
                      fontSize: 16,
                      fontWeight: 'bold'
                    },
                  }}
                >
                  <DataGrid
                    getRowClassName={(params) => `${params.row.nombre_asesor}`}
                    getCellClassName={(
                      params: GridCellParams<any, any, number>
                    ) => {
                      if (params.value === "TOTAL") {
                        return "totales";
                      }
                      if (params.field === "nombre_asesor") {
                        return "";
                      }
                      if (params.field === "muc_pac") {
                        return "verde";
                      }
                      if (params.field === "muc_ventas") {
                        return "verdeoscuro";
                      }
                      if (
                        params.field === "muc_prospeccion_bruta" ||
                        params.field === "muc_gestion" ||
                        params.field === "muc_prospeccion_valida" ||
                        params.field === "muc_cita" ||
                        params.field === "muc_cita_confirmada"
                      ) {
                        if (canal === 1) {
                          return "gris";
                        } else {
                          return "naranja";
                        }
                      }
                      if (
                        params.field === "muc_trafico" ||
                        params.field === "muc_test_drive" ||
                        params.field === "muc_cotizacion" ||
                        params.field === "muc_solicitud" ||
                        params.field === "muc_solicitud_aprobada" ||
                        params.field === "muc_credito"
                      ) {
                        return "amarillo";
                      }
                      return "";
                      // return params.value >= 15 ? 'hot' : 'cold';
                    }}
                    getRowId={(row) => row.muc_id}
                    sx={{ height: 600 }}
                    editMode="row"
                    rows={listaPlantilla2}
                    columns={canal === 1 ? columnsShowRoom : columnsAsesor}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pageSizeOptions={[10, 20, 30]}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                  />
                </Box>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
